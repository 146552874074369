* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .tarifBox3 .tarifBox3list ul li {
    list-style-image: url(./tarif_img/Check.svg);
  }
  .tarifBox4 .tarifBox4list ul li {
    list-style-image: url(./tarif_img/Check.svg);
  }
  .tarifBox5 .tarifBox5list ul li {
    list-style-image: url(./tarif_img/Check.svg);
  }
  .tarifBox2 .tarifBox2Box1list ul li {
    list-style-image: url(./tarif_img/Check.svg);
  }
  .tarifBox2 .tarifBox2Box2list ul li {
    list-style-image: url(./tarif_img/Check.svg);
  }
  .tarifBox2 .tarifBox2Box3list ul li {
    list-style-image: url(./tarif_img/Check.svg);
  }
  .tarifBox2 .tarifBox2Box4list ul li {
    list-style-image: url(./tarif_img/Check.svg);
  }
  a{
    text-decoration: none;
    color: #ffffff;
  }

 
  
  @media (max-width: 2560px) {
    .tarif{
     padding: 0 100px;
    }
     h1 {
       font-weight: 700;
       font-size: 70px;
       color: #0e082b;
     }
    
     .tarifBox1{
     padding-top: 90px;
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     position: relative;
     }
     .tarifBox1 .tarifBox1ti{
      display: flex;
     }
     .tarifBox1img{
         width: 100%;
         
     }
     .tarifBox1img img{
         width: 150%;
         margin-top: -6%;
         margin-left: -43%;
         position: relative;
         z-index: -1;
     }
     .tarifBox1 .tarifBox1text {
      position: relative;
      top: 300px;
     }
     .tarifBox1 .tarifBox1text h1 {
        width: 55%;
     }
     .tarifBox1 .tarifBox1text p {
       width: 60%;  
     }
     .tarifBox2{
       width: 100%;
       display: flex;
       position: relative;
       z-index: 0;
       margin-top: -15%;
     }
     .tarifBox2 h2{
       font-size: 36px;
       margin: 5% 0; 
       width: 88%;
 
     }
     .tarifBox2 p{
       color: #878496;
       margin-top: 10px; 
       width: 100%;
       font-size: 30px;
     }
     .tarifBox2 h3{
       color: #5231e4;;
       margin: 10% 0; 
       width: 100%;
       font-size: 40px;
     }
     .tarifBox2 .tarifBox2Box1{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 10px 10px 10px;
       width: 100%;
       height: 100%;

     }
     .tarifBox2 .tarifBox2Box1 ul li{
       font-size: 36px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       
     }
      .tarifBox2Box1Button button {
       font-size: 35px;
     padding: 12px 20px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% 0;
     cursor: pointer;
      
     }
     .tarifBox2 .tarifBox2Box2{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 10px 10px 10px;
       width: 100%;
       height: 100%;
       margin-left: 2%;
     }
     .tarifBox2 .tarifBox2Box2 ul li{
       font-size: 36px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       
     }
     .tarifBox2 .tarifBox2Box2  .tarifBox2Box2text ul li{
       font-size: 32px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       color: #878496;
       
       
     }
      .tarifBox2Box2Button  a {
       font-size: 35px;
     padding: 12px 20px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff !important;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% 0;
     cursor: pointer;
     }
     .tarifBox2 .tarifBox2Box3{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 10px 10px 10px;
       width: 100%;
       height: 100%;
       margin-left: 2%;
     }
     .tarifBox2 .tarifBox2Box3 ul li{
       font-size: 36px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       
     }
     .tarifBox2 .tarifBox2Box3  .tarifBox2Box3text ul li{
       font-size: 32px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       color: #878496;
       
       
     }
      .tarifBox2Box3Button a {
       font-size: 35px;
     padding: 12px 20px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff !important;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% 0;
     cursor: pointer;
     }
     .tarifBox2 .tarifBox2Box4{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 10px 10px 10px;
       width: 100%;
       height: 100%;
       margin-left: 2%;
      
     }
     .tarifBox2 .tarifBox2Box4 ul li{
       font-size: 36px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       
     }
     .tarifBox2 .tarifBox2Box4  .tarifBox2Box4text ul li{
       font-size: 32px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       color: #878496;
     }
      .tarifBox2Box4Button a {
       font-size: 35px;
     padding: 12px 20px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff !important;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% 0;
     cursor: pointer;
     }

     .own{
      display: flex;
      justify-content: end;
      width: 100%;
      position: relative;
      top: 80px;
      
     }
     .own a{
      align-items: center;
      background: #ffffff;
      border-style: none;
      color: #5131e4 !important;
      display: flex;
      font-size: 32px;
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 520;
      justify-content: end;
      width: 100%;
     
      
    }
    
 
     .tarifBox3{
       width: 100%;
       display: flex;
       flex-direction: row-reverse;
       margin-top: 10%;

     }
     .tarifBox3text h1{
       width: 70%;
     }
     .tarifBox3text .tarifBox3list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list1 {
       width: 100%;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list1 ul li {
       font-size: 36px;
       padding: 40px 0px 10px 0px;
       width: 71%;   
       margin-left: 20px; 
       
     }
     .tarifBox3text .tarifBox3list .tarifBox3list2 {
       width: 100%;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list2 ul li {
       font-size: 36px;
       padding: 40px 0px 10px 0px;
       width: 65%;   
       margin-left: 20px;
       
     }
     .tarifBox3img  {
       width: 100%;
     }
     .tarifBox3img  img {
       width: 90%;
       border-radius: 15px;
       margin-top: 3%; 
     }
     .tarifBox4{
       width: 100%;
       display: flex;
       margin-top: 10%;

     }
     .tarifBox4text h1{
       width: 70%;
     }
     .tarifBox4text .tarifBox4list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list1 {
       width: 100%;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list1 ul li {
       font-size: 36px;
       padding: 40px 0px 10px 0px;
       width: 79%;   
       margin-left: 20px; 
     }
     .tarifBox4text .tarifBox4list .tarifBox4list2 {
       width: 100%;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list2 ul li {
       font-size: 36px;
       padding: 40px 0px 10px 0px;
       width: 58%;   
       margin-left: 30px;
     }
     .tarifBox4img  {
       width: 100%;
     }
     .tarifBox4img  img {
       width: 90%;
       border-radius: 15px; 
       margin-top: 4%;
       margin-left: 15%;
     }
     .tarifBox5{
       width: 100%;
       margin-top: 10%;
       display: flex;
       flex-direction: row-reverse;

     }
     .tarifBox5text h1{
       width: 72%;
     }
     .tarifBox5text {
      width: 100%;
      margin-left: 4%;
    }
     .tarifBox5text .tarifBox5list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list1 {
       width: 100%;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list1 ul li {
       font-size: 36px;
       padding: 40px 0px 10px 0px;
       width: 60%;   
       margin-left: 20px; 
     }
     .tarifBox5text .tarifBox5list .tarifBox5list2 {
       width: 100%;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list2 ul li {
       font-size: 38px;
       padding: 40px 0px 10px 0px;
       width: 61%;   
       margin-left: 20px;
     }
     .tarifBox5img  {
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 0px 30px;
       display: flex;
       margin:0 0 130px 0;
       width: 100%;
     }
     .tarifBox5img  img {
       width: 128%;
       border-radius: 15px;
       margin-left: -70px;
       margin-top: -21px;
     }
 
     .tarifBox10 {
      background-color: #f9f9f9;
      border-radius: 35px;
      padding: 100px 100px;
      display: block;
      height: 30vh;
    }
    .tarifBox10 .tarifBox10ti h1 {
      width: 55%;
  
    }
    .tarifBox10 .tarifBox10ti p {
      width: 60%;
    }
    .tarifBox10 .tarifBox10tb {
      display: flex;
    }
    .tarifBox10 .tarifBox10Button10 a button {
      font-size: 40px;
      padding: 14px 50px;
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 500;
      text-align: center;
      background: #5231e4;
      color: #ffffff;
      border-radius: 10px;
      border-style: none;
      margin: 30px 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
    .tarifBox10 .tarifBox10img img {
      width: 100%;
      position: relative;
      left: -300px;
      top: 140px;
    }
 }
 @media (max-width: 1920px) {
  .tarif{
   padding: 0 80px;
  }
  
   h1 {
     font-weight: 700;
     font-size: 60px;
     color: #0e082b;
   }
  
   .tarifBox1{
   padding-top: 160px;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   position: relative;
   }
   .tarifBox1 .tarifBox1ti{
    display: flex;
   }
   .tarifBox1img{
       width: 100%;
       
   }
   .tarifBox1img img{
       width: 100%;
       margin-top: -12%;
       margin-left: -12%;
       position: relative;
       z-index: -1;
   }
   .tarifBox1 .tarifBox1text {
    position: relative;
    top: 180px;
   }
   .tarifBox1 .tarifBox1text h1 {
      width: 55%;
   }
   .tarifBox1 .tarifBox1text p {
     width: 60%;  
   }
   .tarifBox2{
     width: 100%;
     display: flex;
     position: relative;
     z-index: 0;
     margin-top: -15%;
   }
   .tarifBox2 h2{
     font-size: 36px;
     margin: 5% 0; 
     width: 88%;

   }
   .tarifBox2 p{
     color: #878496;
     margin-top: 10px; 
     width: 100%;
     font-size: 30px;
   }
   .tarifBox2 h3{
     color: #5231e4;;
     margin: 10% 0; 
     width: 100%;
     font-size: 40px;
   }
   .tarifBox2 .tarifBox2Box1{
     background-color: #f9f9f9;
     border-radius: 35px;
     padding: 20px 10px 10px 10px;
     width: 100%;
     height: 100%;

   }
   .tarifBox2 .tarifBox2Box1 ul li{
     font-size: 20px;
     padding: 20px 0px 10px 0px;
     width: 87%;   
     margin-left: 20px;
     
   }
    .tarifBox2Box1Button button {
     font-size: 28px;
   padding: 12px 20px;
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 500;
   text-align: center;
   color: #ffffff;
   background: #5231e4;
   border-radius: 10px;
   border-style: none;
   margin-left: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   text-decoration: none;
   width: 100%;
   margin: 10% 0;
   cursor: pointer;
    
   }
   .tarifBox2 .tarifBox2Box2{
     background-color: #f9f9f9;
     border-radius: 35px;
     padding: 20px 10px 10px 10px;
     width: 100%;
     height: 100%;
     margin-left: 2%;
   }
   .tarifBox2 .tarifBox2Box2 ul li{
     font-size: 20px;
     padding: 20px 0px 10px 0px;
     width: 87%;   
     margin-left: 20px;
     
   }
   .tarifBox2 .tarifBox2Box2  .tarifBox2Box2text ul li{
     font-size: 20px;
     padding: 10px 0px 10px 0px;
     width: 87%;   
     margin-left: 20px;
     color: #878496;
     
     
   }
    .tarifBox2Box2Button  button {
     font-size: 28px;
   padding: 12px 20px;
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 500;
   text-align: center;
   color: #ffffff;
   background: #5231e4;
   border-radius: 10px;
   border-style: none;
   margin-left: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   text-decoration: none;
   width: 100%;
   margin: 10% 0;
   cursor: pointer;
   }
   .tarifBox2 .tarifBox2Box3{
     background-color: #f9f9f9;
     border-radius: 35px;
     padding: 20px 10px 10px 10px;
     width: 100%;
     height: 100%;
     margin-left: 2%;
   }
   .tarifBox2 .tarifBox2Box3 ul li{
     font-size: 20px;
     padding: 20px 0px 10px 0px;
     width: 87%;   
     margin-left: 20px;
     
   }
   .tarifBox2 .tarifBox2Box3  .tarifBox2Box3text ul li{
     font-size: 20px;
     padding: 20px 0px 10px 0px;
     width: 87%;   
     margin-left: 20px;
     color: #878496;
     
     
   }
    .tarifBox2Box3Button a {
     font-size: 28px;
   padding: 12px 20px;
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 500;
   text-align: center;
   color: #ffffff;
   background: #5231e4;
   border-radius: 10px;
   border-style: none;
   margin-left: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   text-decoration: none;
   width: 100%;
   margin: 10% 0;
   cursor: pointer;
   }
   .tarifBox2 .tarifBox2Box4{
     background-color: #f9f9f9;
     border-radius: 35px;
     padding: 20px 10px 10px 10px;
     width: 100%;
     height: 100%;
     margin-left: 2%;
    
   }
   .tarifBox2 .tarifBox2Box4 ul li{
     font-size: 20px;
     padding: 20px 0px 10px 0px;
     width: 87%;   
     margin-left: 20px;
   }
   .tarifBox2 .tarifBox2Box4  .tarifBox2Box4text ul li{
     font-size: 20px;
     padding: 20px 0px 10px 0px;
     width: 87%;   
     margin-left: 20px;
     color: #878496;
   }
    .tarifBox2Box4Button  a{
     font-size: 28px;
   padding: 12px 20px;
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 500;
   text-align: center;
   color: #ffffff;
   background: #5231e4;
   border-radius: 10px;
   border-style: none;
   margin-left: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   text-decoration: none;
   width: 100%;
   margin: 10% 0;
   cursor: pointer;
   }

   .own a{
    align-items: center;
    background: #ffffff;
    border-style: none;
    color: #5131e4;
    display: flex;
    font-size: 24px;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 520;
    justify-content: end;
    width: 100%;
  }

   .tarifBox3{
     width: 100%;
     display: flex;
     flex-direction: row-reverse;
     margin-top: 10%;

   }
   .tarifBox3{
     width: 100%;
     display: flex;
     flex-direction: row-reverse;
     margin-top: 10%;

   }
   .tarifBox3text h1{
     width: 70%;
   }
   .tarifBox3text .tarifBox3list {
     display: flex;
     padding: 0 0px;
     overflow: hidden;
     height: auto;
   }
   .tarifBox3text .tarifBox3list .tarifBox3list1 {
     width: 100%;
   }
   .tarifBox3text .tarifBox3list .tarifBox3list1 ul li {
     font-size: 20px;
     padding: 20px 0px 10px 0px;
     width: 71%;   
     margin-left: 20px; 
     
   }
   .tarifBox3text .tarifBox3list .tarifBox3list2 {
     width: 100%;
   }
   .tarifBox3text .tarifBox3list .tarifBox3list2 ul li {
     font-size: 20px;
     padding: 20px 0px 10px 0px;
     width: 56%;   
     margin-left: 20px;
     
   }
   .tarifBox3img  {
     width: 100%;
   }
   .tarifBox3img  img {
     width: 90%;
     border-radius: 15px;
     margin-top: 3%; 
   }
   .tarifBox4{
     width: 100%;
     display: flex;
     margin-top: 10%;

   }
   .tarifBox4text h1{
     width: 70%;
   }
   .tarifBox4text .tarifBox4list {
     display: flex;
     padding: 0 0px;
     overflow: hidden;
     height: auto;
   }
   .tarifBox4text .tarifBox4list .tarifBox4list1 {
     width: 100%;
   }
   .tarifBox4text .tarifBox4list .tarifBox4list1 ul li {
     font-size: 20px;
     padding: 20px 0px 10px 0px;
     width: 79%;   
     margin-left: 20px; 
   }
   .tarifBox4text .tarifBox4list .tarifBox4list2 {
     width: 100%;
   }
   .tarifBox4text .tarifBox4list .tarifBox4list2 ul li {
     font-size: 20px;
     padding: 20px 0px 10px 0px;
     width: 58%;   
     margin-left: 30px;
   }
   .tarifBox4img  {
     width: 100%;
   }
   .tarifBox4img  img {
     width: 90%;
     border-radius: 15px; 
     margin-top: 4%;
     margin-left: 15%;
   }
   .tarifBox5{
     width: 100%;
     margin-top: 10%;
     display: flex;
     flex-direction: row-reverse;

   }
   .tarifBox5text h1{
     width: 83%;
   }
   .tarifBox5text {
    width: 100%;
    margin-left: 4%;
  }
   .tarifBox5text .tarifBox5list {
     display: flex;
     padding: 0 0px;
     overflow: hidden;
     height: auto;
   }
   .tarifBox5text .tarifBox5list .tarifBox5list1 {
     width: 100%;
   }
   .tarifBox5text .tarifBox5list .tarifBox5list1 ul li {
     font-size: 20px;
     padding: 20px 0px 10px 0px;
     width: 60%;   
     margin-left: 20px; 
   }
   .tarifBox5text .tarifBox5list .tarifBox5list2 {
     width: 100%;
   }
   .tarifBox5text .tarifBox5list .tarifBox5list2 ul li {
     font-size: 20px;
     padding: 20px 0px 10px 0px;
     width: 54%;   
     margin-left: 20px;
   }
   .tarifBox5img  {
     background-color: #f9f9f9;
     border-radius: 35px;
     padding: 0px 30px;
     display: flex;
     margin: 0 0 120px 0;
     width: 100%;
   }
   .tarifBox5img  img {
     width: 128%;
     border-radius: 15px;
     margin-left: -70px;
     margin-top: -21px;
   }

   .tarifBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
    
  }
  .tarifBox10 .tarifBox10ti h1 {
    width: 55%;

  }
  .tarifBox10 .tarifBox10ti p {
    width: 60%;
  }
  .tarifBox10 .tarifBox10tb {
    display: flex;
  }
  .tarifBox10 .tarifBox10Button10 a button {
    font-size: 24px;
    padding: 14px 100px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .tarifBox10 .tarifBox10img img {
    width: 100%;
    position: relative;
    left: -200px;
    top: 35px;

  }
}
  @media (max-width: 1440px) {
    .tarif{
     padding: 0 80px;
    }
     h1 {
       font-weight: 700;
       font-size: 36px;
       color: #0e082b;
     }
     p {
       font-weight: 300;
       font-size: 20px;
       color: #0e082b;
       margin-top: 23px;
     }
    
     .tarifBox1{
     padding-top: 115px;
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     position: relative;
     }
     .tarifBox1 .tarifBox1ti{
      display: flex;
     }
     .tarifBox1img{
         width: 70%;
         
     }
     .tarifBox1img img{
         width: 150%;
         margin-top: -12%;
         margin-left: -43%;
         position: relative;
         z-index: -1;
     }
     .tarifBox1 .tarifBox1text {
      position: relative;
      top: 150px;
     }

     .tarifBox1 .tarifBox1text h1 {
        width: 46%;
     }
     .tarifBox1 .tarifBox1text p {
       width: 50%;  
     }
     .tarifBox2{
       width: 100%;
       display: flex;
       position: relative;
       z-index: 0;
       margin-top: -12%;
     }
     .tarifBox2 h2{
       font-size: 18px;
       margin: 5% 0; 
       width: 88%;
 
     }
     .tarifBox2 p{
       color: #878496;
       margin-top: 10px; 
       width: 100%;
       font-size: 18px;
     }
     .tarifBox2 h3{
       color: #5231e4;;
       margin: 10% 0; 
       width: 100%;
       font-size: 20px;
     }
     .tarifBox2 .tarifBox2Box1{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 10px 10px 10px;
       width: 100%;
       height: 100%;

     }
     .tarifBox2 .tarifBox2Box1 ul li{
       font-size: 12px;
       padding: 20px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       font-size: 18px;
     }
      .tarifBox2Box1Button  button {
       font-size: 14px;
     padding: 12px 20px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% 0;
      
     }
     .tarifBox2 .tarifBox2Box2{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 10px 10px 10px;
       width: 100%;
       height: 100%;
       margin-left: 2%;
     }
     .tarifBox2 .tarifBox2Box2 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       font-size: 18px;
     }
     .tarifBox2 .tarifBox2Box2  .tarifBox2Box2text ul li{
       font-size: 12px;
       padding: 20px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       color: #878496;
       font-size: 16px;
       
     }
     .tarifBox2Box2Button a {
      font-size: 14px;
      padding: 12px 20px;
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      background: #5231e4;
      border-radius: 10px;
      border-style: none;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      width: 100%;
      margin: 10% 0;
  }
      .tarifBox2Box2Button  button {
       font-size: 14px;
     padding: 12px 20px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% 0;
     }
     .tarifBox2 .tarifBox2Box3{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 10px 10px 10px;
       width: 100%;
       height: 100%;
       margin-left: 2%;
     }
     .tarifBox2 .tarifBox2Box3 ul li{
       font-size: 12px;
       padding: 20px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       font-size: 18px;
     }
     .tarifBox2 .tarifBox2Box3  .tarifBox2Box3text ul li{
       font-size: 12px;
       padding: 20px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       color: #878496;
       font-size: 16px;
       
     }
      .tarifBox2Box3Button a {
       font-size: 14px;
     padding: 12px 20px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% 0;
     }
     .tarifBox2 .tarifBox2Box4{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 10px 10px 10px;
       width: 100%;
       height: 100%;
       margin-left: 2%;
      
     }
     .tarifBox2 .tarifBox2Box4 ul li{
       font-size: 12px;
       padding: 20px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       font-size: 18px;
     }
     .tarifBox2 .tarifBox2Box4  .tarifBox2Box4text ul li{
       font-size: 12px;
       padding: 20px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       color: #878496;
       font-size: 16px;
       
     }
      .tarifBox2Box4Button a {
       font-size: 14px;
     padding: 12px 20px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% 0;
     }
     .own{
      justify-content: end;
      position: relative;
      top: 40px;
     }
     .own a{
      align-items: center;
      background: #ffffff;
      border-style: none;
      color: #5131e4 !important;
      display: flex;
      font-size: 24px;
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 520;
      justify-content: end;
      width: 100%;
    }
 
     .tarifBox3{
       width: 100%;
       display: flex;
       flex-direction: row-reverse;
       margin-top: 10%;

     }
     .tarifBox3text h1{
       width: 70%;
     }
     .tarifBox3text .tarifBox3list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list1 {
       width: 100%;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list1 ul li {
       font-size: 12px;
       padding: 20px 0px 10px 0px;
       width: 71%;   
       margin-left: 20px; 
       font-size: 18px;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list2 {
       width: 100%;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list2 ul li {
       font-size: 12px;
       padding: 20px 0px 10px 0px;
       width: 65%;   
       margin-left: 20px;
       font-size: 18px;
     }
     .tarifBox3img  {
       width: 100%;
     }
     .tarifBox3img  img {
       width: 90%;
       border-radius: 15px;
       margin-top: 3%; 
     }
     .tarifBox4{
       width: 100%;
       display: flex;
       margin-top: 10%;

     }
     .tarifBox4text h1{
       width: 70%;
     }
     .tarifBox4text .tarifBox4list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list1 {
       width: 100%;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list1 ul li {
       font-size: 12px;
       padding: 20px 0px 10px 0px;
       width: 79%;   
       margin-left: 20px; 
       font-size: 18px;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list2 {
       width: 100%;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list2 ul li {
       font-size: 12px;
       padding: 20px 0px 10px 0px;
       width: 58%;   
       margin-left: 30px;
       font-size: 18px;
     }
     .tarifBox4img  {
       width: 100%;
     }
     .tarifBox4img  img {
       width: 90%;
       border-radius: 15px; 
       margin-top: 4%;
       margin-left: 15%;
     }
     .tarifBox5{
       width: 100%;
       margin-top: 10%;
       display: flex;
       flex-direction: row-reverse;

     }
     .tarifBox5text h1{
       width: 75%;
     }
     .tarifBox5text {
      width: 100%;
      margin-left: 4%;
    }
     .tarifBox5text .tarifBox5list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list1 {
       width: 100%;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list1 ul li {
       font-size: 12px;
       padding: 20px 0px 10px 0px;
       width: 60%;   
       margin-left: 20px;
       font-size: 18px; 
     }
     .tarifBox5text .tarifBox5list .tarifBox5list2 {
       width: 100%;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list2 ul li {
       font-size: 12px;
       padding: 20px 0px 10px 0px;
       width: 54%;   
       margin-left: 20px;
       font-size: 18px;
     }
     .tarifBox5img  {
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 0px 30px;
       display: flex;
       margin:0 0 100px 0;
       width: 100%;
     }
     .tarifBox5img  img {
       width: 128%;
       border-radius: 15px;
       margin-left: -70px;
       margin-top: -21px;
     }
 
     .tarifBox10 {
      background-color: #f9f9f9;
      border-radius: 35px;
      padding: 30px 30px;
      display: block;
      height: 40vh;
    }
    .tarifBox10 {
      background-color: #f9f9f9;
      border-radius: 35px;
      padding: 30px 30px;
      display: block;
      height: auto;
    }
    .tarifBox10 .tarifBox10ti h1 {
      width: 55%;
  
    }
    .tarifBox10 .tarifBox10ti p {
      width: 60%;
    }
    .tarifBox10 .tarifBox10tb {
      display: flex;
    }
    .tarifBox10 .tarifBox10Button10 a button {
      font-size: 18px;
      padding: 14px 70px;
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 500;
      text-align: center;
      background: #5231e4;
      color: #ffffff;
      border-radius: 10px;
      border-style: none;
      margin: 50px 0 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
    .tarifBox10 .tarifBox10img img {
      width: 110%;
      position: relative;
      left: -160px;
      top: 35px;
    }
 }
 @media (max-width: 1366px) {
  .tarif{
   padding: 0 80px;
  }
   h1 {
     font-weight: 700;
     font-size: 36px;
     color: #0e082b;
   }
   p {
     font-weight: 300;
     font-size: 20px;
     color: #0e082b;
     margin-top: 23px;
   }
  
   .tarifBox1{
   padding-top: 115px;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   position: relative;
   }
   .tarifBox1 .tarifBox1ti{
    display: flex;
   }
   .tarifBox1img{
       width: 70%;
       
   }
   .tarifBox1img img{
       width: 150%;
       margin-top: -12%;
       margin-left: -43%;
       position: relative;
       z-index: -1;
   }
   .tarifBox1 .tarifBox1text {
    position: relative;
    top: 100px;
   }

   .tarifBox1 .tarifBox1text h1 {
      width: 46%;
   }
   .tarifBox1 .tarifBox1text p {
     width: 50%;  
   }
   .tarifBox2{
     width: 100%;
     display: flex;
     position: relative;
     z-index: 0;
     margin-top: -12%;
   }
   .tarifBox2 h2{
     font-size: 18px;
     margin: 5% 0; 
     width: 88%;

   }
   .tarifBox2 p{
     color: #878496;
     margin-top: 10px; 
     width: 100%;
     font-size: 18px;
   }
   .tarifBox2 h3{
     color: #5231e4;;
     margin: 10% 0; 
     width: 100%;
     font-size: 20px;
   }
   .tarifBox2 .tarifBox2Box1{
     background-color: #f9f9f9;
     border-radius: 35px;
     padding: 20px 10px 10px 10px;
     width: 100%;
     height: 100%;

   }
   .tarifBox2 .tarifBox2Box1 ul li{
     font-size: 12px;
     padding: 20px 0px 10px 0px;
     width: 87%;   
     margin-left: 20px;
     font-size: 18px;
   }
    .tarifBox2Box1Button  button {
     font-size: 14px;
   padding: 12px 20px;
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 500;
   text-align: center;
   color: #ffffff;
   background: #5231e4;
   border-radius: 10px;
   border-style: none;
   margin-left: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   text-decoration: none;
   width: 100%;
   margin: 10% 0;
    
   }
   .tarifBox2 .tarifBox2Box2{
     background-color: #f9f9f9;
     border-radius: 35px;
     padding: 20px 10px 10px 10px;
     width: 100%;
     height: 100%;
     margin-left: 2%;
   }
   .tarifBox2 .tarifBox2Box2 ul li{
     font-size: 12px;
     padding: 10px 0px 10px 0px;
     width: 87%;   
     margin-left: 20px;
     font-size: 18px;
   }
   .tarifBox2 .tarifBox2Box2  .tarifBox2Box2text ul li{
     font-size: 12px;
     padding: 20px 0px 10px 0px;
     width: 87%;   
     margin-left: 20px;
     color: #878496;
     font-size: 16px;
     
   }
    .tarifBox2Box2Button a {
     font-size: 14px;
   padding: 12px 20px;
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 500;
   text-align: center;
   color: #ffffff;
   background: #5231e4;
   border-radius: 10px;
   border-style: none;
   margin-left: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   text-decoration: none;
   width: 100%;
   margin: 10% 0;
   }
   .tarifBox2 .tarifBox2Box3{
     background-color: #f9f9f9;
     border-radius: 35px;
     padding: 20px 10px 10px 10px;
     width: 100%;
     height: 100%;
     margin-left: 2%;
   }
   .tarifBox2 .tarifBox2Box3 ul li{
     font-size: 12px;
     padding: 20px 0px 10px 0px;
     width: 87%;   
     margin-left: 20px;
     font-size: 18px;
   }
   .tarifBox2 .tarifBox2Box3  .tarifBox2Box3text ul li{
     font-size: 12px;
     padding: 20px 0px 10px 0px;
     width: 87%;   
     margin-left: 20px;
     color: #878496;
     font-size: 16px;
     
   }
    .tarifBox2Box3Button a {
     font-size: 14px;
   padding: 12px 20px;
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 500;
   text-align: center;
   color: #ffffff;
   background: #5231e4;
   border-radius: 10px;
   border-style: none;
   margin-left: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   text-decoration: none;
   width: 100%;
   margin: 10% 0;
   }
   .tarifBox2 .tarifBox2Box4{
     background-color: #f9f9f9;
     border-radius: 35px;
     padding: 20px 10px 10px 10px;
     width: 100%;
     height: 100%;
     margin-left: 2%;
    
   }
   .tarifBox2 .tarifBox2Box4 ul li{
     font-size: 12px;
     padding: 20px 0px 10px 0px;
     width: 87%;   
     margin-left: 20px;
     font-size: 18px;
   }
   .tarifBox2 .tarifBox2Box4  .tarifBox2Box4text ul li{
     font-size: 12px;
     padding: 20px 0px 10px 0px;
     width: 87%;   
     margin-left: 20px;
     color: #878496;
     font-size: 16px;
     
   }
    .tarifBox2Box4Button a{
     font-size: 14px;
   padding: 12px 20px;
   font-family: "SF Pro Display";
   font-style: normal;
   font-weight: 500;
   text-align: center;
   color: #ffffff;
   background: #5231e4;
   border-radius: 10px;
   border-style: none;
   margin-left: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   text-decoration: none;
   width: 100%;
   margin: 10% 0;
   }

   .tarifBox3{
     width: 100%;
     display: flex;
     flex-direction: row-reverse;
     margin-top: 10%;

   }
   .tarifBox3text h1{
     width: 70%;
   }
   .tarifBox3text .tarifBox3list {
     display: flex;
     padding: 0 0px;
     overflow: hidden;
     height: auto;
   }
   .tarifBox3text .tarifBox3list .tarifBox3list1 {
     width: 100%;
   }
   .tarifBox3text .tarifBox3list .tarifBox3list1 ul li {
     font-size: 12px;
     padding: 20px 0px 10px 0px;
     width: 71%;   
     margin-left: 20px; 
     font-size: 18px;
   }
   .tarifBox3text .tarifBox3list .tarifBox3list2 {
     width: 100%;
   }
   .tarifBox3text .tarifBox3list .tarifBox3list2 ul li {
     font-size: 12px;
     padding: 20px 0px 10px 0px;
     width: 65%;   
     margin-left: 20px;
     font-size: 18px;
   }
   .tarifBox3img  {
     width: 100%;
   }
   .tarifBox3img  img {
     width: 90%;
     border-radius: 15px;
     margin-top: 3%; 
   }
   .tarifBox4{
     width: 100%;
     display: flex;
     margin-top: 10%;

   }
   .tarifBox4text h1{
     width: 70%;
   }
   .tarifBox4text .tarifBox4list {
     display: flex;
     padding: 0 0px;
     overflow: hidden;
     height: auto;
   }
   .tarifBox4text .tarifBox4list .tarifBox4list1 {
     width: 100%;
   }
   .tarifBox4text .tarifBox4list .tarifBox4list1 ul li {
     font-size: 12px;
     padding: 20px 0px 10px 0px;
     width: 79%;   
     margin-left: 20px; 
     font-size: 18px;
   }
   .tarifBox4text .tarifBox4list .tarifBox4list2 {
     width: 100%;
   }
   .tarifBox4text .tarifBox4list .tarifBox4list2 ul li {
     font-size: 12px;
     padding: 20px 0px 10px 0px;
     width: 58%;   
     margin-left: 30px;
     font-size: 18px;
   }
   .tarifBox4img  {
     width: 100%;
   }
   .tarifBox4img  img {
     width: 90%;
     border-radius: 15px; 
     margin-top: 4%;
     margin-left: 15%;
   }
   .tarifBox5{
     width: 100%;
     margin-top: 10%;
     display: flex;
     flex-direction: row-reverse;

   }
   .tarifBox5text h1{
     width: 75%;
   }
   .tarifBox5text {
    width: 100%;
    margin-left: 4%;
  }
   .tarifBox5text .tarifBox5list {
     display: flex;
     padding: 0 0px;
     overflow: hidden;
     height: auto;
   }
   .tarifBox5text .tarifBox5list .tarifBox5list1 {
     width: 100%;
   }
   .tarifBox5text .tarifBox5list .tarifBox5list1 ul li {
     font-size: 12px;
     padding: 20px 0px 10px 0px;
     width: 60%;   
     margin-left: 20px;
     font-size: 18px; 
   }
   .tarifBox5text .tarifBox5list .tarifBox5list2 {
     width: 100%;
   }
   .tarifBox5text .tarifBox5list .tarifBox5list2 ul li {
     font-size: 12px;
     padding: 20px 0px 10px 0px;
     width: 54%;   
     margin-left: 20px;
     font-size: 18px;
   }
   .tarifBox5img  {
     background-color: #f9f9f9;
     border-radius: 35px;
     padding: 0px 30px;
     display: flex;
     margin:0 0 80px 0;
     width: 100%;
   }
   .tarifBox5img  img {
     width: 120%;
     border-radius: 15px;
     margin-left: -70px;
     margin-top: -21px;
   }


  .tarifBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
  }
  .tarifBox10 .tarifBox10ti h1 {
    width: 60%;

  }
  .tarifBox10 .tarifBox10ti p {
    width: 60%;
  }
  .tarifBox10 .tarifBox10tb {
    display: flex;
  }
  .tarifBox10 .tarifBox10Button10 a button {
    font-size: 16px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .tarifBox10 .tarifBox10img img {
    width: 110%;
    position: relative;
    left: -160px;
    top: 35px;
  }
}
 @media (max-width: 1024px) {
    .tarif{
     padding: 0 60px;
    }
     h1 {
       font-weight: 700;
       font-size: 24px;
       color: #0e082b;
     }
     p {
       font-weight: 300;
       font-size: 14px;
       color: #0e082b;
       margin-top: 23px;
     }
     .tarifBox1{
     padding-top: 90px;
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     position: relative;
     }
     .tarifBox1 .tarifBox1ti{
      display: flex;
     }
     .tarifBox1img{
         width: 70%;
         
     }
     .tarifBox1img img{
         width: 150%;
         margin-top: -12%;
         margin-left: -43%;
         position: relative;
         z-index: -1;
     }
     .tarifBox1 .tarifBox1text {
      position: relative;
      top: 100px;
  }
     .tarifBox1 .tarifBox1text h1 {
        width: 50%;
     }
     .tarifBox1 .tarifBox1text p {
       width: 55%;  
     }
     .tarifBox2{
       width: 100%;
       display: flex;
       position: relative;
       z-index: 0;
       margin-top: -12%;
     }
     .tarifBox2 h2{
       font-size: 18px;
       margin: 5% 0; 
       width: 88%;
 
     }
     .tarifBox2 p{
       color: #878496;
       margin-top: 10px; 
       width: 100%;
       font-size: 14px;
     }
     .tarifBox2 h3{
       color: #5231e4;;
       margin: 10% 0; 
       width: 100%;
       font-size: 16px;
     }
     .tarifBox2 .tarifBox2Box1{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 10px 10px 10px;
       width: 100%;
       height: 100%;

     }
     .tarifBox2 .tarifBox2Box1 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
     }
      .tarifBox2Box1Button  button {
       font-size: 14px;
     padding: 12px 20px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% 0;
      
     }
     .tarifBox2 .tarifBox2Box2{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 10px 10px 10px;
       width: 100%;
       height: 100%;
       margin-left: 2%;
     }
     .tarifBox2 .tarifBox2Box2 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
     }
     .tarifBox2 .tarifBox2Box2  .tarifBox2Box2text ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       color: #878496;
       
     }
      .tarifBox2Box2Button a {
       font-size: 14px;
     padding: 12px 20px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% 0;
     }
     .tarifBox2 .tarifBox2Box3{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 10px 10px 10px;
       width: 100%;
       height: 100%;
       margin-left: 2%;
     }
     .tarifBox2 .tarifBox2Box3 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
     }
     .tarifBox2 .tarifBox2Box3  .tarifBox2Box3text ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       color: #878496;
       
     }
      .tarifBox2Box3Button a {
       font-size: 14px;
     padding: 12px 20px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% 0;
     }
     .tarifBox2 .tarifBox2Box4{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 10px 10px 10px;
       width: 100%;
       height: 100%;
       margin-left: 2%;
      
     }
     .tarifBox2 .tarifBox2Box4 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
     }
     .tarifBox2 .tarifBox2Box4  .tarifBox2Box4text ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       color: #878496;
       
     }
      .tarifBox2Box4Button a {
       font-size: 14px;
     padding: 12px 20px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% 0;
     }
     .own{
      justify-content: end;
      position: relative;
      top: 40px;
     }
     .own a{
      align-items: center;
      background: #ffffff;
      border-style: none;
      color: #5131e4;
      display: flex;
      font-size: 20px;
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 520;
      justify-content: end;
      width: 100%;
    }
 
     .tarifBox3{
       width: 100%;
       display: flex;
       flex-direction: row-reverse;
       margin-top: 10%;

     }
     .tarifBox3text h1{
       width: 70%;
     }
     .tarifBox3text .tarifBox3list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list1 {
       width: 100%;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list1 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 71%;   
       margin-left: 20px; 
     }
     .tarifBox3text .tarifBox3list .tarifBox3list2 {
       width: 100%;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list2 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 65%;   
       margin-left: 20px;
     }
     .tarifBox3img  {
       width: 100%;
     }
     .tarifBox3img  img {
       width: 80%;
       border-radius: 15px;
       margin-top: 3%; 
     }
     .tarifBox4{
       width: 100%;
       display: flex;
       margin-top: 10%;

     }
     .tarifBox4text h1{
       width: 70%;
     }
     .tarifBox4text .tarifBox4list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list1 {
       width: 100%;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list1 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 79%;   
       margin-left: 20px; 
     }
     .tarifBox4text .tarifBox4list .tarifBox4list2 {
       width: 100%;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list2 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 58%;   
       margin-left: 30px;
     }
     .tarifBox4img  {
       width: 100%;
     }
     .tarifBox4img  img {
       width: 80%;
       border-radius: 15px; 
       margin-top: 4%;
       margin-left: 15%;
     }
     .tarifBox5{
       width: 100%;
       margin-top: 10%;
       display: flex;
       flex-direction: row-reverse;

     }
     .tarifBox5text h1{
       width: 75%;
     }
     .tarifBox5text {
      width: 100%;
      margin-left: 4%;
    }
     .tarifBox5text .tarifBox5list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list1 {
       width: 100%;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list1 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 60%;   
       margin-left: 20px; 
     }
     .tarifBox5text .tarifBox5list .tarifBox5list2 {
       width: 100%;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list2 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 54%;   
       margin-left: 20px;
     }
     .tarifBox5img  {
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 0px 30px;
       display: flex;
       margin:2% 0;
       width: 100%;
     }
     .tarifBox5img  img {
       width: 120%;
       border-radius: 15px;
       margin-left: -70px;
       margin-top: -21px;
     }
 
     .tarifBox10 {
      background-color: #f9f9f9;
      border-radius: 35px;
      padding: 30px 30px;
      display: block;
      height: auto;
      margin-top: 40px;
    }
    .tarifBox10 .tarifBox10ti h1 {
      width: 70%;
    }
    .tarifBox10 .tarifBox10ti p {
      width: 75%;
    }
    .tarifBox10 .tarifBox10tb {
      display: flex;
    }
    .tarifBox10 .tarifBox10Button10 a button {
      font-size: 14px;
      padding: 14px 50px;
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 500;
      text-align: center;
      background: #5231e4;
      color: #ffffff;
      border-radius: 10px;
      border-style: none;
      margin: 30px 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
    .tarifBox10 .tarifBox10img img {
      width: 100%;
      position: relative;
      left: -90px;
      top: 35px;
    }
 }
  @media (max-width: 768px) {
    .tarif{
     padding: 0 40px;
    }
     h1 {
       font-weight: 700;
       font-size: 24px;
       color: #0e082b;
     }
     p {
       font-weight: 300;
       font-size: 14px;
       color: #0e082b;
       margin-top: 23px;
     }
     .tarifBox1{
     padding-top: 90px;
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     position: relative;
     }
     .tarifBox1 .tarifBox1ti{
      display: flex;
     }
     .tarifBox1img{
         width: 100%;
         
     }
     .tarifBox1img img{
         width: 150%;
         margin-top: -12%;
         margin-left: -43%;
         position: relative;
         z-index: -1;
     }
     .tarifBox1 .tarifBox1text {
      position: relative;
      top: 60px;
  }
     .tarifBox1 .tarifBox1text h1 {
        width: 55%;
     }
     .tarifBox1 .tarifBox1text p {
       width: 70%;  
     }
     .tarifBox2{
       width: 100%;
       display: flex;
       position: relative;
       z-index: 0;
       margin-top: -12%;
     }
     .tarifBox2 h2{
       font-size: 18px;
       margin: 5% 0; 
       width: 75%;
 
     }
     .tarifBox2 p{
       color: #878496;
       margin-top: 10px; 
       width: 100%;
     }
     .tarifBox2 h3{
       color: #5231e4;;
       margin: 10% 0; 
       width: 100%;
     }
     .tarifBox2 .tarifBox2Box1{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 10px 10px 10px;
       width: 100%;
       height: 100%;

     }
     .tarifBox2 .tarifBox2Box1 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
     }
      .tarifBox2Box1Button  button {
       font-size: 14px;
     padding: 12px 20px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% 0;
      
     }
     .tarifBox2 .tarifBox2Box2{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 10px 10px 10px;
       width: 100%;
       height: 100%;
       margin-left: 2%;
     }
     .tarifBox2 .tarifBox2Box2 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
     }
     .tarifBox2 .tarifBox2Box2  .tarifBox2Box2text ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 100%;   
       margin-left: 20px;
       color: #878496;
       
     }
      .tarifBox2Box2Button  button {
       font-size: 14px;
     padding: 12px 20px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% 0;
     }
     .tarifBox2 .tarifBox2Box3{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 10px 10px 10px;
       width: 100%;
       height: 100%;
       margin-left: 2%;
     }
     .tarifBox2 .tarifBox2Box3 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 95%;   
       margin-left: 20px;
     }
     .tarifBox2 .tarifBox2Box3  .tarifBox2Box3text ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       color: #878496;
       
     }
      .tarifBox2Box3Button  button {
       font-size: 14px;
     padding: 12px 20px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% 0;
     }
     .tarifBox2 .tarifBox2Box4{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 10px 10px 10px;
       width: 100%;
       height: 100%;
       margin-left: 2%;
      
     }
     .tarifBox2 .tarifBox2Box4 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 9%;   
       margin-left: 20px;
     }
     .tarifBox2 .tarifBox2Box4  .tarifBox2Box4text ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 100%;   
       margin-left: 20px;
       color: #878496;
       
     }
      .tarifBox2Box4Button a  {
       font-size: 14px;
     padding: 12px 20px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% 0;
     }

     .own{
      display: flex;
      width: 100%;
      justify-content: end;
      position: relative;
      top: 40px;
     }
     .own a{
      align-items: center;
      background: #ffffff;
      border-style: none;
      color: #5131e4;
      display: flex;
      font-size: 18px;
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 520;
      justify-content: end;
      width: 100%;
    }
 
     .tarifBox3{
       width: 100%;
       display: flex;
       flex-direction: row-reverse;
       margin-top: 10%;

     }
     .tarifBox3text h1{
       width: 70%;
     }
     .tarifBox3text .tarifBox3list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list1 {
       width: 100%;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list1 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 78%;   
       margin-left: 20px; 
     }
     .tarifBox3text .tarifBox3list .tarifBox3list2 {
       width: 100%;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list2 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 72%;   
       margin-left: 20px;
     }
     .tarifBox3img  {
       width: 100%;
     }
     .tarifBox3img  img {
       width: 95%;
       border-radius: 15px;
       margin-top: 3%; 
     }
     .tarifBox4{
       width: 100%;
       display: flex;
       margin-top: 10%;

     }
     .tarifBox4text h1{
       width: 70%;
     }
     .tarifBox4text .tarifBox4list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list1 {
       width: 100%;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list1 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 88%;   
       margin-left: 20px; 
     }
     .tarifBox4text .tarifBox4list .tarifBox4list2 {
       width: 100%;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list2 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 64%;   
       margin-left: 30px;
     }
     .tarifBox4img  {
       width: 100%;
     }
     .tarifBox4img  img {
       width: 100%;
       border-radius: 15px; 
       margin-top: 4%;
       margin-left: 8%;
     }
     .tarifBox5{
       width: 100%;
       margin-top: 10%;
       display: flex;
       flex-direction: row-reverse;

     }
     .tarifBox5text h1{
       width: 75%;
     }
     .tarifBox5text {
      width: 100%;
      margin-left: 4%;
    }
     .tarifBox5text .tarifBox5list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list1 {
       width: 100%;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list1 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 70%;   
       margin-left: 20px; 
     }
     .tarifBox5text .tarifBox5list .tarifBox5list2 {
       width: 100%;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list2 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 72%;   
       margin-left: 20px;
     }
     .tarifBox5img  {
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 0px 30px;
       display: flex;
       margin:2% 0;
       width: 100%;
     }
     .tarifBox5img  img {
       width: 128%;
       border-radius: 15px;
       margin-left: -70px;
       margin-top: -21px;
     }
 
     .tarifBox10 {
      background-color: #f9f9f9;
      border-radius: 35px;
      padding: 30px 30px;
      display: block;
      height: auto;
      margin-top: 30px;
    }
    .tarifBox10 .tarifBox10tb {
      display: flex;
    }
    .tarifBox10 .tarifBox10Button10 a button {
      font-size: 14px;
      padding: 14px 50px;
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 500;
      text-align: center;
      background: #5231e4;
      color: #ffffff;
      border-radius: 10px;
      border-style: none;
      margin: 30px 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
    .tarifBox10 .tarifBox10ti h1 {
      width: 70%;
    }
    .tarifBox10 .tarifBox10ti p {
      width: 90%;
    }
    .tarifBox10 .tarifBox10img img {
      width: 105%;
      position: relative;
      left: -40px;
      top: 35px;
    }
 }
  @media (max-width: 425px) {
    .tarif{
     padding: 0 16px;
    }
     h1 {
       font-weight: 700;
       font-size: 24px;
       color: #0e082b;
     }
     p {
       font-weight: 300;
       font-size: 14px;
       color: #0e082b;
       margin-top: 23px;
     }
     .tarifBox1{
     padding-top: 90px;
     width: 100%;
     display: flex;
     flex-direction: column;
     position: relative;
     }
     .tarifBox1 .tarifBox1ti{
      display: block;
     }
     .tarifBox1img{
         width: 100%;
         overflow: hidden;
     }
     .tarifBox1img img{
         width: 190%;
         margin-top: -12%;
         margin-left: -43%;
         position: relative;
         z-index: -1;
     }
     .tarifBox1 .tarifBox1text {
      position: relative;
      top: 0px;
     }
     .tarifBox1 .tarifBox1text h1 {
        width: 55%;
     }
     .tarifBox1 .tarifBox1text p {
       width: 65%;  
     }
     .tarifBox2{
       width: 100%;
       display: block;
       position: relative;
       z-index: 0;
       margin-top: -35%;
     }
     .tarifBox2 h2{
       font-size: 18px;
       margin: 5% 0; 
       width: 60%;
 
     }
     .tarifBox2 p{
       color: #878496;
       margin-top: 10px; 
       width: 100%;
     }
     .tarifBox2 h3{
       color: #5231e4;;
       margin: 10% 0; 
       width: 80%;
     }
     .tarifBox2 .tarifBox2Box1{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 30px 50px 30px;
       width: 100%;
       position: relative;
       
     }
     .tarifBox2 .tarifBox2Box1 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
     }
     .tarifBox2Box1Button{
      position: absolute;
      width: 90%;
      bottom: -18%;
    }
      .tarifBox2Box1Button  button {
       font-size: 12px;
     padding: 12px 35px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% -2%;
      
     }
     .tarifBox2 .tarifBox2Box2{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 30px 20px 30px;
       width: 100%;
       margin-top: 30%;
    
     }
     .tarifBox2 .tarifBox2Box2 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       position: relative;
     }
     .tarifBox2 .tarifBox2Box2  .tarifBox2Box2text ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       color: #878496;
       
     }
     .tarifBox2Box2Button{
      position: absolute;
      width: 90%;
      bottom: 61%;
    }
      .tarifBox2Box2Button  button {
       font-size: 12px;
     padding: 12px 35px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% -2%;
     }
     .tarifBox2 .tarifBox2Box3{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 30px 50px 30px;
       width: 100%;
       margin-top: 30%;
     }
     .tarifBox2 .tarifBox2Box3 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       position: relative;
     }
     .tarifBox2 .tarifBox2Box3  .tarifBox2Box3text ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       color: #878496;
       
     }
     .tarifBox2Box3Button{
      position: absolute;
      width: 90%;
      bottom: 30%;
    }
      .tarifBox2Box3Button  button {
       font-size: 12px;
     padding: 12px 35px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% -2%;
     }
     .tarifBox2 .tarifBox2Box4{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 30px 20px 30px;
       width: 100%;
       margin-top: 30%;
       position: relative;
     }
     .tarifBox2 .tarifBox2Box4 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       
     }
     .tarifBox2 .tarifBox2Box4  .tarifBox2Box4text ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       color: #878496;
       
     }
     .tarifBox2Box4Button{
      position: absolute;
      width: 90%;
      bottom: -9%;
    }
      .tarifBox2Box4Button a {
       font-size: 12px;
     padding: 12px 35px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% -2%;
     }
     .own{
      display: flex;
      width: 100%;
      justify-content: center;
      position: relative;
      top: 80px;
      
     }
     .own a{
      align-items: center;
      background: #ffffff;
      border-style: none;
      color: #5131e4;
      display: flex;
      font-size: 18px;
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 520;
      justify-content: center;
      width: 100%;
     
      
    }
 
     .tarifBox3{
       width: 100%;
       margin-top: 30%;
       display: block;
     }
     .tarifBox3text h1{
       width: 70%;
     }
     .tarifBox3text .tarifBox3list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list1 {
       width: 100%;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list1 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 63%;   
       margin-left: 20px; 
     }
     .tarifBox3text .tarifBox3list .tarifBox3list2 {
       width: 100%;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list2 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 57%;   
       margin-left: 20px;
     }
     .tarifBox3img  {
       width: 100%;
     }
     .tarifBox3img  img {
       width: 100%;
       border-radius: 15px; 
       margin-left: 0;
     }
     .tarifBox4{
       width: 100%;
       display: block;
     }
     .tarifBox4text h1{
       width: 70%;
     }
     .tarifBox4text .tarifBox4list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list1 {
       width: 100%;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list1 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 72%;   
       margin-left: 20px; 
     }
     .tarifBox4text .tarifBox4list .tarifBox4list2 {
       width: 100%;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list2 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 56%;   
       margin-left: 20px;
     }
     .tarifBox4img  {
       width: 100%;
     }
     .tarifBox4img  img {
       width: 100%;
       border-radius: 15px; 
       margin-left: 0;
     }
     .tarifBox5{
       width: 100%;
       display: block;
     }
     .tarifBox5text h1{
       width: 68%;
     }
     .tarifBox5text .tarifBox5list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list1 {
       width: 100%;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list1 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 60%;   
       margin-left: 20px; 
     }
     .tarifBox5text .tarifBox5list .tarifBox5list2 {
       width: 100%;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list2 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 58%;   
       margin-left: 20px;
     }
     .tarifBox5img  {
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 0px 30px;
       display: flex;
       margin:10% 0;
       width: 100%;
     }
     .tarifBox5img  img {
       width: 141%;
       border-radius: 15px;
       margin-left: -70px;
       margin-top: -21px;
     }
 
     .tarifBox10 {
      background-color: #f9f9f9;
      border-radius: 35px;
      padding: 30px 30px;
      display: block;
      height:auto;
    }
    .tarifBox10 .tarifBox10tb {
      display: block;
    }
    .tarifBox10 .tarifBox10ti h1 {
      width: 85%;
    }
    .tarifBox10 .tarifBox10ti p {
      width: 73%;
    }
    .tarifBox10 .tarifBox10Button10 a button {
      font-size: 12px;
      padding: 12px 66px;
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 500;
      text-align: center;
      background: #5231e4;
      color: #ffffff;
      border-radius: 10px;
      border-style: none;
      margin: 30px 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
    .tarifBox10 .tarifBox10img img {
      width: 100%;
      position: relative;
      left: 0px;
      top: 35px;
    }
 }
  @media (max-width: 390px) {
    .tarif{
     padding: 0 16px;
    }
     h1 {
       font-weight: 700;
       font-size: 24px;
       color: #0e082b;
     }
     p {
       font-weight: 300;
       font-size: 14px;
       color: #0e082b;
       margin-top: 23px;
     }
     .tarifBox1{
     padding-top: 90px;
     width: 100%;
     display: flex;
     flex-direction: column;
     position: relative;
     }
     .tarifBox1img{
         width: 100%;
         overflow: hidden;
     }
     .tarifBox1img img{
         width: 190%;
         margin-top: -12%;
         margin-left: -43%;
         position: relative;
         z-index: -1;
     }
     .tarifBox1 .tarifBox1text h1 {
        width: 64%;
     }
     .tarifBox1 .tarifBox1text p {
       width: 80%;  
     }
     .tarifBox2{
       width: 100%;
       display: block;
       position: relative;
       z-index: 0;
       margin-top: -35%;
     }
     .tarifBox2 h2{
       font-size: 18px;
       margin: 5% 0; 
       width: 70%;
 
     }
     .tarifBox2 p{
       color: #878496;
       margin-top: 10px; 
       width: 100%;
     }
     .tarifBox2 h3{
       color: #5231e4;;
       margin: 10% 0; 
       width: 80%;
     }
     .tarifBox2 .tarifBox2Box1{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 30px 50px 30px;
       width: 100%
     }
     .tarifBox2 .tarifBox2Box1 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
     }
      .tarifBox2Box1Button  button {
       font-size: 12px;
     padding: 12px 35px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% -5%;
      
     }
     .tarifBox2 .tarifBox2Box2{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 30px 50px 30px;
       width: 100%
     }
     .tarifBox2 .tarifBox2Box2 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
     }
     .tarifBox2 .tarifBox2Box2  .tarifBox2Box2text ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       color: #878496;
       
     }
      .tarifBox2Box2Button  button {
       font-size: 12px;
     padding: 12px 35px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% -5%;
     }
     .tarifBox2 .tarifBox2Box3{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 30px 50px 30px;
       width: 100%
     }
     .tarifBox2 .tarifBox2Box3 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
     }
     .tarifBox2 .tarifBox2Box3  .tarifBox2Box3text ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       color: #878496;
       
     }
      .tarifBox2Box3Button  button {
       font-size: 12px;
     padding: 12px 35px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% -5%;
     }
     .tarifBox2 .tarifBox2Box4{
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 20px 30px 50px 30px;
       width: 100%
     }
     .tarifBox2 .tarifBox2Box4 ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
     }
     .tarifBox2 .tarifBox2Box4  .tarifBox2Box4text ul li{
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 87%;   
       margin-left: 20px;
       color: #878496;
       
     }
      .tarifBox2Box4Button  button {
       font-size: 12px;
     padding: 12px 35px;
     font-family: "SF Pro Display";
     font-style: normal;
     font-weight: 500;
     text-align: center;
     color: #ffffff;
     background: #5231e4;
     border-radius: 10px;
     border-style: none;
     margin-left: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     width: 100%;
     margin: 10% -5%;
     }
 
     .tarifBox3{
       width: 100%;
     }
     .tarifBox3text h1{
       width: 80%;
     }
     .tarifBox3text .tarifBox3list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list1 {
       width: 100%;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list1 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 73%;   
       margin-left: 20px; 
     }
     .tarifBox3text .tarifBox3list .tarifBox3list2 {
       width: 100%;
     }
     .tarifBox3text .tarifBox3list .tarifBox3list2 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 67%;   
       margin-left: 20px;
     }
     .tarifBox3img  {
       width: 100%;
     }
     .tarifBox3img  img {
       width: 100%;
       border-radius: 15px; 
     }
     .tarifBox4{
       width: 100%;
     }
     .tarifBox4text h1{
       width: 80%;
     }
     .tarifBox4text .tarifBox4list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list1 {
       width: 100%;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list1 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 82%;   
       margin-left: 20px; 
     }
     .tarifBox4text .tarifBox4list .tarifBox4list2 {
       width: 100%;
     }
     .tarifBox4text .tarifBox4list .tarifBox4list2 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 66%;   
       margin-left: 20px;
     }
     .tarifBox4img  {
       width: 100%;
     }
     .tarifBox4img  img {
       width: 100%;
       border-radius: 15px; 
     }
     .tarifBox5{
       width: 100%;
     }
     .tarifBox5text h1{
       width: 78%;
     }
     .tarifBox5text .tarifBox5list {
       display: flex;
       padding: 0 0px;
       overflow: hidden;
       height: auto;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list1 {
       width: 100%;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list1 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 70%;   
       margin-left: 20px; 
     }
     .tarifBox5text .tarifBox5list .tarifBox5list2 {
       width: 100%;
     }
     .tarifBox5text .tarifBox5list .tarifBox5list2 ul li {
       font-size: 12px;
       padding: 10px 0px 10px 0px;
       width: 66%;   
       margin-left: 20px;
     }
     .tarifBox5img  {
       background-color: #f9f9f9;
       border-radius: 35px;
       padding: 0px 30px;
       display: flex;
       margin:10% 0;
       width: 100%;
     }
     .tarifBox5img  img {
       width: 141%;
       border-radius: 15px;
       margin-left: -70px;
       margin-top: -21px;
     }
 
     .tarifBox10 {
      background-color: #f9f9f9;
      border-radius: 35px;
      padding: 30px 30px;
      display: block;
      height: auto;
    }
    .tarifBox10 .tarifBox10ti h1 {
      width: 100%;
    }
    .tarifBox10 .tarifBox10ti p {
      width: 86%;
    }
    .tarifBox10 .tarifBox10Button10 a button {
      font-size: 12px;
      padding: 12px 48px;
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 500;
      text-align: center;
      background: #5231e4;
      color: #ffffff;
      border-radius: 10px;
      border-style: none;
      margin: 30px 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
    .tarifBox10 .tarifBox10img img {
      width: 100%;
      position: relative;
      left: 0;
      top: 35px;
    }
 }
  @media (max-width: 320px) {
   .tarif{
    padding: 0 16px;
   }
    h1 {
      font-weight: 700;
      font-size: 24px;
      color: #0e082b;
    }
    p {
      font-weight: 300;
      font-size: 14px;
      color: #0e082b;
      margin-top: 23px;
    }
    .tarifBox1{
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    }
    .tarifBox1img{
        width: 100%;
        overflow: hidden;
    }
    .tarifBox1img img{
        width: 190%;
        margin-top: -12%;
        margin-left: -43%;
        position: relative;
        z-index: -1;
    }
    .tarifBox1 .tarifBox1text h1 {
       width: 80%;
    }
    .tarifBox1 .tarifBox1text p {
      width: 90%;  
    }
    .tarifBox2{
      width: 100%;
      display: block;
      position: relative;
      z-index: 0;
      margin-top: -35%;
    }
    .tarifBox2 h2{
      font-size: 18px;
      margin: 5% 0; 
      width: 80%;

    }
    .tarifBox2 p{
      color: #878496;
      margin-top: 10px; 
      width: 100%;
    }
    .tarifBox2 h3{
      color: #5231e4;;
      margin: 10% 0; 
      width: 80%;
    }
    .tarifBox2 .tarifBox2Box1{
      background-color: #f9f9f9;
      border-radius: 35px;
      padding: 20px 30px 50px 30px;
      width: 100%;
      position: relative;
    }
    .tarifBox2 .tarifBox2Box1 ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 87%;   
      margin-left: 20px;
    }
    .tarifBox2Box1Button{
      position: absolute;
      width: 90%;
      bottom: -15%;
    }
     .tarifBox2Box1Button button {
      font-size: 12px;
    padding: 12px 35px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 100%;
    margin: 10% -5%;
     
    }
    .tarifBox2 .tarifBox2Box2{
      background-color: #f9f9f9;
      border-radius: 35px;
      padding: 20px 30px 50px 30px;
      width: 100%;
      position: relative;
    }
    .tarifBox2 .tarifBox2Box2 ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 87%;   
      margin-left: 20px;
    }
    .tarifBox2 .tarifBox2Box2  .tarifBox2Box2text ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 87%;   
      margin-left: 20px;
      color: #878496;
      
    }
    .tarifBox2Box2Button{
      position: absolute;
      width: 90%;
      bottom: -11%;
    }
     .tarifBox2Box2Button a {
      font-size: 12px;
    padding: 12px 35px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 100%;
    margin: 10% -5%;
    }
    .tarifBox2 .tarifBox2Box3{
      background-color: #f9f9f9;
      border-radius: 35px;
      padding: 20px 30px 50px 30px;
      width: 100%;
      position: relative;
    }
    .tarifBox2 .tarifBox2Box3 ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 87%;   
      margin-left: 20px;
    }
    .tarifBox2 .tarifBox2Box3  .tarifBox2Box3text ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 87%;   
      margin-left: 20px;
      color: #878496;
      
    }
    .tarifBox2Box3Button{
      position: absolute;
      width: 90%;
      bottom: -10%;
    }
     .tarifBox2Box3Button a {
      font-size: 12px;
    padding: 12px 35px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 100%;
    margin: 10% -5%;
    }
    .tarifBox2 .tarifBox2Box4{
      background-color: #f9f9f9;
      border-radius: 35px;
      padding: 20px 30px 50px 30px;
      width: 100%;
      position: relative;
    }
    .tarifBox2 .tarifBox2Box4 ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 87%;   
      margin-left: 20px;
    }
    .tarifBox2 .tarifBox2Box4  .tarifBox2Box4text ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 87%;   
      margin-left: 20px;
      color: #878496;
      
    }
    .tarifBox2Box4Button{
      position: absolute;
      width: 90%;
      bottom: -9%;
    }
     .tarifBox2Box4Button button {
      font-size: 12px;
    padding: 12px 35px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 100%;
    margin: 10% -5%;
    }

    .tarifBox3{
      width: 100%;
    }
    .tarifBox3text h1{
      width: 80%;
    }
    .tarifBox3text .tarifBox3list {
      display: flex;
      padding: 0 0px;
      overflow: hidden;
      height: auto;
    }
    .tarifBox3text .tarifBox3list .tarifBox3list1 {
      width: 100%;
    }
    .tarifBox3text .tarifBox3list .tarifBox3list1 ul li {
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 87%;   
      margin-left: 20px; 
    }
    .tarifBox3text .tarifBox3list .tarifBox3list2 {
      width: 100%;
    }
    .tarifBox3text .tarifBox3list .tarifBox3list2 ul li {
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 80%;   
      margin-left: 20px;
    }
    .tarifBox3img  {
      width: 100%;
    }
    .tarifBox3img  img {
      width: 100%;
      border-radius: 15px; 
    }
    .tarifBox4{
      width: 100%;
    }
    .tarifBox4text h1{
      width: 80%;
    }
    .tarifBox4text .tarifBox4list {
      display: flex;
      padding: 0 0px;
      overflow: hidden;
      height: auto;
    }
    .tarifBox4text .tarifBox4list .tarifBox4list1 {
      width: 100%;
    }
    .tarifBox4text .tarifBox4list .tarifBox4list1 ul li {
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 95%;   
      margin-left: 20px; 
    }
    .tarifBox4text .tarifBox4list .tarifBox4list2 {
      width: 100%;
    }
    .tarifBox4text .tarifBox4list .tarifBox4list2 ul li {
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 78%;   
      margin-left: 20px;
    }
    .tarifBox4img  {
      width: 100%;
    }
    .tarifBox4img  img {
      width: 100%;
      border-radius: 15px; 
    }
    .tarifBox5{
      width: 100%;
    }
    .tarifBox5text h1{
      width: 83%;
    }
    .tarifBox5text .tarifBox5list {
      display: flex;
      padding: 0 0px;
      overflow: hidden;
      height: auto;
    }
    .tarifBox5text .tarifBox5list .tarifBox5list1 {
      width: 100%;
    }
    .tarifBox5text .tarifBox5list .tarifBox5list1 ul li {
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 80%;   
      margin-left: 20px; 
    }
    .tarifBox5text .tarifBox5list .tarifBox5list2 {
      width: 100%;
    }
    .tarifBox5text .tarifBox5list .tarifBox5list2 ul li {
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 79%;   
      margin-left: 20px;
    }
    .tarifBox5img  {
      background-color: #f9f9f9;
      border-radius: 35px;
      padding: 0px 30px;
      display: flex;
      margin:10% 0;
      width: 100%;
    }
    .tarifBox5img  img {
      width: 141%;
      border-radius: 15px;
      margin-left: -70px;
      margin-top: -21px;
    }

    .tarifBox10 {
      background-color: #f9f9f9;
      border-radius: 35px;
      padding: 30px 30px;
      display: block;
      height: auto;
    }
    .tarifBox10 .tarifBox10Button10 a button {
      font-size: 12px;
      padding: 12px 36px;
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 500;
      text-align: center;
      background: #5231e4;
      color: #ffffff;
      border-radius: 10px;
      border-style: none;
      margin: 30px 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
    .tarifBox10 .tarifBox10ti h1 {
      width: 107%;
    }
    .tarifBox10 .tarifBox10ti p {
      width: 100%;
    }
  
    .tarifBox10 .tarifBox10img img {
      width: 100%;
      position: relative;
      left: 0;
      top: 35px;
    }

}