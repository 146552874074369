* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  /* .cabinetBox2{
    display: flex;
  } */
 .cabinetBox{
  display: flex;
}

#cabinetBox1_h5 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

.linker {
  background-color:#F6F6F6 ;
  border-radius: 10px;
}

#linker_itme {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#linker_itme  #linker_itme_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60%;
}

#linker_itme_img{
    display: flex;
    height: 40%;
    align-items: center;
}

#linker_itme a {
  width: 83%;
  padding: 10px;
  text-align: center;
  background-color: #DDDDDD;
  border-radius: 10px;
  margin-top: 5px;
}

#cabinetBox1_h5 a{
  width: 20%;
  font-size: 14px;
  color: #727272 !important;
  text-decoration: underline;
}

  .cabinetBox1 a{
    width: 100%;
    color: #9581EE ;
  }
  .cabinetButton button{
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
  }
.cabinetButton1 a button{
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background: #5231e4;
  border-radius: 10px;
  border-style: none;
  text-decoration: none;
}

.cabinetBox3 .CabinetData .CabinetData1 .CabinetData12  #karmir{
  text-decoration: none;
 }

 .cabinetBox3 .CabinetData .CabinetData1 .CabinetData11 #kanac{
  text-decoration: none;

}

.cabinetBox3 button{
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  background: #f3f3f3;
  color: #9A9A9A;
  border-style: none;
  text-decoration: underline;
 
}
.CabinetData{
  width: 100%;
    max-height: 280px;
    overflow-y: scroll;
}
#pad{
   cursor: pointer;
}
.cabinetBox21{
  display: flex;
  justify-content: baseline;
  align-items: baseline;
}
.cabinetBox22{
  display: flex;
  justify-content: baseline;
  align-items: baseline;
}
.cabinetBox23{
  display: flex;
  justify-content: baseline;
  align-items: center;
}
#karmir{
 color: #D14B39;
}
#kanac{
  color: #93D900;
}
․link ul li {
  list-style-type: none;
}
.link ul  li {
  list-style-type: none;
}

.cabinetBox2 {
  line-height: 30px;
}

@media (max-width: 2560px) {

   
   .cabinetBox{
       padding: 230px 100px;
       width: 100%;
   }
   .cabinetBox1{
    width: 40%;
    
  }
 
   .cabinetButton1{
  display: flex;
  width: 100%;
  justify-content: center;
}
   .cabinetBox1 a{
    font-size: 28px;
       width: 100%; 
       align-items: baseline;
       justify-content: start;  
   }

  .cabinetBox23 img {
    width: 5%;
    
  }
  .cabinetBox22 img{
   width: 5%;
  }
  .cabinetBox21 img{
    width: 5%;
  }
   .cabinetButton{
    width: 100%;
    height: 5vh;
    display: flex;
    justify-content: end;
    padding-right: 100px ;
    margin-top: -200px;
    
  }
   .cabinetButton button{
      font-size: 28px;
     padding: 12px 20px;
     width: 20%;
   }
 .cabinetButton1 a button{
   font-size: 28px;
  padding: 12px 60px;
  width: 100%;
  height: 5vh;
  margin-top: 540px;
  
}
.cabinetBox3{
  display: flex;
  justify-content: end;
}
.CabinetData12{
  width: 20%;
  height: 10vh;
  background: #F3F3F3;
  margin-top: 5px;
  display: flex;
  justify-content: space-around;
  margin-left: 0;
  padding: 0px;
  border-radius: 0px;
 }
 .CabinetData1{
  display: flex;
  flex-direction: column;
  padding: 20px 70px;
}
.cabinetBox3 button{
  font-size: 42px;
}


#karmir{
  font-size: 56px;
}
#kanac{
  font-size: 56px;
}

.link{
  display: flex;
  justify-content: center;
}
.link ol  li a{
  font-size: 28px !important;
}
.link ol {
  font-size: 28px;
}
.CabinetData12 p{
  display: flex;
  justify-content:center ;
  width: 100%;
}
.xcv{
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.xcv h4{
  color: #D14B39;
  font-size: 28px;
}
.CabinetData12 p{
  font-size: 28px !important;
  display: flex;
    justify-content: center;
    width: 60%;
}
.linker{
  display: flex;
  justify-content: space-between;
  width: 70%;
  padding: 25px 0;
}
 }
@media (max-width: 1920px) {

   
   .cabinetBox{
    padding: 105px 75px 40px 75px;
    width: 100%;
    } 
    .cabinetBox1{
    width: 30%;
    padding: 60px 0;
  }


  .cabinetBox1 p{
    margin-left: 10px;
  }


  .cabinetBox23 img {
    width: 5%; 
  }
  .cabinetBox22 img{
   width: 5%;
  }
  .cabinetBox21 img{
    width: 5%;
  }
   .cabinetButton1{
  display: flex;
  width: 100%;
  justify-content: center;
}
   .cabinetBox1 a{
    font-size: 15px;
       width: 100%; 
       align-items: baseline;
       justify-content: start;  
   }
 
   .cabinetButton{
    display: flex;
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right:80px ;
    margin-top: -290px;
  }
   .cabinetButton button{
      font-size: 20px;
     padding: 12px 20px;
     width: 20%;
   }
 .cabinetButton1 a button{
   font-size: 20px;
  padding: 12px 60px;
  width: 100%;
  margin-top: 110px;
}
.cabinetBox3 button{
  font-size: 28px;
}

#karmir{
  font-size: 26px;
 }
 #kanac{
  font-size: 23px;
 }
 .xcv h4{
  font-size: 24px;
}
 .CabinetData12 {
  width: 100%;
  background: #F3F3F3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  padding: 15px 30px;
  border-radius: 10px;
}
.CabinetData11 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  flex-direction: column;
  border-radius: 10px;
  line-height: 30px;
}

.CabinetData11 ul {
  width: 100%;
  list-style: none;
}

.CabinetData11 ul a{
  text-decoration: underline;
  color: #5131e4 !important;
  cursor: pointer;
}

.CabinetData11 ul li {
  width: 20%;
  display: flex;
  justify-content: space-between;
}

.link ol li a {
  font-size: 20px !important;
}
.CabinetData12 p {
  font-size: 20px !important;
  display: flex;
  justify-content: center;
  width: 100%;
}
   }
@media (max-width: 1440px) {

   
   .cabinetBox{
       padding: 90px 80px;
       width: 100%;
   }
   .cabinetBox1{
    width: 40%;
  }
   .cabinetBox1 h6{
    font-size: 16px;
       width: 30%;   
   }
   .cabinetButton1{
  display: flex;
  width: 100%;
  justify-content: center;
}
   .cabinetBox1 a{
    font-size: 16px;
       width: 100%; 
       align-items: baseline;
       justify-content: start;  
   }
   .cabinetBox2 p{
    font-size: 18px !important;

   }
   .cabinetBox21 p {
    margin-left: 10px;
  }
  .cabinetBox22 p {
    margin-left: 10px;
  }
  .cabinetBox23 p {
    margin-left: 10px;
  }
  .cabinetBox23 img {
    position: relative;
    top: 10px;
    width: 5%;
    
  }
  .cabinetBox22 img{
   width: 5%;
  }
  .cabinetBox21 img{
    width: 5%;
  }
   .cabinetButton{
    width: 100%;
    justify-content: end;
    padding-right:40px ;
    margin-top: -200px;
    
  }
   .cabinetButton button{
      font-size: 14px;
     padding: 12px 20px;
     width: 20%;
   }
 .cabinetButton1 a button{
   font-size: 14px;
  padding: 12px 60px;
  width: 100%;
  margin-top: 0px;
  margin: 250px 0 30px 0px;
}
.cabinetBox3 button {
  font-size: 20px;
}

#karmir{
  font-size: 20px;
 }
 #kanac{
  font-size: 20px;
 }
 .CabinetData12 {
  width: 100%;
  margin-left: 0px;
  padding: 15px 30px;
  border-radius: 10px;
  height: auto;
}
.CabinetData11 {
  width: 100%;
  border-radius: 10px;
  line-height: 30px;
}
.xcv{
  width: 100%;
  justify-content: start;
  align-items: center;
}
.xcv p{
  margin-top: 0;
}
.xcv h4 {
  font-size: 18px;
}

.link ol  li a{
  font-size: 18px !important;
}
.link ol {
  font-size: 18px ;
}
.CabinetData12 p{
  font-size: 18px !important;
  display: flex;
    justify-content: center;
    width: 65%;
}
#linker_itme a{
  font-size: 12px;
}
#linker_itme #linker_itme_content {
  height: 40%;
}
.linker {
 
  padding: 100px 0;
}
 }
@media (max-width: 1366px) {
  .cabinetBox1 p{
       font-size: 16px !important;
       
   }
   
   .cabinetBox{
       padding: 120px 80px;
       width: 100%;
   }
   .cabinetBox1{
    width: 40%;
  }
   .cabinetBox1 h6{
    font-size: 16px;
       width: 16%;   
   }
   .cabinetButton1{
  display: flex;
  width: 100%;
  justify-content: center;
}
   .cabinetBox1 a{
    font-size: 12px;
       width: 100%; 
       align-items: baseline;
       justify-content: start;  
   }
   .cabinetButton{
    width: 100%;
    justify-content: end;
    padding-right:40px ;
    margin-top: -200px;
    
  }
   .cabinetButton button{
      font-size: 14px;
     padding: 12px 20px;
     width: 15%;
   }
  
 .cabinetButton1 button{
   font-size: 12px;
  padding: 12px 60px;
  width: 100%;
  margin-top: 300px;
  
}
.cabinetBox3 button{
  font-size: 18px;
}

.CabinetData12 p{
    width: 75% ;
}
.cabinetBox23 img {
  top: 0px;
  
}
 }
@media (max-width: 1024px) {
  .cabinetBox1 p{
       font-size: 14px !important;
       
   }
   
   .cabinetBox{
       padding: 50px 60px;
       width: 100%;
   }
   .cabinetBox1{
    width: 40%;
  }
   .cabinetBox1 h6{
    font-size: 16px;
       width: 30%;   
   }
   .cabinetButton1{
  display: flex;
  width: 100%;
  justify-content: center;
}
   .cabinetBox1 a{
    font-size: 14px;
       width: 100%; 
       align-items: baseline;
       justify-content: start;  
   }
   .cabinetBox2 p{
    font-size: 16px !important;
   }
   .cabinetBox21 p {
    margin-left: 10px;
  }
  .cabinetBox22 p {
    margin-left: 10px;
  }
  .cabinetBox23 p {
    margin-left: 10px;
  }
  .cabinetBox23 img {
    top: 0;
    width: 9%;
  }
  .cabinetBox22 img{
   width: 9%;
  }
  .cabinetBox21 img{
    width: 9%;
  }
   .cabinetButton{
    width: 100%;
    justify-content: end;
    padding-right:40px ;
    margin-top: -200px;
  }
   .cabinetButton button{
      font-size: 12px;
     padding: 12px 20px;
     width: 20%;
   }
 .cabinetButton1 a button{
   font-size: 12px;
  padding: 12px 60px;
  width: 100%;
  margin-top: 300px; 
}
.CabinetData11 ul li span{
  font-size: 14px;
}

#karmir{
  font-size: 18px;
 }
 #kanac{
  font-size: 18px;
 }
 .xcv h4{
  font-size: 14px;
}
.xcv{
  width: 100%;
  justify-content: start;
  align-items: center;
}
.CabinetData1 {

  padding: 90px 70px;
}
.CabinetData12 {
  width: 100%;
  margin-left: 0px;
  padding: 15px 30px;
  border-radius: 10px;
  height: auto;
}
.CabinetData11 {
  width: 100%;
  border-radius: 10px;
  line-height: 30px;
}
.cabinetBox3 button{
  font-size: 14px;
}
.link ol  li a{
  font-size: 14px !important;
}
.link ol {
  font-size: 18px ;
}
.CabinetData12 p{
  font-size: 14px !important;
  display: flex;
    justify-content: center;
    width: 76 !important;
}
.linker {
  width: 100%;
}
#linker_itme a {
  font-size: 10px;
  width: 95%;
}
 }
 @media (max-width: 768px) {
  .cabinetBox1 p{
       font-size: 14px !important;
       
   }
   
   .cabinetBox{
       padding: 50px 60px;
       width: 100%;
   }
   .cabinetBox1{
    width: 50%;
  }
   .cabinetBox1 h6{
    font-size: 16px;
       width: 30%;   
   }
   .cabinetButton1{
  display: flex;
  width: 100%;
  justify-content: center;
}
   .cabinetBox1 a{
    font-size: 14px;
       width: 100%; 
       align-items: baseline;
       justify-content: start;  
   }
   .cabinetBox2 p{
    font-size: 14px !important;
   }
   .cabinetBox21 p {
    margin-left: 10px;
  }
  .cabinetBox22 p {
    margin-left: 10px;
  }
  .cabinetBox23 p {
    margin-left: 10px;
  }
  .cabinetBox23 img {
    top: 0;
    width: 9%;
  }
  .cabinetBox22 img{
   width: 9%;
  }
  .cabinetBox21 img{
    width: 9%;
  }
   .cabinetButton{
    width: 100%;
    justify-content: end;
    padding-right:40px ;
    margin-top: -200px;
  }
   .cabinetButton button{
      font-size: 12px;
     padding: 12px 20px;
     width: 20%;
   }
 .cabinetButton1 a button{
   font-size: 12px;
  padding: 12px 60px;
  width: 100%;
  margin-top: 300px; 
}
.CabinetData11 ul li span{
  font-size: 14px;
}

#karmir{
  font-size: 18px;
 }
 #kanac{
  font-size: 18px;
 }
 .xcv h4{
  font-size: 14px;
}
.xcv{
  width: 100%;
  justify-content: start;
  align-items: center;
}
.CabinetData1 {

  padding: 90px 70px;
}
.CabinetData12 {
  width: 100%;
  margin-left: 0px;
  padding: 15px 30px;
  border-radius: 10px;
  height: auto;
}
.CabinetData11 {
  width: 100%;
  border-radius: 10px;
  line-height: 30px;
}
.cabinetBox3 button{
  font-size: 14px;
}
.link ol  li a{
  font-size: 14px !important;
}
.link ol {
  font-size: 18px ;
}
.CabinetData12 p{
  font-size: 14px !important;
  display: flex;
    justify-content: center;
    width: 76 !important;
}
.linker {
  width: 100%;
}
#linker_itme a {
  font-size: 10px;
  width: 75%;
}
#linker_itme #linker_itme_content span{
  font-size: 10px;
}
#linker_itme_img img {
  width: 100%;
}
 }

@media (max-width: 425px) {
  .cabinetBox1 p{
       font-size: 14px !important;
       
   }
   
   .cabinetBox{
       padding: 0px 16px;
       width: 100%;
       display: flex;
       flex-direction: column;
   }
   .cabinetBox1{
    width: 100%;
    display: block;
    align-items: baseline;
  }
   .cabinetBox1 h6{
    font-size: 14px;
       width: 100%;   
   }
   .cabinetButton1{
  display: flex;
  width: 100%;
  justify-content: center;
}
   .cabinetBox1 a{
    font-size: 14px;
       width: 100%; 
       align-items: baseline;
       justify-content: end;  
   }
   .cabinetBox2 p{
    font-size: 16px !important;
   }
   .cabinetBox21 p {
    margin-left: 10px;
  }
  .cabinetBox22 p {
    margin-left: 10px;
  }
  .cabinetBox23 p {
    margin-left: 10px;
  }
  .cabinetBox23 img {
    top:0px;
    width: 6%;
    
  }
  .cabinetBox22 img{
   width: 6%;
  }
  .cabinetBox21 img{
    width:6%;
  }
   .cabinetButton{
    width: 100%;
    justify-content: start;
    padding-left:16px ;
    margin-top: 0px;
    
  }
   .cabinetButton button{
      font-size: 12px;
     padding: 12px 20px;
     width: 50%;
   }
 
 .cabinetButton1 a button{
   font-size: 12px;
  padding: 12px 20px;
  width: 100%;
  margin-top: 200px;
  height: 5vh;
}
.cabinetBox3{
  display: block;
  justify-content: center;
}

#karmir{
  font-size: 18px;
 }
 #kanac{
  font-size: 18px;
 }

 .CabinetData1{
  display: block;
}
.xcv{
  align-items: baseline;
}
.link{
  display: flex;
  width: 100%;
  justify-content: center;
}
.CabinetData12 p {
  width: 70%;
}
.linker{
  display: flex;
  justify-content: start;
  width: 100%;
  margin-top: 20px;
}
.linker img{
  width: 90%;
}
#linker_itme #linker_itme_content span {
  font-size: 9px;
}
.CabinetData11 ul li {
  width: 52%;
}


 }
@media (max-width: 390px) {
  .cabinetBox1 p{
       font-size: 14px !important;
       
   }
   
   .cabinetBox{
       padding: 90px 16px;
       width: 100%;
   }
    h6{
    font-size: 14px;
       width: 100%;   
   }
   .cabinetButton button{
      font-size: 12px;
     padding: 12px 20px;
   }
 
 .cabinetButton1 button{
   font-size: 12px;
  padding: 12px 20px;
  margin-top: 200px;
  
}
#linker_itme #linker_itme_content span {
  font-size: 12px;
}
#linker_itme a {
  font-size: 10px;
  width: 90%;
}
 }
  @media (max-width: 320px) {
   .cabinetBox1 p{
        font-size: 14px !important;
        
    }
    
    .cabinetBox{
        padding: 90px 16px;
        width: 100%;
    }
     h6{
     font-size: 14px;
        width: 100%;   
    }
    .cabinetBox21 p {
      margin-left: 10px;
    }
    .cabinetBox22 p {
      margin-left: 10px;
    }
    .cabinetBox23 p {
      margin-left: 10px;
    }
    .cabinetBox23 img {
      position: relative;
      top:6px
    }
    .cabinetButton button{
       font-size: 12px;
      padding: 12px 20px;
    }
  .cabinetButton1 button{
    font-size: 12px;
   padding: 12px 20px;
   margin-top: 200px;
   
 }
 .CabinetData12 p {
  width: 77%;
}
#linker_itme a {
  font-size: 10px;
  width: 95%;
}
  }