* {
margin: 0;
padding: 0;
box-sizing: border-box;
}
@media (max-width: 2560px) {
    .reset {
        padding: 640px 700px;

    }
    .resetBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
    }
    .resetBox{
       display: flex;
       flex-direction: row-reverse;
       height: 30vh;
       
       
    }
    .resetBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 42px;
        width: 100%;
        padding-top: 220px;
        padding-right: 120px;
    }
    .resetBox img {
       padding: 150px 350px 0 0;
       width: 120%;
    }
}
@media (max-width: 1920px) {
    .reset {
        padding: 390px 630px;

    }
    .resetBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
    }
    .resetBox{
       display: flex;
       flex-direction: row-reverse;
       height: 30vh;
       
       
    }
    .resetBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 32px;
        width: 100%;
        padding-top: 90px;
        padding-right: 60px;
    }
    .resetBox img {
       padding: 60px 100px 0 0;
    }
}
@media (max-width: 1440px) {
    .reset {
        padding: 320px 420px;

    }
    .resetBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
    }
    .resetBox{
       display: flex;
       flex-direction: row-reverse;
       height: 30vh;
       
       
    }
    .resetBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
        width: 100%;
        padding-top: 90px;
        padding-right: 60px;
    }
    .resetBox img {
       padding: 60px 100px 0 0;
       width: 100%;
    }
}
@media (max-width: 1366px) {
    .reset {
        padding: 320px 420px;

    }
    .resetBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
    }
    .resetBox{
       display: flex;
       flex-direction: row-reverse;
       height: 30vh;
       
       
    }
    .resetBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
        width: 100%;
        padding-top: 80px;
    }
    .resetBox img {
       padding: 50px 80px 0 0;
    }
}
@media (max-width: 1024px) {
    .reset {
        padding: 320px 260px;

    }
    .resetBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
    }
    .resetBox{
       display: flex;
       flex-direction: row-reverse;
       height: 30vh;
       
       
    }
    .resetBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
        width: 100%;
        padding-top: 70px;
        padding-right: 20px;
    }
    .resetBox img {
       padding: 40px 80px 0 0;
    }
}
@media (max-width: 768px) {
    .reset {
        padding: 280px 140px;

    }
    .resetBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
    }
    .resetBox{
       display: flex;
       flex-direction: row-reverse;
       height: 30vh;
       
       
    }
    .resetBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
        width: 100%;
        padding-top: 60px;
    }
    .resetBox img {
       padding: 30px 60px 0 0;
    }
}
@media (max-width: 425px) {
    .reset {
        padding: 290px 16px;

    }
    .resetBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
    }
    .resetBox{
        display: block;
     }
    .resetBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
        width: 100%;
        padding-top: 0px;
    }
    .resetBox2 img {
       padding: 20px 120px;
    }
}
@media (max-width: 390px) {
    .reset {
        padding: 290px 16px;

    }
    .resetBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
    }
    .resetBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
        width: 104%;
    }
    .resetBox img {
       padding: 20px 100px;
    }
}
@media (max-width: 320px) {
    .reset {
        padding: 290px 16px;

    }
    .resetBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
    }
    .resetBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
        width: 114%;
    }
    .resetBox2 img {
       padding: 20px 70px;
    }
   

  }