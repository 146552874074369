@media (max-width: 2560px) {
    .download{
        padding: 0 100px;
    }
        
    .downloadBox{
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        
    }
    .downloadBoxti{
        display: flex;
        height: 52vh;
    }
    .downloadBox1text {
       margin-top: 24%;
    }

    .downloadBox1text h1{
        width: 50%;
    }
    .downloadBox1text p{
        width: 85%;
    }
    .downloadBoximg {
        width: 100%;
        
    }
    .downloadBoximg img {
        width: 100%;
        margin-top: -4%;
        margin-left: -10%;
        z-index: -1;
    }
    .downloadBox2text{
       height: 30vh;
       margin-top: -70px;
    }
    .downloadBox2text #list {
    
     border-radius: 7px;
        width: 22%;
        padding: 8px ;
        border-color: #E1E1E1;
        font-size: 30px;
        background-color:#F7F5FE  ;
    }
    .downloadBox2text p{
        color: #878496;
        font-size: 35px;
        width: 26%;
        
    }
    
    
    }
 @media (max-width: 1920px) {
        .download{
            padding: 0 100px;
        }
            
        .downloadBox{
            padding-top: 90px;
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            
        }
        .downloadBoxti{
            display: flex;
            height: 52vh;
        }
        .downloadBox1text {
           margin-top: 24%;
        }
    
        .downloadBox1text h1{
            width: 50%;
        }
        .downloadBox1text p{
            width: 85%;
        }
        .downloadBoximg {
            width: 100%;
            
        }
        .downloadBoximg img {
            width: 100%;
            margin-top: -4%;
            margin-left: -10%;
            z-index: -1;
        }
        .downloadBox2text{
           height: 30vh;  
          margin-top: 120px;

        }
        .downloadBox2text #list {
        
         border-radius: 7px;
            width: 22%;
            padding: 8px ;
            border-color: #E1E1E1;
            font-size: 26px;
            background-color:#F7F5FE  ;
        }
        .downloadBox2text p{
            color: #878496;
            font-size: 28px;
            width: 26%;
            
        }
        
        
        }
@media (max-width: 1440px) {
    .download{
        padding: 0 80px;
    }
        
    .downloadBox{
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        
    }
    .downloadBoxti{
        display: flex;
        height: 46vh;
    }
    .downloadBox1text {
       margin-top: 14%;
    }

    .downloadBox1text h1{
        width: 50%;
    }
    .downloadBox1text p{
        width: 85%;
    }
    .downloadBoximg {
        width: 100%;
        
    }
    .downloadBoximg img {
        width: 100%;
        margin-top: -8%;
        margin-left: -10%;
        z-index: -1;
    }
    .downloadBox2text{
       height: 30vh;    
       margin-top: -100px;
    }
    .downloadBox2text #list {
    
     border-radius: 7px;
        width: 22%;
        padding: 8px ;
        border-color: #E1E1E1;
        font-size: 16px;
        background-color:#F7F5FE  ;
    }
    .downloadBox2text p{
        color: #878496;
        font-size: 16px;
        width: 22%;
        
    }
    
    
    }
@media (max-width: 1366px) {
        .download{
            padding: 0 80px;
        }
            
        .downloadBox{
            padding-top: 90px;
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            
        }
        .downloadBoxti{
            display: flex;
            height: 46vh;
        }
        .downloadBox1text {
           margin-top: 14%;
        }
    
        .downloadBox1text h1{
            width: 50%;
        }
        .downloadBox1text p{
            width: 85%;
        }
        .downloadBoximg {
            width: 100%;
            
        }
        .downloadBoximg img {
            width: 100%;
            margin-top: -8%;
            margin-left: -10%;
            z-index: -1;
        }
        .downloadBox2text{
           height: 30vh;    
           margin-top: -60px;
        }
        .downloadBox2text #list {
        
         border-radius: 7px;
            width: 22%;
            padding: 8px ;
            border-color: #E1E1E1;
            font-size: 16px;
            background-color:#F7F5FE  ;
        }
        .downloadBox2text p{
            color: #878496;
            font-size: 16px;
            width: 23%;
            
        }
        
        
        }
@media (max-width: 1024px) {
    .download{
        padding: 0 60px;
    }
    h1 {
        font-weight: 700;
        font-size: 32px;
        color: #0e082b;
      }
      p {
        font-weight: 300;
        font-size: 14px;
        color: #0e082b;
      }
        
    .downloadBox{
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        
    }
    .downloadBoxti{
        display: flex;
        height: 38vh;
    }
    .downloadBox1text {
       margin-top: 14%;
    }

    .downloadBox1text h1{
        width: 50%;
    }
    .downloadBox1text p{
        width: 85%;
    }
    .downloadBoximg {
        width: 100%;
        
    }
    .downloadBoximg img {
        width: 110%;
        margin-top: -16%;
        margin-left: -10%;
        z-index: -1;
    }
    .downloadBox2text{
       height: 30vh;
    }
    .downloadBox2text #list {
    
     border-radius: 7px;
        width: 24%;
        padding: 8px ;
        border-color: #E1E1E1;
        font-size: 12px;
        background-color:#F7F5FE  ;
    }
    .downloadBox2text p{
        color: #878496;
        font-size: 12px;
        width: 23%;
        
    }
    
    
    }
@media (max-width: 768px) {
    .download{
        padding: 0 40px;
    }
    h1 {
        font-weight: 700;
        font-size: 24px;
        color: #0e082b;
      }
      p {
        font-weight: 300;
        font-size: 14px;
        color: #0e082b;
      }
        
    .downloadBox{
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        
    }
    .downloadBoxti{
        display: flex;
        height: 26vh;
    }
    .downloadBox1text {
        margin-top: 8%;
       
    }

    .downloadBox1text h1{
        width: 50%;
    }
    .downloadBox1text p{
        width: 85%;
    }
    .downloadBoximg {
        width: 100%;
        
    }
    .downloadBoximg img {
        width: 110%;
        margin-top: -16%;
        margin-left: -10%;
        z-index: -1;
    }
    .downloadBox2text{
       height: 25vh;
       margin-top: -30px;
    }
    .downloadBox2text #list {
    
     border-radius: 7px;
        width: 30%;
        padding: 8px ;
        border-color: #E1E1E1;
        font-size: 12px;
        background-color:#F7F5FE  ;
    }
    .downloadBox2text p{
        color: #878496;
        font-size: 12px;
        width: 31%;
        
    }
    
    
    }
@media (max-width: 425px) {
    .download{
        padding: 0 40px;
    }
        
    .downloadBox{
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .downloadBoxti{
        display: block;
        height: auto;
    }
    .downloadBox1text {
        margin-top: 0;
       
    }
    .downloadBox1text h1{
        width: 50%;
    }
    .downloadBox1text p{
        width: 74%;
    }
    .downloadBoximg {
        width: 100%;
        overflow: hidden;
    }
    .downloadBoximg img {
        width: 150%;
        margin-top: -16%;
        margin-left: -26%;
        z-index: -1;
    }
    .downloadBox2text{
        margin-top: -32%;
        height: auto;
    }
    .downloadBox2text #list {
    
     border-radius: 7px;
        width: 95%;
        padding: 8px ;
        border-color: #E1E1E1;
        font-size: 12px;
        background-color:#F7F5FE  ;
    }
    .downloadBox2text p{
        color: #878496;
        font-size: 12px;
        width: 64%;
        
    }
    
    
    }
@media (max-width: 425px) {
    .download{
        padding: 0 16px;
    }
        
    .downloadBox{
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        
    }
    .downloadBox1text h1{
        width: 50%;
    }
    .downloadBox1text p{
        width: 65%;
    }
    .downloadBoximg {
        width: 100%;
        overflow: hidden;
    }
    .downloadBoximg img {
        width: 150%;
        margin-top: -16%;
        margin-left: -26%;
        z-index: -1;
    }
    .downloadBox2text{
        margin-top: -32%;
    }
    .downloadBox2text #list {
    
     border-radius: 7px;
        width: 95%;
        padding: 8px ;
        border-color: #E1E1E1;
        font-size: 12px;
        background-color:#F7F5FE  ;
    }
    .downloadBox2text p{
        color: #878496;
        font-size: 12px;
        width: 64%;
        
    }
    
    
    }
@media (max-width: 390px) {
    .download{
        padding: 0 16px;
    }
        
    .downloadBox{
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        
    }
    .downloadBox1text h1{
        width: 50%;
    }
    .downloadBox1text p{
        width: 74%;
    }
    .downloadBoximg {
        width: 100%;
        overflow: hidden;
    }
    .downloadBoximg img {
        width: 150%;
        margin-top: -16%;
        margin-left: -26%;
        z-index: -1;
    }
    .downloadBox2text{
        margin-top: -32%;
    }
    .downloadBox2text #list {
    
     border-radius: 7px;
        width: 95%;
        padding: 8px ;
        border-color: #E1E1E1;
        font-size: 12px;
        background-color:#F7F5FE  ;
    }
    .downloadBox2text p{
        color: #878496;
        font-size: 12px;
        width: 64%;
        
    }
    
    
    }
@media (max-width: 320px) {
.download{
    padding: 0 16px;
}
    
.downloadBox{
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    
}
.downloadBox1text h1{
    width: 60%;
}
.downloadBox1text p{
    width: 80%;
}
.downloadBoximg {
    width: 100%;
    overflow: hidden;
}
.downloadBoximg img {
    width: 150%;
    margin-top: -16%;
    margin-left: -26%;
    z-index: -1;
}
.downloadBox2text{
    margin-top: -32%;
}
.downloadBox2text #list {

 border-radius: 7px;
    width: 95%;
    padding: 8px ;
    border-color: #E1E1E1;
    font-size: 12px;
    background-color:#F7F5FE  ;
}
.downloadBox2text p{
    color: #878496;
    font-size: 12px;
    width: 80%;
    
}


}