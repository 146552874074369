* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.retailBox4 .retailBox4list ul li {
  list-style-image: url(./retail_img/Check.svg);
}
.retailBox5 .retailBox5list ul li {
  list-style-image: url(./retail_img/Check.svg);
}
.retailBox7 .retailBox7list ul li {
  list-style-image: url(./retail_img/Check.svg);
}
.retailBox8 .retailBox8list ul li {
  list-style-image: url(./retail_img/Check.svg);
}
.retailBox9 .retailBox9list ul li {
  list-style-image: url(./retail_img/Check.svg);
}
.retailBox3img{
  display: flex;
  justify-content: center;
  width: 100%;
}
.retailBox3img .retailBox3img1{
  display: flex;
  justify-content: space-around;
  width: 50%;
}
.retailBox3img .retailBox3img2{
  display: flex;
  justify-content: space-around;
  width: 50%;
}
@media (max-width: 2560px) {
  .retail {
    padding: 0 100px;
  }

  h1 {
    font-weight: 700;
    font-size: 70px;
    color: #0e082b;
    width: 60%;
  }

  .retailBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: unset;
    position: relative;
  }
  .retailBox1img {
    width: 100%;
    overflow: hidden;
  }

  .retailBox1img img {
    width: 100%;
    margin-left: -70px;
    margin-bottom: -244px;
  }
  .retailBox1 .retailBox1text h1 {
    padding: 180px 0px 0px 10px;
    width: 52%;
  }
  .retailBox1 .retailBox1text {
    padding: 0 0px 0 0;
  }
  .retailBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
    margin-top: 50px;
  }
  .retailBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .retailBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  .retailBox2 .programss img {
    width: 10%;
  }
  .retailBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .retailBox2 h1 {
    width: 70%;
  }
  .retailBox2 p {
    width: 70%;
  }
  .retailBox2 .img2 {
    padding: 0px 0px 0 0;
  }
  .retailBox2 .img2 img {
    width: 140%;
  }
  .retailBox2 .retailBox2text {
    width: 60%;
  }

  .retailBox3 {
    width: 100%;
    margin-top: 100px;
  }
  #xsw{
    width: 140%;
  }
  #cde{
    width: 150%;
  }

  .retailBox3 .retailBox3ti {
    width: 100%;
    display: flex;
  }
  .retailBox3 .retailBox3ti h1 {
    width: 50%;
  }
  .retailBox3img {
    margin-top: 30px;
   }
  .retailBox3img .retailBox3img1 h6{
    font-size: 28px;
    display: flex;
    justify-content: center;
   }
   .retailBox3img .retailBox3img2 h6{
   font-size: 28px;
   display: flex;
    justify-content: center;
   }
   .retailBox3img .retailBox3img1 img{
     width: 100%;
    }
    .retailBox3img .retailBox3img2 img{
    width: 100%;
    }
    #zaq{
      width: 90%;
    }

  .retailBox3 .retailBox3Button3 a button {
    font-size: 40px;
    padding: 6px 14px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 100px 0 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;    
    cursor: pointer;
  }
  .retailBox4 {
    width: 100%;
    display: flex;
    margin-top: 50px;
  }
  .retailBox4 .retailBox4list {
    padding: 0 0px;
    overflow: hidden;
    height: auto;
  }
  .retailBox4 .retailBox4ti h1 {
    width: 88%;
  }
  .retailBox4 .retailBox4ti p {
    width: 48%;
  }
  .retailBox4 .retailBox4list ul li {
    font-size: 36px;
    padding: 40px 0px 10px 0px;
    width: 50%;
  }
  .retailBox4 .retailBox4img {
    margin-left: 0%;
    width: 50%;
  }
  .retailBox41{
    width: 60%;
  }
  .retailBox4 .retailBox4img img {
    width: 100%;
    border-radius: 30px;
  }
  .retailBox4Button4 a button {
    font-size: 40px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 60px 0 50px 950px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .retailBox5 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 70px;
  }
  .retailBox5 .retailBox5m {
    margin-left: 50px;
    width: 60%;
  }
  .retailBox5 .retailBox5ti h1 {
    width: 78%;
  }
  .retailBox5 .retailBox5ti p {
    width: 72%;
  }
  .retailBox5 .retailBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox5 .retailBox5list ul li {
    font-size: 36px;
    padding: 40px 0px 10px 0px;
    width: 60%;
  }
  .retailBox5 .retailBox5img  {
    width: 70%;
    display: flex;
    align-items: center;
  }

  .retailBox5 .retailBox5img img {
    width: 100%;
    margin-left: 0%;
    border-radius: 30px !important;
  }
  .retailBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 0px 0px;
    display: block;
    height: auto;
    margin-top: 100px;
  }
  .retailBox6 .retailBox6ti h1 {
    width: 100%;
    margin-top: 90px;
    margin-right: 100px;
  }
  .retailBox6 .retailBox6ti p {
    width: 52%;
  }

  .retailBox6 .retailBox6tb {
    display: flex;
    flex-direction: row-reverse;
  }
  .retailBox6 .retailBox6Button6 a button {
    font-size: 40px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .retailBox6 .retailBox6img img {
    width: 180%;
    margin-left: -170%;
  }
  .retailBox6Button61 a button {
    font-size: 40px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 100px 0 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .upr a button {
    font-size: 40px;
    padding: 6px 14px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 20px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;    
    cursor: pointer;
  }
  .retailBox7 {
    width: 100%;
    display: flex;
    margin-top: 70px;
  }
  .retailBox7 .retailBox7ti h1 {
    width: 100%;
  }
  .retailBox7 .retailBox7ti p {
    width: 55%;
  }
  .retailBox7 .retailBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox7 .retailBox7img{
    width: 60%;
    display: flex;
    align-items: center;
  }
  .retailBox7 .retailBox7list ul li {
    font-size: 36px;
    padding: 40px 0px 10px 0px;
    width: 50%;
  }
  .retailBox7 .retailBox7img img {
    width: 100%;
    border-radius: 30px;
    margin: 0px 0px 0px 0;
  }
  .retailBox7Button7 a button {
    font-size: 40px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin:100px 0 50px 1120px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .retailBox8 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    margin-top: 70px;
  }
  .retailBox8 .retailBox8m {
    margin-left: 40px;
    width: 85%;
    
  }
  .retailBox8 .retailBox8img {
    margin: 0px 0 0 0;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .retailBox8 .retailBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox8 .retailBox8ti h1 {
    width: 88%;
  }
  .retailBox8 .retailBox8ti p {
    width: 87%;
  }
  .retailBox8 .retailBox8list ul li {
    font-size: 36px;
    padding: 40px 0px 10px 0px;
    width: 57%;
  }
  .retailBox8 .retailBox8img img {
    width: 90%;
    border-radius: 30px;
  }
  .retailBox8 .retailBox8img .burg1 img {
    display: flex;
    position: absolute;
    left: -240px;
    width: 32%;
    top: 260px;
}
  .retailBox8 .retailBox8img p {
    margin: 0px 0px 0 50px;
    width: 83%;
  }
  .retailBox8Button8 a button {
    font-size: 40px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 100px 0 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .retailBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 50px 50px;
    display: block;
    height: auto;
    margin-top: 100px;
  }
  .retailBox10 .retailBox10ti h1 {
    width: 55%;

  }
  .retailBox10 .retailBox10ti p {
    width: 60%;
  }
  .retailBox10 .retailBox10tb {
    display: flex;
  }
  .retailBox10 .retailBox10Button10 a button {
    font-size: 40px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .retailBox10 .retailBox10img img {
    width: 100%;
    position: relative;
    left: -280px;
    top: 58px;
  }
}
@media (max-width: 1920px) {
  .retail {
    padding: 0 80px;
  }
  ul li{
    color: #0e082b;
    font-size: 20px ;

  }

  h1 {
    font-weight: 700;
    font-size: 70px;
    color: #0e082b;
    width: 60%;
  }

  .retailBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: unset;
    position: relative;
  }
  .retailBox1img {
    width: 100%;
    overflow: hidden;
  }

  .retailBox1img img {
    width: 120%;
    margin-left: -70px;
    margin-bottom: -244px;
  }
  .retailBox1 .retailBox1text h1 {
    padding: 150px 0px 0px 10px;
    width: 80%;
  }
  .retailBox1 .retailBox1text {
    padding: 0 0px 0 0;
  }
  .retailBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
  }
  .retailBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .retailBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  .retailBox2 .programss img {
    width: 10%;
  }
  .retailBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .retailBox2 h1 {
    width: 80%;
  }
  .retailBox2 p {
    width: 76%;
  }
  .retailBox2 .img2 {
    padding: 0px 0px 0 0;
  }
  .retailBox2 .img2 img {
    width: 100%;
  }
  .retailBox2 .retailBox2text {
    width: 60%;
  }

  .retailBox3 {
    width: 100%;
  }
  #xsw{
    width: 130%;
  }
  #cde{
    width: 130%;
  }

  .retailBox3 .retailBox3ti {
    width: 100%;
    display: flex;
  }
  .retailBox3 .retailBox3ti h1 {
    width: 50%;
    margin-left: 70px;
  }
  .retailBox3img .retailBox3img1 h6{
    font-size: 20px;
    width: 120%;
   }
   .retailBox3img .retailBox3img2 h6{
   font-size: 20px;
   width: 120%;
   }
   .retailBox3img .retailBox3img1 img{
     width: 120%;
    }
    .retailBox3img .retailBox3img2 img{
    width: 120%;
    }
    #zaq{
      width: 110%;
    }
  .retailBox3 .retailBox3Button3 a button {
    font-size: 28px;
    padding: 6px 14px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 100px 0 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;    
    cursor: pointer;
  }
  .retailBox4 {
    width: 100%;
    display: flex;
  }
  .retailBox4 .retailBox4ti h1 {
    width: 100%;
  }
  .retailBox4 .retailBox4ti p {
    width: 50%;
  }
  .retailBox4 .retailBox4list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox4 .retailBox4list ul li {
    font-size: 20px;
    padding: 40px 0px 10px 0px;
    width: 52%;
  }
  .retailBox4 .retailBox4img  {
    width: 70%;
   display: flex;
   align-items: center;
  }
  .retailBox4 .retailBox4img img {
    width: 100%;
  }
  .retailBox41{
    width: 80%;
  }
  .retailBox4Button4 a button {
    font-size: 28px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 0 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .retailBox5 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .retailBox5 .retailBox5m {
    margin-left: 50px;
    width: 50%;
  }
  .retailBox5 .retailBox5ti h1 {
    width: 88%;
  }
  .retailBox5 .retailBox5ti p {
    width: 74%;
  }
  .retailBox5 .retailBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox5 .retailBox5list ul li {
    font-size: 20px;
    padding: 40px 0px 10px 0px;
    width: 58%;
  }
  .retailBox5 .retailBox5img  {
    width: 50%;
    display: flex;
    align-items: center;
  }

  .retailBox5 .retailBox5img img {
    width: 100%;
    margin-left: 0%;
    border-radius: 30px;
  }
  .retailBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 0px 0px;
    display: block;
    height: auto;
  }
  .retailBox6 .retailBox6ti h1 {
    width: 100%;
  }
  .retailBox6 .retailBox6ti p {
    width: 50%;
  }

  .retailBox6 .retailBox6tb {
    display: flex;
    flex-direction: row-reverse;
  }
  .retailBox6 .retailBox6Button6 a button {
    font-size: 28px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .retailBox6 .retailBox6img img {
    width: 130%;
    margin-left: -70%;
  }
  .retailBox6Button61 a button {
    font-size: 28px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 100px 0 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .upr a button{
    font-size: 28px;
  }
  .retailBox7 {
    width: 100%;
    display: flex;
  }
  .retailBox7 .retailBox7ti h1 {
    width: 100%;
  }
  .retailBox7 .retailBox7ti p {
    width: 55%;
  }
  .retailBox7 .retailBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox71{
    width: 70%;
  }
  .retailBox7 .retailBox7list ul li {
    font-size: 20px;
    padding: 40px 0px 10px 0px;
    width: 52%;
  }
  .retailBox7 .retailBox7img img {
    width: 80%;
  
  
   
  }
  .retailBox7 .retailBox7img img {
    width: 100%;
    border-radius: 35px;
    margin: 0px 0px 0px 0;
   
  }
  .retailBox7Button7 a button {
    font-size: 28px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 750px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .retailBox8 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
  }
  .retailBox8 .retailBox8m {
    margin-left: 40px;
    
  }
  .retailBox8 .retailBox8img {
    margin: 40px 0 0 0;
    width: 100%;
  }
  .retailBox8 .retailBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox8 .retailBox8ti h1 {
    width: 88%;
  }
  .retailBox8 .retailBox8ti p {
    width: 90%;
  }
  .retailBox8 .retailBox8list ul li {
    font-size: 20px;
    padding: 40px 0px 10px 0px;
    width: 52%;
  }
  .retailBox8 .retailBox8img img {
    width: 90%;
  }
  .retailBox8 .retailBox8img .burg1 img {
    display: flex;
    position: absolute;
    left: -200px;
    width: 32%;
    top: 200px;
}
  .retailBox8 .retailBox8img p {
    margin: 0px 0px 0 50px;
    width: 100%;
    font-size: 20px;
  }
  .retailBox8Button8 a button {
    font-size: 28px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .retailBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
    
  }
  .retailBox10 .retailBox10ti h1 {
    width: 55%;

  }
  .retailBox10 .retailBox10ti p {
    width: 60%;
  }
  .retailBox10 .retailBox10tb {
    display: flex;
  }
  .retailBox10 .retailBox10Button10 a button {
    font-size: 24px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .retailBox10 .retailBox10img img {
    width: 100%;
    position: relative;
    left: -80px;
    top: 35px;

  }
}
@media (max-width: 1440px) {
  h1 {
    font-weight: 700;
    font-size: 36px;
    color: #0e082b;
    width: 68%;
  }

  .retailBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: unset;
    position: relative;
  }
  .retailBox1img {
    width: 100%;
    overflow: hidden;
  }

  .retailBox1img img {
    width: 120%;
    margin-left: -60px;
    margin-bottom: -160px;
  }
  .retailBox1 .retailBox1text h1 {
    padding: 120px 0px 0px 10px;
    width: 54%;
  }
  .retailBox1 .retailBox1text {
    padding: 0 0px 0 0;
  }
  .retailBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
  }
  .retailBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .retailBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .retailBox2 h1 {
    width: 65%;
  }
  .retailBox2 p {
    width: 60%;
  }
  .retailBox2 .img2 {
    padding: 0px 0px 0 0;
  }
  .retailBox2 .img2 img {
    width: 100%;
  }
  .retailBox2 .retailBox2text {
    width: 100%;
  }

  .retailBox3 {
    width: 100%;
  }

  .retailBox3 .retailBox3ti {
    width: 100%;
    display: flex;
  }
  .retailBox3 .retailBox3ti h1 {
    width: 50%;
  }
  .retailBox3img .retailBox3img1 h6{
    font-size: 14px;
   }
   .retailBox3img .retailBox3img2 h6{
   font-size: 14px;
   }
   .retailBox3img .retailBox3img1 img{
     width: 100%;
    }
    .retailBox3img .retailBox3img2 img{
    width: 90%;
    }
    #zaq{
      width: 100%;
    }
    #xsw{
      width: 100%;
    }
    #cde{
      width: 100%;
    }

  .retailBox3 .retailBox3Button3 a button {
    font-size: 14px;
    padding: 6px 14px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox4 {
    width: 100%;
    display: flex;
  }
  .retailBox4 .retailBox4ti h1 {
    width: 100%;
  }
  .retailBox4 .retailBox4ti p {
    width: 50%;
  }
  .retailBox4 .retailBox4list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox4 .retailBox4list ul li {
    font-size: 18px;
    padding: 10px 0px 10px 0px;
    width: 50%;
  }
  .retailBox4 .retailBox4img  {
    width: 60%;
    margin-left: 0%;
    display: flex;
    align-items: center;
  }
  .retailBox4 .retailBox4img img {
    width: 90%;
  }
  .retailBox4Button4 a button {
    font-size: 20px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 540px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox5 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .retailBox5 .retailBox5m {
    margin-left: 40px;
    width: 70%;
  }
  
  .retailBox5 .retailBox5ti h1 {
    width: 85%;
  }
  .retailBox5 .retailBox5ti p {
    width: 80%;
  }
  .retailBox5 .retailBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox5 .retailBox5list ul li {
    font-size: 18px;
    padding: 10px 0px 10px 0px;
    width: 62%;
  }
  .retailBox5 .retailBox5img  {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .retailBox5 .retailBox5img img {
    width: 80%;
    margin-left: 0;
  }
  .retailBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 0px 00px;
    display: block;
    height: auto;
  }
  .retailBox6 .retailBox6ti h1 {
    width: 100%;
    margin-top: 50px;
    margin-right: 100px;
  }
  .retailBox6 .retailBox6tb {
    display: flex;
  }
  
  .retailBox6 .retailBox6Button6 a button {
    font-size: 20px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox6 .retailBox6img img {
    width: 100%;
    margin-left: -35%;
  }
  .retailBox6Button61 a button {
    font-size: 20px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .upr a button{
    font-size: 18px;
  }
  .retailBox7 {
    width: 100%;
    display: flex;
  }
  .retailBox7 .retailBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  
  .retailBox7 .retailBox7list ul li {
    font-size: 18px;
    padding: 20px 0px 10px 0px;
    width: 55%;
  }
  .retailBox7 .retailBox7img{
    width: 80%;
  }

  .retailBox7 .retailBox7img img {
    width: 100%;
    border-radius: 30px;
    margin: 0px 0px 0px 0;
    position: relative;
    left: 0px;
  }
  .retailBox7 .retailBox7ti h1 {
    width: 100%;
  }
  .retailBox7 .retailBox7ti p {
    width: 80%;
  }
  .retailBox7Button7 a button {
    font-size: 20px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox8 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative;

  }
  .retailBox8 .retailBox8m {
    margin-left: 40px;
    
  }
  .retailBox8 .retailBox8img {
    margin: 0px 0 0 0;
    width: 100%;
  }
  .retailBox8 .retailBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox8 .retailBox8ti h1 {
    width: 88%;
  }
  .retailBox8 .retailBox8ti p {
    width: 88%;
  }
  .retailBox8 .retailBox8list ul li {
    font-size: 18px;
    padding: 20px 0px 10px 0px;
    width: 53%;
  }
  .retailBox8 .retailBox8img img {
    width: 90%;
  }
  .retailBox8 .retailBox8img .burg1 img {
    display: flex;
    position: absolute;
    left: -130px;
    width: 32%;
    top: 138px;
}
  .retailBox8 .retailBox8img p {
    margin: 0px 0px 0 50px;
    width: 66%;
    font-size: 14px;
  }
  .retailBox8Button8 a button {
    font-size: 20px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: 40vh;
  }
  .retailBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
  }
  .retailBox10 .retailBox10ti h1 {
    width: 55%;

  }
  .retailBox10 .retailBox10ti p {
    width: 60%;
  }
  .retailBox10 .retailBox10tb {
    display: flex;
  }
  .retailBox10 .retailBox10Button10 a button {
    font-size: 18px;
    padding: 14px 70px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox10 .retailBox10img img {
    width: 100%;
    position: relative;
    left: -160px;
    top: 35px;
  }
  .retailBox3img .retailBox3img1 h6 {
    width: 100%;
}
.retailBox3img .retailBox3img2 h6 {

  width: 100%;
}
}
@media (max-width: 1366px) {
  h1 {
    font-weight: 700;
    font-size: 36px;
    color: #0e082b;
    width: 68%;
  }
  p {
    font-weight: 300;
    font-size: 20px;
    color: #0e082b;
    margin-top: 23px;
    width: 50%;
  }
  .retailBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: unset;
    position: relative;
  }
  .retailBox1img {
    width: 100%;
    overflow: hidden;
  }

  .retailBox1img img {
    width: 120%;
    margin-left: -60px;
    margin-bottom: -160px;
  }
  .retailBox1 .retailBox1text h1 {
    padding: 120px 0px 0px 10px;
    width: 56%;
  }
  .retailBox1 .retailBox1text {
    padding: 0 0px 0 0;
  }
  .retailBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
  }
  .retailBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .retailBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .retailBox2 h1 {
    width: 70%;
  }
  .retailBox2 p {
    width: 68%;
  }
  .retailBox2 .img2 {
    padding: 0px 0px 0 0;
  
  }
  .retailBox2 .img2 img {
    width: 100%;
  }
  .retailBox2 .retailBox2text {
    width: 100%;
  }

  .retailBox3 {
    width: 100%;
  }

  .retailBox3 .retailBox3ti {
    width: 100%;
    display: flex;
  }
  .retailBox3 .retailBox3ti h1 {
    width: 60%;
  }
 
  .retailBox3 .retailBox3Button3 a button {
    font-size: 14px;
    padding: 6px 14px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox4 {
    width: 100%;
    display: flex;
  }
  .retailBox4 .retailBox4ti h1 {
    width: 100%;
  }
  .retailBox4 .retailBox4ti p {
    width: 50%;
  }
  .retailBox4 .retailBox4list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox4 .retailBox4list ul li {
    font-size: 18px;
    padding: 10px 0px 10px 0px;
    width: 52%;
  }
  .retailBox4 .retailBox4img  {
    width: 60%;
    margin-left: 0;
  }
  .retailBox4 .retailBox4img img {
    width: 100%;
  }
  .retailBox4Button4 a button {
    font-size: 16px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox5 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .retailBox5 .retailBox5m {
    margin-left: 40px;
    width: 70%;
  }
  .retailBox5 .retailBox5ti h1 {
    width: 90%;
  }
  .retailBox5 .retailBox5ti p {
    width: 82%;
  }
  .retailBox5 .retailBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox5 .retailBox5list ul li {
    font-size: 18px;
    padding: 10px 0px 10px 0px;
    width: 66%;
  }

  .retailBox5 .retailBox5img img {
    width: 80%;
    margin-left: 0;
  }
   .retailBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: 39vh;
  }
  .retailBox6 .retailBox6ti h1 {
    width: 100%;
    margin-top: 40px;
    margin-right: 40px;
  }
  .retailBox6 .retailBox6tb {
    display: flex;
  }
  
  .retailBox6 .retailBox6Button6 a button {
    font-size: 16px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox6 .retailBox6img img {
    width: 100%;
    margin-left: -40%;
  }
  .retailBox6Button61 a button {
    font-size: 16px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox7 {
    width: 100%;
    display: flex;
  }
  .retailBox7 .retailBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  
  .retailBox7 .retailBox7list ul li {
    font-size: 18px;
    padding: 20px 0px 10px 0px;
    width: 63%;
  }
  .retailBox7 .retailBox7img img {
    width: 130%;
    border-radius: 35px;
    margin: 0px 0px 0px 0;
    position: relative;
    left: 0px;
  }
  .retailBox7 .retailBox7ti h1 {
    width: 100%;
  }
  .retailBox7 .retailBox7ti p {
    width: 72%;
  }
  .retailBox7Button7 a button {
    font-size: 16px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 555px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox8 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative;

  }
  .retailBox8 .retailBox8m {
    margin-left: 40px;
    
  }
  .retailBox8 .retailBox8img {
    margin: 0px 0 0 0;
    width: 100%;
  }
  .retailBox8 .retailBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox8 .retailBox8ti h1 {
    width: 88%;
  }
  .retailBox8 .retailBox8ti p {
    width: 95%;
  }
  .retailBox8 .retailBox8list ul li {
    font-size: 18px;
    padding: 20px 0px 10px 0px;
    width: 58%;
  }
  .retailBox8 .retailBox8img img {
    width: 90%;
  }
  .retailBox8 .retailBox8img .burg1 img {
    display: flex;
    position: absolute;
    left: -125px;
    width: 32%;
    top: 120px;
}
  .retailBox8 .retailBox8img p {
    margin: 0px 0px 0 50px;
    width: 80%;
    font-size: 14px;
  }
  .retailBox8Button8 a button {
    font-size: 16px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
 
  .retailBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
  }
  .retailBox10 .retailBox10ti h1 {
    width: 60%;

  }
  .retailBox10 .retailBox10ti p {
    width: 65%;
  }
  .retailBox10 .retailBox10tb {
    display: flex;
  }
  .retailBox10 .retailBox10Button10 a button {
    font-size: 16px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox10 .retailBox10img img {
    width: 100%;
    position: relative;
    left: -160px;
    top: 35px;
  }
}
@media (max-width: 1024px) {
  .retail {
    padding: 0 60px;
  }
  h1 {
    font-weight: 700;
    font-size: 32px;
    color: #0e082b;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
    margin-top: 23px;
    width: 60%;
  }
  .retailBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: unset;
    position: relative;
  }
  .retailBox1img {
    width: 100%;
    overflow: hidden;
  }

  .retailBox1img img {
    width: 100%;
    margin-left: -70px;
    margin-bottom: -100px;
  }
  .retailBox1 .retailBox1text h1 {
    padding: 90px 0px 0px 10px;
    width: 54%;
  }
  .retailBox1 .retailBox1text {
    padding: 0 0px 0 0;
  }
  .retailBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
  }
  .retailBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .retailBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .retailBox2 h1 {
    width: 82%;
  }
  .retailBox2 p {
    width: 70%;
  }
  .retailBox2 .img2 {
    padding: 0 0px 0 0;
  }
  .retailBox2 .img2 img {
    width: 100%;
  }
  .retailBox2 .retailBox2 {
    width: 100%;
  }
  #zaq{
    width: 90%;
  }
  #xsw{
    width: 90%;
  }
  #cde{
    width: 90%;
  }

  .retailBox3 {
    width: 100%;
  }

  .retailBox3 .retailBox3ti {
    width: 100%;
    display: flex;
  }
  .retailBox3 .retailBox3ti h1 {
    width: 50%;
    margin-left: 15px;
  }
  .retailBox3img .retailBox3img1 h6{
    font-size: 14px;
   }
   .retailBox3img .retailBox3img2 h6{
   font-size: 14px;
   }
   .retailBox3img .retailBox3img1 img{
     width: 90%;
    }
    .retailBox3img .retailBox3img2 img{
    width: 90%;
    }

  .retailBox3 .retailBox3Button3 a button {
    font-size: 14px;
    padding: 6px 14px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox4 {
    width: 100%;
    display: flex;
  }
  .retailBox4 .retailBox4ti h1 {
    width: 100%;
  }
  .retailBox4 .retailBox4ti p {
    width: 57%;
  }
  .retailBox4 .retailBox4list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox4 .retailBox4list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 37%;
  }
  .retailBox41{
    width: 100%;
  }
  .retailBox4 .retailBox4img  {
    width: 100%;
    margin-left: 0%;
    display: flex;
    align-items: center;
  }
  .retailBox4 .retailBox4img img {
    width: 100%;
  }
  .retailBox4Button4 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox5 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 50px;
  }
  .retailBox5 .retailBox5m {
    margin-left: 40px;
    width: 100%;
  }
  .retailBox5 .retailBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox5 .retailBox5list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 49%;
  }
  .retailBox5 .retailBox5img img {
    width: 100%;
  }
  .retailBox5 .retailBox5ti h1 {
    width: 90%;
  }
  .retailBox5 .retailBox5ti p {
    width: 90%;
  }
  .retailBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: 35vh;
    margin-top: 40px;
  }
  .retailBox6 .retailBox6tb {
    display: flex;
  }
  .retailBox6 .retailBox6ti h1 {
    width: 100%;
    margin-top: 30px;
  }
  .retailBox6 .retailBox6ti h1 {
    width: 104%;
  }
  .retailBox6 .retailBox6ti p {
    width: 49%;
  }
  .retailBox6 .retailBox6ti {
    width: 60%;
  }
  .retailBox6 .retailBox6Button6 a button {
    font-size: 14px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox6 .retailBox6img img {
    width: 85%;
    margin-left: -8%;
    position: relative;
    top: -20px;
    left: 10px;
  }
  .retailBox6Button61 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .upr a button{
    font-size: 14px;
  }
  .retailBox7 {
    width: 100%;
    display: flex;
    margin-top: 50px;
  }
  .retailBox7 .retailBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox7 .retailBox7ti h1 {
    width: 100%;
  }
  .retailBox7 .retailBox7ti p {
    width: 77%;
  }
  .retailBox7 .retailBox7list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 60%;
  }

  .retailBox7 .retailBox7img img {
    width: 100%;
    border-radius: 30px;
    margin: 0px 0px 0px 0;
  }
  .retailBox7Button7 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 440px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox8 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    margin-top: 50px;
  }
  .retailBox8 .retailBox8m {
    margin-left: 40px;
  }
  .retailBox8 .retailBox8img {
    margin: 0px 0 0 0;
  }
  .retailBox8 .retailBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox8 .retailBox8ti h1 {
    width: 93%;
  }
  .retailBox8 .retailBox8ti p {
    width: 97%;
  }
  .retailBox8 .retailBox8list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 54%;
  }
  .retailBox8 .retailBox8img img {
    width: 100%;
  
  }
  .retailBox8 .retailBox8img .burg1 img {
    display: flex;
    position: absolute;
    left: -98px;
    width: 34%;
    top: 68px;
}
  .retailBox8 .retailBox8img p {
    margin: 0px 0px 0 50px;
    font-size: 12px;
    width: 100%;
  }
  .retailBox8Button8 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
    margin-top: 40px;
  }
  .retailBox10 .retailBox10ti h1 {
    width: 62%;
  }
  .retailBox10 .retailBox10ti p {
    width: 68%;
  }
  .retailBox10 .retailBox10tb {
    display: flex;
  }
  .retailBox10 .retailBox10Button10 a button {
    font-size: 14px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox10 .retailBox10img img {
    width: 100%;
    position: relative;
    left: -90px;
    top:35px;
  }
}

@media (max-width: 768px) {
  .retail {
    padding: 0 40px;
  }
  h1 {
    font-weight: 700;
    font-size: 24px;
    color: #0e082b;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
    margin-top: 23px;
  }
  .retailBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: unset;
    position: relative;
  }
  .retailBox1img {
    width: 100%;
    overflow: hidden;
  }

  .retailBox1img img {
    width: 130%;
    margin-left: -35px;
    margin-bottom: -90px;
  }
  .retailBox1 .retailBox1text h1 {
    padding: 70px 0px 0px 10px;
    width: 57%;
  }
  .retailBox1 .retailBox1text {
    padding: 0 0px 0 0;
  }
  .retailBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
  }
  .retailBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .retailBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .retailBox2 h1 {
    width: 90%;
  }
  .retailBox2 p {
    width: 95%;
  }
  .retailBox2 .img2 {
    padding: 0 0px 0 0;
  }
  .retailBox2 .img2 img {
    width: 100%;
  }
  .retailBox2 .retailBox2 {
    width: 100%;
  }

  .retailBox3 {
    width: 100%;
    margin-top: 50px;
  }

  .retailBox3 .retailBox3ti {
    width: 100%;
    display: flex;
  }
  .retailBox3 .retailBox3ti h1 {
    width: 60%; 
  }
  .retailBox3img .retailBox3img1 h6{
   font-size: 12px;
  }
  .retailBox3img .retailBox3img2 h6{
  font-size: 12px;
  }
  .retailBox3img .retailBox3img1 img{
    width: 90%;
   }
   .retailBox3img .retailBox3img2 img{
   width: 90%;
   }

  .retailBox3 .retailBox3Button3 a button {
    font-size: 14px;
    padding: 6px 14px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox4 {
    width: 100%;
    display: flex;
  }
  .retailBox4 .retailBox4ti h1 {
    width: 100%;
  }
  .retailBox4 .retailBox4ti p {
    width: 78%;
  }
  .retailBox4 .retailBox4list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox4 .retailBox4list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 50%;
  }
  .retailBox4 .retailBox4img  {
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: 0%;
  }
  .retailBox4 .retailBox4img img {
    width: 100%;
  }
  .retailBox4Button4 a button {
    font-size: 14px;
    padding: 6px 6px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox5 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .retailBox5 .retailBox5m {
    margin-left: 40px;
    width: 70%;
  }
  .retailBox5 .retailBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox5 .retailBox5ti h1 {
    width: 102%;
  }
  .retailBox5 .retailBox5ti p {
    width: 100%;
  }
  .retailBox5 .retailBox5list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 80%;
  }
  .retailBox5 .retailBox5img  {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .retailBox5 .retailBox5img img {
    width: 100%;
    margin: 0px 0 0 0;
  }
  .retailBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;  
    height: 32vh;
    margin-top: 30px;
    
  }
  .retailBox6 .retailBox6ti h1 {
    width: 105%;
    margin-top: 20px;
  }
  .retailBox6 .retailBox6ti p {
    width: 70%;
}
  .retailBox6 .retailBox6tb {
    display: flex;
;
  }
  .retailBox6 .retailBox6Button6 a button {
    font-size: 14px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox6 .retailBox6img img {
    width: 90%;
    margin-left: -10%;
    position: relative;
    top: -27px;
    left: 10px;
  }
  .retailBox6Button61 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox7 {
    width: 100%;
    display: flex;
  }
  .retailBox7 .retailBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox7 .retailBox7ti h1 {
    width: 100%;
  }
  .retailBox7 .retailBox7ti p {
    width: 87%;
  }
  .retailBox7 .retailBox7list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 80%;
  }
  .retailBox7 .retailBox7img{
    width: 100%;
    display: flex;
    align-items: center;
  }

  .retailBox7 .retailBox7img img {
    width: 100%;
    border-radius: 35px;
    margin: 22px 0px 0px 0;
  }
  .retailBox7Button7 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox8 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .retailBox8 .retailBox8ti h1 {
    width: 100%;
  }
  .retailBox8 .retailBox8m {
    margin-left: 40px;
  }
  .retailBox8 .retailBox8img {
    margin: 0px 0 0 0;
  }
  .retailBox8 .retailBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox8 .retailBox8list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 76%;
  }
  .retailBox8 .retailBox8ti p {
    width: 100%;
  }
  .retailBox8 .retailBox8img img {
    width: 100%;
  }
  .retailBox8 .retailBox8img .burg1 img {
    display: flex;
    position: absolute;
    left: -78px;
    width: 33%;
    top: 80px;
}
  
  .retailBox8 .retailBox8img .burg img {
    position: absolute;
    left: -60px;
    width: 40%;
  }
  
  .retailBox8 .retailBox8img p {
    width: 70%;
  }
  .retailBox8Button8 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 60px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: 31vh;
  }
  .retailBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
    margin-top: 30px;
  }
  .retailBox10 .retailBox10tb {
    display: flex;
  }
  .retailBox10 .retailBox10Button10 a button {
    font-size: 14px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox10 .retailBox10ti h1 {
    width: 75%;
  }
  .retailBox10 .retailBox10ti p {
    width: 90%;
  }
  .retailBox10 .retailBox10img img {
    width: 120%;
    position: relative;
    left: -40px;
    top: 35px;
  }
}

@media (max-width: 425px) {
  .retail {
    padding: 0 20px;
  }
  h1 {
    font-weight: 700;
    font-size: 24px;
    color: #0e082b;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
    margin-top: 23px;
    width: 85%;
  }
  .retailBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .retailBox1img {
    width: 100%;
    overflow: hidden;
  }

  .retailBox1img img {
    width: 170%;
    margin-left: -13%;
    margin-bottom: -29%;
  }
  .retailBox1 .retailBox1text h1 {
    padding: 0 0px 0 0;
    width: 46%;
  }
  .retailBox1 .retailBox1text {
    padding: 0 0px 0 0;
  }
  .retailBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
  }
  .retailBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .retailBox2 .programss {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  .retailBox2 h1 {
    width: 80%;
  }
  .retailBox2 p {
    width: 84%;
  }

  .retailBox2 .img2 {
    padding: 30px 0;
  }
  .retailBox2 .img2 img {
    width: 100%;
  }
  .retailBox2 .retailBox2 {
    width: 100%;
  }

  .retailBox3 {
    width: 100%;
  }

  .retailBox3 .retailBox3ti {
    width: 100%;
    display: flex;
  }
  .retailBox3 .retailBox3ti h1 {
    width: 60%;
  }
  .retailBox3img .retailBox3img1{
    display: flex;
    justify-content: space-around;
    width: 50%;
    flex-direction: column;
  }
  .retailBox3img .retailBox3img2{
    display: flex;
    justify-content: space-around;
    width: 50%;
    flex-direction: column;
  }
  
  .retailBox3 .retailBox3Button3 a button {
    font-size: 12px;
    padding: 6px 14px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox4 {
    width: 100%;
    display: block;
  }
  .retailBox4 .retailBox4ti  h1{
    width: 100%;
  }
  .retailBox4 .retailBox4ti  p{
    width: 84%;
  }
  .retailBox4 .retailBox4list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox4 .retailBox4list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 60%;
  }
  .retailBox4 .retailBox4img  {
    width: 100%;
    margin-left: 0;
  }
  .retailBox4 .retailBox4img img {
    width: 100%;
  }
  .retailBox4Button4 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox5 {
    width: 100%;
    display: block;
  }
  .retailBox5 .retailBox5m {
    margin-left: 0px;
    width: 100%;
    margin-top: 30px;
  }
  .retailBox5 .retailBox5ti h1 {
    width: 65%;
}
  .retailBox5 .retailBox5ti p {
    width: 76%;
  }
  .retailBox5 .retailBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox5 .retailBox5list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 55%;
  }
  .retailBox5 .retailBox5img {
    width: 100%;
}
  .retailBox5 .retailBox5img img {
    width: 100%;
    margin: 0 0 0 0;
  }
  .retailBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
  }

  .retailBox6 .retailBox6tb {
    display: block;
  }
  .retailBox6 .retailBox6ti h1 {
    width: 65%;
  }
  .retailBox6 .retailBox6ti p {
    width: 80%;
  }
  .retailBox6 .retailBox6ti {
    width: 100%;
  }
  .retailBox6 .retailBox6Button6 a button {
    font-size: 12px;
    padding: 12px 56px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox6 .retailBox6img img {
    width: 100%;
    position: relative;
    top: 34px;
  }

   .retailBox6Button61 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox7 {
    width: 100%;
    display: block;
    margin-top: 30px;
  }
  .retailBox7 .retailBox7ti h1 {
    width: 85%;
  }
  .retailBox7 .retailBox7ti p {
    width: 98%;
  }
  .retailBox7 .retailBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox7 .retailBox7list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 85%;
  }
  .retailBox7 .retailBox7img img {
    width: 100%;
    border-radius: 35px;
    margin: 0px 0px 0px 0;
  }
  .retailBox7Button7 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox8 {
    width: 100%;
    display: block;
    margin-top: 30px;
  }
  .retailBox8 .retailBox8ti h1 {
    width: 85%;
  }
  .retailBox8 .retailBox8ti p {
    width: 83%;
  }
  .retailBox8 .retailBox8m {
    margin-left: 0;
  }
  .retailBox8 .retailBox8img {
    margin: 0 0 0 0;
  }
  .burg1{
    display: none;
  }
  .retailBox8 .retailBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox8 .retailBox8list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 64%;
  }
  .retailBox8 .retailBox8img img {
    width: 100%;
  }
  .retailBox8 .retailBox8img p {
    margin: 0px 0px 0 150px;
    width: 45%
  }
  .retailBox8Button8 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height:auto;
  }
  .retailBox10 .retailBox10tb {
    display: block;
  }
  .retailBox10 .retailBox10ti h1 {
    width: 85%;
  }
  .retailBox10 .retailBox10ti p {
    width: 73%;
  }
  .retailBox10 .retailBox10Button10 a button {
    font-size: 12px;
    padding: 12px 66px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox10 .retailBox10img img {
    width: 100%;
    position: relative;
    left: 0px;
    top: 35px;
  }
}
@media (max-width: 390px) {
  h1 {
    font-weight: 700;
    font-size: 24px;
    color: #0e082b;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
    margin-top: 23px;
  }
  .retailBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .retailBox1img {
    width: 100%;
    overflow: hidden;
  }

  .retailBox1img img {
    width: 170%;
    margin-left: -50px;
    margin-bottom: -96px;
  }
  .retailBox1 .retailBox1text h1 {
    padding: 0 0px 0 0;
    width: 52%;
  }
  .retailBox1 .retailBox1text {
    padding: 0 0px 0 0;
  }
  .retailBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
  }
  .retailBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .retailBox2 .programss {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .retailBox2 .img2 {
    padding: 30px 0;
  }
  .retailBox2 .img2 img {
    width: 100%;
  }
  .retailBox2 .retailBox2 {
    width: 100%;
  }
  .retailBox2 h1 {
    width: 98%;
  }
  .retailBox2 p {
    width: 96%;
}



  .retailBox3 {
    width: 100%;
  }
  

  .retailBox3 .retailBox3ti {
    width: 100%;
    display: flex;
  }
  .retailBox3 .retailBox3ti h1 {
    width: 72%;
    
  }
  .retailBox3img .retailBox3img1{
    display: flex;
    justify-content: space-around;
    width: 50%;
  }
  .retailBox3img .retailBox3img2{
    display: flex;
    justify-content: space-around;
    width: 50%;
  }

  .retailBox3 .retailBox3Button3 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox4 {
    width: 100%;
  }
  .retailBox4 .retailBox4ti h1 {
    width: 80%;
  }
  .retailBox4 .retailBox4ti p {
    width: 94%;
  }
  .retailBox4 .retailBox4list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox4 .retailBox4list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 69%;
  }
  .retailBox4 .retailBox4img img {
    width: 100%;
  }
   .retailBox4Button4 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox5 {
    width: 100%;
  }
  .retailBox5 .retailBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox5 .retailBox5list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 68%;
  }
  .retailBox5 .retailBox5ti h1 {
    width: 78%;
  }

  .retailBox5 .retailBox5ti p {
    width: 80%;
  }
  .retailBox5 .retailBox5img img {
    width: 100%;
  }
  .retailBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
  }
  .retailBox6 .retailBox6Button6 a button {
    font-size: 12px;
    padding: 12px 40px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox6 .retailBox6img img {
    width: 100%;
    position: relative;
    top: 34px;
  }
  .retailBox6 .retailBox6ti h1 {
    width: 77%;
  }
  .retailBox6Button61 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox7 {
    width: 100%;
  }
  .retailBox7 .retailBox7ti h1 {
    width: 68%;
  }
  .retailBox7 .retailBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox7 .retailBox7list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 100%;
  }
  .retailBox7 .retailBox7img img {
    width: 100%;
  }
  .retailBox7Button7 a button {
    font-size: 12px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox8 {
    width: 100%;
  }
  .retailBox8 .retailBox8ti h1 {
    width: 95%;
  }
  .retailBox8 .retailBox8ti p {
    width: 90%;
  }
  .retailBox8 .retailBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox8 .retailBox8ti h1 {
    width: 97%;
  }
  .retailBox8 .retailBox8list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 76%;
  }
  .retailBox8 .retailBox8img img {
    width: 100%;
  }
  .retailBox8 .retailBox8img p {
    margin: 0px 0px 0 90px;
    width: 56%;
  }
  .retailBox8 .retailBox8Button8 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox9 {
    width: 100%;
  }
  .retailBox9 .retailBox9list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox9 .retailBox9list ul li {
    font-size: 12px;
    padding: 10px 30px 10px 0px;
  }
  .retailBox9 .retailBox9img img {
    width: 100%;
  }

  .retailBox9 .retailBox9Button9 a button {
    font-size: 14px;
    padding: 0px 0px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;

    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
  }
  .retailBox10 .retailBox10ti h1 {
    width: 100%;
  }
  .retailBox10 .retailBox10ti p {
    width: 86%;
  }
  .retailBox10 .retailBox10Button10 a button {
    font-size: 12px;
    padding: 12px 48px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox10 .retailBox10img img {
    width: 100%;
    top: 35px;
  }
}
@media (max-width: 320px) {
  h1 {
    font-weight: 700;
    font-size: 24px;
    color: #0e082b;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
    margin-top: 23px;
  }
  .retailBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .retailBox1img {
    width: 100%;
    overflow: hidden;
  }

  .retailBox1img img {
    width: 172%;
    margin-left: -16%;
    margin-bottom: -29%;
  }
  .retailBox1 .retailBox1text h1 {
    padding: 0 0px 0 0;
    width: 65%;
  }
  
  .retailBox1 .retailBox1text {
    padding: 0 0px 0 0;
  }
  .retailBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 0px 30px;
    display: block;
  }
  .retailBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .retailBox2 .programss {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  .retailBox2 h1 {
    width: 100%;
}
   .retailBox2 p {
    width: 100%;
  }
  .retailBox2 .img2 {
    padding: 30px 0;
  }
  .retailBox2 .img2 img {
    width: 100%;
  }
  .retailBox2 .retailBox2 {
    width: 100%;
  }

  .retailBox3 {
    width: 100%;
  }
  .retailBox3 .retailBox3ti h1 {
    width: 80%;
  }
  .retailBox3img .retailBox3img1{
    display: flex;
    justify-content: space-around;
    width: 50%;
  }
  .retailBox3img .retailBox3img2{
    display: flex;
    justify-content: space-around;
    width: 50%;
  }

  .retailBox3 .retailBox3Button3 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox4 {
    width: 100%;
  }
  .retailBox4 .retailBox4list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox4 .retailBox4ti h1 {
    width: 100%;
  }
  .retailBox4 .retailBox4ti p {
    width: 97%;
  }
  .retailBox4 .retailBox4list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 85%;
  }
  .retailBox4 .retailBox4img img {
    width: 100%;
  }
   .retailBox4Button4 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox5 {
    width: 100%;
  }
  .retailBox5 .retailBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox5 .retailBox5ti h1 {
    width: 90%;
  }
  .retailBox5 .retailBox5ti p {
    width: 100%;
  }
  .retailBox5 .retailBox5img {
    width: 100%;
}

  .retailBox5 .retailBox5list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 80%;
  }
  .retailBox5 .retailBox5img img {
    width: 100%;
  }
  .retailBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
  } 
   .retailBox6 .retailBox6ti h1 {
    width: 96%;
  }
  .retailBox6 .retailBox6ti p {
    width: 98%;
}
  .retailBox6 .retailBox6Button6 a button {
    font-size: 12px;
    padding: 12px 24px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox6 .retailBox6img img {
    width: 100%;
    position: relative;
    left: 0;
    top: 34px;
  }
  .retailBox6Button61 a button {
    font-size: 12px;
    padding: 6px 8px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox7 {
    width: 100%;
  }
  .retailBox7 .retailBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox7 .retailBox7ti h1 {
    width: 82%;
  }
  .retailBox7 .retailBox7ti p {
    width: 94%;
}

  .retailBox7 .retailBox7list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 100%;
  }
  .retailBox7 .retailBox7img img {
    width: 100%;
  }
  .retailBox7Button7 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox8 {
    width: 100%;
  }
  .retailBox8 .retailBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .retailBox8 .retailBox8ti h1 {
    width: 100%;
  }
  .retailBox8 .retailBox8ti p {
    width: 104%;
  }

  .retailBox8 .retailBox8list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 90%;
  }
  .retailBox8 .retailBox8img img {
    width: 100%;
  }
  .retailBox8 .retailBox8img p {
    margin: 0px 0px 0 50px;
    width: 66%;
  }
   .retailBox8Button8 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

  .retailBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
  }
  .retailBox10 .retailBox10Button10 a button {
    font-size: 12px;
    padding: 12px 36px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .retailBox10 .retailBox10ti h1 {
    width: 111%;
  }
  .retailBox10 .retailBox10ti p {
    width: 104%;
  }

  .retailBox10 .retailBox10img img {
    width: 100%;
    left: 0;
    top: 35px;
  }
}
