.telqr{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 2560px) {
    .telqr img{
        width: 300px;
    }
}
@media (max-width: 1920px) {
    .telqr img{
        width: 250px;
    }
}
@media (max-width: 1440px) {
    .telqr img{
        width: 180px;
    }
}
@media (max-width: 1024px) {
    .telqr img{
        width: 150px;
    }
}
@media (max-width: 768px) {
    .telqr img{
        width: 120px;
    }
}
@media (max-width: 425px) {
    .telqr img{
        width: 120px;
    }
}
@media (max-width: 390px) {
    .telqr img{
        width: 120px;
    }
}
@media (max-width: 320px) {
    .telqr img{
        width: 120px;
    }
}

   
    
