

.faqBox1{
        width: 50%;
    }
.faqimg{
    width: 50%;
}

@media (max-width: 2560px) {
    .faqBox{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .faq{
        padding: 0 100px;
        padding-top: 100px;
    }
    .faqBox{
        padding-top: 90px; 
    }
    .faqimg img{
        width: 100%;
    }
  
    .faqBox1 p{
        width: 100%;
        font-size: 24px;
    }
    .faqBox1 h1{
        width: 80%;
    }
    .faqBox1{
        width: 50%;
    }
.faqimg{
    width: 50%;
}
}
@media (max-width: 1920px) {
    .faq{
        padding: 0 100px;
        padding-top: 100px;
    }
    .faqBox{
        padding-top: 90px; 
    }
    .faqimg img{
        width: 100%;
    }
  
    .faqBox1 p{
        width: 100%;
        font-size: 20px;
    }
    .faqBox1 h1{
        width: 50%;
    }
    
}
@media (max-width: 1440px) {
    .faq{
        padding:0 80px;
    }
    .faqBox1 p{
        width: 100%;
    }
    .faqBox1 h1{
        width: 70%;
    }
    .faqBox1 p{
        width: 100%;
        font-size: 18px;
    }
}
@media (max-width: 1366px) {
    .faq{
        padding:0 80px;
    }
}
@media (max-width: 1024px) {
    .faq{
        padding:0 60px;
    }
}
@media (max-width: 1024px) {
    .faq{
        padding:0 40px;
    }
}
@media(max-width:425px) {
    .faq{
        padding:0 20px;
    }
    .faqBox{
        display: block;
      
    }
    .faqBox1{
        width: 100%;
    }
.faqimg{
    width: 100%;
}
}