* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  @media (max-width: 2560px) {
    .besp{
       background: #E5E5E5;
       width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
       position: absolute;
       border-radius: 100px;
       box-shadow: 0px 0px 100px 0px #888888;
    }
    .bespBox{
        width: 100%;
        display: flex;
        background: white;
        border-radius: 100px;
         
    }
.bespimg{
    width: 100%;
}
.bespimg img{
    width: 100%;
    margin-top: 10%;
    margin-left: 10%;
}
.besptb{
    width: 100%;
    margin-top: 30% ;
    margin-left: 6%;
}
.besptb h1{
    width: 62%;
    font-size: 100px;
}
.besptb p{
    width: 50%;
    font-size: 36px;
}
.besptb button{
    width: 100%;
    width: 60%;
    height: 100px;
    margin-top: 20px;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    font-size: 36px;
    cursor: pointer;
}
.besptb input {
    width: 60%;
    height: 90px;
    margin-top: 39px;
    border: 1px solid #e1e1e1;
    border-radius: 15px;
    font-size: 36px;

}
 .union1{
    position: relative;
 }
 .union1 img{
    position: absolute;
    top: 100px;
    right: 100px;
    cursor: pointer;
    width: 50px;
 }

  }
  @media (max-width: 1920px) {
    .besp{
       background: #E5E5E5;
       width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
       position: absolute;
       border-radius: 100px;
       box-shadow: 0px 0px 100px 0px #888888;
    }
    .bespBox{
        width: 100%;
        display: flex;
        background: white;
        border-radius: 100px;
         
    }
.bespimg{
    width: 100%;
}
.bespimg img{
    width: 100%;
    margin-top: 10%;
    margin-left: 10%;
}
.besptb{
    width: 100%;
    margin-top: 30% ;
    margin-left: 6%;
}
.besptb h1{
    width: 56%;
    font-size: 70px;
}
.besptb p{
    width: 50%;
    font-size: 28px;
}
.besptb button{
    width: 100%;
    width: 60%;
    height: 100px;
    margin-top: 20px;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    font-size: 28px;
    cursor: pointer;
}
.besptb input {
    width: 60%;
    height: 90px;
    margin-top: 39px;
    border: 1px solid #e1e1e1;
    border-radius: 15px;
    font-size: 28px;

}
 .union1{
    position: relative;
 }
 .union1 img{
    position: absolute;
    top: 100px;
    right: 100px;
    cursor: pointer;
    width: 70px;
 }

  }
  @media (max-width: 1440px) {
    .besp{
       background: #E5E5E5;
       width: 100%;
       display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 60px 0px #888888;
    
    }
    .bespBox{
        width: 100%;
        display: flex;
        background: white;
        border-radius: 35px;
         
    }
.bespimg{
    width: 100%;
}
.bespimg img{
    width: 100%;
    margin-top: 10%;
    margin-left: 10%;
}
.besptb{
    width: 100%;
    margin-top: 24% ;
    margin-left: 6%;
}
.besptb h1{
    width: 74%;
    font-size: 70px;
}
.besptb p{
    width: 48%;
    font-size: 18px;
}
.besptb button{
    width: 100%;
    width: 60%;
    height: 50px;
    margin-top: 20px;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
}
.besptb input {
    width: 60%;
    height: 45px;
    margin-top: 39px;
    border: 1px solid #e1e1e1;
    border-radius: 15px;
    font-size: 18px;

}
.union1 img{
    position: absolute;
    top: 60px;
    right: 60px;
    cursor: pointer;
    width: 30px;
 }

  }
  @media (max-width: 1024px) {
    .besp{
       background: #E5E5E5;
       width: 100%;
       display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 30px 0px #888888;
    
    }
    .bespBox{
        width: 100%;
        display: flex;
        background: white;
        border-radius: 35px;
         
    }
.bespimg{
    width: 100%;
}
.bespimg img{
    width: 100%;
    margin-top: 10%;
    margin-left: 10%;
}
.besptb{
    width: 100%;
    margin-top: 25% ;
    margin-left: 6%;
}
.besptb h1{
    width: 75%;
    font-size: 50px;
}
.besptb p{
    width: 48%;
    font-size: 14px;
}
.besptb button{
    width: 100%;
    width: 60%;
    height: 50px;
    margin-top: 20px;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
}
.besptb input {
    width: 60%;
    height: 45px;
    margin-top: 39px;
    border: 1px solid #e1e1e1;
    border-radius: 15px;
    font-size: 14px;

}
.union1 img{
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    width: 30px;
 }

  }

  @media (max-width: 768px) {
    .besp{
    background: #E5E5E5;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 30px 0px #888888;

    
    }
    .bespBox{
        width: 100%;
        display: flex;
        background: white;
        border-radius: 35px;
        
         
    }
.bespimg{
    width: 100%;
}
.bespimg img{
    width: 100%;
    margin-top: 10%;
    margin-left: 10%;
}
.besptb{
    width: 100%;
    margin-top: 12% ;
    margin-left: 6%;
}
.besptb h1{
    width: 75%;
    font-size: 40px;
}
.besptb p{
    width: 68%;
    font-size: 14px;
}
.besptb button{
    width: 60%;
    height: 40px;
    margin-top: 20px;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
}
.besptb input {
    width: 60%;
    height: 35px;
    margin-top: 39px;
    border: 1px solid #e1e1e1;
    border-radius: 15px;

}
.union1{
    position: relative;
 }
 .union1 img{
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    width: 30px;
 }

  }
  @media (max-width: 425px) {
    .besp{
       
       width: 100%;
       z-index: 1;
       justify-content: center;
       align-items: start;
       box-shadow: 0px 0px 10px 0px #888888;
       
    }
    .bespBox{
        width: 100%;
        height: 40vh;
        display: flex;
        background: white;
        border-radius: 35px;
        box-shadow: 0px 0px 10px 0px #888888;
        
         
    }
.bespimg{
    display: none;
    width: 100%;
}
.bespimg img{
    width: 100%;
    margin-top: 10%;
    margin-left: 10%;
    display: block;
}
.besptb{
    width: 100%;
    margin-top: 10% ;
    margin-left: 6%;
}
.besptb h1{
    width: 52%;
    font-size: 30px;
}
.besptb p{
    width: 54%;
    font-size: 14px;
}
.besptb button{
    width: 90%;
    height: 40px;
    margin-top: 20px;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
}
.besptb input {
    width: 90%;
    height: 35px;
    margin-top: 39px;
    border: 1px solid #e1e1e1;
    border-radius: 15px;

}
.union1{
    position: relative;
 }
 .union1 img{
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    width: 10px;
 }

  }
  @media (max-width: 390px) {
    .besp{
       width: 100%;
       justify-content: center;
       align-items: start;
       
    }
    .bespBox{
        width: 100%;
        height: 40vh ;
        display: flex;
        background: white;
        border-radius: 35px;
        
         
    }
.bespimg{
    display: none;
    width: 100%;
}
.bespimg img{
    width: 100%;
    margin-top: 10%;
    margin-left: 10%;
    display: block;
}
.besptb{
    width: 100%;
    margin-top: 10% ;
    margin-left: 6%;
}
.besptb h1{
    width: 61%;
    font-size: 30px;
}
.besptb p{
    width: 61%;
    font-size: 14px;
}
.besptb button{
    width: 90%;
    height: 40px;
    margin-top: 20px;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
}
.besptb input {
    width: 90%;
    height: 35px;
    margin-top: 39px;
    border: 1px solid #e1e1e1;
    border-radius: 15px;

}

  }
  @media (max-width: 320px) {
    .besp{
       
       width: 100%;
       justify-content: center;
       align-items: start;
       position: absolute;
       
    }
    .bespBox{
        width: 100%;
        display: flex;
        background: white;
        border-radius: 35px;
        
         
    }
.bespimg{
    display: none;
    width: 100%;
}
.bespimg img{
    width: 100%;
    margin-top: 10%;
    margin-left: 10%;
    display: block;
}
.besptb{
    width: 100%;
    margin-top: 10% ;
    margin-left: 6%;
}
.besptb h1{
    width: 68%;
    font-size: 30px;
}
.besptb p{
    width: 74%;
    font-size: 14px;
}
.besptb button{
    width: 90%;
    height: 40px;
    margin-top: 20px;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
}
.besptb input {
    width: 90%;
    height: 35px;
    margin-top: 39px;
    border: 1px solid #e1e1e1;
    border-radius: 15px;

}

  }