.word {
 color: #B7B7B7;
}

.word.active {
  /* Styles for active link */
  color: #000000; /* Dark color */
  font-weight: bold; /* Increase font weight for emphasis */
}
.navTwo_itme li a:hover {
  background-color:rgb(238, 235, 235);
  padding: 10px;
  border-radius: 10px;
  transition: .5s;
}
@media (max-width: 2560px) {
  nav {
    width: 100%;
    height: auto;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background: #fff;
    z-index: 999;
    padding: 30px 100px;
  }
.ynd{
  display: flex;
  justify-content: start;
  align-items: center;
}
.navOne{
  width: 15%;
}
.navOne img{
  width: 90%;
}
.navTwo{
  width: 85%;
}

.mnb1{
  display: none;
}
.mnb h6{
  font-size: 32px;
  font-weight:400 ;
}
.tex{
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.tex a img{
  width: 150%;
}
.erku{
  display: none;
}
.twolines{
  display:none;
}
.navTwo_itme{
  display: flex;
    justify-content: space-between;
    width: 100%;
}
.navTwo ul {
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items:baseline;
  background-color: white;
  width: 100%;
}

.navTwo ul li {
  display: inline;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  text-align: center;
  text-decoration: none;
  color: #000000;
}
.navTwo ul li a {
  display: inline;
  font-style: normal;
  text-decoration: none;
  color: #000000;
}
.zxc{
  display: flex;
  justify-content: center;
}
.navThree1 button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  text-align: center;
  color: #5231e4;
  background: #eeeafc;
  border-radius: 10px;
  border-style: none;
  padding: 10px 40px;
  cursor: pointer;
}
.userName2{
  font-size: 36px;
  cursor: pointer;
}
.vixd{
  display: flex;
  justify-content: center;
  align-items: end;
  margin-left: 30px;
}
.vixd button{
  align-items: center;
background: #ffffff;
border-style: none;
color:#B7B7B7;
font-size: 32px;
font-family: SF Pro Display;
font-style: normal;
font-weight: 520;
justify-content: start;
width: 100%;
cursor: pointer;
}
.qwe{
  display: flex;
  align-items: baseline;
}
.qwe button {
  align-items: center;
  background: #ffffff;
  border-style: none;
  color:#000000;
  font-size: 32px;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 520;
  justify-content: start;
  width: 100%;
  cursor: pointer;
}
#reg{
  font-size: 32px;
  font-family: "SF Pro Display";
  padding: 10px 40px;
  font-weight: 500;
  text-align: center;
  color: #5131e4;
  background: #EEEAFC;
  border-radius: 10px;
  border-style: none;
  display: flex;
  justify-content: center;
  margin-left: 10px;
}
}
@media (max-width: 1920px) {
  nav {
    width: 100%;
    height: auto;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background: #fff;
    z-index: 999;
    padding: 15px 80px;
  }
.ynd{
  display: flex;
  justify-content: start;
  align-items: center;
}
.navOne{
  width: 15%;
}
.navOne img{
  width: 90%;
}
.navTwo{
  width: 85%;
}

.mnb1{
  display: none;
}
.mnb h6{
  font-size: 24px;
  font-weight:400 ;
}
.tex{
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.tex a img{
  width: 150%;
}

.navTwo_itme{
  display: flex;
    justify-content: space-between;
    width: 100%;
}
.navTwo ul {
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items:baseline;
  background-color: white;
  width: 100%;
}

.navTwo ul li {
  display: inline;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  text-decoration: none;
  color: #000000;
}
.navTwo ul li a {
  display: inline;
  font-style: normal;
  text-decoration: none;
  color: #000000;
}
/* .navTwo ul li a:hover{
 font-weight: 600;
  
} */


.navThree1 button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: #5231e4;
  background: #eeeafc;
  border-radius: 10px;
  border-style: none;
  padding: 10px 40px;
}
.userName2{
  font-size: 24px;
}
.vixd{
  display: flex;
  justify-content: center;
  align-items: end;
  margin-left: 30px;
}
.vixd button{
  align-items: center;
background: #ffffff;
border-style: none;
color:#B7B7B7;
font-size: 24px;
font-family: SF Pro Display;
font-style: normal;
font-weight: 520;
justify-content: start;
width: 100%;
}
.qwe button {
  align-items: center;
  background: #ffffff;
  border-style: none;
  color:#000000;
  font-size: 24px;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 520;
  justify-content: start;
  width: 100%;
  cursor: pointer;
}
#reg{
  font-size: 24px;
  font-family: "SF Pro Display";
  padding: 10px 40px;
  font-weight: 500;
  text-align: center;
  color: #5131e4;
  background: #EEEAFC;
  border-radius: 10px;
  border-style: none;
  display: flex;
  justify-content: center;
  margin-left: 10px;
}
}
@media (max-width: 1440px) {
  nav {
    width: 100%;
    height: auto;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background: #fff;
    z-index: 999;
    padding: 15px 80px;
  }
.ynd{
  display: flex;
  justify-content: start;
  align-items: center;
}
.navOne{
  width: 15%;
}
.navOne img{
  width: 90%;
}
.navTwo{
  width: 85%;
}

.mnb1{
  display: none;
}
.mnb h6{
  font-size: 18px;
  font-weight:400 ;
}
.tex{
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.tex a img{
  width: 100%;
}

.navTwo_itme{
  display: flex;
    justify-content: space-between;
    width: 100%;
}
.navTwo ul {
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items:center;
  background-color: white;
  width: 100%;
}

.navTwo ul li {
  display: inline;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  color: #000000;
}
.navTwo ul li a {
  display: inline;
  font-style: normal;
  text-decoration: none;
  color: #000000;
}

.navThree1 button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #5231e4;
  background: #eeeafc;
  border-radius: 10px;
  border-style: none;
  padding: 10px 40px;
}
.userName2{
  font-size: 18px;
}
.vixd{
  display: flex;
  justify-content: center;
  align-items: end;
  margin-left: 30px;
}
.vixd button{
  align-items: center;
background: #ffffff;
border-style: none;
color:#B7B7B7;
font-size: 18px;
font-family: SF Pro Display;
font-style: normal;
font-weight: 520;
justify-content: start;
width: 100%;
}
.qwe button {
  align-items: center;
  background: #ffffff;
  border-style: none;
  color:#000000;
  font-size: 18px;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 520;
  justify-content: start;
  width: 100%;
  cursor: pointer;
}
#reg{
  font-size: 18px;
  font-family: "SF Pro Display";
  padding: 10px 40px;
  font-weight: 500;
  text-align: center;
  color: #5131e4;
  background: #EEEAFC;
  border-radius: 10px;
  border-style: none;
  display: flex;
  justify-content: center;
}
}
@media (max-width: 1366px) {
  nav {
    width: 100%;
    height: auto;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background: #fff;
    z-index: 999;
    padding: 15px 80px;
  }
.ynd{
  display: flex;
  justify-content: start;
  align-items: center;
}
.navOne{
  width: 15%;
}
.navOne img{
  width: 90%;
}
.navTwo{
  width: 85%;
}

.mnb1{
  display: none;
}
.mnb h6{
  font-size: 18px;
  font-weight:400 ;
}
.tex{
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.tex a img{
  width: 100%;
}

.navTwo_itme{
  display: flex;
    justify-content: space-between;
    width: 100%;
}
.navTwo ul {
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items:center;
  background-color: white;
  width: 100%;
}

.navTwo ul li {
  display: inline;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  color: #000000;
}
.navTwo ul li a {
  display: inline;
  font-style: normal;
  text-decoration: none;
  color: #000000;
}

.navThree1 button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #5231e4;
  background: #eeeafc;
  border-radius: 10px;
  border-style: none;
  padding: 10px 40px;
}
.userName2{
  font-size: 18px;
}
.vixd{
  display: flex;
  justify-content: center;
  align-items: end;
  margin-left: 30px;
}
.vixd button{
  align-items: center;
background: #ffffff;
border-style: none;
color:#B7B7B7;
font-size: 18px;
font-family: SF Pro Display;
font-style: normal;
font-weight: 520;
justify-content: start;
width: 100%;
}
}
@media (max-width: 1024px) {
  nav {
    width: 100%;
    height: auto;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background: #fff;
    z-index: 999;
    padding: 5px 60px;
  }
.ynd{
  display: flex;
  justify-content: start;
  align-items: center;
}
.navOne{
  width: 15%;
}
.navOne img{
  width: 90%;
}
.navTwo{
  width: 85%;
}

.mnb1{
  display: none;
}
.mnb h6{
  font-size: 14px;
  font-weight:400 ;
}
.tex{
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.tex a img{
  width: 100%;
}

.navTwo_itme{
  display: flex;
    justify-content: space-between;
    width: 100%;
}
.navTwo ul {
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items:center;
  background-color: white;
  width: 100%;
}

.navTwo ul li {
  display: inline;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  color: #000000;
}
.navTwo ul li a {
  display: inline;
  font-style: normal;
  text-decoration: none;
  color: #000000;
}

.navThree1 button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #5231e4;
  background: #eeeafc;
  border-radius: 10px;
  border-style: none;
  padding: 10px 40px;
}
.userName2{
  font-size: 14px;
}
.vixd{
  display: flex;
  justify-content: center;
  align-items: end;
  margin-left: 10px;
}
.vixd button{
  align-items: center;
background: #ffffff;
border-style: none;
color:#B7B7B7;
font-size: 14px;
font-family: SF Pro Display;
font-style: normal;
font-weight: 520;
justify-content: start;
width: 100%;
}
.qwe button {
  align-items: center;
  background: #ffffff;
  border-style: none;
  color:#000000;
  font-size: 14px;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 520;
  justify-content: start;
  width: 100%;
  cursor: pointer;
}
#reg{
  font-size: 14px;
  font-family: "SF Pro Display";
  padding: 10px 20px;
  font-weight: 500;
  text-align: center;
  color: #5131e4;
  background: #EEEAFC;
  border-radius: 10px;
  border-style: none;
  display: flex;
  justify-content: center;
}
}
@media (max-width: 768px) {
  nav {
    width: 100%;
    height: auto;
    margin: auto;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    background: #fff;
    z-index: 999;
    padding: 5px 40px;
  }
.ynd{
  display: flex;
  justify-content: start;
  align-items: center;
}
.navOne{
  width: 15%;
}
.navOne img{
  width: 90%;
}
.navTwo{
  width: 85%;
}

.mnb1{
  display: none;
}
.mnb h6{
  font-size: 12px;
  font-weight:400 ;
}
.tex{
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.tex a img{
  width: 100%;
}

.navTwo_itme{
  display: flex;
    justify-content: space-between;
    width: 100%;
}
.navTwo ul {
  margin: 0;
  display: flex;
  justify-content: space-a;
  align-items:center;
  background-color: white;
  width: 100%;
}

.navTwo ul li {
  display: inline;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  color: #000000;
}
.navTwo ul li a {
  display: inline;
  font-style: normal;
  text-decoration: none;
  color: #000000;
}

.navThree1 button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #5231e4;
  background: #eeeafc;
  border-radius: 10px;
  border-style: none;
  padding: 10px 30px;
}
.userName2{
  font-size: 12px;
}
.vixd{
  display: flex;
  justify-content: center;
  align-items: end;
  margin-left: 10px;
}
.vixd button{
  align-items: center;
background: #ffffff;
border-style: none;
color:#B7B7B7;
font-size: 12px;
font-family: SF Pro Display;
font-style: normal;
font-weight: 520;
justify-content: start;
width: 100%;
}
.qwe button {
  align-items: center;
  background: #ffffff;
  border-style: none;
  color:#000000;
  font-size: 12px;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 520;
  justify-content: start;
  width: 100%;
  cursor: pointer;
}
#reg{
  font-size: 12px;
  font-family: "SF Pro Display";
  padding: 10px 10px;
  font-weight: 500;
  text-align: center;
  color: #5131e4;
  background: #EEEAFC;
  border-radius: 10px;
  border-style: none;
  display: flex;
  justify-content: center;
}
}
@media (max-width: 425px) {
  nav {
    width: 100%;
    height: 8vh;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background-color: #fff;
    padding: 0 20px;
  }
  .navOne img {
    width: 100%;
    height: 30px;
    vertical-align: baseline;
  }
  .navbar {
    width: 100%;
    padding: 40px;
  }
  .navbar_nav {
    justify-content: start;
    padding-top: 10px;
    align-items: baseline;
   background-color: #fff;
   height: 7vh;
  }

  .navFour {
    display: none;
  }
  .navThree{
    margin-left: 40px;
  }
  .navThree a button {
    font-size: 75%;
    padding: 10px 30px;
    width: 100%;
  }
  .navThree1 a button {
    font-size: 80%;
    width: 100%;
  }
  .twolines {
    display: flex;
    margin-left: 20px;
  }
  .twolines img {
    width: 10%;
    position: absolute;
    top: 6px;
    left: 350px;
    transition: .9s;
  }
  #twolines {
    top: 50px !important;
    transition: .9s;
    left: 0;
  }
  #navThree {
    display: none;
  }

  .navTwo ul {
    margin: 0;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: -470px;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    width: 100%;
    left: 5px;
    height: 50vh;
    z-index: -1;
    transition: .9s;
    padding: 0 20px;
  }

  .navTwo ul li {
    display: inline;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    text-decoration: none;
    color: #000000;
  }
  .navTwo ul li a {
    display: inline;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;
    position: relative;
    top: 15px;
    text-align: center;
    text-decoration: none;
    color: #000000;
  }
  .navThree1 {
    display: block;
  }
  .navti{
    display: block;
  }
  .navOne {
    width: 25%;
}
  .navThree1 {
    margin-left: 5px;
  }
  .navThree1 button {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #5231e4;
    background: white;
    border-radius: 10px;
    border-style: none;
    padding: 0px 0px ;
  }
  .navFour {
    flex-direction: column;
    margin-top: 24px;
  }
  .asd {
    display: flex;
    align-items: center;
  }
  .asd1 {
    text-align: end;
  }
  .asd1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    text-decoration-line: underline;
    color: #5131e4;
  }
  .navFour img {
    width: 12px;
    height: 12px;
  }
  .navFour p {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
  }
  .qwe {
    align-items: baseline;
    width: 34px;
    justify-content: space-between;
    margin-right: 15px;
    height: 10vh;
  }
  .navtitext p{
    font-size: 14px;
    width: 100%;
    font-weight: 400;
    margin-left: 5px;
     }
     .login{
      display: block;
      width: 35%;
      position: relative;
      top: -15px;
      left: -5px;
    }
    .login button{
      align-items: center;
      background: #ffffff;
      border-style: none;
      color:#5131e4;
      font-size: 18px;
      font-style: normal;
      font-weight: 520;
      justify-content: start;
      width: 100%;
      margin-top: 10px;
    }
    .anpetq{
      display: flex;
    }
    .zxc{
      display: block;
    }
    .vixd button{
      align-items: center;
    background: #ffffff;
    border-style: none;
    color:#B7B7B7;
    font-size: 16px;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 520;
    justify-content: start;
    width: 100%;
    }
    .userName1{
      display: flex;
    }
  
    .userNameimg{
      display: flex;
      cursor: pointer;
    }
    .userNameimg img{
     width: 40px;
    }
    .mnb1 {
    display: none;
  }
  .mnb {
   display: flex;
   flex-direction: column;
   margin-top: 10px;
}
    
}
/* //////////////////////////////////////////////////// */
@media (max-width: 390px) {
  nav {
    width: 100%;
    height: 7.6vh;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background: #fff;
  }
  .navOne img {
    vertical-align: baseline;
    width: 80%;
  }
  .navbar_nav {
    justify-content: start;
    padding-top: 10px;
    align-items: baseline;
  }
  .navbar {
    width: 100%;
    padding: 10px;
  }
  .navThree {
    margin-left: -5px;
}
  .navFour {
    display: none;
  }
  .navThree a button {
    font-size: 70%;
    padding: 10px 20px;
    width: 100%;
  }
  /* .navThree1 a button {
    font-size: 70%;
    padding: 10px;
    width: 100%;
  } */
  .twolines {
    display: flex;
    margin-left: 20px;
  }
  .twolines img {
    width: 10%;
    position: absolute;
    top: 17px;
    left: 320px;
  }
  .navTwo ul {
    margin: 0;
    justify-content: space-between;
    position: absolute;
    top: -420px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    width: 100%;
    left: 5px;
    height: 50vh;
  }
  #twolines {
    top: 37px !important;
    transition: .9s;
  }

  .navTwo ul li {
    display: inline;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    text-decoration: none;
    color: #000000;
  }
  .navTwo ul li a {
    display: inline;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;
    position: relative;
    top: 15px;
    text-align: center;
    text-decoration: none;
    color: #000000;
  }

  .navThree button {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-left: 10px;
    text-align: center;
    color: #5231e4;
    background: #eeeafc;
    border-radius: 10px;
    border-style: none;
  }
  .navThree1 button {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #5231e4;
    background: white;
    border-radius: 10px;
    border-style: none;
  }
  .navFour {
    flex-direction: column;
    margin-top: 24px;
  }

  .asd {
    display: flex;
    align-items: center;
  }
  .asd1 {
    text-align: end;
  }
  .asd1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    text-decoration-line: underline;
    color: #5131e4;
  }

  .navFour img {
    width: 12px;
    height: 12px;
  }
  .navFour p {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
  }

  .qwe {
    align-items: baseline;
    width: 34px;
    justify-content: space-between;
    margin-right: 15px;
  }
  .navtitext p{
 font-size: 14px;
 width: 100%;
 font-weight: 400;
 margin-left: 5px;
  }
  .login{
    display: block;
    width: 35%;
    position: relative;
    top: -15px;
    left: -5px;
}
  .login button{
    align-items: center;
    background: #ffffff;
    border-style: none;
    color:#5131e4;
    font-size: 18px;
    font-style: normal;
    font-weight: 520;
    justify-content: start;
    width: 100%;
    margin-top: 10px;
  }
  .anpetq{
    display: flex;
  }
  .zxc{
    display: block;
  }
  .vixd button{
    align-items: center;
  background: #ffffff;
  border-style: none;
  color:#B7B7B7;
  font-size: 16px;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 520;
  justify-content: start;
  width: 100%;
  }
  .userName1{
    display: flex;
  }

  .userNameimg{
    display: flex;
    cursor: pointer;
  }
  .userNameimg img{
   width: 40px;
  
  }
}
/* //////////////////////////////// */
@media (max-width: 320px) {
  nav {
    width: 100%;
    height: 6.6vh;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background: #fff;
  }
  .navOne img {
    vertical-align: baseline;
    width: 80%;
    margin-top: 0px;
  }
  .navbar_nav {
    justify-content: start;
    padding-top: 10px;
    align-items: baseline;
  }
  .navbar {
    width: 100%;
    padding: 10px;
  }
  .navThree {
    margin-left: -20px;
}

  .navFour {
    display: none;
  }
  .navThree a button {
    font-size: 60%;
    padding: 6px 16px;
    width: 100%;
  }
  /* .navThree1 a button {
    font-size: 60%;
    padding: 10px;
    width: 100%;
  } */
  .twolines {
    display: flex;
    margin-left: 15px;
  }
  .twolines img {
    width: 10%;
    position: absolute;
    top: 16px;
    left: 270px;
  }
  .navTwo ul {
    margin: 0;
    justify-content: space-between;
    position: absolute;
    top: -400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    width: 100%;
    left: 5px;
    height: 50vh;
  }
  #twolines {
    top: 30px !important;
    transition: .9s;
    height: 55vh;
  }
  #twolines a {
   position: relative;
   top: 20px;  
  }
  #navThree{
    display: none;
    margin-left: 140px;
  }
  #twolines img{
    left: 0px;
    position: relative;
  }

  .navTwo ul li {
    display: inline;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    text-decoration: none;
    color: #000000;
  }
  .navTwo ul li a {
    display: inline;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;
    position: relative;
    top: 15px;
    text-align: center;
    text-decoration: none;
    color: #000000;
  }

  .navThree button {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-left: 10px;
    text-align: center;
    color: #5231e4;
    background: #eeeafc;
    border-radius: 10px;
    border-style: none;
  }
  .navThree1 button {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #5231e4;
    background: white;
    border-radius: 10px;
    border-style: none;
  }
  .navFour {
    flex-direction: column;
    margin-top: 24px;
  }
  .asd {
    display: flex;
    align-items: center;
  }
  .asd1 {
    text-align: end;
  }
  .asd1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    text-decoration-line: underline;
    color: #5131e4;
    position: relative;
    left: -8px;
  }
  .navFour img {
    width: 12px;
    height: 12px;
  }
  .navFour p {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
  }
  .qwe {
    align-items: baseline;
    width: 34px;
    justify-content: space-between;
    margin-right: 15px;
  }
  .navtitext p{
 font-size: 14px;
 width: 100%;
 font-weight: 400;
 margin-left: 5px;
  }
  .login{
   display: flex;
   display: block;
   width: 35%;
   position: relative;
   top: -33px;
   left: -5px;
  }
  .login button{
    align-items: center;
    background: #ffffff;
    border-style: none;
    color:#5131e4;
    font-size: 18px;
    font-style: normal;
    font-weight: 520;
    justify-content: start;
    width: 100%;
    margin-top: 10px;
  }
  .anpetq{
    display: flex;
  }
  .zxc{
    display: block;
  }
  .vixd button{
    align-items: center;
  background: #ffffff;
  border-style: none;
  color:#B7B7B7;
  font-size: 16px;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 520;
  justify-content: start;
  width: 100%;
  }
  .userName1{
    display: flex;
  }

  .userNameimg{
    display: flex;
    cursor: pointer;
  }
  .userNameimg img{
   width: 40px;
  
  }
}
/* .bar{
  display: flex;
  flex-direction: column;
} */
.jkl{
  display: flex;
}

.mnb{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 30px 0 20px ;
}


