* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  @media (max-width: 2560px) {
    
    .mersi h1 {
         font-weight: 700;
         font-size: 100px;
         color: #0e082b;
         width: 43%;
       }
     .mersi  p {
         font-weight: 300;
         font-size: 35px;
         color: #0e082b;
         margin-top: 23px;
         width: 50%;
         
       }
       .mersi{
        overflow: hidden;
         padding: 0 100px;
         display: flex;
       }
       .mersiBox{
         width: 100%;
         padding-top: 900px;
         flex-direction: column;
         position: relative;
         
       }
       .mersiimg{
         width: 100%;
         
       }
       .mersiimg img{
         width: 90%;
         margin-top: 25%;
         margin-left: -15%;
       }
 
     }
  @media (max-width: 1920px) {
    
      .mersi h1 {
           font-weight: 700;
           font-size: 70px;
           color: #0e082b;
           width: 40%;
         }
       .mersi  p {
           font-weight: 300;
           font-size: 30px;
           color: #0e082b;
           margin-top: 23px;
           width: 60%;
           
         }
         .mersi{
          overflow: hidden;
           padding: 0 100px;
           display: flex;
         }
         .mersiBox{
           width: 100%;
           padding-top: 650px;
           flex-direction: column;
           position: relative;
           
         }
         .mersiimg{
           width: 100%;
           
         }
         .mersiimg img{
           width: 90%;
           margin-top: 25%;
           margin-left: -15%;
         }
   
       }
  @media (max-width: 1440px) {
    
    .mersi h1 {
         font-weight: 700;
         font-size: 50px;
         color: #0e082b;
         width: 40%;
       }
     .mersi  p {
         font-weight: 300;
         font-size: 18px;
         color: #0e082b;
         margin-top: 23px;
         width: 48%;
         
       }
       .mersi{
        overflow: hidden;
         padding: 0 80px;
         display: flex;
       }
       .mersiBox{
         width: 100%;
         padding-top: 500px;
         flex-direction: column;
         position: relative;
         
       }
       .mersiimg{
         width: 100%;
         
       }
       .mersiimg img{
         width: 90%;
         margin-top: 20%;
         margin-left: -15%;
       }
       
 
 
 
     }
 @media (max-width: 1366px) {
    
      .mersi h1 {
           font-weight: 700;
           font-size: 50px;
           color: #0e082b;
           width: 45%;
         }
       .mersi  p {
           font-weight: 300;
           font-size: 18px;
           color: #0e082b;
           margin-top: 23px;
           width: 50%;
           
         }
         .mersi{
          overflow: hidden;
           padding: 0 80px;
           display: flex;
         }
         .mersiBox{
           width: 100%;
           padding-top: 500px;
           flex-direction: column;
           position: relative;
           
         }
         .mersiimg{
           width: 100%;
           
         }
         .mersiimg img{
           width: 90%;
           margin-top: 22%;
           margin-left: -15%;
         }
         
   
   
   
       }
  @media (max-width: 1024px) {

    .mersi h1 {
         font-weight: 700;
         font-size: 41px;
         color: #0e082b;
         width: 45%;
       }
     .mersi  p {
         font-weight: 300;
         font-size: 14px;
         color: #0e082b;
         margin-top: 23px;
         width: 54%;
         
       }
       .mersi{
        overflow: hidden;
         padding: 0 60px;
         display: flex;
       }
       .mersiBox{
         width: 100%;
         padding-top: 350px;
         flex-direction: column;
         position: relative;
         
       }
       .mersiimg{
         width: 100%;
         
       }
       .mersiimg img{
         width: 100%;
         margin-top: 30%;
         margin-left: -15%;
       }
       
 
 
 
     }
  @media (max-width: 768px) {
    
        
    
    .mersi h1 {
         font-weight: 700;
         font-size: 40px;
         color: #0e082b;
         width: 62%;
       }
     .mersi  p {
         font-weight: 300;
         font-size: 14px;
         color: #0e082b;
         margin-top: 23px;
         width: 70%;
         
       }
       .mersi{
        overflow: hidden;
         padding: 0 40px;
         display: flex;
       }
       .mersiBox{
         width: 100%;
         padding-top: 250px;
         flex-direction: column;
         position: relative;
         
       }
       .mersiimg{
         width: 100%;
         
       }
       .mersiimg img{
         width: 100%;
         margin-top: 30%;
         margin-left: -15%;
       }
       
 
 
 
     }
  @media (max-width: 425px) {
    
        
    
    .mersi h1 {
         font-weight: 700;
         font-size: 45px;
         color: #0e082b;
         width: 60%;
       }
     .mersi  p {
         font-weight: 300;
         font-size: 14px;
         color: #0e082b;
         margin-top: 23px;
         width: 44%;
         
       }
       .mersi{
        overflow: hidden;
         padding: 0 16px;
         display: block;
       }
       .mersiBox{
         width: 100%;
         padding-top: 90px;
         flex-direction: column;
         position: relative;
         
       }
       .mersiimg{
         width: 100%;
         
       }
       .mersiimg img{
         width: 100%;
         margin-top: -30%;
         margin-left: 25%;
       }
       
 
 
 
     }
  @media (max-width: 390px) {
    
        
    
    .mersi h1 {
         font-weight: 700;
         font-size: 41px;
         color: #0e082b;
         width: 64%;
       }
     .mersi  p {
         font-weight: 300;
         font-size: 14px;
         color: #0e082b;
         margin-top: 23px;
         width: 50%;
         
       }
       .mersi{
        overflow: hidden;
         padding: 0 16px;
       }
       .mersiBox{
         width: 100%;
         padding-top: 90px;
         flex-direction: column;
         position: relative;
         
       }
       .mersiimg{
         width: 100%;
         
       }
       .mersiimg img{
         width: 100%;
         margin-top: -30%;
         margin-left: 25%;
       }
       
 
 
 
     }
  @media (max-width: 320px) {
    
        
    
   .mersi h1 {
        font-weight: 700;
        font-size: 41px;
        color: #0e082b;
        width: 75%;
      }
    .mersi  p {
        font-weight: 300;
        font-size: 14px;
        color: #0e082b;
        margin-top: 23px;
        width: 60%;
        
      }
      .mersi{
       overflow: hidden;
        padding: 0 16px;
      }
      .mersiBox{
        width: 100%;
        padding-top: 90px;
        flex-direction: column;
        position: relative;
        
      }
      .mersiimg{
        width: 100%;
        
      }
      .mersiimg img{
        width: 100%;
        margin-top: -30%;
        margin-left: 30%;
      }
      



    }