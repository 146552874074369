.Bycard_1{
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 0 0px;
    border-radius: 35px;
    box-shadow: 0px 0px 0px 0px #888888;
    position: absolute;
    top: 60px;
}

button {
    cursor: pointer;
}

.Bycard{
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 0 0px;
    border-radius: 35px;
    box-shadow: 0px 0px 0px 0px #888888;
}
@media (max-width: 2560px) {
  
    .BycardBox1{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .BycardBoxS{
        display: flex;
        justify-content: start;
        padding: 25px;
    }
    .BycardBox1 h2{
       color: white;
       font-size: 28px;
    
    }
    .BycardBox{
        height: auto;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 4px 28px  #888888;
        padding: 15px 0 30px 0;
    
    }
    .BycardBox2{
       margin-left: 12px;
        height: auto;
        width: 35%;
        background-color: #5231e4;
        border-radius: 5px;
        padding: 10px;
    }
    .BycardBox2 img{
        width: 12%;
        margin: 10px 0 0 5px;
    }
    .BycardBox1img{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .BycardBox3{
        margin-left: 40px;
         height: auto;
         width: 35%;
         background-color: #A1A1A1;  
         border-radius: 5px;
         display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;

     }
     .BycardBox3 img{
         width: 10%;
         margin-top: 10px;
         
     
     }
     .BycardBox4{
        display: flex;
        justify-content: center;
        width: 100%;
       
    }
    .BycardBox4 h2{
       color: white;
       font-size: 32px;
    
    }
    .BycardBox5 {
        margin-top: 10px;
        margin-left: 35px;
    }
    .BycardBox5 input{
       width: 52%;
       margin-top: 15px;
    }
    .BycardBox6 {
        display: flex;
        margin-left: 35px;
        margin-top: 10px;
    }
    .BycardBox6 input{
        width: 25.75%;
        margin: 5px 13px 0 0;
    }
    .BycardBox7{
        display: flex;
        align-items: end;
        margin-left: 24px;
    }
    .BycardBox7 h4{
        font-size: 32px;
        font-weight: 400;
    }
    .BycardBox7 input{
        height: 34px;
        width: 60px;
    }
    .BycardButton1{
        width: 15%;
    }
    .BycardButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; 
        display: flex;
        font-family: SF Pro Display;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 70px;
    }
    .BycardButton2{
        display: flex;
        justify-content: center;
        width: 20%;
    
    }
    .BycardButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 90%;
        height: 70px;
        
    }
    .BycardBox8{
        display: flex;
        justify-content: end;
        margin-left: 25px;
    }
    .BycardBox9 h2{
       margin: 0px 60px 15px; 
       font-weight: 400;
       font-size: 32px;
       display: flex;
       justify-content: end;
       align-items: end;
    }

}
@media (max-width: 1920px) {
  
    .BycardBox1{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .BycardBoxS{
        display: flex;
        justify-content: start;
        padding: 25px;
    }
    .BycardBox1 h2{
       color: white;
       font-size: 18px;
    
    }
    .BycardBox{
        height: auto;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 4px 28px  #888888;
        padding: 15px 0 30px 0;
    
    }
    .BycardBox2{
       margin-left: 12px;
        height: auto;
        width: 35%;
        background-color: #5231e4;
        border-radius: 5px;
        padding: 10px;
    }
    .BycardBox2 img{
        width: 12%;
        margin: 10px 0 0 5px;
    }
    .BycardBox1img{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .BycardBox3{
        margin-left: 40px;
         height: auto;
         width: 35%;
         background-color: #A1A1A1;
         border-radius: 5px;
         display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;

     }
     .BycardBox3 img{
         width: 10%;
         margin-top: 10px;
         
     
     }
     .BycardBox4{
        display: flex;
        justify-content: center;
        width: 100%;
       
    }
    .BycardBox4 h2{
       color: white;
       font-size: 20px;
    
    }
    .BycardBox5 {
        margin-top: 10px;
        margin-left: 35px;
    }
    .BycardBox5 input{
       width: 52%;
       margin-top: 15px;
    }
    .BycardBox6 {
        display: flex;
        margin-left: 35px;
        margin-top: 10px;
    }
    .BycardBox6 input{
        width: 25.6%;
        margin: 5px 13px 0 0;
    }
    .BycardBox7{
        display: flex;
        align-items: end;
        margin-left: 24px;
    }
    .BycardBox7 h4{
        font-size: 20px;
        font-weight: 400;
    }
    .BycardBox7 input{
        height: 20px;
        width: 40px;
    }
    .BycardButton1{
        width: 15%;
    }
    .BycardButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; 
        display: flex;
        font-family: SF Pro Display;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 60px;
    }
    .BycardButton2{
        display: flex;
        justify-content: center;
        width: 20%;
    
    }
    .BycardButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 90%;
        height: 60px;
        
    }
    .BycardBox8{
        display: flex;
        justify-content: end;
        margin-left: 25px;
    }
    .BycardBox9 h2{
       margin: 0px 50px 15px; 
       font-weight: 400;
       font-size: 20px;
       display: flex;
       justify-content: end;

    }

}
@media (max-width: 1440px) {

    .BycardBox1{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }
    .BycardBoxS{
        display: flex;
        justify-content: start;
        margin-left: 25px;
    }
    .BycardBox1 h2{
       color: white;
       font-size: 18px;
    
    }
    .BycardBox{
        height: 70vh;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 4px 28px  #888888;
    
    }
    .BycardBox2{
       margin-left: 12px;
        height: auto;
        width: 35%;
        background-color: #5231e4;
        margin-top: 20px;
        border-radius: 5px;
    }
    .BycardBox2 img{
        width: 12%;
        margin: 10px 0 0 5px;
    }
    .BycardBox1img{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .BycardBox3{
        margin-left: 8px;
         height: auto;
         width: 35%;
         background-color: #A1A1A1;
         margin-top: 20px;
         border-radius: 5px;
         display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

     }
     .BycardBox3 img{
         width: 10%;
         margin-top: 10px;
         
     
     }
     .BycardBox4{
        display: flex;
        justify-content: center;
        width: 100%;
       
    }
    .BycardBox4 h2{
       color: white;
       font-size: 18px;
    
    }
    .BycardBox5 {
        margin-top: 10px;
        margin-left: 35px;
    }
    .BycardBox5 input{
       width: 52%;
       margin-top: 10px;
    }
    .BycardBox6 {
        display: flex;
        margin-left: 35px;
        margin-top: 5px;
    }
    .BycardBox6 input{
        width: 25.5%;
        margin: 5px 13px 0 0;
    }
    .BycardBox7{
        display: flex;
        align-items: end;
        margin-left: 24px;
    }
    .BycardBox7 h4{
        font-size: 18px;
        font-weight: 400;
    }
    .BycardBox7 input{
        height: 20px;
        width: 40px;
    }
    .BycardButton1{
        width: 15%;
    }
    .BycardButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; 
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .BycardButton2{
        display: flex;
        justify-content: center;
        width: 20%;
    
    }
    .BycardButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 90%;
        height: 40px;
        
    }
    .BycardBox8{
        margin-left: 25px;
    }
    .BycardBox9 h2{
        margin: 0 35px 15px;
        font-weight: 400;
        font-size: 18px;
        display: flex;
        justify-content: end;
        align-items: end;
        height: auto;

    }

}
@media (max-width: 1366px) {
    .BycardBox1{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }
    .BycardBoxS{
        display: flex;
        justify-content: start;
        margin-left: 25px;
    }
    .BycardBox1 h2{
       color: white;
       font-size: 18px;
    
    }
    .BycardBox{
        height: 70vh;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 4px 28px  #888888;
    
    }
    .BycardBox2{
       margin-left:12px;
        height: 12vh;
        width: 35%;
        background-color: #5231e4;
        margin-top: 20px;
        border-radius: 5px;
    }
    .BycardBox2 img{
        width: 12%;
        margin: 10px 0 0 5px;
    }
    .BycardBox1img{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .BycardBox3{
        margin-left: 8px;
         height: 12vh;
         width: 35%;
         background-color: #A1A1A1;
         margin-top: 20px;
         border-radius: 5px;
         display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

     }
     .BycardBox3 img{
         width: 10%;
         margin-top: 10px;
         
     
     }
     .BycardBox4{
        display: flex;
        justify-content: center;
        width: 100%;
       
    }
    .BycardBox4 h2{
       color: white;
       font-size: 18px;
    
    }
    .BycardBox5 {
        margin-top: 10px;
        margin-left: 35px;
    }
    .BycardBox5 input{
       width: 52%;
    }
    .BycardBox6 {
        display: flex;
        margin-left: 35px;
    }
    .BycardBox6 input{
        width: 25.5%;
        margin: 5px 13px 0 0;
    }
    .BycardBox7{
        display: flex;
        align-items: end;
        margin-left: 24px;
    }
    .BycardBox7 h4{
        font-size: 18px;
        font-weight: 400;
    }
    .BycardBox7 input{
        height: 20px;
        width: 40px;
    }
    .BycardButton1{
        width: 15%;
    }
    .BycardButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; 
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .BycardButton2{
        display: flex;
        justify-content: center;
        width: 20%;
    
    }
    .BycardButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 90%;
        height: 40px;
        
    }
    .BycardBox8{
        margin-left: 25px;
    }
    .BycardBox9 h2{
       margin: 0px 35px 15px; 
       font-weight: 400;
       font-size: 18px;

    }

}

@media (max-width: 1024px) {
    .BycardBox1{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }
    .BycardBoxS{
        display: flex;
        justify-content: start;
        margin-left: 25px;
    }
    .BycardBox1 h2{
       color: white;
       font-size: 14px;
    
    }
    .BycardBox{
        height: 70vh;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 4px 28px  #888888;
    
    }
    .BycardBox2{
       margin-left: 8px;
        height: auto;
        width: 35%;
        background-color: #5231e4;
        margin-top: 20px;
        border-radius: 5px;
    }
    .BycardBox2 img{
        width: 12%;
        margin: 10px 0 0 5px;
    }
    .BycardBox1img{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .BycardBox3{
        margin-left: 8px;
         height: auto;
         width: 35%;
         background-color: #A1A1A1;
         margin-top: 20px;
         border-radius: 5px;
         display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

     }
     .BycardBox3 img{
         width: 10%;
         margin-top: 10px;
         
     
     }
     .BycardBox4{
        display: flex;
        justify-content: center;
        width: 100%;
       
    }
    .BycardBox4 h2{
       color: white;
       font-size: 14px;
    
    }
    .BycardBox5 {
        margin-top: 10px;
        margin-left: 35px;
    }
    .BycardBox5 input{
       width: 52%;
       margin-top: 10px;
    }
    .BycardBox6 {
        display: flex;
        margin-left: 35px;
        margin-top: 5px;
    }
    .BycardBox6 input{
        width: 25.3%;
        margin: 5px 13px 0 0;
    }
    .BycardBox7{
        display: flex;
        align-items: end;
        margin-left: 24px;
    }
    .BycardBox7 h4{
        font-size: 14px;
        font-weight: 400;
    }
    .BycardBox7 input{
        height: 16px;
        width: 40px;
    }
    .BycardButton1{
        width: 15%;
    }
    .BycardButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; 
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .BycardButton2{
        display: flex;
        justify-content: center;
        width: 20%;
    
    }
    .BycardButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 90%;
        height: 40px;
        
    }
    .BycardBox8{
        
        margin-left: 25px;
    }
    .BycardBox9 h2{
       margin: 0px 35px 15px; 
       font-weight: 400;
       font-size: 14px;

    }

}
@media (max-width: 768px) {
    .BycardBox1{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }
    .BycardBoxS{
        display: flex;
        justify-content: center;
        margin-left: 0px;
    }
    .BycardBox1 h2{
       color: white;
       font-size: 14px;
    
    }
    .BycardBox{
        height: 70vh;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 4px 28px  #888888;
    
    }
    .BycardBox2{
       margin-left: 8px;
        height: auto;
        width: 45%;
        background-color: #5231e4;
        margin-top: 20px;
        border-radius: 5px;
    }
    .BycardBox2 img{
        width: 12%;
        margin: 10px 0 0 5px;
    }
    .BycardBox1img{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .BycardBox3{
        margin-left: 8px;
         height:auto;
         width: 45%;
         background-color: #A1A1A1;
         margin-top: 20px;
         border-radius: 5px;
         display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

     }
     .BycardBox3 img{
         width: 10%;
         margin-top: 10px;
         
     
     }
     .BycardBox4{
        display: flex;
        justify-content: center;
        width: 100%;
       
    }
    .BycardBox4 h2{
       color: white;
       font-size: 14px;
    
    }
    .BycardBox5 {
        margin-top: 10px;
        margin-left: 35px;
    }
    .BycardBox5 input{
       width: 70%;
       margin-top: 5px;
    }
    .BycardBox6 {
        display: flex;
        margin-left: 35px;
        margin-top: 5px;
    }
    .BycardBox6 input{
        width: 34%;
        margin: 5px 13px 0 0;
    }
    .BycardBox7{
        display: flex;
        align-items: end;
        margin-left: 24px;
    }
    .BycardBox7 h4{
        font-size: 14px;
        font-weight: 400;
    }
    .BycardBox7 input{
        height: 16px;
        width: 40px;
    }
    .BycardButton1{
        width: 15%;
    }
    .BycardButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; 
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .BycardButton2{
        display: flex;
        justify-content: center;
        width: 20%;
    
    }
    .BycardButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 90%;
        height: 40px;
        
    }
    .BycardBox8{
      
        margin-left: 25px;
    }
    .BycardBox9 h2{
       margin: 0px 35px 15px; 
       font-weight: 400;
       font-size: 14px;

    }

}

@media (max-width: 425px) {
    .BycardBox1{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }
    .BycardBox1 h2{
       color: white;
       font-size: 12px;
    
    }
    .BycardBox{
        height: 70vh;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 4px 28px  #888888;
    
    }
    .BycardBox2{
       margin-left: 8px;
        height: auto;
        width: 45%;
        background-color: #5231e4;
        margin-top: 20px;
        border-radius: 5px;
    }
    .BycardBox2 img{
        width: 20%;
        margin: 10px 0 0 5px;
    
    }
    .BycardBox3{
        margin-left: 8px;
         height: auto;
         width: 45%;
         background-color: #A1A1A1;
         margin-top: 20px;
         border-radius: 5px;
         display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

     }
     .BycardBox3 img{
         width: 20%;
         
     
     }
     .BycardBox4{
        display: flex;
        justify-content: center;
        width: 100%;
       
    }
    .BycardBox4 h2{
       color: white;
       font-size: 12px;
    
    }
    .BycardBox5 {
        margin-top: 10px;
        margin-left: 20px;
    }
    .BycardBox5 input{
       width: 70%;
    }
    .BycardBox6 {
        display: flex;
        margin-left: 20px;
    }
    .BycardBox6 input{
        width: 33%;
        margin: 5px 13px 0 0;
    }
    .BycardBox7{
        display: flex;
        align-items: end;
        margin-left: 10px;
    }
    .BycardBox7 h4{
        font-size: 14px;
        font-weight: 400;
    }
    .BycardBox7 input{
        height: 16px;
        width: 40px;
    }
    .BycardButton1{
        width: 100%;
    }
    .BycardButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; 
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .BycardButton2{
        display: flex;
        justify-content: center;
        width: 100%;
    
    }
    .BycardButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 90%;
        height: 40px;
        
    }
    .BycardBox8{
        display: block;
        margin-left: 0px;
       
    }
    .BycardBox9 h2{
       margin: 0px 25px 15px; 
       font-weight: 400;
       font-size: 14px;

    }

}


input[type="checkbox"]{
    accent-color: #5231e4; 
}
