
.PartnerBox1img{
    width: 100%;
}
.PartnerBox1text{
    width: 100%;
}
.PartnerBox2img{
    width: 100%;
}
.PartnerBox2text{
    width: 100%;
}
.PartnerBoxTanks{
    display: flex;
    justify-content: center;
    align-items: center;
}
.PartnerBoxTanksimg img{
    width: 100%;
}
.PartnerBoxTanks button{
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: white;
    background: #5131E4;
    border-radius: 10px;
    border-style: none;
    padding: 10px 40px;
}
#otp{
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: white;
    background: #5131E4;
    border-radius: 10px;
    border-style: none;
    
}
.PartnerBoxRequest h1{
    display: flex;
    justify-content: center;
    width: 97%;
}
.PartnerBoxRequestimg{
    width: 50%;
}
.PartnerBoxRequestinput{
    width: 50%;
}
.PartnerInputSelect label{
    display: flex;
    flex-direction: column;
}
.PartnerInputSelect label select{
    border: 1px solid #E1E1E1;
    border-radius: 4px;

}
.PartnerInputComment label textarea{
    border: 1px solid #E1E1E1;
    padding-left: 10px;
    max-height: 100px;
    max-width: 100%; 
    min-height: 100px;
    min-width: 100%; 
}
.PartnerBoxRequestinput form{
    width: 100%;
}
.PartnerInput label input{
    border: 1px solid #E1E1E1; 
  
}
/* .PartnerInputComment{
    position: relative;
}
.PartnerInputComment input[type="text"]::placeholder {
    position: absolute;
    left: 2px;
    top: 0;
} */



@media (max-width: 2560px) {
    .Partner{
        padding: 0px 100px;
        width: 100%;
    }
    .PartnerBox1{
        display: flex;
        padding-top: 200px;
    }
    .PartnerBox1text h1{
        width: 65%;
        
    }
    .PartnerBox1text p{
        margin-top: 20px;
        width: 50%;
        font-size: 20px;
    }
    .PartnerBox1img img{
        width: 100%;
    }
    
    .PartnerBox2{
       display: flex;
    align-items: center; 
    }
    .PartnerBox2img img{
        width: 85%;
        margin-top: 10px;
    }
    .PartnerBox2text p{
        margin-top: 0px;
        width: 77%;
        font-size: 20px;
    }
    .PartnerBox3{
        display: flex;
     align-items: center; 
     flex-direction: row-reverse;
     }
    .PartnerBox3img img{
    width: 85%;
    }
    .PartnerBox3img{
        width: 100%;
      }
    .PartnerBox3text {
        display: flex;
        justify-content: end;
        width: 90%;
       
        }
    .PartnerBox3text p{
        margin-top: 0px;
        width: 77%;
        font-size: 20px;
    }
   
    .PartnerBox4{
        display: flex;
     align-items: center; 
     }
    .PartnerBox4img img{
        width: 85%;
        margin-top: 10px;
    }
    .PartnerBox4img{
      width: 100%;
    }
    .PartnerBox4text {
        width: 90%;
    }
    .PartnerBox4text p{
        margin-top: 0px;
        width: 77%;
        font-size: 20px;
    }
    .PartnerBox5{
        margin-top: 40px;
    }
    .PartnerBoxRequest{
        margin-top: 40px;
    }
    .PartnerBoxTanks{
        margin-top: 40px;
    }
    .PartnerBox5Box{
        display: flex;
    }
    .PartnerBox5 h1{
        width: 100%;
    }

    .PartnerBox5ob{
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        width: 100%;
    }
    .PartnerBox5ob h4{
        display: flex;
        align-items: center;
        padding: 0px;
        width: 80%;
    }
    .PartnerBox5ob img{
       width: 70%;
    }
    .PartnerBoxTanks button{
        padding: 10px 110px;
    }
    .PartnerBoxRequestimg img{
        width: 100%;
    }
    .PartnerBoxRequestBox{
        display: flex;
        align-items: center;
    }
    .PartnerInputSelect label{
        font-size: 24px;
    }
    .PartnerInput label{
        font-size: 24px;
    }
    .PartnerInputComment label{
        font-size: 24px;
    }
    .PartnerInputSelect label select {
        height: 70px;
        font-size: 24px;
    }
    .PartnerInput input {
        height: 70px;
        font-size: 24px;
    }
    .PartnerInputComment label textarea {
        font-size: 24px;
        height: 160px;
        width: 100%;
        font-family: "Roboto";
    }
    .PartnerBoxRequestimg img {
        width: 80%;
    }
    #otp {
        padding: 20px 0px;
        width: 100%;
        margin: 40px 0 0 0;
        font-size: 24px;
    }
    .PartnerInputComment{
        margin: 15px 0 0 0;
    }
    .PartnerInput{
        margin: 15px 0 0 0;
    }
    .PartnerInputSelect{
        margin: 15px 0 0 0;  
    }
}
@media (max-width: 1920px) {
    .Partner{
        padding: 0px 80px;
        width: 100%;
    }
    .PartnerBox1{
        display: flex;
        padding-top: 150px;
    }
    .PartnerBox1text h1{
        width: 65%;
        
    }
    .PartnerBox1text p{
        margin-top: 20px;
        width: 50%;
        font-size: 20px;
    }
    .PartnerBox1img img{
        width: 100%;
    }
    
    .PartnerBox2{
       display: flex;
    align-items: center; 
    }
    .PartnerBox2img img{
        width: 85%;
        margin-top: 10px;
    }
    .PartnerBox2text p{
        margin-top: 0px;
        width: 77%;
        font-size: 20px;
    }
    .PartnerBox3{
        display: flex;
     align-items: center; 
     flex-direction: row-reverse;
     }
    .PartnerBox3img img{
    width: 85%;
    }
    .PartnerBox3img{
        width: 100%;
      }
    .PartnerBox3text {
        display: flex;
        justify-content: end;
        width: 90%;
       
        }
    .PartnerBox3text p{
        margin-top: 0px;
        width: 77%;
        font-size: 20px;
    }
   
    .PartnerBox4{
        display: flex;
     align-items: center; 
     }
    .PartnerBox4img img{
        width: 85%;
        margin-top: 10px;
    }
    .PartnerBox4img{
      width: 100%;
    }
    .PartnerBox4text {
        width: 90%;
    }
    .PartnerBox4text p{
        margin-top: 0px;
        width: 77%;
        font-size: 20px;
    }
    .PartnerBox5Box{
        display: flex;
    }
    .PartnerBox5 h1{
        width: 100%;
    }

    .PartnerBox5ob{
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        width: 100%;
    }
    .PartnerBox5ob h4{
        display: flex;
        align-items: center;
        padding: 0px;
        width: 80%;
    }
    .PartnerBox5ob img{
       width: 70%;
    }
    .PartnerBoxTanks button{
        padding: 10px 110px;
    }
    .PartnerBoxRequestinput {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .PartnerInputSelect label{
        font-size: 18px;
    }
    .PartnerInput label{
        font-size: 18px;
    }
    .PartnerInputComment label{
        font-size: 18px;
    }
    .PartnerInputSelect label select {
        height: 50px;
        font-size: 18px;
    }
    .PartnerInput input {
        height: 50px;
        font-size: 18px;
    }
    .PartnerInputComment label textarea {
        font-size: 18px;
        height: 120px;
    }
    .PartnerBoxRequestimg img {
        width: 80%;
    }
    #otp {
        padding: 10px 0px;
        width: 100%;
        font-size: 18px;
        margin: 20px 0 0 0;
    }
}
@media (max-width: 1440px) {
    .Partner{
        padding: 0px 80px;
        width: 100%;
    }
    .PartnerBox1{
        display: flex;
        padding-top: 150px;
    }
    .PartnerBox1text h1{
        width: 65%;
        
    }
    .PartnerBox1text p{
        margin-top: 20px;
        width: 70%;
    }
    .PartnerBox1img img{
        width: 100%;
    }
    
    .PartnerBox2{
       display: flex;
    align-items: center; 
    }
    .PartnerBox2img img{
        width: 85%;
        margin-top: 10px;
    }
    .PartnerBox2text p{
        margin-top: 0px;
        width: 77%;
    }
    .PartnerBox3{
        display: flex;
     align-items: center; 
     flex-direction: row-reverse;
     }
    .PartnerBox3img img{
    width: 85%;
    }
    .PartnerBox3img{
        width: 100%;
      }
    .PartnerBox3text {
        display: flex;
        justify-content: end;
        width: 90%;
       
        }
    .PartnerBox3text p{
        margin-top: 0px;
        width: 77%;
    }
   
    .PartnerBox4{
        display: flex;
     align-items: center; 
     }
    .PartnerBox4img img{
        width: 85%;
        margin-top: 10px;
    }
    .PartnerBox4img{
      width: 100%;
    }
    .PartnerBox4text {
        width: 90%;
    }
    .PartnerBox4text p{
        margin-top: 0px;
        width: 77%;
    }
    .PartnerBox5Box{
        display: flex;
    }
    .PartnerBox5 h1{
        width: 100%;
    }

    .PartnerBox5ob{
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        width: 100%;
    }
    .PartnerBox5ob h4{
        display: flex;
        align-items: center;
        padding: 0px;
        width: 80%;
    }
    .PartnerBox5ob img{
       width: 70%;
    }
    .PartnerBoxTanks button {
        padding: 10px 70px;
    }
    .PartnerBoxTanks p{
       width: 75%;
    }
    #otp{
        padding: 10px 0px;
        width: 100%;
        margin: 20px 0 0 0;
    }
    .PartnerInputSelect label select {
      height: 40px;
      font-size: 16px;
    }
    .PartnerInputComment label textarea {
       height: 100px;
    }
    .PartnerBoxRequestinput {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .PartnerInputSelect label{
        font-size: 16px;
    }
    .PartnerInput label{
        font-size: 16px;
    }
    .PartnerInputComment label{
        font-size: 16px;
    }
    .PartnerInputSelect label select {
        height: 40px;
    }
    .PartnerInput input {
        height: 40px;
        font-size: 16px;
    }
    .PartnerInputComment label textarea {
        font-size: 16px;
    }
    .PartnerBoxRequestimg img {
        width: 80%;
    }
}
@media (max-width: 1366px) {
    .Partner{
        padding: 0px 80px;
        width: 100%;
    }
    .PartnerBox1{
        display: flex;
        padding-top: 150px;
    }
    .PartnerBox1text h1{
        width: 70%;
        
    }
    .PartnerBox1text p{
        margin-top: 20px;
    }
    .PartnerBox1img img{
        width: 100%;
    }
    
    .PartnerBox2{
       display: flex;
    align-items: center; 
    }
    .PartnerBox2img img{
        width: 85%;
        margin-top: 10px;
    }
    .PartnerBox2text p{
        margin-top: 0px;
        width: 95%;
    }
    .PartnerBox3{
        display: flex;
     align-items: center; 
     flex-direction: row-reverse;
     }
    .PartnerBox3img img{
    width: 85%;
    }
    .PartnerBox3img{
        width: 100%;
      }
    .PartnerBox3text {
        display: flex;
        justify-content: end;
        width: 90%;
       
        }
    .PartnerBox3text p{
        margin-top: 0px;
        width: 90%;
    }
   
    .PartnerBox4{
        display: flex;
     align-items: center; 
     }
    .PartnerBox4img img{
        width: 85%;
        margin-top: 10px;
    }
    .PartnerBox4img{
      width: 100%;
    }
    .PartnerBox4text {
        width: 90%;
    }
    .PartnerBox4text p{
        margin-top: 0px;
        width: 90%;
    }
    .PartnerBox5Box{
        display: flex;
    }
    .PartnerBox5 h1{
        width: 100%;
    }

    .PartnerBox5ob{
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        width: 100%;
    }
    .PartnerBox5ob h4{
        display: flex;
        align-items: center;
        padding: 0px;
        width: 80%;
    }
    .PartnerBox5ob img{
       width: 70%;
    }
}
@media (max-width: 1024px) {
    .Partner{
        padding: 0px 60px;
        width: 100%;
    }
    .PartnerBox1{
        display: flex;
        padding-top: 100px;
    }
    .PartnerBox1text h1{
        width: 70%;
        
    }
    .PartnerBox1text p{
        margin-top: 20px;
    }
    .PartnerBox1img img{
        width: 100%;
    }
    
    .PartnerBox2{
       display: flex;
    align-items: center; 
    }
    .PartnerBox2img img{
        width: 100%;
        margin-top: 10px;
    }
    .PartnerBox2text p{
        margin-top: 0px;
        width: 95%;
    }
    .PartnerBox3{
        display: flex;
     align-items: center; 
     flex-direction: row-reverse;
     }
    .PartnerBox3img img{
   
    width: 100%;
    }
    .PartnerBox3img{
        width: 100%;
      }
    .PartnerBox3text {
        display: flex;
        justify-content: end;
        width: 90%;
       
        }
    .PartnerBox3text p{
        margin-top: 0px;
        width: 90%;
    }
   
    .PartnerBox4{
        display: flex;
     align-items: center; 
     }
    .PartnerBox4img img{
        width: 100%;
        margin-top: 10px;
    }
    .PartnerBox4img{
      width: 100%;
    }
    .PartnerBox4text {
        width: 90%;
    }
    .PartnerBox4text p{
        margin-top: 0px;
        width: 90%;
    }
    .PartnerBox5Box{
        display: flex;
    }
    .PartnerBox5 h1{
        width: 100%;
    }

    .PartnerBox5ob{
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        width: 100%;
    }
    .PartnerBox5ob h4{
        display: flex;
        align-items: center;
        padding: 0px;
        font-size: 12px;
    }
    .PartnerBox5ob img{
       width: 90%;
    }
    .PartnerBoxTanks button {
        padding: 10px 45px;
    }
    .PartnerBoxTanks p{
       width: 70%;
    }
    #otp{
        padding: 10px 0px;
        width: 100%;
        margin: 20px 0 0 0;
    }
    .PartnerInputSelect label select {
      height: 30px;
      font-size: 14px;
    }
    .PartnerInputComment label textarea {
       height: 80px;
    }
    .PartnerBoxRequestinput {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .PartnerBoxRequestimg img {
        width: 100%;
    }
    .PartnerInputComment{
        margin: 10px 0 0 0;
    }
    .PartnerInput{
        margin: 10px 0 0 0;
    }
    .PartnerInputSelect{
        margin: 10px 0 0 0;  
    }
}
@media (max-width: 768px) {
    .Partner{
        padding: 0px 40px;
        width: 100%;
    }
    .PartnerBox1{
        display: flex;
        padding-top: 100px;
    }
    .PartnerBox1text h1{
        width: 100%;
        
    }
    .PartnerBox1text p{
        margin-top: 20px;
    }
    .PartnerBox1img img{
        width: 100%;
    }
    
    .PartnerBox2{
       display: flex;
    align-items: center; 
    }
    .PartnerBox2img img{
        width: 100%;
        margin-top: 10px;
    }
    .PartnerBox2text p{
        margin-top: 0px;
        width: 95%;
    }
    .PartnerBox3{
        display: flex;
     align-items: center; 
     flex-direction: row-reverse;
     }
    .PartnerBox3img img{
   
    width: 100%;
    }
    .PartnerBox3img{
        width: 100%;
      }
    .PartnerBox3text {
        display: flex;
        justify-content: end;
        width: 90%;
       
        }
    .PartnerBox3text p{
        margin-top: 0px;
        width: 90%;
    }
   
    .PartnerBox4{
        display: flex;
     align-items: center; 
     }
    .PartnerBox4img img{
        width: 100%;
        margin-top: 10px;
    }
    .PartnerBox4img{
      width: 100%;
    }
    .PartnerBox4text {
        width: 90%;
    }
    .PartnerBox4text p{
        margin-top: 0px;
        width: 90%;
    }
    .PartnerBox5Box{
        display: flex;
    }
    .PartnerBox5 h1{
        width: 100%;
    }

    .PartnerBox5ob{
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        width: 100%;
    }
    .PartnerBox5ob h4{
        display: flex;
        align-items: center;
        padding: 0px;
    }
    .PartnerBox5ob img{
       width: 90%;
    }
    #otp{
        padding: 10px 0px;
        width: 100%;
        margin: 10px 0 10px 0;
    }
    .PartnerInputSelect label select {
      height: 30px;
    }
    .PartnerInputComment label textarea {
       height: 80px;
    }
}
@media (max-width: 425px) {
    .Partner{
        padding: 0px 20px;
        width: 100%;
    }
    .PartnerBox1{
       display: block ;
        padding-top: 60px;
    }
    .PartnerBox1text h1{
        width: 100%;
    }
    .PartnerBox1text p{
        margin-top: 20px;
    }
    .PartnerBox1img img{
        width: 100%;
    }
    .PartnerBox2{
        display: block;
    
     }
     .PartnerBoxRequestinput {
        width: 100%;
    }
    .PartnerBox2img img{
        width: 100%;
        margin-top: 10px;
    }
    .PartnerBox2text p{
        margin-top: 20px;
    }
    .PartnerBox3img img{
        width: 100%;
        margin-top: 10px;
    }
    .PartnerBox3{
        display: block;
    }
    .PartnerBox3text{
        display: block;
    }
    
    .PartnerBox3text p{
        margin-top: 20px;
    }
    .PartnerBox4{
        display: block;
    }
    .PartnerBox4img img{
        width: 100%;
        margin-top: 10px;
    }
    .PartnerBox4text p{
        margin-top: 20px;
    }
    .PartnerBox5Box{
        display: block;
    }
    .PartnerBox5 h1{
        width: 100%;
    }
    .PartnerBox5ob{
        display: flex;
        margin-top: 20px;
        flex-direction: inherit;
    }
    .PartnerBox5ob h4{
        display: flex;
        align-items: center;
        padding: 25px;
    }
    .PartnerBox5ob img{
       width: 50%;
    }
    .PartnerInputSelect label{
        font-size: 14px;
    }
    .PartnerInput label{
        font-size: 14px;
    }
    .PartnerInputComment label{
        font-size: 14px;
    }
    .PartnerInputSelect label select {
        height: 30px;
    }
    .PartnerInput input {
        height: 30px;
        font-size: 14px;
    }
    .PartnerInputComment label textarea {
        font-size: 14px;
    }
   
    #otp{
        padding: 10px 0;
        margin:10px 0 10px 0;  
        width: 100%;
    }
    .PartnerBoxRequestimg{
        display: none;
    }

}
