* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
    .password {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100vh;

    }
     .passwordButon1{
        width: 100%;
        display: flex;
        justify-content: center;
     }
  @media (max-width: 2560px) {
  
    .passwordBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
        width: 60%;
    }
    .passwordBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 60px;
    }
    .passwordBox2 input{
     width: 100%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 70px;
    font-size: 28px;    
    }
    .passwordBox2 {
        margin-top: 43px;
        display: flex;
        justify-content: center;
    }
    .passwordBox21{
        width: 40%;
    }
    .passwordBox22{
        width: 40%;
        margin-left: 40px;
    }
    .passwordBox2 p{
        font-weight: 300;
        font-size: 28px;
        margin: 0 0 6px 0px;
        width: 100%;
       }
    .passwordBox2 .passwordBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 26px;
        display: flex;
        justify-content: end;
        margin-top: 6px;
        cursor: pointer;
    }
    .passwordButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 40%;
    height: 70px;
    cursor: pointer;
    }
    .pass{
        margin-left: 27px;
    }
    .passwordBox2 label{
        font-size: 28px;
    }
  }
  @media (max-width: 1920px) {
  
    .passwordBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
        width: 60%;
    }
    .passwordBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 32px;
    }
    .passwordBox2 input{
     width: 100%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 60px;
    font-size: 20px;    
    }
    .passwordBox2 {
        margin-top: 43px;
        display: flex;
        justify-content: center;
    }
    .passwordBox21{
        width: 40%;
    }
    .passwordBox22{
        width: 40%;
        margin-left: 40px;
    }
    .passwordBox2 p{
        font-weight: 300;
        font-size: 18px;
        margin: 0 0 6px 0px;
        width: 100%;
       }
    .passwordBox2 .passwordBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 20px;
        justify-content: end;
        margin-top: 6px;
    }
    .passwordButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 40%;
    height: 60px;
    }
    .pass{
        margin-left: 27px;
    }
    .passwordBox2 label{
        font-size: 24px;
    }
  }
  @media (max-width: 1440px) {
  
    .passwordBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
        width: 60%;
    }
    .passwordBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
    }
    .passwordBox2 input{
     width: 100%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 30px;
    font-size: 16px;    
    }
    .passwordBox2 {
        margin-top: 43px;
        display: flex;
        justify-content: center;
    }
    .passwordBox21{
        width: 40%;
    }
    .passwordBox22{
        width: 40%;
        margin-left: 20px;
    }
    .passwordBox2 p{
        font-weight: 300;
        font-size: 18px;
        margin: 0 0 6px 0px;
        width: 100%;
       }
    .passwordBox2 .passwordBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 14px;
        justify-content: end;
        margin-top: 6px;
    }
    .passwordButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 40%;
    height: 30px;
    }
    .pass{
        margin-left: 27px;
    }
    .passwordBox2 label{
        font-size: 18px;
    }
    
  }
  @media (max-width: 1366px) {
 
    .passwordBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
    }
    .passwordBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
    }
    .passwordBox2 input{
     width: 100%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 30px;    
    }
    .passwordBox2 {
        margin-top: 43px;
        display: flex;
        justify-content: center;
    }
    .passwordBox21{
        width: 40%;
    }
    .passwordBox22{
        width: 40%;
        margin-left: 20px;
    }
    .passwordBox2 p{
        font-weight: 300;
        font-size: 16px;
        margin: 0 0 6px 0px;
        width: 100%;
       }
    .passwordBox2 .passwordBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 14px;
        justify-content: end;
        margin-top: 6px;
    }
   
    .passwordButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 40%;
    height: 30px;
    }
  
        .pass{
            margin-left: 27px;
        }
  }
  @media (max-width: 1024px) {
 
    .passwordBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
        width: 70%;
    }
    .passwordBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
    }
    .passwordBox2 input{
     width: 70%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 30px;    
    }
    .passwordBox2 {
        margin-top: 43px;
        justify-content: center
    }
    .passwordBox21{
        width: 40%;
    }
    .passwordBox22{
        width: 40%;
        margin-left: 20px;
    }
    .passwordBox2 p{
        font-weight: 300;
        font-size: 16px;
        margin: 0 0 6px 0px;
       }
    .passwordBox2 .passwordBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 12px;
        justify-content: end;
        margin-top: 6px;
    }
    .passwordButon1{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .passwordButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 40%;
    height: 30px;
    }
    .passwordBox2 label{
        font-size: 16px;
    }
  }

  @media (max-width: 768px) {
  
    .passwordBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
        width: 80%;
    }
    .passwordBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
    }
    .passwordBox2 input{
     width: 100%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 30px;    
    }
    .passwordBox2 {
        margin-top: 23px;
        display: flex;
        margin-left: 50px;
    }
    .passwordBox21{
        width: 40%;
    }
    .passwordBox22{
        width: 40%;
        margin-left: 20px;
    }
    .passwordBox2 p{
        font-weight: 300;
        font-size: 14px;
        margin: 0 0 6px 0px;
        width: 100%;
       }
    .passwordBox2 .passwordBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 12px;
        justify-content: end;
        margin-top: 6px;
    }
    .passwordButon1{
        display: flex;
        width: 100%;
        justify-content: center;
    }
    .passwordButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 47px 0 0;
    width: 40%;
    height: 30px;
    }
    
        .pass{
            margin-left: 27px;
        }
        .passwordBox2 label{
            font-size: 14px;
        }
  }
  @media (max-width: 425px) {
    .passwordBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
        width: 80%;
    }
    .passwordBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
    }
    .passwordBox2 input{
     width: 100%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 30px;    
    }
    .passwordBox2 {
        margin-top: 43px;
        display: block;
        margin-left: 0;
    }
    .passwordBox21{
        width: 100%;
    }
    .passwordBox22{
        width: 100%;
        margin-left: 0px;
        margin-top: 27px;
    }
    .passwordBox2 p{
        font-weight: 300;
        font-size: 14px;
        margin: 0 0 6px 0px;
       }
    .passwordBox2 .passwordBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 12px;
        justify-content: end;
        margin-top: 6px;
    }
    .passwordButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 100%;
    height: 30px;
    }
    
  }
  @media (max-width: 390px) {

    .passwordBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
    }
    .passwordBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
    }
    .passwordBox2 input{
     width: 100%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 30px;    
    }
    .passwordBox2 {
        margin-top: 43px;
    }
    .passwordBox2 p{
        font-weight: 300;
        font-size: 14px;
        margin: 0 0 6px 0px;
       }
    .passwordBox2 .passwordBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 12px;
        justify-content: end;
        margin-top: 6px;
    }
    .passwordButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 100%;
    height: 30px;
    }
  
  }
  @media (max-width: 320px) {
    .passwordBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
    }
    .passwordBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
        width: 102%;
    }
    .passwordBox2 input{
     width: 100%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 30px;    
    }
    .passwordBox2 {
        margin-top: 43px;
    }
    .passwordBox2 p{
        font-weight: 300;
        font-size: 14px;
        margin: 0 0 6px 0px;
       }
    .passwordBox2 .passwordBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 12px;
        justify-content: end;
        margin-top: 6px;
    }
    .passwordButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 100%;
    height: 30px;
    }

  }