* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .signin {
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .signinButon1 a {
        display: flex;
        justify-content: end;
        color: #9581EE !important;
    }
   
    
  
  @media (max-width: 2560px) {
    .signin {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
    }
    

    .signinBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
        width: 40%;
    }
    .signinBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 60px;
    }
    .signinBox1 input{
        width: 100%;
        border: 1px solid #9581EE;
        border-radius: 4px;
        font-size: 28px;
        outline: none;
        padding: 5px 15px;
        height: 70px;
        margin: 5px 0 20px 0px;
    }
    .signinBox2 {
        margin-top: 43px;
    }
    .signinBox2 p{
        font-weight: 300;
        font-size: 28px;
        margin: 0 0 6px 0px;
       }
    .signinBox2 .signinBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 26px;
        display: flex;
        justify-content: end;
        margin-top: 6px;
        cursor: pointer;
    }
    .signinButon1 {
        font-size: 28px;
    }
    .signinButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 100%;
    height: 70px;
    cursor: pointer;
    }
    .signinButon2 button{
        align-items: center;
        background: #f9f9f9;
        border-radius: 4px;
        border-style: none;
        color: #000000;
        display: flex;
        font-family: SF Pro Display;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 17px 0 0 0px;
        width: 100%;
        cursor: pointer;
        }
        .basd{
            font-size: 24px;
            color: red;
        }
        .signineror{
            font-size: 24px;
            color: red;
        }
        .signinButon1{
            position: relative;
          }
          .signinButon1 img {
            position: absolute;
            top: 46%;
            left: 90%;
            transform: translateY(-50%);
            width: 30px; /* adjust the size of the picture */
            height: 30px;
          }
          .signinButon1{
            position: relative;
          }

          .signinButon1 img {
            position: absolute;
            top: 50%;
            left: 90%;
            transform: translateY(-50%);
            width: 60px; /* adjust the size of the picture */
            height: 60px;
          }
  }
  @media (max-width: 1920px) {
   
    .signinBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
        width: 40%;
    }
    .signinBox1 input{
        width: 100%;
        border: 1px solid #9581EE;
        border-radius: 4px;
        font-size: 24px;
        outline: none;
        padding: 5px 15px;
        height: 40px;
        margin-top: 5px;
    }
    .signinBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 40px;
    }
    .signinBox2 input{
     width: 100%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 50px;    
    font-size: 32px;
    }
    .signinBox2 {
        margin-top: 43px;
    }
    .signinBox2 p{
        font-weight: 300;
        font-size: 24px;
        margin: 0 0 6px 0px;
       }
    .signinBox2 .signinBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 24px;
        justify-content: end;
        margin-top: 6px;
    }
    .signinButon1 {
        font-size: 24px;
    }
    .signinButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 100%;
    height: 50px;
    }
    .signinButon2 button{
        align-items: center;
        background: #f9f9f9;
        border-radius: 4px;
        border-style: none;
        color: #000000;
        display: flex;
        font-family: SF Pro Display;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 17px 0 0 0px;
        width: 100%;
        }
        .basd{
            font-size: 18px;
        }
        .signineror{
            font-size: 18px;
        }
        .signinButon1{
            position: relative;
          }
          
          
          .signinButon1 img {
            position: absolute;
            top: 48%;
            left: 90%;
            transform: translateY(-50%);
            width: 50px; /* adjust the size of the picture */
            height: 50px;
          }
  }
  @media (max-width: 1440px) {
 
    .signinBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
        width: 40%;
    }
    .signinBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 28px;
    }
    .signinBox1 input{
      
       font-size: 20px;    
       }
    .signinBox2 input{
     width: 100%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 30px;
    font-size: 16px;    
    
    }
    .signinBox2 {
        margin-top: 43px;
    }
    .signinBox2 p{
        font-weight: 300;
        font-size: 18px;
        margin: 0 0 6px 0px;
       }
    .signinBox2 .signinBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 14px;
        justify-content: end;
        margin-top: 6px;
    }
    .signinButon1 {
        font-size: 20px;
    }
    .signinButon1 a {
        font-size: 14px;
       
    }

    .signinButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 100%;
    height: 40px;
    }
    .signinButon2 button{
        align-items: center;
        background: #f9f9f9;
        border-radius: 4px;
        border-style: none;
        color: #000000;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 17px 0 0 0px;
        width: 100%;
        }
        .basd {
            font-size: 14px;
        }
        .signineror{
            font-size: 14px;
        }
        .signinButon1{
            position: relative;
          }
          .signinButon1 img {
            position: absolute;
            top: 50%;
            left: 90%;
            transform: translateY(-50%);
            width: 40px; /* adjust the size of the picture */
            height: 40px;
          }
  }
  @media (max-width: 1366px) {
 
    .signinBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
        width: 40%;
    }
    .signinBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 28px;
    }
    .signinBox2 input{
     width: 100%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 30px;    
    }
    .signinBox2 {
        margin-top: 43px;
    }
    .signinBox2 p{
        font-weight: 300;
        font-size: 16px;
        margin: 0 0 6px 0px;
       }
    .signinBox2 .signinBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 14px;
        justify-content: end;
        margin-top: 6px;
    }
    .signinButon1 {
        font-size: 20px;
    }
    .signinButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 100%;
    height: 30px;
    }
    .signinButon2 button{
        align-items: center;
        background: #f9f9f9;
        border-radius: 4px;
        border-style: none;
        color: #000000;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 17px 0 0 0px;
        width: 100%;
        }
        .signinButon1{
            position: relative;
          }
          .signinButon1 img {
            position: absolute;
            top: 52%;
            left: 90%;
            transform: translateY(-50%);
            width: 40px; /* adjust the size of the picture */
            height: 40px;
          }
  }
  @media (max-width: 1024px) {
    
    .signinBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
        width: 60%;
    }
    .signinBox1 input{
        width: 100%;
        border: 1px solid #9581EE;
        border-radius: 4px;
        font-size: 16px;
        outline: none;
        padding: 5px 15px;
        height: 30px;
        margin: 5px 0 10px 0px;
    }
    
    .signinBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
    }
    .signinBox2 input{
     width: 100%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 30px;    
    }
    .signinBox2 {
        margin-top: 43px;
    }
    .signinBox2 p{
        font-weight: 300;
        font-size: 16px;
        margin: 0 0 6px 0px;
       }
    .signinBox2 .signinBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 12px;
        justify-content: end;
        margin-top: 6px;
    }
    .signinButon1 {
        font-size: 16px;
    }
    .signinButon1 a {
    font-size: 12px;
    }
    .signinButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 100%;
    height: 40px;
    }
    .signinButon2 button{
        align-items: center;
        background: #f9f9f9;
        border-radius: 4px;
        border-style: none;
        color: #000000;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 17px 0 0 0px;
        width: 100%;
        }
        .signineror{
            font-size: 12px;
        }
        .signinButon1{
            position: relative;
          }
          .signinButon1 img {
            position: absolute;
            top: 46%;
            left: 92%;
            transform: translateY(-50%);
            width: 30px; /* adjust the size of the picture */
            height: 30px;
          }
  }
  @media (max-width: 768px) {
    
    .signinBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
        width: 60%;
    }
    .signinBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
    }
    .signinBox2 input{
     width: 100%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 30px;    
    }
    .signinBox2 {
        margin-top: 43px;
    }
    .signinBox2 p{
        font-weight: 300;
        font-size: 14px;
        margin: 0 0 6px 0px;
       }
    .signinBox2 .signinBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 12px;
        justify-content: end;
        margin-top: 6px;
    }
    .signinButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 100%;
    height: 40px;
    }
    .signinButon2 button{
        align-items: center;
        background: #f9f9f9;
        border-radius: 4px;
        border-style: none;
        color: #000000;
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 17px 0 0 0px;
        width: 100%;
        }
        .signinButon1{
            position: relative;
          }
          .signinButon1 img {
            position: absolute;
            top: 46%;
            left: 90%;
            transform: translateY(-50%);
            width: 30px; /* adjust the size of the picture */
            height: 30px;
          }
  }
  @media (max-width: 425px) {
   
    .signinBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
        width: 80%;
    }
    .signinBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
    }
    .signinBox2 input{
     width: 100%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 30px;    
    }
    .signinBox2 {
        margin-top: 43px;
    }
    .signinBox2 p{
        font-weight: 300;
        font-size: 14px;
        margin: 0 0 6px 0px;
       }
    .signinBox2 .signinBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 12px;
        justify-content: end;
        margin-top: 6px;
    }
    .signinButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 100%;
    height: 40px;
    }
    .signinButon2 button{
        align-items: center;
        background: #f9f9f9;
        border-radius: 4px;
        border-style: none;
        color: #000000;
        display: flex;
        font-family: SF Pro Display;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 17px 0 0 0px;
        width: 100%;
        }
    .basd{
        font-size: 12px;
    }
    .signinButon1 img {
        position: absolute;
        top: 46%;
        left: 88%;
        transform: translateY(-50%);
        width: 30px; /* adjust the size of the picture */
        height: 30px;
      }
  }
  @media (max-width: 390px) {
  
    .signinBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
    }
    .signinBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
    }
    .signinBox2 input{
     width: 100%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 30px;    
    }
    .signinBox2 {
        margin-top: 43px;
    }
    .signinBox2 p{
        font-weight: 300;
        font-size: 14px;
        margin: 0 0 6px 0px;
       }
    .signinBox2 .signinBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 12px;
        justify-content: end;
        margin-top: 6px;
    }
    .signinButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 100%;
    height: 40px;
    }
    .signinButon2 button{
        align-items: center;
        background: #f9f9f9;
        border-radius: 4px;
        border-style: none;
        color: #000000;
        display: flex;
        font-family: SF Pro Display;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 17px 0 0 0px;
        width: 100%;
        }
        .signinButon1 img {
            position: absolute;
            top: 46%;
            left: 86%;
            transform: translateY(-50%);
            width: 30px; /* adjust the size of the picture */
            height: 30px;
          }
  }

  @media (max-width: 320px) {
 
    .signinBox{
        background: #F9F9F9;
        padding: 30px 30px;
        border-radius: 15px;
    }
    .signinBox1 h3 {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
    }
    .signinBox2 input{
     width: 100%;
    border: 1px solid #9581EE;
    border-radius: 4px;
    height: 30px;    
    }
    .signinBox2 {
        margin-top: 43px;
    }
    .signinBox2 p{
        font-weight: 300;
        font-size: 14px;
        margin: 0 0 6px 0px;
       }
    .signinBox2 .signinBox2button button{
        width: 100%;
        border: 0px solid #e1e1e1;
        background: #f9f9f9;
        color: #9581EE;
        font-size: 12px;
        justify-content: end;
        margin-top: 6px;
    }
    .signinButon1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 45px 0 0;
    width: 100%;
    height: 40px;
    }
    .signinButon2 button{
        align-items: center;
        background: #f9f9f9;
        border-radius: 4px;
        border-style: none;
        color: #000000;
        display: flex;
        font-family: SF Pro Display;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 17px 0 0 0px;
        width: 100%;
        }
  
  .basd{
   color: red;
  }
  .signinButon1{
    margin-top: 43px;
}
.signin {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.signinButon1 a {
    
    color: #9581EE !important;
    display: flex;
    width: 100%;
    justify-content: end;
}
.signineror{
    color: red;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.signinButon1 img {
    position: absolute;
    top: 46%;
    left: 82%;
    transform: translateY(-50%);
    width: 30px; /* adjust the size of the picture */
    height: 30px;
  }
}

.password{
    border: 1px solid red !important;
}
.email{
    border: 1px solid red !important;
}