
.footerBox1{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footerBox1 a{
  text-decoration: underline;
  color: #A4A4A4 !important;
}
.smms{
  display: flex;
  align-items: baseline;
  
}
.her{
  display: flex;
  align-items: baseline;
}
.hasce{
  display: flex;
  align-items: baseline;
}
.footerBox2{
  display: flex;
  justify-content: center;
}
.footerBox22 {
  display: flex;
  flex-direction: column;
}
.footerBox22 div{
  margin-top: 0px;
}
.footerBox22 h5{
  display: flex;
  justify-content: center;
}
.quar{
  display: flex;
  justify-content: space-evenly;
}
.info div {
  display: flex;
  justify-content: start;
  align-items: baseline;
  width: auto;
}
.info div p{
width: auto;
}
.info div a{
  
}
@media (max-width: 2560px) {
  .footerBox{
  padding: 20px 40px;
  display: flex;
  justify-content: space-around;
  background: #F4F4F4;
}
  .footerBox1 img{
    width: 100%;
  }
  .smms p{
    margin-left: 10px;
  }
  .her p{
    margin-left: 10px;
  }
  .hasce p{
    margin: 0 0 15px 15px;
  }
  .quar{
    width: 25%;
  }
  .quar img{
    width: 25%;
  }
  .footerBox22 h5{
   font-size: 32px;
  }
  .footerBox1 a{
    font-size: 20px;
  }
  .info div p{
    font-size: 24px !important;
    }
    .info div a{
      font-size: 24px !important;
      font-weight: 400;
    }
}
@media (max-width: 1920px) {
  .footerBox{
  padding: 20px 40px;
  display: flex;
  justify-content: space-around;
}
  .footerBox1 img{
    width: 100%;
  }
  .smms p{
    margin-left: 10px;
  }
  .her p{
    margin-left: 10px;
  }
  .hasce p{
    margin: 0 0 15px 15px;
  }
  .quar img{
    width: 25%;
  }
  .footerBox22 h5{
   font-size: 24px;
  }
  .footerBox1 a{
    font-size: 14px;
  }
  .info div p{
    font-size: 18px !important;
    }
    .info div a{
      font-size: 20px !important;
      font-weight: 400;
    }
}
@media (max-width: 1440px) {
  .footerBox{
  padding: 15px 40px;
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
}
  .footerBox1 img{
    width: 90%;
  }
  .smms p{
    margin-left: 10px;
  }
  .her p{
    margin-left: 10px;
  }
  .hasce p{
    margin: 0 0 15px 15px;
  }
  .quar img{
    width: 25%;
  }
  .footerBox22 h5{
   font-size: 18px;
  }
  .footerBox1 a {
    font-size: 12px;
}
.info div p{
font-size: 14px !important;
}
.info div a{
  font-size: 14px !important;
  font-weight: 300;
}
}
@media (max-width: 1366px) {
  .footerBox{
  padding: 15px 40px;
  display: flex;
  justify-content: space-around;
}
  .footerBox1 img{
    width: 90%;
  }
  .smms p{
    margin-left: 10px;
  }
  .her p{
    margin-left: 10px;
  }
  .hasce p{
    margin: 0 0 15px 15px;
  }
  .quar img{
    width: 25%;
  }
  .footerBox22 h5{
   font-size: 12px;
  }
}
@media (max-width: 1024px) {
  .footerBox{
  padding: 15px 40px;
  display: flex;
  justify-content: space-around;
}
  .footerBox1 img{
    width: 75%;
  }
  .smms p{
    margin-left: 10px;
  }
  .her p{
    margin-left: 10px;
  }
  .hasce p{
    margin: 0 0 15px 15px;
  }
  .quar{
    width: 50%;
  }
  .quar img{
    width: 25%;
  }
  .footerBox22 h5{
    font-size: 14px;
   }
   .footerBox1 a {
    font-size: 10px;
}
}
@media (max-width: 768px) {
  .footerBox{
  padding: 15px 40px;
  display: flex;
}
  .footerBox1 img{
    width: 75%;
  }
  .smms p{
    margin-left: 10px;
  }
  .her p{
    margin-left: 10px;
  }
  .hasce p{
    margin: 0 0 15px 15px;
  }
  .quar img{
    width: 25%;
  }
  .info div a {
    font-size: 10px !important;
    font-weight: 300;
}
.info div p {
  font-size: 10px !important;
}
}
@media (max-width: 425px) {
  .footerBox{
  padding: 10px 20px;
  display: block;
}
  .footerBox1 img{
    width: 35%;
  }
  .footerBox1 a{
    font-size: 12px;
  }
  .quar{
  margin-top: 20px;
  width: 100%;
  }
  .quar img{
    width: 25%;
  }
  .footerBox2{
    margin-top: 20px;
  }
  .info{
    padding: 20px;
  }

}


