* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .registrationeror{
    color: red;
    display: flex;
    justify-content: center;
  }

  .registrationBox5 .registrationBox52 {
    position: relative;
  }

  .registrationBox5 .registrationBox52 img{
    width:auto;
    position: absolute;
    z-index: 1;
  }
  @media (max-width: 2560px) {
    .registrationpaid p{
        margin: 0 0 5px 0;
        padding: 27px 0 0 0;
    }

    .registrationBox5 .registrationBox52 img{
        top: 25%;
        right: 10%;
    }
    .registrationpaidBox61 #acc_country_name_ul {
        width: 95%;
        padding-left: 20px;
        height: 40px;
    }

    .registrationpaid{
        /* padding: 460px 830px; */
    }
    .registrationpaidBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 36px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 60px;
    margin: 0 0 20px 0;
    }
    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
        width: 125%;
        position: absolute;
        z-index: 1;
        box-shadow: 0px 0px 28px 0px #888888;
    }
    .registrationpaidBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    font-size: 40px;
    }
    .registrationpaidBox2im{
        display: flex;
    }
    .registrationpaidBox2im input{
        width: 95%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 28px;
    }
    .registrationBox3 .label{
        font-size: 28px;
    }
    .registrationpaidBox3 input{
        width: 98%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 28px;
    }
    .registrationpaidBox4{
        display: flex;
    }
    .registrationpaidBox4 p{
        width: 100%;
        
    }
    .registrationpaidBox4 input{
        width: 95%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 28px;
    }
    .registrationpaidBox5 input{
        width: 98%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 24px;
    }

    .registrationpaidBox6{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    .registrationpaidBox6 p{
        width: 100%;
    }
    .registrationpaidBox6 input{
        width: 95%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 24px;
    }

    .registrationpaidBox61{
        width: 50%;
        font-size: 28px;
    }

    .registrationpaidBox62{
        width: 50%;
        font-size: 28px;
    }
    .registrationpaidBox7{
        display: flex;
        margin-top: 27px;
    }
    .registrationpaidBox71{
        width: 50%;
        font-size: 28px;
    }
    .registrationpaidBox72{
        width: 50%;
        font-size: 28px;
    }.registrationpaidBox7{
        
        font-size: 28px;
    }
    .registrationpaidBox7 input{
        width: 95%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 60px;
        font-size: 24px;
    }
    .registrationpaidBox8{
        margin-top: 27px;
    }
    .registrationpaidBox8{
        
        font-size: 28px;
    }

    .registrationpaidBox8 input{
        width: 98%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 24px;
    }
    .registrationpaidButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 60px;
        margin: 27px 0 17px 0;
    }
    .registrationpaidButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    }

    .registrationpaid .registrationpaidimg img{
        position: absolute;
        top: 270px;
        left: 1500px;
        width: 40%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
    }
    .acc_inn{
       font-size: 24px;
    }
    .acc_kpp {
        font-size: 24px;
    }
    .acc_bik{
        font-size: 24px;
    }
    .acc_bank_name{
        font-size: 24px;
    }
    .acc_k_schet{
        font-size: 24px;
    }
    .acc_r_schet{
        font-size: 24px;
    }
    .registrationeror{
       font-size: 24px;
      }

    
   
  }
  @media (max-width: 1920px) {
    .registrationpaid p{
        margin: 0 0 5px 0;
    }

    .registrationBox5 .registrationBox52 img{
        top: 10%;
        right: 8%;
    }
    .registrationpaidBox61 #acc_country_name_ul {
        width: 95%;
        padding-left: 5px;
        height: 40px;
    }
    .registrationpaid{
        /* padding: 195px 0px; */
        display: flex;
    justify-content: center;
    }
    .registrationpaidBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 28px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 60px;
    margin: 0 0 20px 0;
    }
    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
        width: 125%;
        position: absolute;
        z-index: 1;
    }
    .registrationpaidBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    font-size: 40px;
    }
    .registrationpaidBox2im{
        display: flex;
    }
    .registrationpaidBox2im input{
        width: 95%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 24px;
    }
    .registrationpaidBox3 input{
        width: 98%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 24px;
    
    }
    .registrationpaidBox4{
        display: flex;
    }
    .registrationpaidBox4 p{
        width: 100%;
    }
    .registrationpaidBox4 input{
        width: 95%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 60px;
        font-size: 24px;
    }
    .registrationpaidBox5 input{
        width: 98%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 24px;
    }

    .registrationpaidButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 60px;
    }
    .registrationpaidButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    width: 100%;
    }

    .registrationpaid .registrationpaidimg img{
        position: absolute;
        top: 260px;
        left: 1220px;
        width: 35%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        position: fixed;
    }
    .acc_inn{
        font-size: 20px;
    }
    .acc_kpp {
        font-size: 20px;
    }
    .acc_bik{
        font-size: 20px;
    }
    .acc_bank_name{
        font-size: 20px;
    }
    .acc_k_schet{
        font-size: 20px;
    }
    .acc_r_schet{
        font-size: 20px;
    }
    .registrationeror{
        font-size: 20px;
       }
    
   
  }
  @media (max-width: 1440px) {
   
    .registrationpaid{
        /* padding: 190px 0px; */
    }

    .registrationBox5 .registrationBox52 img{
        top: 10%;
        right: 8%;
    }

    .registrationpaidBox61 #acc_country_name_ul {
        width: 95%;
        padding-left: 5px;
        height: 30px;
    }

    .registrationpaidBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 20px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 30px;
    margin: 0 0 20px 0;
    }
    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
        width: 125%;
        position: absolute;
        z-index: 1;
    }
    .registrationpaidBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    font-size: 24px;
    }
    .registrationpaidBox2 p{
        margin: 0 0 5px 0;
        padding: 15px 0 0 0;
    }
    .registrationpaidBox2im{
        display: flex;
    }
    .registrationpaidBox2im input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 18px;
        
    }
    .registrationpaidBox3 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 18px;
    }
    .registrationpaidBox3 p{
        margin: 0 0 5px 0;
        padding: 15px 0 0 0;
    }
    .registrationpaidBox4 p{
        margin: 0 0 5px 0;
        padding: 15px 0 0 0;
    }
    .registrationpaidBox4{
        display: flex;
    }
    .registrationpaidBox4 p{
        width: 100%;
    }
    .registrationpaidBox4 input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 18px;
    }

    .registrationpaidBox5 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 18px;
    }
    .registrationpaidBox6 p{
        margin: 0 0 5px 0;
        padding: 15px 0 0 0;
    }
    .registrationpaidBox6 p{
        width: 100%;
    }
    .registrationpaidBox6 input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 18px;
    }
    .registrationpaidBox6{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    .registrationpaidBox61{
        width: 100%;
        font-size: 18px;
    }
    .registrationpaidBox62{
        width: 50%;
        font-size: 18px;
    }
    .registrationpaidBox7{
        display: flex;
        margin-top: 27px;
    }
    .registrationpaidBox71{
        width: 50%;
        font-size: 18px;
    }
    .registrationpaidBox72{
        width: 50%;
        font-size: 18px;
    }
    .registrationpaidBox7 input{
        width: 95%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 18px;
    }
    .registrationpaidBox8{
        font-size: 18px;
    }
    .registrationpaidBox8 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 18px;
    }
    .registrationpaidButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 30px;
    }
    .registrationpaidButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 17px 0 0 0px;
    width: 100%;
    }

    .registrationpaid .registrationpaidimg img{
        position: absolute;
        top: 224px;
        left: 870px;
        width: 35%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        position: fixed;
    }
    .acc_inn{
        font-size: 14px;
    }
    .acc_kpp {
        font-size: 14px;
    }
    .acc_bik{
        font-size: 14px;
    }
    .acc_bank_name{
        font-size: 14px;
    }
    .acc_k_schet{
        font-size: 14px;
    }
    .acc_r_schet{
        font-size: 14px;
    }
    .registrationeror{
        font-size: 18px;
       }
  
    
    
  }
  @media (max-width: 1366px) {
    p{
        margin: 0 0 5px 0;
    }

    .registrationBox5 .registrationBox52 img{
        top: 10%;
        right: 8%;
    }

    .registrationpaidBox61 #acc_country_name_ul {
        width: 95%;
        padding-left: 10px;
        height: 30px;
    }

    .registrationpaid{
        /* padding: 164px 0px; */
    }
    .registrationpaidBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 20px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 30px;
    margin: 0 0 20px 0;
    }
    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
        width: 95%;
    }
    .registrationpaidBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    }
    .registrationpaidBox2im{
        display: flex;
    }
    .registrationpaidBox2im input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox3 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox4{
        display: flex;
    }
    .registrationpaidBox4 p{
        width: 100%;
    }
    .registrationpaidBox4 input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox5 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }

    .registrationpaidBox6{
        display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    }
    .registrationpaidBox6 p{
        width: 100%;
    }
    .registrationpaidBox6 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox7{
        display: flex;
    }
    .registrationpaidBox7 p{
        width: 100%;
    }
    .registrationpaidBox7 input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox8 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 30px;
    }
    .registrationpaidButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 17px 0 0 0px;
    width: 100%;
    }

    .registrationpaid .registrationpaidimg img{
        position: absolute;
        top: 208px;
        left: 840px;
        width: 35%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        position: fixed;
    }

    
   
  }
  @media (max-width: 1024px) {
    p{
        margin: 0 0 5px 0;
        padding: 7px 0 0 0;
    }

    .registrationBox5 .registrationBox52 img{
        top: 10%;
        right: 8%;
    }

    .registrationpaid{
        /* padding: 133px 0px; */
    }
    .registrationpaidBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 30px;
    margin: 0 0 20px 0;
    }
    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
        width: 95%;
    }
    .registrationpaidBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    }
    .registrationpaidBox2im{
        display: flex;
    }
    .registrationpaidBox2im input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 14px;
    }
    .registrationpaidBox3 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 14px;
    }
    .registrationpaidBox4{
        display: flex;
    }
    .registrationpaidBox4 p{
        width: 100%;
    }
    .registrationpaidBox4 input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 14x;
    }
    .registrationpaidBox5 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 14px;
    }


    .registrationpaidBox6 p{
        width: 100%;
    }
    .registrationpaidBox6 input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 14x;
    }
    .registrationpaidBox6{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    .registrationpaidBox61{
        width: 100%;
        font-size: 14px;
    }
    .registrationpaidBox62{
        width: 50%;
        font-size: 14px;
    }
    .registrationpaidBox7{
        display: flex;
        margin-top: 27px;
    }
    .registrationpaidBox71{
        width: 50%;
        font-size: 14px;
    }
    .registrationpaidBox72{
        width: 50%;
        font-size: 14px;
    }
    .registrationpaidBox8 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 14px;
    }
    .registrationpaidButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 30px;
    }
    .registrationpaidButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
   
    width: 100%;
    }

    .registrationpaid .registrationpaidimg img{
        position: absolute;
        top: 222px;
        left: 610px;
        width: 40%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        position: fixed;
    }
    .registrationeror{
        font-size: 14px;
       }
  }
  @media (max-width: 768px) {
    p{
        margin: 0 0 5px 0;
    }
    .registrationpaid{
        /* padding: 130px 0px; */
    }
    .registrationBox5 .registrationBox52 img{
        top: 10%;
        right: 10%;
    }
    .registrationpaidBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 30px;
    margin: 0 0 20px 0;
    }
    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
        width: 95%;
    }
    .registrationpaidBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    }
    .registrationpaidBox2im{
        display: flex;
    }
    .registrationpaidBox2im input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox3 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox4{
        display: flex;
    }
    .registrationpaidBox4 p{
        width: 100%;
    }
    .registrationpaidBox4 input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox5 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }

    .registrationpaidBox6{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    .registrationpaidBox6 p{
        width: 100%;
    }
    .registrationpaidBox6 input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox7{
        display: flex;
    }
    .registrationpaidBox7 p{
        width: 100%;
    }
    .registrationpaidBox7 input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox8 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 30px;
    }
    .registrationpaidButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 17px 0 0 0px;
    width: 100%;
    }

    .registrationpaid .registrationpaidimg img{
        position: absolute;
        top: 370px;
        left: 460px;
        width: 40%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        position: fixed;
    }
    .registrationeror{
        font-size: 12px;
       }
  }
  @media (max-width: 430px) {
    .registrationBox2 .label{
        font-size: 14px !important;
    }
    .registrationBox6 .label{
        font-size: 14px !important;
    }
    p{
        margin: 0 0 5px 0;
        padding: 3px 0 0 0;
    } 
    .registrationpaid{
        /* padding: 70px 16px; */
        width: 0%;
    }
    .registrationpaidBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 30px;
    margin: 0 0 20px 0;
    }
    .registrationBox5 .registrationBox52 img{
        top: 10%;
       right: 5%;
    }

    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
        width: 100%;
    }
    .registrationpaidBox2im{
        display: block;
    }
    .registrationpaidBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    }
    .registrationpaidBox2im input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox3 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox4{
        display: block;
    }
    .registrationpaidBox4 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox5 input {
        width: 960%;
        padding-left: 10px;
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
    }

    .registrationBox5 {
        display: flex;
        align-items: baseline;
    }
    .registrationBox51 {
        width: 50%;
        font-size: 14px;
    }
    .registrationBox52 {
        width: 50%;
        font-size: 14px;
    }
    
    .registrationpaidBox6 input{
        width: 96%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 14px;
    }
    .registrationpaidBox6{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 12px;
    }
    .registrationpaidBox61{
        width: 100%;
        font-size: 14px;
    }
    .registrationpaidBox62{
        width: 100%;
        font-size: 14px;
        margin: 12px 0 12px 0px;
    }
    .registrationpaidBox7{
        display: block;
        margin: 12px 0 12px 0px;
    }
    .registrationpaidBox71{
        width: 100%;
        font-size: 14px;
    }
    .registrationpaidBox72{
        width: 100%;
        font-size: 14px;
        margin: 12px 0 12px 0px;
    }
    .registrationpaidBox7 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 14px;
    }
    .registrationpaidBox8{
        margin: 12px 0 0 0;
    }
    .registrationpaidBox8 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 14px;
    }
    .registrationpaidButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 30px;
    }
    .registrationpaidButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 17px 0 0 0px;
    width: 100%;
    }
    .registrationpaidimg{
        display: none;
        position: absolute;
    }
    .acc_inn{
        font-size: 12px;
    }
    .acc_kpp {
        font-size: 12px;
    }
    .acc_bik{
        font-size: 12px;
    }
    .acc_bank_name{
        font-size: 12px;
    }
    .acc_k_schet{
        font-size: 12px;
    }
    .acc_r_schet{
        font-size: 12px;
    }

    
   
  }
  @media (max-width: 390px) {
    .registrationpaid{
        /* padding: 70px 16px; */
    }
    .registrationpaidBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 30px;
    }
    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
    }
    .registrationpaidBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    }
    .registrationpaidBox2im input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox3 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox4 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }

    .registrationpaidBox6 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox7 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox8 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 30px;
    }
    .registrationpaidButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 17px 0 0 0px;
    width: 100%;
    }

    
   
  }
@media (max-width: 320px) {
    .registrationpaid{
        /* padding: 70px 16px; */
    }
    .registrationpaidBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 30px;
    }

    .registrationpaidBox61 #acc_country_name_ul {
        width: 95%;
        padding-left: 10px;
    }

    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
        width: 100%;
    }

    .registrationpaidBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    }
    .registrationpaidBox2im input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox3 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox4 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }

    
    .registrationpaidBox6 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    
    
    .registrationpaidBox7 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidBox8 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationpaidButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 30px;
    }
    .registrationpaidButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 17px 0 0 0px;
    width: 100%;
    }

   
  }
  #butt1{
    text-decoration: underline #E2E2E2 solid 2px ; 
   
}
#butt2{
    text-decoration: underline  #5231e4 solid 2px ;  
}
.acc_inn{
    color: red;
    margin-top: 5px;
}
.acc_kpp {
    color: red;
    margin-top: 5px;
}
.acc_bik{
    color: red;
    margin-top: 5px;
}
.acc_bank_name{
    color: red;
    margin-top: 5px;
}
.acc_k_schet{
    color: red;
    margin-top: 5px;
}
.acc_r_schet{
    color: red;
    margin-top: 5px;
}






.registrationpaidBox61 {
    position: relative;
  }

  .registrationpaidBox61 #acc_country_name_ul {
    border: 1px solid #9581EE;
    border-radius: 3px;
    overflow: hidden;
    color: #757575;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    cursor: pointer;
  }

  .registrationpaidBox61 #acc_country_name_ul img {
    width: 8%;
    cursor: pointer;
  }

  .registrationpaidBox61 #acc_country_name_bottom {
    border: 1px solid #9581EE;
    width: 95%;
    height: auto;
    margin-top: 2px;
    overflow: hidden;
    overflow-y: scroll;
    color: #757575;
    display: none;
    flex-direction: column;
    cursor: pointer;
    background-color: #E2E2E2;
    transition: .5s;
    position: absolute;
  }

  .registrationpaidBox61 #acc_country_name_bottom li:hover {
    background-color: #9581EE;
    color: #ffffff;
    transition: .5s;
  }

  .acc_country_name_bottom {
    display: flex !important;
  }
  

  .registrationpaidBox61 ul li {
      list-style: none;
      display: flex;
      align-items: center;
      padding: 5px;
  }

  .registrationpaidBox61 ul li span{
      margin-left: 15px;
  }

  .registrationpaidBox61 ul li img {
    width: 13%;
    height: 100%;
  }