.Transfer{
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 0 0px;
    border-radius: 35px;
    box-shadow: 0px 0px 0px 0px #888888;
    top: 43.5%;
}
@media (max-width: 2560px) {
 
    .TransferBox1{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }
    .TransferBoxS{
        display: flex;
        justify-content: start;
        margin-left: 25px;
    }
    .TransferBox1 h2{
       color: white;
       font-size: 28px;
    
    }
    .TransferBox{
        height: 70vh;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 4px 28px  #888888;
    
    }
    .TransferBox2{
       margin-left: 12px;
        height: 12vh;
        width: 35%;
        background-color:#A1A1A1 ;
        margin-top: 20px;
        border-radius: 5px;
    }
    .TransferBox2 img{
        width: 12%;
        margin: 10px 0 0 5px;
    }
    .TransferBox1img{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .TransferBox3{
        margin-left: 40px;
         height: 12vh;
         width: 35%;
         background-color:#5231e4 ;
         margin-top: 20px;
         border-radius: 5px;
         display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

     }
     .TransferBox3 img{
         width: 10%;
         margin-top: 10px;
         
     
     }
     .TransferBox4{
        display: flex;
        justify-content: center;
        width: 100%;
       
    }
    .TransferBox4 h2{
       color: white;
       font-size: 32px;
    
    }
    .TransferBox5 h2 {
        font-size: 36px;
        font-weight: 400;
        width: 80%;
        margin: 30px 0px 0px 35px;
    }
   
    .TransferBox6{
        display: flex;
        justify-content: end;
        margin-top: 750px;
    }
  
    .TransferButton1{
        width: 15%;
    }
    .TransferButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; 
        display: flex;
        font-family: SF Pro Display;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 70px;
    }
    .TransferButton2{
        display: flex;
        justify-content: center;
        width: 20%;
    
    }
    .TransferButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 90%;
        height: 70px;
        
    }
}
@media (max-width: 1920px) {
   
    .TransferBox1{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }
    .TransferBoxS{
        display: flex;
        justify-content: start;
        margin-left: 25px;
    }
    .TransferBox1 h2{
       color: white;
       font-size: 18px;
    
    }
    .TransferBox{
        height: 70vh;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 4px 28px  #888888;
    
    }
    .TransferBox2{
       margin-left: 12px;
        height: 12vh;
        width: 35%;
        background-color:#A1A1A1 ;
        margin-top: 20px;
        border-radius: 5px;
    }
    .TransferBox2 img{
        width: 12%;
        margin: 10px 0 0 5px;
    }
    .TransferBox1img{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .TransferBox3{
        margin-left: 40px;
         height: 12vh;
         width: 35%;
         background-color: #5231e4;
         margin-top: 20px;
         border-radius: 5px;
         display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

     }
     .TransferBox3 img{
         width: 10%;
         margin-top: 10px;
         
     
     }
     .TransferBox4{
        display: flex;
        justify-content: center;
        width: 100%;
       
    }
    .TransferBox4 h2{
       color: white;
       font-size: 20px;
    }
    .TransferBox5 h2 {
        font-size: 32px;
        font-weight: 400;
        width: 80%;
        margin: 30px 0px 0px 35px;
    }
   
    .TransferBox6{
        display: flex;
        justify-content: end;
        margin-top: 450px;
    }
   
    .TransferButton1{
        width: 15%;
    }
    .TransferButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; 
        display: flex;
        font-family: SF Pro Display;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 60px;
    }
    .TransferButton2{
        display: flex;
        justify-content: center;
        width: 20%;
    
    }
    .TransferButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 90%;
        height: 60px;
        
    }
}
@media (max-width: 1440px) {

    .TransferBox1{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }
    .TransferBoxS{
        display: flex;
        justify-content: start;
        margin-left: 25px;
    }
    .TransferBox1 h2{
       color: white;
       font-size: 18px;
    
    }
    .TransferBox{
        height: 70vh;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 4px 28px  #888888;
    
    }
    .TransferBox2{
       margin-left: 12px;
        height: 12vh;
        width: 35%;
        background-color:#A1A1A1;
        margin-top: 20px;
        border-radius: 5px;
    }
    .TransferBox2 img{
        width: 12%;
        margin: 10px 0 0 5px;
    }
    .TransferBox1img{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .TransferBox3{
        margin-left: 8px;
         height: 12vh;
         width: 35%;
         background-color:#5231e4 ;
         margin-top: 20px;
         border-radius: 5px;
         display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

     }
     .TransferBox3 img{
         width: 10%;
         margin-top: 10px;
         
     
     }
     .TransferBox4{
        display: flex;
        justify-content: center;
        width: 100%;
       
    }
    .TransferBox4 h2{
       color: white;
       font-size: 18px;
    }
    .TransferBox5 h2 {
        font-size: 18px;
        font-weight: 400;
        width: 80%;
        margin: 30px 0px 0px 35px;
    }
   
    .TransferBox6{
        display: flex;
        justify-content: end;
        margin-top: 360px;
    }
    .TransferButton1{
        width: 15%;
    }
    .TransferButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; 
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .TransferButton2{
        display: flex;
        justify-content: center;
        width: 20%;
    
    }
    .TransferButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 90%;
        height: 40px;
        
    }
   
}
@media (max-width: 1366px) {
    .TransferBox1{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }
    .TransferBoxS{
        display: flex;
        justify-content: start;
        margin-left: 25px;
    }
    .TransferBox1 h2{
       color: white;
       font-size: 18px;
    
    }
    .TransferBox{
        height: 70vh;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 4px 28px  #888888;
    
    }
    .TransferBox2{
       margin-left:12px;
        height: 12vh;
        width: 35%;
        background-color:#A1A1A1 ;
        margin-top: 20px;
        border-radius: 5px;
    }
    .TransferBox2 img{
        width: 12%;
        margin: 10px 0 0 5px;
    }
    .TransferBox1img{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .TransferBox3{
        margin-left: 8px;
         height: 12vh;
         width: 35%;
         background-color: #5231e4;
         margin-top: 20px;
         border-radius: 5px;
         display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

     }
     .TransferBox3 img{
         width: 10%;
         margin-top: 10px;
         
     
     }
     .TransferBox4{
        display: flex;
        justify-content: center;
        width: 100%;
       
    }
    .TransferBox4 h2{
       color: white;
       font-size: 18px;
    
    }
    .TransferBox5 h2 {
        font-size: 18px;
        font-weight: 400;
        width: 80%;
        margin: 30px 0px 0px 35px;
    }
    .TransferBox6{
        margin-top: 350px;
    }
  
    .TransferButton1{
        width: 15%;
    }
    .TransferButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; 
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .TransferButton2{
        display: flex;
        justify-content: center;
        width: 20%;
    
    }
    .TransferButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 90%;
        height: 40px;
        
    }
  

}

@media (max-width: 1024px) {
    .TransferBox1{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }
    .TransferBoxS{
        display: flex;
        justify-content: start;
        margin-left: 25px;
    }
    .TransferBox1 h2{
       color: white;
       font-size: 14px;
    
    }
    .TransferBox{
        height: 70vh;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 4px 28px  #888888;
    
    }
    .TransferBox2{
       margin-left: 8px;
        height: 12vh;
        width: 35%;
        background-color:#A1A1A1 ;
        margin-top: 20px;
        border-radius: 5px;
    }
    .TransferBox2 img{
        width: 12%;
        margin: 10px 0 0 5px;
    }
    .TransferBox1img{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .TransferBox3{
        margin-left: 8px;
         height: 12vh;
         width: 35%;
         background-color: #5231e4;
         margin-top: 20px;
         border-radius: 5px;
         display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

     }
     .TransferBox3 img{
         width: 10%;
         margin-top: 10px;
         
     
     }
     .TransferBox4{
        display: flex;
        justify-content: center;
        width: 100%;
       
    }
    .TransferBox4 h2{
       color: white;
       font-size: 14px;
    
    }
    .TransferBox5 h2 {
        font-size: 18px;
        font-weight: 400;
        width: 80%;
        margin: 30px 0px 0px 35px;
    }
    .TransferBox6{
        margin-top: 310px;
    }
    
    
    .TransferButton1{
        width: 15%;
    }
    .TransferButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; 
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .TransferButton2{
        display: flex;
        justify-content: center;
        width: 20%;
    
    }
    .TransferButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 90%;
        height: 40px;
        
    }

}
@media (max-width: 768px) {
    .TransferBox1{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }
    .TransferBoxS{
        display: flex;
        justify-content: center;
        margin-left: 0px;
    }
    .TransferBox1 h2{
       color: white;
       font-size: 14px;
    
    }
    .TransferBox{
        height: 70vh;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 4px 28px  #888888;
    
    }
    .TransferBox2{
       margin-left: 8px;
        height: 12vh;
        width: 45%;
        background-color:#A1A1A1 ;
        margin-top: 20px;
        border-radius: 5px;
    }
    .TransferBox2 img{
        width: 12%;
        margin: 10px 0 0 5px;
    }
    .TransferBox1img{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .TransferBox3{
        margin-left: 8px;
         height: 12vh;
         width: 45%;
         background-color: #5231e4;
         margin-top: 20px;
         border-radius: 5px;
         display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

     }
     .TransferBox3 img{
         width: 10%;
         margin-top: 10px;
         
     
     }
     .TransferBox4{
        display: flex;
        justify-content: center;
        width: 100%;
       
    }
    .TransferBox4 h2{
       color: white;
       font-size: 14px;
    
    }
    .TransferBox5 h2 {
        font-size: 18px;
        font-weight: 400;
        width: 80%;
        margin: 30px 0px 0px 35px;
    }
    .TransferBox6{
        margin-top: 310px;
    }
   
    .TransferButton1{
        width: 15%;
    }
    .TransferButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; 
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .TransferButton2{
        display: flex;
        justify-content: center;
        width: 20%;
    
    }
    .TransferButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 90%;
        height: 40px;
        
    }
}

@media (max-width: 425px) {
    .TransferBox1{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }
    .TransferBox1 h2{
       color: white;
       font-size: 12px;
    
    }
    .TransferBox{
        height: 70vh;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 4px 28px  #888888;
    
    }
    .TransferBox2{
       margin-left: 8px;
        height: 10vh;
        width: 45%;
        background-color: #A1A1A1;
        margin-top: 20px;
        border-radius: 5px;
    }
    .TransferBox2 img{
        width: 20%;
        margin: 10px 0 0 5px;
    
    }
    .TransferBox3{
        margin-left: 8px;
         height: 10vh;
         width: 45%;
         background-color:#5231e4 ;
         margin-top: 20px;
         border-radius: 5px;
         display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

     }
     .TransferBox3 img{
         width: 20%;
         
     
     }
     .TransferBox4{
        display: flex;
        justify-content: center;
        width: 100%;
       
    }
    .TransferBox4 h2{
       color: white;
       font-size: 12px;
    }
    .TransferBox5 h2{
        font-size: 14px;
        font-weight: 400;
        width: 80%;
         margin: 30px 0px 0px 25px;
    }
    .TransferBox6{
        display: block;
        margin-top: 270px;
    }
    .TransferButton1{
        width: 100%;
    }
    .TransferButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; 
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .TransferButton2{
        display: flex;
        justify-content: center;
        width: 100%;
    
    }
    .TransferButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 90%;
        height: 40px;
        
    }
 

}


input[type="checkbox"]{
    accent-color: #5231e4; 
}
