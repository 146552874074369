h1 {
  font-weight: 700;
  font-size: 30px;
  color: #0e082b;
}
.EndpartInput label{
  display: flex;
  flex-direction: column;
}

@media (max-width: 2560px) {
  .endpart {
    display: flex;
    justify-content: center;
    flex-flow: unset;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 50px 100px;
    margin-top: 50px;
  }
  .endpartBox1 img {
    width: 120%;
    margin-left: 100px;
  }
  .endpartBox2 {
    margin-top: 340px;
    margin-left: 320px;
  }
  .endpartBox2 p {
    width: 60%;
  }
  .endpartBox2 h1 {
    width: 66%;
  }
  .endpartBox2 input {
    width: 50%;
    height: 70px;
    margin-top: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    font-size: 28px;
  }
  .endpartBox2 button {
    width: 50%;
    height: 70px;
    margin-top: 20px;
    background:  #93D900;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    font-size: 36px;
    cursor: pointer;
  }
}
@media (max-width: 1920px) {
  .endpart {
    display: flex;
    justify-content: center;
    flex-flow: unset;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 50px 100px;
  }
  .endpartBox1 img {
    width: 120%;
    margin-left: 100px;
  }
  .endpartBox2 {
    margin-top: 340px;
    margin-left: 320px;
  }
  .endpartBox2 p {
    width: 60%;
  }
  .endpartBox2 h1 {
    width: 90%;
  }
  .endpartBox2 input {
    width: 50%;
    height: 70px;
    margin-top: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    font-size: 20px;
  }
  .endpartBox2 button {
    width: 50%;
    height: 70px;
    margin-top: 20px;
    border-radius: 4px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    font-size: 20px;
    cursor: pointer;
  }
}

@media (max-width: 1440px) {
  .endpart {
    display: flex;
    justify-content: center;
    flex-flow: unset;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 50px;
  }
  .endpartBox1 img {
    width: 60%;
    margin-left: 100px;
  }
  .endpartBox2 {
    margin-top: 40px;
    margin-left: 0;
  }
  .endpartBox2 p {
    width: 60%;
  }
  .endpartBox2 h1 {
    width: 70%;
  }
  .endpartBox2 input {
    width: 55%;
    height: 45px;
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    font-size: 14px;
  }
  .endpartBox2 button {
    width: 55%;
    height: 50px;
    margin-top: 20px;
    border-radius: 4px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
  }
}
@media (max-width: 1336px) {
  .endpart {
    display: flex;
    justify-content: center;
    flex-flow: unset;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .endpartBox1 img {
    width: 60%;
    margin-left: 100px;
  }
  .endpartBox2 {
    margin-top: 40px;
    margin-left: 0;
  }
  .endpartBox2 p {
    width: 60%;
  }
  .endpartBox2 h1 {
    width: 65%;
  }
  .endpartBox2 input {
    width: 55%;
    height: 45px;
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    font-size: 14px;
  }
  .endpartBox2 button {
    width: 55%;
    height: 50px;
    margin-top: 20px;
    border-radius: 4px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .endpart {
    display: flex;
    justify-content: center;
    flex-flow: unset;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .endpartBox1 img {
    width: 60%;
    margin-left: 100px;
  }
  .endpartBox2 p {
    width: 48%;
  }
  .endpartBox2 h1 {
    width: 90%;
  }
  .endpartBox2 h1 {
    padding: 0 280px 0 0;
  }
  .endpartBox2 input {
    width: 40%;
    height: 45px;
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
  }
  .endpartBox2 button {
    width: 40%;
    height: 50px;
    margin-top: 20px;
    border-radius: 4px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
  }
}

@media (max-width: 768px) {
  .endpart {
    display: flex;
    justify-content: center;
    flex-flow: unset;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 0 40px;
  }
  .endpartBox1 img {
    width: 60%;
    margin-left: 100px;
  }
  .endpartBox2 p {
    width: 80%;
  }
  .endpartBox2 h1 {
    width: 90%;
  }
  .endpartBox2 h1 {
    padding: 0 70px 0 0;
  }
  .endpartBox2 input {
    width: 60%;
    height: 45px;
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
  }
  .endpartBox2 button {
    width: 60%;
    height: 50px;
    margin-top: 20px;
    border-radius: 4px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
  }
}
@media (max-width: 425px) {
  .catering {
    padding: 0 16px;
  }
  .endpart {
    display: flex;
    justify-content: center;
    flex-flow: row-reverse;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .endpartBox1 img {
    width: 180%;
    margin-left: -45px;
    position: relative;
    z-index: -1;
  }
  .endpartBox2 {
    margin-top: 90px;
  }
  .endpartBox2 p {
    width: 70%;
  }
  .endpartBox2 h1 {
    width: 100%;
  }
  .endpartBox2 input {
    width: 120%;
    height: 45px;
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
  }
  .endpartBox2 button {
    width: 120%;
    height: 50px;
    margin-top: 20px;
    border-radius: 4px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
  }
}
@media (max-width: 390px) {
  .endpart {
    display: flex;
    justify-content: center;
    flex-flow: row-reverse;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .endpartBox1 img {
    width: 240%;
    margin-left: -80px;
  }
  .endpartBox2 {
    margin-top: 30px;
  }
  .endpartBox2 p {
    width: 80%;
  }
  .endpartBox2 h1 {
    width: 100%;
  }
  .endpartBox2 input {
    width: 100%;
    height: 45px;
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
  }
  .endpartBox2 button {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    border-radius: 4px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
  }
}

@media (max-width: 320px) {
  .endpart {
    display: flex;
    justify-content: center;
    flex-flow: row-reverse;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .endpartBox1 img {
    width: 380%;
    margin-left: -75px;
  }
  .endpartBox2 input {
    width: 100%;
    height: 45px;
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
  }
  .endpartBox2 p {
    width: 80%;
  }
  .endpartBox2 h1 {
    width: 100%;
  }
  .endpartBox2 button {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    border-radius: 4px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
  }
}
