.tarifOwn{
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 0 0px;
    border-radius: 35px;
    box-shadow: 0px 0px 0px 0px #888888;
    top: 43.5%;
}
#acc_country_name_span{
    color: black;
}
#tarifOwnBox_content {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 10px 0 10px 0;
   max-width: 100%;
   overflow-x: scroll;
}

#tarif_data_content section{
   width: 25%;
}
#plus{
    margin-left: 5px;
    font-size: 20px;
}
#minus{
    margin-right: 5px;
    font-size: 20px;
}



#tarif_data_content section img {
    margin-right: 0px;
    z-index: 0;
}

.tarifOwnBox2 {
    width: 80%;
}

.tarifOwnButton2 {
    width: 100%;
}

#tarif_data_content section {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 13px;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgb(105, 108, 134, 0.2);
}

#tarif_data_content_content_add_wallet_itme span{
   font-size: 13px !important;
}

#tarif_data_content section #button_li {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#tarif_data_content section ul {
    list-style: none;
}


#tarif_data_content section #tarif_data_content_content {
    padding: 21px 20px 76px 27px;
}

#tarif_data_content section #tarif_data_content_content > li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 8px;
}

#tarif_data_content section #tarif_data_content_content_1 {
    padding: 20px 26px 22px 26px;
}


#tarif_data_content section #tarif_data_content_top{
  width: 100%;
  padding: 21px 26px 22px 26px;
}

#tarif_data_content section #tarif_data_content_header {
  width: 70%;
  display: flex;
  justify-content: start;
  align-items: center;
}

#tarif_data_content section #button_li button {
    text-align: center;
    border: 2px solid #C9CDDD;
    border-radius: 8px;
    color: #13141C;
    font-weight: 600;
    font-size: 14px;
    padding: 13px 10px 13px 10px;
    cursor: pointer;
    user-select: none;
    outline: none;
    width: 80%;
    margin:15px 0 15px 0;
    transition: .5s;
}

#tarif_data_content section ul{
    border-top: 1px solid #EDEFF5;
    width: 100%;
}

#tarif_data_content_content #tarif_data_content_content_add{
  width: 80%;
  display: flex;
    align-items: baseline;
}

#tarif_data_content_content #tarif_data_content_content_add_span{
    width: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#tarif_data_content_content #tarif_data_content_content_add_span span {
    width: 100%;
    text-align: center;
}


#tarif_data_content_content #tarif_data_content_content_add_span a{
    cursor: pointer;
}

#tarif_data_content_content #tarif_data_content_content_add_span li{
    width: 65%;
    height: auto;
    font-size: 20px;
    text-align: center;
    border: 1px solid silver;
    border-radius: 5px;
    margin-top: 5px;
    padding: 3px;
}


#tarif_data_content section  button:hover {
    background-color: #5231e4;
    color: #fff !important;
    border: 2px solid #5231e4 !important;
    transition: .5s;
}

.tarifOwnBox1{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}

.gin{
   list-style: none;
}


.CashierStation{
    display: flex;
    justify-content: space-evenly;
}
.CashierStation a{
    color: white !important;
    background: #5231e4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.MobileCash a{
    color: white !important;
    background: #5231e4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;  
}
.MobileManager a{
    color: white !important;
    background: #5231e4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center; 
}
#ikon1{
    position: absolute;
    z-index: 1;
}
#ikon3{
    position: absolute;
    z-index: 1;
}
#ikon2{
    position: absolute;
    z-index: 1;
    color: red;
    font-style: italic;
    text-decoration: line-through;
    z-index: 0;
}
 .barev:last-child #tarif_data_content_content_1 li:last-child{
color:#b3b0b0;
font-style: italic;
}

.barev:nth-child(2) #tarif_data_content_content li:last-child{
    display: none !important;
    }
   

@media(max-width: 2560px){
    .tarifOwnBox{
        height: auto;
        width: 100%;
        z-index: 1;
        position: relative;
        background: white;
        border-radius: 0px;
        overflow: hidden;
        box-shadow: 0px 0px 0px  #888888;
    }
    .tarifOwnBox1 h1 {
        font-size: 64px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .tarifOwnButton{
        display: flex;
        justify-content: start;
        align-items: end;
        margin-top: 35px;
        padding: 10px;
    }
    .tarifOwnButton1{
        width: 15%;
    }
    .CashierStation span img{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
    }
    .MobileCash{
        display: flex;
        justify-content: space-evenly;
    }
    .MobileCash span img{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px; 
    }
    .MobileManager{
        display: flex;
        justify-content: space-evenly;
    }
    .MobileManager span img{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        
    }
    .tarifOwnButton2{
        width: 20%;
    }
    .gin{
        padding: 0 20px;
        margin-top: 27px;
    }
    .gin ul li{
        font-size: 32px !important;
        font-weight: 400;
    }
    .gin1{
        display: flex;
    }
    .gin1 li{
        font-size: 32px !important;
        font-weight: 400;
    }
    .gin2{
        display: flex;
    }
    .gin2 li{
        font-size: 32px !important;
        font-weight: 400;
    }
    .gin3{
        display: flex;
    }
    .gin3 li{
        font-size: 32px !important;
        font-weight: 400;
    }
    .gin div{
        display: flex;
        justify-content: space-between;
        font-size: 32px;
        margin-top: 20px;
        align-items: center;
    }
    .CashierStation{
        width: 30%;
    }
    .MobileCash{
        width: 30%;
    }
    .MobileManager{
        width: 30%;
    }
    .gin1{
        width: 20%;
    }
    .gin2{
        width: 20%;
    }
    .gin3{
        width: 20%;
    }
    .gin11 li{
        font-size: 32px;
        display: flex;
        align-items: baseline;
        color: #5231e4;
    }
    .gin12 li{
        font-size: 32px;
        display: flex;
        align-items: baseline;
        color: #5231e4;
    }
    .gin13 li{
        font-size: 32px;
        display: flex;
        align-items: baseline;
        color: #5231e4;
    }
    .gin11 p{
        color: #5231e4;
    }
    .gin12 p{
        color: #5231e4;
    }
    .gin13 p{
        color: #5231e4;
    }
    .tarifOwnButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC;
        display: flex;
        font-family: SF Pro Display;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 80px;
    }
    .tarifOwnButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 80px; 
  
    }
    .CashierStation a{
        height: 60px;
        width: 60px;
    }
    .MobileCash a{
        height: 60px;
        width: 60px;
    }
    .MobileManager a{
        height: 60px;
        width: 60px;
    }
    #tarif_data_content {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: baseline;
     }
     #request0 {
        display: none;
         width: 150%;
       }
       #request1 {
           display: none;
           width: 150%;
       }
       #request2 {
           display: none;
           width: 150%;
       }
       #request3 {
           display: none;
           width: 150%;
       }
       
       .request0 {
           position: absolute;
           top: 2%;
           left: 220%;
           display: flex !important;
          }
       .request1 {
           position: absolute;
           top: 2%;
           left: 112%;
           display: flex !important;
          }
       .request2 {
           position: absolute;
           top: 2%;
           left: 4%;
           display: flex !important;
       }
       .request3 {
           position: absolute;
           top: 2%;
           left: -104%;
           display: flex !important;
          }
          #ikon1{
            right: -4%;
            top: -1%;
              } 
         #ikon2{
            right: 70%;
            top: 3%;
         } 
         #ikon1{
            right: -4%;
            top: -1%;
              } 
              .barev:last-child #tarif_data_content_content_1 li:last-child {
                font-size: 18px !important;
                 }
            .barev:last-child #tarif_data_content_content_1 li:last-child span{
               font-size: 18px !important;
                }
       
}
@media(max-width: 1920px){
    .tarifOwnBox{
        height: auto;
        width: 100%;
        z-index: 1;
        position: relative;
        background: white;
        border-radius: 0px;
        overflow: hidden;
        box-shadow: 0px 0px 0px  #888888;
        padding: 15px;
    }
    .tarifOwnBox1 h1 {
        font-size: 42px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .tarifOwnButton{
        display: flex;
        justify-content: start;
        align-items: end;
        margin-top: 30px;
        padding: 10px;
    }
    .tarifOwnButton1{
        width: 15%;
    }
    .CashierStation span img{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
    }
    .MobileCash{
        display: flex;
        justify-content: space-evenly;
    }
    .MobileCash span img{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px; 
    }
    .MobileManager{
        display: flex;
        justify-content: space-evenly;
    }
    .MobileManager span img{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        
    }
    .tarifOwnButton2{
        width: 100%;
    }
    .gin{
        padding: 0 20px;
        margin-top: 27px;
    }
    .gin ul li{
        font-size: 24px !important;
        font-weight: 400;
    }
    .gin1{
        display: flex;
    }
    .gin1 li{
        font-size: 24px !important;
        font-weight: 400;
    }
    .gin2{
        display: flex;
    }
    .gin2 li{
        font-size: 24px !important;
        font-weight: 400;
    }
    .gin3{
        display: flex;
    }
    .gin3 li{
        font-size: 24px !important;
        font-weight: 400;
    }
    .gin div{
        display: flex;
        justify-content: space-between;
        font-size: 24px;
    }
    .CashierStation{
        width: 30%;
    }
    .MobileCash{
        width: 30%;
    }
    .MobileManager{
        width: 30%;
    }
    .gin1{
        width: 20%;
    }
    .gin2{
        width: 20%;
    }
    .gin3{
        width: 20%;
    }
    .gin11 li{
        font-size: 24px;
    }
    .gin12 li{
        font-size: 24px;
    }
    .gin13 li{
        font-size: 24px;
    }
    .tarifOwnButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC;;
        display: flex;
        font-family: SF Pro Display;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 60px;
        padding: 10px;
        cursor: pointer; 
    }
    .tarifOwnButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 60px;
        padding: 10px;
        cursor: pointer;
    }
    .CashierStation a{
        height: 50px;
        width: 50px;
    }
    .MobileCash a{
        height: 50px;
        width: 50px;
    }
    .MobileManager a{
        height: 50px;
        width: 50px;
    }
    #tarif_data_content section span {
        font-size: 17px;
        
    }
    #ikon1 {
        right: -5%;
        top: -4%;
    }
    #ikon2 {
        right: 40%;
         top: 2.7%;
    }
    #ikon3 {
        right: -5%;
        top: -2%;
    }
    .barev:last-child #tarif_data_content_content_1 li:last-child {
        font-size: 15px !important;
         }
    .barev:last-child #tarif_data_content_content_1 li:last-child span{
       font-size: 15px !important;
        }
    
    
}
@media(max-width: 1440px){
    .tarifOwnBox{
        height: auto;
        width: 100%;
        z-index: 1;
        position:relative;
        background: white;
        border-radius: 0px;
        overflow: hidden;
        box-shadow: 0px 0px 0px  #888888;
    }
    .tarifOwnBox1 h1 {
        font-size: 24px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .tarifOwnButton{
        display: flex;
        justify-content: start;
        align-items: end;
        margin-top: 30px;
        padding: 10px;
    }
    .tarifOwnButton1{
        width: 15%;
    }
    .tarifOwnButton2{
        width: 100%;
    }
    .CashierStation span img{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
    }
    .MobileCash{
        display: flex;
        justify-content: space-evenly;
    }
    .MobileCash span img{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px; 
    }
    .MobileManager{
        display: flex;
        justify-content: space-evenly;
    }
    .MobileManager span img{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        
    }
    .gin{
        padding: 0 20px;
        margin-top: 27px;
    }
    .gin div{
        display: flex;
        justify-content: space-between;
        font-size: 18px;
    }
    .CashierStation{
        width: 30%;
    }
    .MobileCash{
        width: 30%;
    }
    .MobileManager{
        width: 30%;
    }
    .gin ul li{
        font-size: 18px !important;
        font-weight: 400;
    }
    .gin1{
        display: flex;
    }
    .gin1 li{
        font-size: 18px !important;
        font-weight: 400;
    }
    .gin2{
        display: flex;
    }
    .gin2 li{
        font-size: 18px !important;
        font-weight: 400;
    }
    .gin3{
        display: flex;
    }
    .gin3 li{
        font-size: 18px !important;
        font-weight: 400;
    }
    .gin1{
        width: 20%;
    }
    .gin2{
        width: 20%;
    }
    .gin3{
        width: 20%;
    }
    .gin11 li{
        font-size: 18px;
    }
    .gin12 li{
        font-size: 18px;
    }
    .gin13 li{
        font-size: 18px;
    }
    .tarifOwnButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC;;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .tarifOwnButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .CashierStation a{
        height: 40px;
        width: 40px;
    }
    .MobileCash a{
        height: 40px;
        width: 40px;
    }
    .MobileManager a{
        height: 40px;
        width: 40px;
    }
    #ikon1 {
        right: -5%;
        top: -4%;
    }
    #ikon2 {
        right: 40%;
    top: 2.5%;
    }
    .barev:last-child #tarif_data_content_content_1 li:last-child {
        font-size: 13px !important;
         }
    .barev:last-child #tarif_data_content_content_1 li:last-child span{
       font-size: 13px !important;
        }
}
@media(max-width: 1366px){
    .tarifOwnBox{
        height: auto;
        width: 100%;
        z-index: 1;
        position: relative;
        background: white;
        border-radius: 0px;
        overflow: hidden;
        box-shadow: 0px 0px 0px  #888888;
    }
    .tarifOwnBox1 h1 {
        font-size: 24px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .tarifOwnButton{
        display: flex;
        justify-content: start;
        align-items: end;
        margin-top: 30px;
        padding: 10px;
    }
    .tarifOwnButton1{
        width: 15%;
    }
    .tarifOwnButton2{
        width: 100%;
    }
    .gin{
        padding: 0 20px;
        margin-top: 27px;
    }
    .gin div{
        display: flex;
        justify-content: space-between;
    }
    .CashierStation{
        width: 30%;
    }
    .MobileCash{
        width: 30%;
    }
    .MobileManager{
        width: 30%;
    }
    .gin1{
        width: 20%;
    }
    .gin2{
        width: 20%;
    }
    .gin3{
        width: 20%;
    }
    .gin11 li{
        font-size: 18px;
    }
    .gin12 li{
        font-size: 18px;
    }
    .gin13 li{
        font-size: 18px;
    }
    .tarifOwnButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; ;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .tarifOwnButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    #ikon1 {
        right: -5%;
        top: -4%;
    }
    #ikon2 {
        right: 30%;
        top: 2.5%;
    }
    #vmesec{
        font-size: 12px !important;
    }
}
@media(max-width: 1024px){
    .tarifOwnBox{
        height: auto;
        width: 100%;
        z-index: 1;
        position: relative;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 0px 0px  #888888;
    }
    .tarifOwnBox1 h1 {
        font-size: 24px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .tarifOwnButton{
        display: flex;
        justify-content: start;
        align-items: end;
        margin-top: 80px;
        padding: 10px;
    }
    .tarifOwnButton1{
        width: 15%;
    }
    .tarifOwnButton2{
        width: 100%;
    }
    .gin{
        padding: 0 20px;
        margin-top: 27px;
    }
    .gin div{
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        margin-top: 10px;
    }
    .CashierStation{
        width: 30%;
    }
    .MobileCash{
        width: 30%;
    }
    .MobileManager{
        width: 30%;
    }
    .gin ul li{
        font-size: 14px !important;
        font-weight: 400;
    }
    .gin1{
        display: flex;
    }
    .gin1 li{
        font-size: 14px !important;
        font-weight: 400;
    }
    .gin2{
        display: flex;
    }
    .gin2 li{
        font-size: 14px !important;
        font-weight: 400;
    }
    .gin3{
        display: flex;
    }
    .gin3 li{
        font-size: 14px !important;
        font-weight: 400;
    }
    .gin1{
        width: 20%;
    }
    .gin2{
        width: 20%;
    }
    .gin3{
        width: 20%;
    }
    .gin11 li{
        font-size: 14px;
    }
    .gin12 li{
        font-size: 14px;
    }
    .gin13 li{
        font-size: 14px;
    }
    .tarifOwnButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; 
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .tarifOwnButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .CashierStation a{
        height: 30px;
        width: 30px;
    }
    .MobileCash a{
        height: 30px;
        width: 30px;
    }
    .MobileManager a{
        height: 30px;
        width: 30px;
    }
    #tarif_data_content{
        flex-direction: column;
        align-items: center;
      
    }
    #tarif_data_content section  {
        margin: 8px 13px;
        width: 50%;
    }

    #tarif_data_content section span {
        font-size: 17px;
       
    }
    
#request0 {
    display: none;
     width: 100%;
   }
   #request1 {
       display: none;
       width: 100%;
   }
   #request2 {
       display: none;
       width: 100%;
   }
   #request3 {
       display: none;
       width: 100%;
   }
   
   .request0 {
       position: absolute;
       top: 2%;
       left: 50%;
       display: flex !important;
      }
   .request1 {
       position: absolute;
       top: 2%;
       left: 50%;
       display: flex !important;
      }
   .request2 {
       position: absolute;
       top: 2%;
       left: 50%;
       display: flex !important;
   }
   .request3 {
       position: absolute;
       top: 2%;
       left: 50%;
       display: flex !important;
      }
      #ikon1 {
        right: -5%;
        top: -4%;
    }
    #ikon2 {
        right: 60%;
        top: 2.5%;
    }
}
@media(max-width: 768px){
    .tarifOwnBox{
        height: auto;
        width: 100%;
        z-index: 1;
        position: relative;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 0px 0px  #888888;
        flex-direction: column;
    }
    .tarifOwnBox1 h1 {
        font-size: 24px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .tarifOwnButton{
        display: flex;
        justify-content: start;
        align-items: end;
        margin-top: 60px;
        padding: 10px;
    }
    .tarifOwnButton1{
        width: 15%;
    }
    .tarifOwnButton2{
        width: 100%;
    }
    .gin{
        padding: 0 20px;
        margin-top: 27px;
    }
    .gin div{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
    .CashierStation{
        width: 30%;
    }
    .MobileCash{
        width: 30%;
    }
    .MobileManager{
        width: 30%;
    }
    .gin1{
        width: 20%;
    }
    .gin2{
        width: 20%;
    }
    .gin3{
        width: 20%;
    }
    .gin11 li{
        font-size: 14px;
    }
    .gin12 li{
        font-size: 14px;
    }
    .gin13 li{
        font-size: 14px;
    }
    .CashierStation a{
        height: 20px;
        width: 20px;
    }
    .MobileCash a{
        height: 20px;
        width: 20px;
    }
    .MobileManager a{
        height: 20px;
        width: 20px;
    }
    .tarifOwnButton1 button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#BCBCBC; 
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .tarifOwnButton2 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 40px;
        
    }
    #tarif_data_content {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

     }
     #tarif_data_content section{
        width: 50%;
        margin: 15px 13px;
     }
     #ikon1 {
        right: -5%;
        top: -4%;
    }
    #ikon2 {
        right: 45%;
        top: 2.5%;
    }
    
}
@media (max-width: 425px) {
    .tarifOwnBox{
        height: auto;
        width: 100%;
        z-index: 1;
        position: relative;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0px 0px 0px  #888888;
        flex-direction: column;
    }
    .tarifOwnBox1 h1 {
        font-size: 20px;
        width: 100%;
    }
    .gin{
        padding:0 10px ;
        margin-top: 10px;
    }
    .gin1{
        justify-content: space-between;
        width: 20%;
        font-size: 14px;
    }
    .gin2{
        justify-content: space-between;
        width: 20%;
    }
    .gin3{
        justify-content: space-between;
        width: 20%;
    }
    .tarifOwnBox2{
        margin-top: 27px;
        padding: 10px;
    }
    .tarifOwnButton{
        display: block;
       margin-top: -20px;
    }
    .tarifOwnButton1{
        width: 100%;
    }
    .tarifOwnButton2{
        width: 100%;
    }
    .tarifOwnButton2 button{
        margin-top: 20px;
    }
    .gin11 li{
        font-size: 14px;
    }
    .gin12 li{
        font-size: 14px;
    }
    .gin13 li{
        font-size: 14px;
    }
    #tarif_data_content section{
        width: 90%;

     }
     #ikon1 {
        right: -5%;
        top: -4%;
    }
    #ikon2 {
        right: 44%;
        top: 2.5%;
    }
}
@media (max-width: 390px) {
    .gin1{
        justify-content: space-between;
    }
    .gin2{
        justify-content: space-between;
    }
    .gin3{
        justify-content: space-between;
    }
    .tarifOwnBox2{
        margin-top: 27px;
        padding: 10px;
    }
    #ikon1 {
        right: -5%;
        top: -4%;
    }
    #ikon2 {
        right: 38%;
        top: 2.5%;
    }
}

@media (max-width: 320px) {
    .gin1{
        justify-content: space-between;
    }
    .gin2{
        justify-content: space-between;
    }
    .gin3{
        justify-content: space-between;
    }
    .tarifOwnBox2{
        margin-top: 27px;
        padding: 10px;
    }
    #ikon1 {
        right: -5%;
        top: -4%;
    }
    #ikon2 {
        right: 25%;
        top: 2.5%;
    }
}

#tarif_data_content section{
  position: relative;
}



#request .registrationpaid {
    width: 100%;
}

#request_span {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}


