* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    }
    @media (max-width: 2560px) {
        .mailcod {
            width: 100%;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
    
        }
        .mailcodBox{
            background: #F9F9F9;
            padding: 30px 30px;
            border-radius: 15px;
            width: 50%;
            height: 38vh;
            align-items: center;
             justify-content: center;
           flex-direction: row-reverse;
        }
      
     
        .mailcodBox1 h3 {
            display: flex;
            justify-content: center;
            font-weight: 300;
            font-size: 32px;
            width: 100%;
            padding-top: 0px;
        }
        
        
        .mailcodBox2img1{
            width: 30%;
            margin-left: 30px;

        }
        .mail{
            width: 14%;
        }
        .gmail{
            width: 14%;
        }
        .mail123{
            display: flex;
            justify-content: end;
            
          }
          .mailcodBox2img1 img{
            height: 14vh;
          }
          .mailcodBox2img1 h2{
            font-size: 32px;
            color: #D90D00;
          }
      
        .mailcodimg img{
            position: absolute;
            top: 265px;
            left: 1520px;
            width: 40%;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -1;
       
        }
        .mailcodBox3{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        #Gmail{
            width: 70%;
          }
    }
    @media (max-width: 1920px) {
        .mailcodBox{
            background: #F9F9F9;
            padding: 30px 30px;
            border-radius: 15px;
        }
     
        .mailcodBox1 h3 {
            display: flex;
            justify-content: center;
            font-weight: 300;
            font-size: 20px;
            width: 100%;
            padding-top: 0px;
        }
        
         .mailcodBox{
           height: 36vh;
         }
        .mailcodBox2img1{
            width: 50%;
            margin-left: 30px;
        }
        .mailcodBox2img1 img {
            height: 12vh;
        }
        .mailcodBox2img1 h2{
            font-size: 28px;
            color: #D90D00;
          }
        .mail{
            width: 14%;
        }
        .gmail{
            width: 14%;
        }
        .mail123{
            justify-content: end;
            
          }
      
        .mailcodimg img{
            position: absolute;
            top: 200px;
            left:1280px;
            width: 30%;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -1;
       
        }
        #Gmail{
            width: 70%;
          }
            
    }
    @media (max-width: 1440px) {
        .mailcodBox{
            background: #F9F9F9;
            padding: 30px 30px;
            border-radius: 15px;
            width: 45%;
            height: 200px;
            align-items: center;
             justify-content: center;
           flex-direction: row-reverse;
        }
     
        .mailcodBox1 h3 {
            display: flex;
            justify-content: center;
            font-weight: 300;
            font-size: 20px;
            width: 100%;
            padding-top: 0px;
        }
        
         .mailcodBox{
           height: 38vh;
         }
        .mailcodBox2img1{
            width: 40%;
            margin-left: 30px;
        }
        .mail{
            width: 14%;
        }
        .gmail{
            width: 14%;
        }
        .mail123{
            justify-content: end;
            
          }
        
        .mailcodimg img{
            position: absolute;
            top: 200px;
            left:875px;
            width: 35%;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -1;
        }
        #Gmail{
            width: 70%;
          }
    }
    @media (max-width: 1366px) {
        .mailcodBox{
            background: #F9F9F9;
            padding: 30px 30px;
            border-radius: 15px;
        }
     
        .mailcodBox1 h3 {
            display: flex;
            justify-content: center;
            font-weight: 300;
            font-size: 20px;
            width: 100%;
            padding-top: 0px;
        }
        
         .mailcodBox{
          height: 34vh;
         }
        .mailcodBox2img1{
            width: 40%;
            margin-left: 30px;
        }
        .mail{
            width: 14%;
        }
        .gmail{
            width: 14%;
        }
        .mail123{
            justify-content: end;
            
          }
      
        .mailcodimg img{
            position: absolute;
            top: 185px;
            left:875px;
            width: 35%;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -1;
        }
        #Gmail{
            width: 70%;
          }
            }
    @media (max-width: 1024px) {
       
        .mailcodBox{
            background: #F9F9F9;
            padding: 30px 30px;
            border-radius: 15px;
        }
     
        .mailcodBox1 h3 {
            display: flex;
            justify-content: center;
            font-weight: 300;
            font-size: 16px;
            width: 100%;
            padding-top: 0px;
        }
        
         .mailcodBox{
          height: 34vh !important;
         }
        .mailcodBox2img1{
            width: 40%;
            margin-left: 30px;
        }
        .mail{
            width: 14%;
        }
        .gmail{
            width: 14%;
        }
        .mail123{
            justify-content: end;
            
          }
    
        .mailcodimg img{
            position: absolute;
            top: 200px;
            left: 610px;
            width: 40%;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -1;
        }
        #Gmail{
            width: 70%;
          }
    }
    @media (max-width: 768px) {
    
        .mailcodBox{
            background: #F9F9F9;
            padding: 30px 30px;
            border-radius: 15px;
        }
     
        .mailcodBox1 h3 {
            display: flex;
            justify-content: center;
            font-weight: 300;
            font-size: 16px;
            width: 110%;
            padding-top: 0px;
        }
        
         .mailcodBox{
           height: 34vh !important;
         }
      
        .mailcodimg img{
            position: absolute;
            top: 300px;
            left: 420px;
            width: 45%;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -1;
       
        }
        .mailcodBox2img1{
            width: 50%;
            margin-left: 30px;
        }
        .mail{
            width: 14%;
        }
        .gmail{
            width: 14%;
        }
        .mail123{
            justify-content: end;
            
          }
          #Gmail{
            width: 70%;
          }
            
        
    }
    @media (max-width: 425px) {

        .mailcodBox{
            background: #F9F9F9;
            padding: 30px 30px;
            border-radius: 15px;
            
        }
        .mailcodBox{
            background: #F9F9F9;
            padding: 30px 30px;
            border-radius: 15px;
            width: 80%;
            height: 330px;
            align-items: center;
             display: flex;
             justify-content: center;
           flex-direction: row-reverse;
        }
        .mailcodBox{
            display: block;
            height: 40vh !important;
         }
        .mailcodBox1 h3 {
            display: flex;
            justify-content: center;
            font-weight: 300;
            font-size: 16px;
            width: 100%;
            padding-top: 0px;
            margin-left: 0px;
        }
       
        .mailcodimg{
            display: none;
        }
       
        .mailcodBox2img1{
            margin-top: 20px;
            width: 40%;
            margin-left: 0px;
            

            }
       .gmail{
        width: 25%;
       }
       .mail{
        width: 25%;
       }
       .mailcodBox2img1{
        width: 100%;
       }
       .mailcodBox3{
        display: block;
        justify-content: center;
        align-items: flex-start;
        flex-direction: row-reverse;
    }
    .mail123{
        display: flex;
        justify-content: center;
        align-items: center;
        
      }
      .mail123 img{
        margin-left: 20px;
      }
      #Gmail{
        width: 75%;
      }
        }
    
    @media (max-width: 390px) {
       
        .mailcodBox{
            background: #F9F9F9;
            padding: 30px 30px;
            border-radius: 15px;
        }
        .mailcodBox1 h3 {
            display: flex;
            justify-content: center;
            font-weight: 300;
            font-size: 16px;
            width: 109%;
        }
        .mailcodBox2img1{
           margin-top: 20px;
           }
      .gmail{
       width: 25%;
      }
      .mail{
       width: 25%;
      }
      .mail123 img{
        margin-left: 20px;
      }
      
      

    
      }
    @media (max-width: 320px) {
      
        .mailcodBox{
            background: #F9F9F9;
            padding: 30px 30px;
            border-radius: 15px;
        }
        .mailcodBox1 h3 {
            display: flex;
            justify-content: center;
            font-weight: 300;
            font-size: 16px;
            width: 109%;
        }
        .mailcodBox2img1{
           margin-top: 20px;
           }
      .gmail{
        width: 25%;
      }
      .mail{
        width: 25%;
      }
      .mail123 img{
        margin-left: 20px;
      }
      

    
      }
      .mailcodBox2img1{
        display: flex;
        justify-content: center;
        flex-direction: column;
    align-items: center;
      
       }
      
        .mail123 img{
        width: 40%;
        margin-top: 20px;
      } 
      /* otpravlen
     */