* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .siteBox2 .siteBox2text .siteBox2textlist ul li {
    list-style-image: url(./site_img/Check.svg);

    background-position: center;
    
  }
  .siteBox3 .siteBox3text .siteBox3textlist ul li {
    list-style-image: url(./site_img/Check.svg);
  }
  @media (max-width: 2560px) {
    .site{
        padding: 0 100px;
    }
    h1 {
        font-weight: 700;
        font-size: 70px;
        color: #0e082b;
      }
      .siteBox1 {
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
      }
      .siteBox12 {
        display: flex;
      }
      .siteBox1text  {
        width: 100%;
        position: relative;
        top: 100px;
      }
      
      .siteBox1text h1 {
        width: 76%;
      }
      .siteBox1text p {
        width: 66%;
      }
      .siteBox1img  {
        width: 100%;
      }
      .siteBox1img  img {
        width: 100%;
        margin-top: 13%;
      }
      .siteBox2{
        width: 100%;
        display: flex;
        flex-direction: row-reverse;

      }
      .siteBox2text {
        width: 80%;
        
    }
      .siteBox2text h1{
        width: 70%;
        
    }
    .siteBox2 .siteBox2text .siteBox2textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
    
  } 
  .siteBox2 .siteBox2text .siteBox2textlist1{
    width: 30%;
  }
    .siteBox2 .siteBox2text .siteBox2textlist1 ul li{
      font-size: 36px;
      padding: 40px 0px 10px 0px;
      width: 100%;   
  }
  .siteBox2 .siteBox2text .siteBox2textlist2{
    width: 50%;
  }
  .siteBox2 .siteBox2text .siteBox2textlist2 ul li{
   margin-left: 20%;
    font-size: 36px;
      padding: 40px 0px 10px 0px;
      width: 57%;  
}

    .siteBox2img  {
        width: 60%;
      }
      .siteBox2img  img {
        width: 90%;
        border-radius: 15px;
        
      }
      .siteBox3{
        width: 100%;
        margin-top: 10%;
        display: flex;

      }
      .siteBox3text h1{
        width: 100%;
        
    }
    .siteBox3 .siteBox3text .siteBox3textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
        display: contents;
    
  } 
  .siteBox3 .siteBox3text .siteBox3textlist1{
    width: 70%;
  }
    .siteBox3 .siteBox3text .siteBox3textlist1 ul li{
      font-size: 36px;
      padding: 40px 0px 10px 0px;
      width: 70%; 
      margin-left: 20px;  
  }
  .siteBox3 .siteBox3text .siteBox3textlist2{
    width: 70%;
  }
  .siteBox3 .siteBox3text .siteBox3textlist2 ul li{
   margin-left: 0;
    font-size: 36px;
      padding: 40px 0px 10px 0px;
      width: 70%;
      margin-left: 20px;  
}

    .siteBox3img  {
        width: 75%;
      }
      .siteBox3img  img {
        width: 70%;
        border-radius: 15px;
        
        
      }
      .siteBox4{
        width: 100%;
        background-color: #f9f9f9;
        border-radius: 35px;
        padding: 0px 30px;
        display: flex;
        margin-top: 10%;
        overflow: hidden;
      }
      .siteBox4text h1{
        width: 82%;
      }
      .siteBox4 .siteBox4bi{
      width: 100%;
      display: flex;
      position: relative;
     z-index: 0;
      
      
      }
      .siteBox4 .siteBox4bi .siteBox4img {
        width: 100%;
        
        }
        .siteBox4 .siteBox4bi .siteBox4img  img{
          width: 100%;
          margin-left: -450px;
          position: relative;
          z-index: -1;
          }
      .siteBox4 .siteBox4bi .siteBox4button button{
      width: 100%;
      height: 80px;
      margin-top: 10px;
      background: #5231e4;
      border-radius: 10px;
      border-style: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      margin-left: 300px;
      text-align: center;
      color: #ffffff;
      font-size: 36px;
      cursor: pointer;
         

      }
      .siteBox4 .siteBox4bi .siteBox4button input{
        width: 100%;
        height: 80px;
        margin-top: 200px;
        margin-left: 300px;
        border: 1px solid #e1e1e1;
        border-radius: 15px;
        font-size: 26px;
         

      }

  }
  @media (max-width: 1920px) {
    .site{
        padding: 60px 100px;
    }
    h1 {
        font-weight: 700;
        font-size: 34px;
        color: #0e082b;
      }
      .siteBox1 {
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
      }
      .siteBox12 {
        display: flex;
      }
      .siteBox1text  {
        width: 100%;
        position: relative;
        top: 50px;
      }
      
      .siteBox1text h1 {
        width: 90%;
      }
      .siteBox1text p {
        width: 66%;
      }
      .siteBox1img  {
        width: 100%;
      }
      .siteBox1img  img {
        width: 100%;
        margin-top: 8%;
      }
      .siteBox2{
        width: 100%;
        display: flex;
        flex-direction: row-reverse;

      }
      .siteBox2text {
        width: 80%;
        
    }
      .siteBox2text h1{
        width: 78%;
        
    }
    .siteBox2 .siteBox2text .siteBox2textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
    
  } 
  .siteBox2 .siteBox2text .siteBox2textlist1{
    width: 30%;
  }
    .siteBox2 .siteBox2text .siteBox2textlist1 ul li{
      font-size: 20px;
      padding: 40px 0px 10px 0px;
      width: 95%;   
  }
  .siteBox2 .siteBox2text .siteBox2textlist2{
    width: 50%;
  }
  .siteBox2 .siteBox2text .siteBox2textlist2 ul li{
   margin-left: 20%;
    font-size: 20px;
      padding: 40px 0px 10px 0px;
      width: 53%;  
}

    .siteBox2img  {
        width: 60%;
      }
      .siteBox2img  img {
        width: 90%;
        border-radius: 15px;
        
      }
      .siteBox3{
        width: 100%;
        margin-top: 10%;
        display: flex;

      }
      .siteBox3text h1{
        width: 100%;
        
    }
    .siteBox3 .siteBox3text .siteBox3textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
        display: contents;
    
  } 
  .siteBox3 .siteBox3text .siteBox3textlist1{
    width: 70%;
  }
    .siteBox3 .siteBox3text .siteBox3textlist1 ul li{
      font-size: 20px;
      padding: 40px 0px 10px 0px;
      width: 70%; 
      margin-left: 20px;  
  }
  .siteBox3 .siteBox3text .siteBox3textlist2{
    width: 70%;
  }
  .siteBox3 .siteBox3text .siteBox3textlist2 ul li{
   margin-left: 0;
    font-size: 20px;
      padding: 40px 0px 10px 0px;
      width: 70%;
      margin-left: 20px;  
}

    .siteBox3img  {
        width: 100%;
      }
      .siteBox3img  img {
        width: 100%;
        border-radius: 15px;
        
        
      }
      .siteBox4{
        width: 100%;
        background-color: #f9f9f9;
        border-radius: 35px;
        padding: 30px 30px;
        display: flex;
        margin-top: 10%;
        overflow: hidden;
      }
      .siteBox4text{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .siteBox4text h1{
        width: 82%;
      }
      .siteBox4 .siteBox4bi{
      width: 100%;
      display: flex;
      position: relative;
     z-index: 0;
      
      
      }
      .siteBox4 .siteBox4bi .siteBox4img {
        width: 100%;
        
        }
        .siteBox4 .siteBox4bi .siteBox4img  img{
          width: 100%;
          margin-left: -380px;
          position: relative;
          z-index: -1;
          top: 33px;
          }
      .siteBox4 .siteBox4bi .siteBox4button button{
      width: 100%;
      height: 60px;
      margin-top: 10px;
      background: #5231e4;
      border-radius: 10px;
      border-style: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      margin-left: 220px;
      text-align: center;
      color: #ffffff;
      font-size: 20px;
      cursor: pointer;
         

      }
      .siteBox4 .siteBox4bi .siteBox4button input{
        width: 100%;
    height: 60px;
    margin-top: 100px;
    margin-left: 220px;
    border: 1px solid #e1e1e1;
    border-radius: 15px;
    font-size: 20px;
         

      }

  }
  @media (max-width: 1440px) {
    .site{
        padding: 0 80px;
    }
    h1 {
        font-weight: 700;
        font-size: 36px;
        color: #0e082b;
      }
      p {
        font-weight: 300;
        font-size: 18px;
        color: #0e082b;
        margin-top: 23px;
      }
      .siteBox1 {
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
      }
      .siteBox12 {
        display: flex;
      }
      .siteBox1text  {
        width: 100%;
      }
      
      .siteBox1text h1 {
        width: 74%;
      }
      .siteBox1text p {
        width: 66%;
      }
      .siteBox1img  {
        width: 100%;
      }
      .siteBox1img  img {
        width: 100%;
        margin-top: 30px;
      }
      .siteBox2{
        width: 100%;
        display: flex;
        flex-direction: row-reverse;

      }
      .siteBox2text {
        width: 80%;
        
    }
      .siteBox2text h1{
        width: 63%;
        
    }
    .siteBox2 .siteBox2text .siteBox2textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
    
  } 
  .siteBox2 .siteBox2text .siteBox2textlist1{
    width: 27%;
  }
    .siteBox2 .siteBox2text .siteBox2textlist1 ul li{
      font-size: 18px;
      padding: 20px 0px 10px 0px;
      width: 100%;   
  }
  .siteBox2 .siteBox2text .siteBox2textlist2{
    width: 50%;
  }
  .siteBox2 .siteBox2text .siteBox2textlist2 ul li{
   margin-left: 20%;
    font-size: 18px;
      padding: 20px 0px 10px 0px;
      width: 49%;  
}

    .siteBox2img  {
        width: 60%;
      }
      .siteBox2img  img {
        width: 90%;
        border-radius: 15px;
        
      }
      .siteBox3{
        width: 100%;
        margin-top: 10%;
        display: flex;

      }
      .siteBox3text h1{
        width: 100%;
        
    }
    .siteBox3 .siteBox3text .siteBox3textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
        display: contents;
    
  } 
  .siteBox3 .siteBox3text .siteBox3textlist1{
    width: 70%;
  }
    .siteBox3 .siteBox3text .siteBox3textlist1 ul li{
      font-size: 18px;
      padding: 20px 0px 10px 0px;
      width: 60%; 
      margin-left: 20px;  
  }
  .siteBox3 .siteBox3text .siteBox3textlist2{
    width: 70%;
  }
  .siteBox3 .siteBox3text .siteBox3textlist2 ul li{
   margin-left: 0;
    font-size: 18px;
      padding: 20px 0px 10px 0px;
      width: 60%;
      margin-left: 20px;  
}

    .siteBox3img  {
        width: 75%;
      }
      .siteBox3img  img {
        width: 100%;
        border-radius: 15px;
        
        
      }
      .siteBox4{
        width: 100%;
        background-color: #f9f9f9;
        border-radius: 35px;
        padding: 0px 30px;
        display: flex;
        margin-top: 10%;
        overflow: hidden;
      }
      .siteBox4text h1{
        width: 82%;
      }
      .siteBox4 .siteBox4bi{
      width: 100%;
      display: flex;
      position: relative;
     z-index: 0;
      
      
      }
      .siteBox4 .siteBox4bi .siteBox4img {
        width: 100%;
        
        }
        .siteBox4 .siteBox4bi .siteBox4img  img{
          width: 100%;
          margin-left: -300px;
          position: relative;
          z-index: -1;
          top: 0px;
          }
      .siteBox4 .siteBox4bi .siteBox4button button{
      width: 120%;
      height: 50px;
      margin-top: 10px;
      background: #5231e4;
      border-radius: 10px;
      border-style: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      margin-left: 150px;
      text-align: center;
      color: #ffffff;
      font-size: 14px;
      cursor: pointer;
         

      }
      .siteBox4 .siteBox4bi .siteBox4button input{
        width: 120%;
        height: 50px;
        margin-top: 80px;
        margin-left: 150px;
        border: 1px solid #e1e1e1;
        border-radius: 15px;
        font-size: 14px;
         

      }

  }
  @media (max-width: 1366px) {
    .site{
        padding: 0 80px;
    }
    h1 {
        font-weight: 700;
        font-size: 36px;
        color: #0e082b;
      }
      p {
        font-weight: 300;
        font-size: 18px;
        color: #0e082b;
        margin-top: 23px;
      }
      .siteBox1 {
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
      }
      .siteBox12 {
        display: flex;
      }
      .siteBox1text  {
        width: 100%;
      }
      
      .siteBox1text h1 {
        width: 85%;
      }
      .siteBox1text p {
        width: 66%;
      }
      .siteBox1img  {
        width: 100%;
      }
      .siteBox1img  img {
        width: 100%;
        margin-top: 30px;
      }
      .siteBox2{
        width: 100%;
        display: flex;
        flex-direction: row-reverse;

      }
      .siteBox2text {
        width: 80%;
        
    }
      .siteBox2text h1{
        width: 70%;
        
    }
    .siteBox2 .siteBox2text .siteBox2textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
    
  } 
  .siteBox2 .siteBox2text .siteBox2textlist1{
    width: 27%;
  }
    .siteBox2 .siteBox2text .siteBox2textlist1 ul li{
      font-size: 18px;
      padding: 20px 0px 10px 0px;
      width: 102%;   
  }
  .siteBox2 .siteBox2text .siteBox2textlist2{
    width: 50%;
  }
  .siteBox2 .siteBox2text .siteBox2textlist2 ul li{
   margin-left: 20%;
    font-size: 18px;
      padding: 20px 0px 10px 0px;
      width: 52%;  
}

    .siteBox2img  {
        width: 60%;
      }
      .siteBox2img  img {
        width: 90%;
        border-radius: 15px;
        
      }
      .siteBox3{
        width: 100%;
        margin-top: 10%;
        display: flex;

      }
      .siteBox3text h1{
        width: 100%;
        
    }
    .siteBox3 .siteBox3text .siteBox3textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
        display: contents;
    
  } 
  .siteBox3 .siteBox3text .siteBox3textlist1{
    width: 70%;
  }
    .siteBox3 .siteBox3text .siteBox3textlist1 ul li{
      font-size: 18px;
      padding: 20px 0px 10px 0px;
      width: 65%; 
      margin-left: 20px;  
  }
  .siteBox3 .siteBox3text .siteBox3textlist2{
    width: 70%;
  }
  .siteBox3 .siteBox3text .siteBox3textlist2 ul li{
   margin-left: 0;
    font-size: 18px;
      padding: 20px 0px 10px 0px;
      width: 65%;
      margin-left: 20px;  
}

    .siteBox3img  {
        width: 75%;
      }
      .siteBox3img  img {
        width: 100%;
        border-radius: 15px;
        
        
      }
      .siteBox4{
        width: 100%;
        background-color: #f9f9f9;
        border-radius: 35px;
        padding: 0px 30px;
        display: flex;
        margin-top: 10%;
        overflow: hidden;
      }
      .siteBox4text h1{
        width: 77%;
      }
      .siteBox4 .siteBox4bi{
      width: 100%;
      display: flex;
      position: relative;
     z-index: 0;
      
      
      }
      .siteBox4 .siteBox4bi .siteBox4img {
        width: 100%;
        
        }
        .siteBox4 .siteBox4bi .siteBox4img  img{
          width: 100%;
          margin-left: -300px;
          position: relative;
          z-index: -1;
          top: 0px;
          }
      .siteBox4 .siteBox4bi .siteBox4button button{
      width: 140%;
      height: 40px;
      margin-top: 10px;
      background: #5231e4;
      border-radius: 10px;
      border-style: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      margin-left: 100px;
      text-align: center;
      color: #ffffff;
      font-size: 14px;
      cursor: pointer;
         

      }
      .siteBox4 .siteBox4bi .siteBox4button input{
        width: 140%;
        height: 40px;
        margin-top: 70px;
        margin-left: 100px;
        border: 1px solid #e1e1e1;
        border-radius: 15px;
        font-size: 14px;
         

      }

  }
  @media (max-width: 1024px) {
    .site{
        padding: 0 60px;
    }
    h1 {
        font-weight: 700;
        font-size: 28px;
        color: #0e082b;
      }
      p {
        font-weight: 300;
        font-size: 14px;
        color: #0e082b;
        margin-top: 23px;
      }
      .siteBox1 {
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
      }
      .siteBox12 {
        display: flex;
      }
      .siteBox1text  {
        width: 100%;
        position: relative;
        top: 10px;
      }
      
      .siteBox1text h1 {
        width: 80%;
      }
      .siteBox1text p {
        width: 66%;
      }
      .siteBox1img  {
        width: 100%;
      }
      .siteBox1img  img {
        width: 100%;
        margin-top: 0;
      }
      .siteBox2{
        width: 100%;
        display: flex;
        flex-direction: row-reverse;

      }
      .siteBox2text {
        width: 80%;
        
        
    }
      .siteBox2text h1{
        width: 72%;
        
    }
    .siteBox2 .siteBox2text .siteBox2textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
    
  } 
  .siteBox2 .siteBox2text .siteBox2textlist1{
    width: 27%;
  }
    .siteBox2 .siteBox2text .siteBox2textlist1 ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 96%;   
  }
  .siteBox2 .siteBox2text .siteBox2textlist2{
    width: 50%;
  }
  .siteBox2 .siteBox2text .siteBox2textlist2 ul li{
   margin-left: 20%;
    font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 48%;  
}

    .siteBox2img  {
        width: 60%;
      }
      .siteBox2img  img {
        width: 90%;
        border-radius: 15px;
        
      }
      .siteBox3{
        width: 100%;
        margin-top: 10%;
        display: flex;

      }
      .siteBox3text h1{
        width: 94%;
        
    }
    .siteBox3 .siteBox3text .siteBox3textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
        display: contents;
    
  } 
  .siteBox3 .siteBox3text .siteBox3textlist1{
    width: 70%;
  }
    .siteBox3 .siteBox3text .siteBox3textlist1 ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 67%;   
      margin-left: 20px;
  }
  .siteBox3 .siteBox3text .siteBox3textlist2{
    width: 70%;
  }
  .siteBox3 .siteBox3text .siteBox3textlist2 ul li{
   margin-left: 0;
    font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 66%;  
      margin-left: 20px;
}

    .siteBox3img  {
        width: 100%;
      }
      .siteBox3img  img {
        width: 90%;
        border-radius: 15px; 
      }
      .siteBox4{
        width: 100%;
        background-color: #f9f9f9;
        border-radius: 35px;
        padding: 0px 30px;
        display: flex;
        margin-top: 10%;
        overflow: hidden;
      }
      .siteBox4text h1{
        width: 77%;
      }
      .siteBox4 .siteBox4bi{
      width: 100%;
      display: flex;
      position: relative;
     z-index: 0;
      
      
      }
      .siteBox4 .siteBox4bi .siteBox4img {
        width: 100%;
        
        }
        .siteBox4 .siteBox4bi .siteBox4img  img{
          width: 120%;
          margin-left: -200px;
          position: relative;
          z-index: -1;
          }
      .siteBox4 .siteBox4bi .siteBox4button button{
      width: 190%;
      height: 30px;
      margin-top: 10px;
      margin-left: 50px;
      background: #5231e4;
      border-radius: 10px;
      border-style: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
      font-size: 14px;
         

      }
      .siteBox4 .siteBox4bi .siteBox4button input{
        width: 190%;
        height: 30px;
        margin-top: 90px;
        margin-left: 50px;
        border: 1px solid #e1e1e1;
        border-radius: 15px;
        font-size: 14px;
         

      }

  }
  @media (max-width: 768px) {
    .site{
        padding: 0 40px;
    }
    h1 {
        font-weight: 700;
        font-size: 24px;
        color: #0e082b;
      }
      p {
        font-weight: 300;
        font-size: 14px;
        color: #0e082b;
        margin-top: 23px;
      }
      .siteBox1 {
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
      }
      .siteBox12 {
        display: flex;
      }
      .siteBox1text  {
        width: 100%;
        position: relative;
        top: 0;
      }
      
      .siteBox1text h1 {
        width: 88%;
      }
      .siteBox1text p {
        width: 83%;
      }
      .siteBox1img  {
        width: 100%;
      }
      .siteBox1img  img {
        width: 100%;
        margin-top: 0;
      }
      .siteBox2{
        width: 100%;
        display: flex;
        flex-direction: row-reverse;

      }
      .siteBox2text {
        width: 80%;
        
    }
      .siteBox2text h1{
        width: 84%;
        
    }
    .siteBox2 .siteBox2text .siteBox2textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
    
  } 
  .siteBox2 .siteBox2text .siteBox2textlist1{
    width: 50%;
  }
    .siteBox2 .siteBox2text .siteBox2textlist1 ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 68%;   
  }
  .siteBox2 .siteBox2text .siteBox2textlist2{
    width: 50%;
  }
  .siteBox2 .siteBox2text .siteBox2textlist2 ul li{
   margin-left: 20%;
    font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 65%;  
}

    .siteBox2img  {
        width: 60%;
      }
      .siteBox2img  img {
        width: 90%;
        border-radius: 15px;
        
      }
      .siteBox3{
        width: 100%;
        margin-top: 10%;
        display: flex;

      }
      .siteBox3text h1{
        width: 88%;
        
    }
    .siteBox3 .siteBox3text .siteBox3textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
        display: contents;
    
  } 
  .siteBox3 .siteBox3text .siteBox3textlist1{
    width: 70%;
  }
    .siteBox3 .siteBox3text .siteBox3textlist1 ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 88%;  
      margin-left: 16px; 
  }
  .siteBox3 .siteBox3text .siteBox3textlist2{
    width: 70%;
  }
  .siteBox3 .siteBox3text .siteBox3textlist2 ul li{
   margin-left: 0;
    font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 82%;  
      margin-left: 16px;
}

    .siteBox3img  {
        width: 100%;
      }
      .siteBox3img  img {
        width: 100%;
        border-radius: 15px;
        
        
      }
      .siteBox4{
        width: 100%;
        background-color: #f9f9f9;
        border-radius: 35px;
        padding: 0px 30px;
        display: flex;
        margin-top: 10%;
        overflow: hidden;
      }
      .siteBox4text h1{
        width: 81%;
      }
      .siteBox4 .siteBox4bi{
      width: 100%;
      display: flex;
      position: relative;
     z-index: 0;
      
      
      }
      .siteBox4 .siteBox4bi .siteBox4img {
        width: 100%;
        
        }
        .siteBox4 .siteBox4bi .siteBox4img  img{
          width: 150%;
          margin-left: -200px;
          position: relative;
          z-index: -1;
          }
      .siteBox4 .siteBox4bi .siteBox4button button{
      width: 170%;
      height: 30px;
      margin-top: 10px;
      margin-left: 10px;
      background: #5231e4;
      border-radius: 10px;
      border-style: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
         

      }
      .siteBox4 .siteBox4bi .siteBox4button input{
        width: 170%;
        height: 30px;
        margin-left: 10px;
        margin-top: 54px;
        border: 1px solid #e1e1e1;
        border-radius: 15px;
         

      }

  }
  @media (max-width: 425px) {
    .site{
        padding: 0 16px;
    }
    h1 {
        font-weight: 700;
        font-size: 24px;
        color: #0e082b;
      }
      p {
        font-weight: 300;
        font-size: 14px;
        color: #0e082b;
        margin-top: 23px;
      }
      .siteBox1 {
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
      }
      .siteBox12 {
        display: block;
      }
      .siteBox1text  {
        width: 100%;
      }
      
      .siteBox1text h1 {
        width: 62%;
      }
      .siteBox1text p {
        width: 83%;
      }
      .siteBox1img  {
        width: 100%;
      }
      .siteBox1img  img {
        width: 100%;
        margin-top: 10%;
      }
      .siteBox2{
        width: 100%;
        display: block;

      }
      .siteBox2text {
        width: 100%;
    }
      .siteBox2text h1{
        width: 84%;
        
    }
    .siteBox2 .siteBox2text .siteBox2textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
    
  } 
  .siteBox2 .siteBox2text .siteBox2textlist1{
    width: 50%;
  }
    .siteBox2 .siteBox2text .siteBox2textlist1 ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 71%;   
  }
  .siteBox2 .siteBox2text .siteBox2textlist2{
    width: 50%;
  }
  .siteBox2 .siteBox2text .siteBox2textlist2 ul li{
   margin-left: 20%;
    font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 65%;  
}

    .siteBox2img  {
        width: 100%;
      }
      .siteBox2img  img {
        width: 100%;
        border-radius: 15px;
        
      }
      .siteBox3{
        width: 100%;
        margin-top: 10%;
        display: block;

      }
      .siteBox3text {
        width: 88%;  
    }
      .siteBox3text h1{
        width: 88%;  
    }
    .siteBox3 .siteBox3text .siteBox3textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
       
    
  } 
  .siteBox3 .siteBox3text .siteBox3textlist1{
    width: 50%;
  }
    .siteBox3 .siteBox3text .siteBox3textlist1 ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 87%;
      margin-left: 0;   
  }
  .siteBox3 .siteBox3text .siteBox3textlist2{
    width: 50%;
  }
  .siteBox3 .siteBox3text .siteBox3textlist2 ul li{
    font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 110%;  
      margin-left: 0;  
}

    .siteBox3img  {
        width: 100%;
      }
      .siteBox3img  img {
        width: 100%;
        border-radius: 15px;
        
        
      }
      .siteBox4{
        width: 100%;
        background-color: #f9f9f9;
        border-radius: 35px;
        padding: 0px 30px;
        display: block;
        margin-top: 10%;
        overflow: hidden;
      }
      .siteBox4text h1{
        width: 81%;
      }
      .siteBox4 .siteBox4bi{
      width: 100%;
      display: flex;
      position: relative;
     z-index: 0;
      
      
      }
      .siteBox4 .siteBox4bi .siteBox4img {
        width: 100%;
        
        }
        .siteBox4 .siteBox4bi .siteBox4img  img{
          width: 150%;
          margin-left: 8px;
          position: relative;
          z-index: -1;
          }
      .siteBox4 .siteBox4bi .siteBox4button button{
      width: 190%;
      height: 50px;
      margin-top: 20px;
      background: #5231e4;
      border-radius: 10px;
      border-style: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
         

      }
      .siteBox4 .siteBox4bi .siteBox4button input{
        width: 190%;
        height: 45px;
        margin-top: 140px;
        border: 1px solid #e1e1e1;
        border-radius: 15px;
         

      }

  }
  @media (max-width: 390px) {
    .site{
        padding: 0 16px;
    }
    h1 {
        font-weight: 700;
        font-size: 24px;
        color: #0e082b;
      }
      p {
        font-weight: 300;
        font-size: 14px;
        color: #0e082b;
        margin-top: 23px;
      }
      .siteBox1 {
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
      }
      
      .siteBox1text h1 {
        width: 73%;
      }
      .siteBox1text p {
        width: 96%;
      }
      .siteBox1img  {
        width: 100%;
      }
      .siteBox1img  img {
        width: 100%;
        margin-top: 10%;
      }
      .siteBox2{
        width: 100%;

      }
      .siteBox2text h1{
        width: 99%;
        
    }
    .siteBox2 .siteBox2text .siteBox2textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
    
  } 
  .siteBox2 .siteBox2text .siteBox2textlist1{
    width: 50%;
  }
    .siteBox2 .siteBox2text .siteBox2textlist1 ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 80%;   
  }
  .siteBox2 .siteBox2text .siteBox2textlist2{
    width: 50%;
  }
  .siteBox2 .siteBox2text .siteBox2textlist2 ul li{
   margin-left: 20%;
    font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 77%;  
}

    .siteBox2img  {
        width: 100%;
      }
      .siteBox2img  img {
        width: 100%;
        border-radius: 15px;
        
      }
      .siteBox3{
        width: 100%;
        margin-top: 10%;

      }
      .siteBox3text h1{
        width: 88%;
        
    }
    .siteBox3 .siteBox3text .siteBox3textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
    
  } 
  .siteBox3 .siteBox3text .siteBox3textlist1{
    width: 50%;
  }
    .siteBox3 .siteBox3text .siteBox3textlist1 ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 100%;   
  }
  .siteBox3 .siteBox3text .siteBox3textlist2{
    width: 50%;
  }
  .siteBox3 .siteBox3text .siteBox3textlist2 ul li{
   margin-left: 20%;
    font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 130%;  
}

    .siteBox3img  {
        width: 100%;
      }
      .siteBox3img  img {
        width: 100%;
        border-radius: 15px;
        
        
      }
      .siteBox4{
        width: 100%;
        background-color: #f9f9f9;
        border-radius: 35px;
        padding: 0px 30px;
        display: block;
        margin-top: 10%;
        overflow: hidden;
      }
      .siteBox4text h1{
        width: 81%;
      }
      .siteBox4 .siteBox4bi{
      width: 100%;
      display: flex;
      position: relative;
     z-index: 0;
      
      
      }
      .siteBox4 .siteBox4bi .siteBox4img {
        width: 100%;
        
        }
        .siteBox4 .siteBox4bi .siteBox4img  img{
          width: 150%;
          margin-left: 8px;
          position: relative;
          z-index: -1;
          }
      .siteBox4 .siteBox4bi .siteBox4button button{
      width: 170%;
      height: 50px;
      margin-top: 20px;
      background: #5231e4;
      border-radius: 10px;
      border-style: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
         

      }
      .siteBox4 .siteBox4bi .siteBox4button input{
        width: 170%;
        height: 45px;
        margin-top: 88px;
        border: 1px solid #e1e1e1;
        border-radius: 15px;
         

      }

      
     






  }
  @media (max-width: 320px) {
    .site{
        padding: 0 16px;
    }
    h1 {
        font-weight: 700;
        font-size: 24px;
        color: #0e082b;
      }
      p {
        font-weight: 300;
        font-size: 14px;
        color: #0e082b;
        margin-top: 23px;
      }
      .siteBox1 {
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
      }
      
      .siteBox1text h1 {
        width: 86%;
      }
      .siteBox1text p {
        width: 100%;
      }
      .siteBox1img  {
        width: 100%;
      }
      .siteBox1img  img {
        width: 100%;
        margin-top: 10%;
      }
      .siteBox2{
        width: 100%;

      }
      .siteBox2text h1{
        width: 101%;
        
    }
    .siteBox2 .siteBox2text .siteBox2textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
    
  } 
  .siteBox2 .siteBox2text .siteBox2textlist1{
    width: 50%;
  }
    .siteBox2 .siteBox2text .siteBox2textlist1 ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 101%;   
  }
  .siteBox2 .siteBox2text .siteBox2textlist2{
    width: 50%;
  }
  .siteBox2 .siteBox2text .siteBox2textlist2 ul li{
   margin-left: 20%;
    font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 85%;  
}

    .siteBox2img  {
        width: 100%;
      }
      .siteBox2img  img {
        width: 100%;
        border-radius: 15px;
        
      }
      .siteBox3{
        width: 100%;
        margin-top: 10%;

      }
      .siteBox3text h1{
        width: 101%;
        
    }
    .siteBox3 .siteBox3text .siteBox3textlist{
        display: flex;   
        padding: 0 20px;
        overflow: hidden;
        height: auto;
    
  } 
  .siteBox3 .siteBox3text .siteBox3textlist1{
    width: 50%;
  }
    .siteBox3 .siteBox3text .siteBox3textlist1 ul li{
      font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 100%;   
  }
  .siteBox3 .siteBox3text .siteBox3textlist2{
    width: 50%;
  }
  .siteBox3 .siteBox3text .siteBox3textlist2 ul li{
   margin-left: 20%;
    font-size: 12px;
      padding: 10px 0px 10px 0px;
      width: 130%;  
}

    .siteBox3img  {
        width: 100%;
      }
      .siteBox3img  img {
        width: 100%;
        border-radius: 15px;
        
        
      }
      .siteBox4{
        width: 100%;
        background-color: #f9f9f9;
        border-radius: 35px;
        padding: 0px 30px;
        display: block;
        margin-top: 10%;
        overflow: hidden;
      }
      .siteBox4text h1{
        width: 101%;
      }
      .siteBox4 .siteBox4bi{
      width: 100%;
      display: flex;
      position: relative;
     z-index: 0;
      
      
      }
      .siteBox4 .siteBox4bi .siteBox4img {
        width: 100%;
        
        }
        .siteBox4 .siteBox4bi .siteBox4img  img{
          width: 150%;
          margin-left: 8px;
          position: relative;
          z-index: -1;
          }
      .siteBox4 .siteBox4bi .siteBox4button button{
      width: 170%;
      height: 40px;
      margin-top: 20px;
      font-size: 12px;
      background: #5231e4;
      border-radius: 10px;
      border-style: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
         

      }
      .siteBox4 .siteBox4bi .siteBox4button input{
        width: 170%;
        height: 35px;
        margin-top: 39px;
        border: 1px solid #e1e1e1;
        border-radius: 15px;
         

      }

      
     






  }