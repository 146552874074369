.recoverBox{
    background: #F9F9F9;
    padding: 30px 30px;
    border-radius: 15px;
    
    
}
.recoverBox1{
    display: flex;
    width: 100%;
    justify-content: center;
}
.recoveryBox4 {
    margin-top: 43px;
}
.recover{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
input{
    width: 100%;
    padding-left: 10px;   
    border: 1px solid #9581EE;
    border-radius: 4px;
}
.recoveryBox3 input{
 width: 70%;
}
.recoverBox2{
    margin-top: 43px;
  
}
.recoveryButton1 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    width: 100%;
   
}
.recoveryButton2 button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    width: 100%;
    
}
@media (max-width: 2560px) {
    .recover{
       display: flex;
       justify-content: center;
       align-items: center;
       height: 100vh;
       width: 100%;
    }
    
   input{
        
    height: 60px;
    font-size: 36px;
    }
    .recoverBox{
       width: 40%;
        
        
    }
    
    .recoverBox1 h3 {
        font-size: 40px;
            
        }
    .recoveryBox3{
        margin-top: 20px;
    }

    .recoveryButton1 button{
       
        font-size: 36px;
        margin: 45px 0 0;
        height: 70px;
    }
    .recoveryButton2 button{
    
        font-size: 36px;
        margin: 45px 0 0;
        height: 70px;
    }
    .recooveryBox21{
        font-size: 36px;
    }
    .recoveryBox31{
        font-size: 36px;
    }


}
@media (max-width: 1920px) {
    .recoverBox{
        width: 40%;
     }
   input{
        
    height: 60px;
    font-size: 20px;
    }
    
    .recoverBox1 h3 {
        font-size: 26px;
            
        }
    .recoveryBox3{
        margin-top: 20px;
    }

    .recoveryButton1 button{
       
        font-size: 20px;
        margin: 45px 0 0;
        height: 60px;
    }
    .recoveryButton2 button{
    
        font-size: 20px;
        margin: 45px 0 0;
        height: 60px;
    }
    .recooveryBox21{
        font-size: 24px;
    }
    .recoveryBox31{
        font-size: 24px;
    }


}
@media (max-width: 1440px) {
    .recoverBox{
        width: 40%;   
     }
     .recoveryBox22 input {
            height: 4vh;
            font-size: 16px;
        }
     
    
   input{
        
    height: 30px;
    font-size: 16px;
    }
    
    .recoverBox1 h3 {
        font-size: 26px;
            
        }
    .recoveryBox3{
        margin-top: 20px;
    }

    .recoveryButton1 button{
       
        font-size: 16px;
        margin: 45px 0 0;
        height: 30px;
    }
    .recoveryButton2 button{
    
        font-size: 16px;
        margin: 45px 0 0;
        height: 30px;
    }
    .recooveryBox21{
        font-size: 18px;
    }
    .recoveryBox31{
        font-size: 18px;
    }


}
@media (max-width: 1366px) {
    .recoverBox{
        width: 40%;
         
         
     }
    
   input{
        
    height: 30px;
    font-size: 16px;
    }
    
   .recoveryBox1 h3 {
    font-size: 26px;   
    }
    .recoverBox3{
        margin-top: 20px;
    }

    .recoveryButton1 button{
       
        font-size: 16px;
        margin: 45px 0 0;
        height: 40px;
    }
    .recoveryButton2 button{
    
        font-size: 16px;
        margin: 45px 0 0;
        height: 40px;
    }


}
@media (max-width: 1024px) {
    .recoverBox{
        width: 60%;
         
         
     }
    
   input{
        
    height: 30px;
    font-size: 16px;
    }
    
    .recoverBox1 h3 {
        font-size: 22px;
    }
    .recoveryBox3{
        margin-top: 20px;
    }

    .recoveryButton1 button{
       
        font-size: 12px;
        margin: 45px 0 0;
        height: 30px;
    }
    .recoveryButton2 button{
    
        font-size: 12px;
        margin: 45px 0 0;
        height: 30px;
    }
    .recooveryBox21{
        font-size: 16px;
    }
    .recoveryBox31{
        font-size: 16px;
    }


}
@media (max-width: 768px) {
    .recoverBox{
        width: 60%;
         
         
     }
    
   input{
        
    height: 30px;
    font-size: 16px;
    }
    
  
    .recoveryBox3{
        margin-top: 20px;
    }

    .recoveryButton1 button{
       
        font-size: 12px;
        margin: 45px 0 0;
        height: 30px;
    }
    .recoveryButton2 button{
    
        font-size: 12px;
        margin: 45px 0 0;
        height: 30px;
    }


}
@media (max-width: 425px) {
    .recoverBox{
        width: 80%;
         
         
     }
   input{
        
    height: 30px;
    font-size: 16px;
    }
    
    .recoveryBox3{
        margin-top: 20px;
    }

    .recoveryButton1 button{
       
        font-size: 12px;
        margin: 45px 0 0;
        height: 30px;
    }
    .recoveryButton2 button{
    
        font-size: 12px;
        margin: 45px 0 0;
        height: 30px;
    }


}
@media (max-width: 390px) {
    .recoverBox{
        width: 80%;
         
         
     }
    
   input{
        
    height: 30px;
    font-size: 16px;
    }
    
    .recoveryBox3{
        margin-top: 20px;
    }

    .recoveryButton1 button{
       
        font-size: 12px;
        margin: 45px 0 0;
        height: 30px;
    }
    .recoveryButton2 button{
    
        font-size: 12px;
        margin: 45px 0 0;
        height: 30px;
    }


}
@media (max-width: 320px) {
    .recoverBox{
        width: 80%;
         
         
     }
   input{
        
    height: 30px;
    font-size: 16px;
    }
    
    .recoveryBox3{
        margin-top: 20px;
    }

    .recoveryButton1 button{
       
        font-size: 12px;
        margin: 45px 0 0;
        height: 30px;
    }
    .recoveryButton2 button{
    
        font-size: 12px;
        margin: 45px 0 0;
        height: 30px;
    }


}
#preloader{
    background: #fff url(recovery_img/loader.gif) no-repeat center center;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 100;
 }
