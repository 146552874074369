@media (max-width: 2560px) {
    .sitenasBox {
        padding-top: 180px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
      }
      .sitenasBox h2{
        font-size: 60px;
        width: 65%;
        text-align: center;
      }
      .sitenasBox img{
       width: 25%;
       margin-top: 75px;
      }
      .sitenasBox a{
        font-size: 60px;
        text-decoration: underline;
        color: #5231e4 !important;
      } 
}
@media (max-width: 1920px) {
    .sitenasBox {
        padding-top: 180px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
      }
      .sitenasBox h2{
        font-size: 40px;
        width: 80%;
        text-align: center;
      }
      .sitenasBox img{
       width: 20%;
       margin-top: 75px;
      }
      .sitenasBox a{
        font-size: 40px;
        text-decoration: underline;
        color: #5231e4 !important;
      } 
}
@media (max-width: 1440px) {
    .sitenasBox {
        padding-top: 110px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
      }
      .sitenasBox h2{
        font-size: 40px;
        width: 80%;
        text-align: center;
      }
      .sitenasBox img{
       width: 30%;
       margin-top: 75px;
      }
      .sitenasBox a{
        font-size: 40px;
        text-decoration: underline;
        color: #5231e4 !important;
      } 
}
@media (max-width: 1366px) {
    .sitenasBox {
        padding-top: 110px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
      }
      .sitenasBox h2{
        font-size: 40px;
        width: 80%;
        text-align: center;
      }
      .sitenasBox img{
       width: 30%;
       margin-top: 75px;
      }
      .sitenasBox a{
        font-size: 40px;
        text-decoration: underline;
        color: #5231e4 !important;
      } 
}
@media (max-width: 1024px) {
    .sitenasBox {
        padding-top: 110px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
      }
      .sitenasBox h2{
        font-size: 30px;
        width: 80%;
        text-align: center;
      }
      .sitenasBox img{
       width: 25%;
       margin-top: 60px;
      }
      .sitenasBox a{
        font-size: 30px;
        text-decoration: underline;
        color: #5231e4 !important;
      } 
}
@media (max-width: 768px) {
    .sitenasBox {
        padding-top: 110px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
      }
      .sitenasBox h2{
        font-size: 30px;
        width: 80%;
        text-align: center;
      }
      .sitenasBox img{
       width: 25%;
       margin-top: 60px;
      }
      .sitenasBox a{
        font-size: 30px;
        text-decoration: underline;
        color: #5231e4 !important;
      } 
}
@media (max-width: 425px) {
    .sitenasBox {
        padding-top: 110px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
      }
      .sitenasBox h2{
        font-size: 24px;
        width: 100%;
        text-align: center;
      }
      .sitenasBox img{
       width: 30%;
       margin-top: 40px;
      }
      .sitenasBox a{
        font-size: 20px;
        text-decoration: underline;
        color: #5231e4 !important;
      } 
}
@media (max-width: 320px) {
    .sitenasBox {
        padding-top: 110px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
      }
      .sitenasBox h2{
        font-size: 20px;
        width: 100%;
        text-align: center;
      }
      .sitenasBox img{
       width: 50%;
       margin-top: 40px;
      }
      .sitenasBox a{
        font-size: 16px;
        text-decoration: underline;
        color: #5231e4 !important;
      } 
}