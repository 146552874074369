.transferok{
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 0 0px;
    border-radius: 35px;
    box-shadow: 0px 0px 0px 0px #888888;
    top: 43.5%;
}

.transferokButton{
    display: flex;
    align-items: end;
    height: 100%;
    justify-content: center;
}
@media (max-width: 2560px) {
    .transferokBox{
        height: 70vh;
        width: 80%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        margin-top: 300px;
        box-shadow: 0px 4px 28px  #888888;
    }
    .transferokButton button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#5231e4;
        display: flex;
        font-family: SF Pro Display;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 80px;
    }
    .transferokBox1{
        height: 20vh;
        width: 60%;
        z-index: 1;
        position: absolute;
        background: #F9F9F9;
        border-radius: 15px;
        overflow: hidden;
        margin-top: 400px;
        display: flex;
    }
    .transferokBox2{
        display: flex;
        justify-content: center;
    }
    .transferokimg{
        width: 40%;
        display: flex;
        justify-content: center;
    }
    .transferokimg img{
        height: 300px;
        width: 150px;
    }
    .transferoktext{
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 100px;
    }
    .transferoktext span{
        font-size: 36px;
        font-weight: 400;
    }
}
@media (max-width: 1920px) {
    .transferokBox{
        height: 70vh;
        width: 80%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        margin-top: 200px;
        box-shadow: 0px 4px 28px  #888888;
    }
    .transferokButton button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#5231e4;
        display: flex;
        font-family: SF Pro Display;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 80px;
    }
    .transferokBox1{
        height: 20vh;
        width: 60%;
        z-index: 1;
        position: absolute;
        background: #F9F9F9;
        border-radius: 15px;
        overflow: hidden;
        margin-top: 200px;
        display: flex;
    }
    .transferokBox2{
        display: flex;
        justify-content: center;
    }
    .transferokimg{
        width: 40%;
        display: flex;
        justify-content: center;
    }
    .transferokimg img{
        height: 200px;
        width: 100px;
    }
    .transferoktext{
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 55px;
    }
    .transferoktext span{
        font-size: 26px;
        font-weight: 400;
    }
}
@media (max-width: 1440px) {
    .transferokBox{
        height: 70vh;
        width: 80%;
        z-index: 1;
        position: absolute;
        background: white;
        border-radius: 35px;
        overflow: hidden;
        margin-top: 130px;
        box-shadow: 0px 4px 28px  #888888;
    }
    .transferokButton button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#5231e4;
        display: flex;
        font-family: SF Pro Display;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 80px;
    }
    .transferokBox1{
        height: 20vh;
        width: 60%;
        z-index: 1;
        position: absolute;
        background: #F9F9F9;
        border-radius: 15px;
        overflow: hidden;
        margin-top: 200px;
        display: flex;
    }
    .transferokBox2{
        display: flex;
        justify-content: center;
    }
    .transferokimg{
        width: 40%;
        display: flex;
        justify-content: center;
    }
    .transferokimg img{
        height: 170px;
        width: 70px;
    }
    .transferoktext{
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 55px;
    }
    .transferoktext span{
        font-size: 20px;
        font-weight: 400;
    }
}
@media (max-width: 1366px) {
    .transferokButton button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#5231e4;
        display: flex;
        font-family: SF Pro Display;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 80px;
    }
    .transferokBox1{
        height: 20vh;
        width: 60%;
        z-index: 1;
        position: absolute;
        background: #F9F9F9;
        border-radius: 15px;
        overflow: hidden;
        margin-top: 200px;
        display: flex;
    }
    .transferokBox2{
        display: flex;
        justify-content: center;
    }
    .transferokimg{
        width: 40%;
        display: flex;
        justify-content: center;
    }
    .transferokimg img{
        height: 170px;
        width: 70px;
    }
    .transferoktext{
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 55px;
    }
    .transferoktext span{
        font-size: 18px;
        font-weight: 400;
    }
}
@media (max-width: 1024px) {
    .transferokButton button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#5231e4;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 80px;
    }
    .transferokBox1{
        height: 20vh;
        width: 60%;
        z-index: 1;
        position: absolute;
        background: #F9F9F9;
        border-radius: 15px;
        overflow: hidden;
        margin-top: 200px;
        display: flex;
    }
    .transferokBox2{
        display: flex;
        justify-content: center;
    }
    .transferokimg{
        width: 40%;
        display: flex;
        justify-content: center;
    }
    .transferokimg img{
        height: 160px;
        width: 60px;
    }
    .transferoktext{
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 55px;
    }
    .transferoktext span{
        font-size: 14px;
        font-weight: 400;
    }
}
@media (max-width: 768px) {
    .transferokButton button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#5231e4;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 80px;
    }
    .transferokBox1{
        height: 20vh;
        width: 80%;
        z-index: 1;
        position: absolute;
        background: #F9F9F9;
        border-radius: 15px;
        overflow: hidden;
        margin-top: 200px;
        display: flex;
    }
    .transferokBox2{
        display: flex;
        justify-content: center;
    }
    .transferokimg{
        width: 35%;
        display: flex;
        justify-content: center;
    }
    .transferokimg img{
        height: 160px;
        width: 60px;
    }
    .transferoktext{
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 55px;
    }
    .transferoktext span{
        font-size: 14px;
        font-weight: 400;
    }
}

@media (max-width: 425px) {
    .transferokButton button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#5231e4;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 80px;
    }
    .transferokBox1{
        height: 25vh;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: #F9F9F9;
        border-radius: 15px;
        overflow: hidden;
        margin-top: 100px;
        display: block;
    }
    .transferokBox2{
        display: flex;
        justify-content: center;
    }
    .transferokimg{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .transferokimg img{
        height: 100px;
        width: 40px;
    }
    .transferoktext{
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 0px;
    }
    .transferoktext span{
        font-size: 14px;
        font-weight: 400;
    }
}
@media (max-width: 390px) {
    .transferokButton button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#5231e4;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 80px;
    }
    .transferokBox1{
        height: 25vh;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: #F9F9F9;
        border-radius: 15px;
        overflow: hidden;
        margin-top: 100px;
        display: block;
    }
    .transferokBox2{
        display: flex;
        justify-content: center;
    }
    .transferokimg{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .transferokimg img{
        height: 100px;
        width: 40px;
    }
    .transferoktext{
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
    }
    .transferoktext span{
        font-size: 14px;
        font-weight: 400;
    }
}
@media (max-width: 320px) {
    .transferokButton button{
        align-items: center;
        background:#fff ;
        border-radius: 10px;
        border-style: none;
        color:#5231e4;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        width: 100%;
        height: 80px;
    }
    .transferokBox1{
        height: 25vh;
        width: 90%;
        z-index: 1;
        position: absolute;
        background: #F9F9F9;
        border-radius: 15px;
        overflow: hidden;
        margin-top: 100px;
        display: block;
    }
    .transferokBox2{
        display: flex;
        justify-content: center;
    }
    .transferokimg{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .transferokimg img{
        height: 100px;
        width: 40px;
    }
    .transferoktext{
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
    }
    .transferoktext span{
        font-size: 14px;
        font-weight: 400;
    }
}