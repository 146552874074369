* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  @media (max-width: 2560px) {
    .pro{
       width: 100%;
       display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    box-shadow: 0px 0px 100px 0px #888888;
    }
    .proBox{
        height: 80vh;
        width: 100%;
        display: flex;
        background: white;
        border-radius: 35px;
        overflow: hidden;
         
    }
.proimg{
    width: 100%;
}
.proimg img{
    width: 81%;
    margin-top: 6%;
    margin-left: -5%;
}
.protb{
    width: 100%;
    margin-top: 5% ;
    margin-left: 6%;
}
.protb h1{
    width: 75%;
    font-size: 100px;
}
.protb p{
    width: 64%;
    font-size: 35px;
}
.protb form{
    margin-top: 0px;
}
.protb button{
    width: 60%;
    height: 100px;
    margin-top: 20px;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    font-size: 30px;
    cursor: pointer;
}
.protb input {
    width: 60%;
    height: 75px;
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    font-size: 35px;
}
.protb #list {
    border-radius: 10px;
    width: 60%;
    padding: 10px ;
    border-color: #E1E1E1;
    font-size: 14px;
    background-color:#F7F5FE  ;
    margin-top: 20px;
    height: 80px;
    font-size: 35px;
   }
   .union2{
    position: relative;
 }
 .union2 img{
    position: absolute;
    top: 100px;
    right: 100px;
    cursor: pointer;
    width: 100px;
 }
  }
  @media (max-width: 1920px) {
    .pro{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    box-shadow: 0px 0px 100px 0px #888888;
    }
    .proBox{
    height: 100vh;
    width: 100%;
    display: flex;
    background: white;
    border-radius: 35px;
    overflow: hidden;
         
    }
.proimg{
    width: 100%;
}
.proimg img{
    width: 84%;
    margin-top: 6%;
    margin-left: -5%;
}
.protb{
    width: 100%;
    margin-top: 5% ;
    margin-left: 6%;
}
.protb h1{
    width: 75%;
    font-size: 70px;
}
.protb p{
    width: 72%;
    font-size: 28px;
}
.protb form{
    margin-top: 0px;
}
.protb button{
    width: 60%;
    height: 60px;
    margin-top: 20px;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    font-size: 30px;
    cursor: pointer;
}
.protb input {
    width: 60%;
    height: 60px;
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    font-size: 28px;
}
.protb #list {
    border-radius: 10px;
    width: 60%;
    padding: 10px ;
    border-color: #E1E1E1;
    font-size: 14px;
    background-color:#F7F5FE  ;
    margin-top: 20px;
    height: 60px;
    font-size: 28px;
   }
   .union2{
    position: relative;
 }
 .union2 img{
    position: absolute;
    top: 100px;
    right: 100px;
    cursor: pointer;
    width: 70px;
 }
  }
  
  @media (max-width: 1440px) {
    .pro{
       width: 100%;
       display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    box-shadow: 0px 0px 50px 0px #888888;
    }
    .proBox{
        height: 80vh;
        width: 100%;
        display: flex;
        background: white;
        border-radius: 35px;
        overflow: hidden;
         
    }
.proimg{
    width: 100%;
}
.proimg img{
    width: 84%;
    margin-top: 6%;
    margin-left: -5%;
}
.protb{
    width: 100%;
    margin-top: 5% ;
    margin-left: 6%;
}
.protb h1{
    width: 75%;
    font-size: 70px;
}
.protb p{
    width: 64%;
    font-size: 18px;
}
.protb form{
    margin-top: 0px;
}
.protb button{
    width: 60%;
    height: 60px;
    margin-top: 10px;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    font-size: 20px;
}
.protb input {
    width: 60%;
    height: 35px;
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    font-size: 14px;
}
.protb #list {
    border-radius: 7px;
    width: 60%;
    padding: 10px ;
    border-color: #E1E1E1;
    font-size: 14px;
    background-color:#F7F5FE  ;
    margin-top: 10px;
    height: 40px;
    font-size: 18px;
   }
   .union2{
    position: relative;
 }
 .union2 img{
    position: absolute;
    top: 50px;
    right: 50px;
    cursor: pointer;
    width: 40px;
 }
  }
  
  @media (max-width: 1024px) {
.pro{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    box-shadow: 0px 0px 40px 0px #888888;
    }
.proBox{
    height: 80vh;
    width: 100%;
    display: flex;
    background: white;
    border-radius: 35px;
    overflow: hidden;
         
}
.proimg{
    width: 100%;
}
.proimg img{
    width: 100%;
    margin-top: 6%;
    margin-left: -5%;
}
.protb{
    width: 100%;
    margin-top: 12% ;
    margin-left: 6%;
}
.protb h1{
    width: 75%;
    font-size: 40px;
}
.protb p{
    width: 75%;
    font-size: 14px;
}
.protb form{
    margin-top: 0px;
}
.protb button{
    width: 60%;
    height: 40px;
    margin-top: 10px;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    font-size: 20px;
}
.protb input {
    width: 60%;
    height: 35px;
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    font-size: 14px;
}
.protb #list {
    border-radius: 7px;
    width: 60%;
    padding: 4px ;
    border-color: #E1E1E1;
    font-size: 14px;
    background-color:#F7F5FE  ;
    margin-top: 10px;
   }
  }

  @media (max-width: 768px) {
    .pro{   
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    box-shadow: 0px 0px 30px 0px #888888;
    }
    .proBox{
        height: 80vh;
        width: 100%;
        display: flex;
        background: white;
        border-radius: 35px;
        overflow: hidden;
         
    }
.proimg{
    width: 100%;
}
.proimg img{
    width: 130%;
    margin-top: 25%;
    margin-left: -18%;
}
.protb{
    width: 100%;
    margin-top: 12% ;
    margin-left: 6%;
}
.protb h1{
    width: 75%;
    font-size: 40px;
}
.protb p{
    width: 96%;
    font-size: 14px;
}
.protb form{
    margin-top: 0px;
}
.protb button{
    width: 60%;
    height: 40px;
    margin-top: 10px;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
}
.protb input {
    width: 60%;
    height: 35px;
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
}
.protb #list {
    border-radius: 7px;
    width: 60%;
    padding: 4px ;
    border-color: #E1E1E1;
    font-size: 14px;
    background-color:#F7F5FE  ;
    margin-top: 10px;
   }
   .union2{
    position: relative;
 }
 .union2 img{
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    width: 20px;
 }
 .union2{
    position: relative;
 }
 .union2 img{
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    width: 30px;
 }
  }
  @media (max-width: 425px) {
    .pro{
       width: 100%;
       z-index: 1;
       display: flex;
       justify-content: center;
       align-items: start;
       padding: 0 0px;
       border-radius: 35px;
       box-shadow: 0px 0px 0px 0px #888888;
       top: 17.5%;
       
    }
    .proBox{
        width: 100%;
        height: 65vh;
        display: flex;
        background: white;
        border-radius: 35px;
        box-shadow: 0px 0px 10px 0px #888888;
         
    }
.proimg{
    display: none;
    width: 100%;
}
.proimg img{
    width: 100%;
    margin-top: 10%;
    margin-left: 10%;
    display: block;
}
.protb{
    width: 100%;
    margin-top: 10% ;
    margin-left: 6%;
}
.protb h1{
    width: 57%;
    font-size: 30px;
}
.protb p{
    width: 78%;
    font-size: 14px;
}
.protb button{
    width: 90%;
    height: 40px;
    margin-top: 20px;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
}
.protb form {
    margin-top: 0px;
}
.protb input {
    width: 90%;
    height: 35px;
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
}
.protb #list {
    border-radius: 7px;
    width: 90%;
    padding: 4px ;
    border-color: #E1E1E1;
    font-size: 14px;
    background-color:#F7F5FE  ;
    margin-top: 10px;
   }
   .union2{
    position: relative;
 }
 .union2 img{
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    width: 10px;
 }
  }
  @media (max-width: 390px) {
    .pro{
      
       width: 100%;
       display: flex;
       justify-content: center;
       align-items: start;
       padding: 0 0px;
       top: 17.5%;
    }
    .proBox{
        width: 100%;
        height: 77vh;
        display: flex;
        background: white;
        border-radius: 35px;
       
         
    }
.proimg{
    display: none;
    width: 100%;
}
.proimg img{
    width: 100%;
    margin-top: 10%;
    margin-left: 10%;
    display: block;
}
.protb{
    width: 100%;
    margin-top: 10% ;
    margin-left: 6%;
}
.protb h1{
    width: 63%;
    font-size: 30px;
}
.protb p{
    width: 94%;
    font-size: 14px;
}
.protb button{
    width: 90%;
    height: 40px;
    margin-top: 20px;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
}
.protb input {
    width: 90%;
    height: 35px;
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
}
.protb #list {
    border-radius: 7px;
    width: 90%;
    padding: 4px ;
    border-color: #E1E1E1;
    font-size: 14px;
    background-color:#F7F5FE  ;
    margin-top: 10px;
   }

  }
  @media (max-width: 320px) {
    .pro{
       
       width: 100%;
       display: flex;
       justify-content: center;
       align-items: start;
       padding: 0 0px;
       top: 17.5%;
    }
    .proBox{
        width: 100%;
        height: 77vh;
        display: flex;
        background: white;
        border-radius: 35px;
       
         
    }
.proimg{
    display: none;
    width: 100%;
}
.proimg img{
    width: 100%;
    margin-top: 10%;
    margin-left: 10%;
    display: block;
}
.protb{
    width: 100%;
    margin-top: 10% ;
    margin-left: 6%;
}
.protb h1{
    width: 75%;
    font-size: 30px;
}
.protb p{
    width: 75%;
    font-size: 14px;
}
.protb button{
    width: 90%;
    height: 40px;
    margin-top: 20px;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
}
.protb input {
    width: 90%;
    height: 35px;
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
}
.protb #list {
    border-radius: 7px;
    width: 90%;
    padding: 4px ;
    border-color: #E1E1E1;
    font-size: 14px;
    background-color:#F7F5FE  ;
    margin-top: 10px;
   }
   .union2{
    position: relative;
 }
 .union2 img{
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    width: 10px;
 }
  }