
.cardok4 p {
    cursor: pointer;
}
@media (max-width: 2560px) {
  
    .cardok{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    height: 100vh;
    padding-top: 100px;
}

.cardok1{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 70vh;
    width: 80%;
    justify-content: center;
    background: white;
    box-shadow: 0px 4px 28px  #888888;
}
.cardok2{
    display: flex;
    flex-direction: column;
    background:#F9F9F9;
    height: 30vh;
    width: 80%;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
.cardok3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cardok3 h2{
    color: #D90D00;
    font-size: 32px;
}
.cardok3 img{
    width: 25%;
}
.cardok3 span{
    width: 100%;
    font-size: 28px;
}
.cardok4{
    display: flex;
    justify-content: center;
}
.cardok4 p{
    font-size: 40px !important;
    font-weight: 500;
    font-family: auto;
    color: #5231e4;
    padding-top: 160px;
}
}
@media (max-width: 1920px) {
  
    .cardok{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    height: 100vh;
}

.cardok1{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 70vh;
    width: 80%;
    justify-content: center;
    background: white;
    box-shadow: 0px 4px 28px  #888888;
}
.cardok2{
    display: flex;
    flex-direction: column;
    background:#F9F9F9;
    height: 30vh;
    width: 80%;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
.cardok3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cardok3 h2{
    color: #D90D00;
}
.cardok3 img{
    width: 25%;
}
.cardok3 span{
    width: 100%;
    font-size: 18px;
}
.cardok4{
    display: flex;
    justify-content: center;
}
.cardok4 p{
    font-size: 24px !important;
    font-weight: 500;
    font-family: auto;
    color: #5231e4;
    padding-top: 160px;
}
}

@media (max-width: 1440px) {
  
    .cardok{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    height: 100vh;
}

.cardok1{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 70vh;
    width: 80%;
    justify-content: center;
    background: white;
    box-shadow: 0px 4px 28px  #888888;
}
.cardok2{
    display: flex;
    flex-direction: column;
    background:#F9F9F9;
    height: 30vh;
    width: 80%;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
.cardok3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cardok3 h2{
    color: #D90D00;
}
.cardok3 img{
    width: 25%;
}
.cardok3 span{
    width: 100%;
    font-size: 18px;
}
.cardok4{
    display: flex;
    justify-content: center;
}
.cardok4 p{
    font-size: 24px !important;
    font-weight: 500;
    font-family: auto;
    color: #5231e4;
    padding-top: 160px;
}
}
@media (max-width: 1366px) {
  
    .cardok{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    height: 100vh;
}

.cardok1{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 70vh;
    width: 80%;
    justify-content: center;
    background: white;
    box-shadow: 0px 4px 28px  #888888;
}
.cardok2{
    display: flex;
    flex-direction: column;
    background:#F9F9F9;
    height: 30vh;
    width: 80%;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
.cardok3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cardok3 h2{
    color: #D90D00;
}
.cardok3 img{
    width: 25%;
}
.cardok3 span{
    width: 100%;
    font-size: 18px;
}
.cardok4{
    display: flex;
    justify-content: center;
}
.cardok4 p{
    font-size: 24px !important;
    font-weight: 500;
    font-family: auto;
    color: #5231e4;
    padding-top: 160px;
}
}
@media (max-width: 1024px) {
  
    .cardok{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    height: 100vh;
}

.cardok1{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 70vh;
    width: 80%;
    justify-content: center;
    background: white;
    box-shadow: 0px 4px 28px  #888888;
}
.cardok2{
    display: flex;
    flex-direction: column;
    background:#F9F9F9;
    height: 30vh;
    width: 80%;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
.cardok3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cardok3 h2{
    color: #D90D00;
}
.cardok3 img{
    width: 25%;
}
.cardok3 span{
    width: 100%;
}
.cardok4{
    display: flex;
    justify-content: center;
}
.cardok4 p{
    font-size: 24px !important;
    font-weight: 500;
    font-family: auto;
    color: #5231e4;
    padding-top: 160px;
}
}
@media (max-width: 768px) {
  
    .cardok{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    height: 100vh;
}

.cardok1{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 70vh;
    width: 80%;
    justify-content: center;
    background: white;
    box-shadow: 0px 4px 28px  #888888;
}
.cardok2{
    display: flex;
    flex-direction: column;
    background:#F9F9F9;
    height: 30vh;
    width: 80%;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
.cardok3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cardok3 h2{
    color: #D90D00;
}
.cardok3 img{
    width: 25%;
}
.cardok3 span{
    width: 100%;
    font-size: 14px;
}
.cardok4{
    display: flex;
    justify-content: center;
}
.cardok4 p{
    font-size: 24px !important;
    font-weight: 500;
    font-family: auto;
    color: #5231e4;
    padding-top: 160px;
}
}
@media (max-width: 425px) {
  
    .cardok{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    height: 100vh;
}

.cardok1{
    display: flex;
    align-items: center;
    height: 45vh;
    width: 80%;
    justify-content: center;
    background: white;
    box-shadow: 0px 4px 28px  #888888;
}
.cardok2{
    display: flex;
    flex-direction: column;
    background: none;
    height: 44vh;
    width: 80%;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}
.cardok3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cardok3 h2{
    color: #D90D00;
}
.cardok3 img{
    width: 40%;
}
.cardok3 span{
    width: 100%;
}
.cardok4{
    height: 10%;
}
}
@media (max-width: 320px) {
  
    .cardok{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    height: 100vh;
}

.cardok1{
    display: flex;
    align-items: center;
    height: 45vh;
    width: 80%;
    justify-content: center;
    background: white;
    box-shadow: 0px 4px 28px  #888888;
}
.cardok2{
    display: flex;
    flex-direction: column;
    background: none;
    height: 44vh;
    width: 80%;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}
.cardok3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cardok3 h2{
    color: #D90D00;
}
.cardok3 img{
    width: 40%;
}
.cardok3 span{
    width: 100%;
}
}
