* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
  @media (max-width: 2560px) {
    .registration{
        padding: 445px 830px;
    }
    .registrationBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 36px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 60px;
    margin: 0 0 20px 0;
    }
    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
    }
    .registrationBox2{
        font-size: 28px;
    }
    .registrationBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    font-size: 40px;
    }
    .registrationBox2im{
        display: flex;
        margin-top: 27px;
    }
    .registrationBox21{
        width: 50%;
        font-size: 28px;
    }
    .registrationBox22{
        width: 50%;
        font-size: 28px;
    }
    .registrationBox2im input{
        width: 95%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 28px;
    }
    .registrationBox3{
        margin-top: 27px;
        font-size: 28px;
    }
    .registrationBox3 input{
        width: 98%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 28px;
    
    }
    .registrationBox4{
        display: block;
        margin-top: 27px;
    }
    .registrationBox4 p{
        width: 100%;
    }
    .registrationBox4{
        
        font-size: 28px;
    }
    .registrationBox4 input{
        width: 98%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 28px;
    }
    .registrationBox5 input{
        width: 95%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 28px;
    }
    .registrationBox5{
        display: flex;
        margin-top: 27px;
    }
    .registrationBox51{
        width: 50%;
        font-size: 28px;
    }
    .registrationBox52{
        width: 50%;
        font-size: 28px;
    }
    .registrationBox52 .label{
        width: 120%;
    }
    .registrationBox6{
        
        font-size: 28px;
    }
    .registrationBox6 input{
        width: 98%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 28px;
        
    }
    .registrationBox6{
        margin-top: 27px;
    }
  
    .registrationButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 60px;
        margin: 27px 0 17px 0;
        cursor: pointer;
    }
    .registrationButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
    }

    .registration img{
        top: 270px;
        left: 1500px;
        width: 40%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        position: fixed;
    }
    .acc_org_name {font-size: 24px;}
    .acc_email{font-size: 24px;}
    .acc_pass{font-size: 24px;}
    .acc_pass_list{font-size: 24px;}
    .acc_phone{font-size: 24px;}
    .acc_address{font-size: 24px;}
    

    
   
  }
  @media (max-width: 1920px) {
  
    .registration{
        padding: 195px 618px;
    }
    .registrationBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 28px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 60px;
    margin: 0 0 20px 0;
    }
    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
    }
    .registrationBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    font-size: 40px;
    }
    .registrationBox2im{
        display: flex;
    }
    .registrationBox2im input{
        width: 95%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 24px;
    }
    .registrationBox3 input{
        width: 98%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 24px;
    
    }
    .registrationBox4{
        display: block;
    }
    .registrationBox4 p{
        width: 100%;
    }
    .registrationBox4 input{
        width: 98%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 24px;
    }
    .registrationBox5 input{
        width: 95%;
        padding-left: 20px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 40px;
        font-size: 24px;
    }
    .registrationButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 60px;
    }
    .registrationButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    width: 100%;
    }

    .registration img{
        top: 200px;
        left: 1220px;
        width: 30%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        position: fixed;
    }
    .acc_contact_name {font-size: 20px;}
    .acc_org_name {font-size: 20px;}
    .acc_email{font-size: 20px;}
    .acc_pass{font-size: 20px;}
    .acc_pass_list{font-size: 20px;}
    .acc_phone{font-size: 20px;}
    .acc_address{font-size: 20px;}
    
   
  }
  @media (max-width: 1440px) {
    .registration{
        padding: 150px 450px;
    }
    .registrationBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 20px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 30px;
    margin: 0 0 20px 0;
    }
    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
    }
    .registrationBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    font-size: 24px;
    }
    .registrationBox2im{
        display: flex;
        margin-top: 27px;
    }
    .registrationBox2 {
        font-size: 16px;
    }
    .registrationBox21{
        width: 50%;
        font-size: 16px;
    }
    .registrationBox22{
        width: 50%;
        font-size: 16px;
    }
    .registrationBox2im input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 18px;
    }
    .registrationBox3{
        margin-top: 27px;
        font-size: 18px;
    }
    .registrationBox3 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 18px;
       
    }
    .registrationBox4{
        display: block;
        margin-top: 27px;
        font-size: 18px;
    }
    .registrationBox4 p{
        width: 100%;
    }
    .registrationBox4 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 18px;
    }
    .registrationBox5{
        margin-top: 27px;
    }
    .registrationBox51{
        width: 50%;
        font-size: 16px;
    }
    .registrationBox52{
        width: 50%;
        font-size: 16px;
    }
    .registrationBox5 input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 18px;
        
    }
    .registrationBox6 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 18px;
        
    }
    .registrationBox6{
        margin-top: 27px;
        font-size: 18px;
    }
    .registrationButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 30px;
    }
    .registrationButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 17px 0 0 0px;
    width: 100%;
    }

    .registration img{
        position: fixed;
        top: 224px;
        left: 870px;
        width: 35%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
    }
    .acc_org_name {font-size: 14px;}
    .acc_email{font-size: 14px;}
    .acc_pass{font-size: 14px;}
    .acc_pass_list{font-size: 14px;}
    .acc_phone{font-size: 14px;}
    .acc_address{font-size: 14px;}

    
   
  }
  @media (max-width: 1366px) {
   
    .registration{
        padding: 164px 420px;
    }
    .registrationBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 20px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 30px;
    margin: 0 0 20px 0;
    }
    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
    }
    .registrationBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    }
    .registrationBox2im{
        display: flex;
    }
    .registrationBox2im input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        
    }
    
    .registrationBox3 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationBox4{
        display: block;
    }
    .registrationBox4 p{
        width: 100%;
    }
    .registrationBox4 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationBox5 input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 30px;
    }
    .registrationButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 17px 0 0 0px;
    width: 100%;
    }

    .registration img{
        position: fixed;
        top: 208px;
        left: 840px;
        width: 35%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
    }

    
   
  }
  @media (max-width: 1024px) {
 
    .registration{
        padding: 133px 300px;
    }
    .registrationBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 30px;
    margin: 0 0 20px 0;
    }
    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
    }
    .registrationBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    }
    .registrationBox2im{
        display: flex;
    }
    .registrationBox2im input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 14px;
    }
    .registrationBox3 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 16px;
    }
    .registrationBox4{
        display: block;
    }
    .registrationBox4 p{
        width: 100%;
    }
    .registrationBox4 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 14x;
    }
    .registrationBox5 input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 14px;
    }
    .registrationButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 30px;
    }
    .registrationButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
   
    width: 100%;
    }

    .registration img{
        position: fixed;
        top: 222px;
        left: 610px;
        width: 40%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
    }

    .acc_contact_name{
        font-size: 12px;
    }
    .acc_org_name {font-size: 12px;}
    .acc_email{font-size: 12px;}
    .acc_pass{font-size: 12px;}
    .acc_pass_list{font-size: 12px;}
    .acc_phone{font-size: 12px;}
    .acc_address{font-size: 12px;}
    
   
  }
  @media (max-width: 768px) {

    .registration{
        padding: 85px 200px;
    }
    .registrationBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 30px;
    margin: 0 0 20px 0;
    }
    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
    }
    .registrationBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    }
    .registrationBox2im{
        display: flex;
    }
    .registrationBox21{
        width: 50%;
        font-size: 14px;
    }
    .registrationBox22{
        width: 50%;
        font-size: 14px;
    }
    .registrationBox2im input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationBox3 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 14px;
        
    }
    .registrationBox3 label {
        font-size: 14px;
    }
    .registrationBox4{
        display: block;
    }
    .registrationBox4 p{
        width: 100%;
    }
    .registrationBox4 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 14px;
    }
    .registrationBox4 label {
        font-size: 14px;
    }

    .registrationBox5{
        display: flex;
    }
    .registrationBox51{
        width: 50%;
        font-size: 14px;
    }
    .registrationBox52{
        width: 50%;
        font-size: 14px;
    }
    .registrationBox52 .label{
        width: 120%;
    }
    .registrationBox5 input{
        width: 95%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationBox6 input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 30px;
    }
    .registrationButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 17px 0 0 0px;
    width: 100%;
    }

    .registration img{
        position: fixed;
        top: 370px;
        left: 460px;
        width: 40%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
    }
    .registrationBox6 label {
        font-size: 14px;
    }

    
   
  }
  @media (max-width: 425px) {
    
 
    .registration{
        padding: 70px 16px;
    }
    .registrationBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 30px;
    margin: 0 0 20px 0;
    }
    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
    }
    .registrationBox2im{
        display: flex;
        align-items: baseline;
    }
    .registrationBox21{
        width: 50%;
    }
    .registrationBox22{
        width: 50%;
    }
    .registrationBox21{
        width: 100%;
    }
    .registrationBox22{
        width: 100%;
    }
    .registrationBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    }
    .registrationBox2im input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationBox3 label {
        font-size: 14px;
    }
    .registrationBox3 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationBox3 {
        margin-top: 12px;
        font-size: 14px;
    }
    .registrationBox4{
        display: block;
    }
    .registrationBox4 {
        margin-top: 12px;
    }
    .registrationBox4 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 14px;
    }
    .registrationBox4 label {
        font-size: 14px;
    }
    .registrationBox5{
        display: block;
    }
    .registrationBox5 {
        margin-top: 0px;
    }
    .registrationBox51{
       width: 100%;
       margin: 12px 0 12px 0px;
    }
    .registrationBox52{
        width: 100%;
    }
    .registrationBox5 input {
        width: 98%;
        padding-left: 10px;
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
    }
    .registrationBox6 {
        margin-top: 12px;
    }
    .registrationBox6 label {
        font-size: 14px;
    }
    .registrationBox6 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px;
        font-size: 14px;
    }

    .registrationButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 30px;
    }
    .registrationButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 17px 0 0 0px;
    width: 100%;
    }
    .registrationimg{
        display: none;
        position: absolute;
    }
   

    
   
  }
  @media (max-width: 390px) {
    .registration{
        padding: 70px 16px;
    }
    .registrationBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 30px;
    }
    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
    }
    .registrationBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    }
    .registrationBox2im input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationBox3 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationBox4 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 30px;
    }
    .registrationButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 17px 0 0 0px;
    width: 100%;
    }

    
   
  }
@media (max-width: 320px) {
    .registration{
        padding: 70px 16px;
    }
    .registrationBox1 button{
        align-items: center;
    background: #fff;
    border-radius: 10px;
    border-style: none;
    color: #0E082B;
    font-family: SF Pro Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 520;
    width: 50%;
    height: 30px;
    
    }
    .fon{
        background-color: #F9F9F9;
        border-radius: 35px;
        padding: 26px 30px;
    }
    .registrationBox2 h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    }
    .registrationBox2im input{
        width: 98%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationBox3 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationBox4 input{
        width: 100%;
        padding-left: 10px;   
        border: 1px solid #9581EE;
        border-radius: 4px;
        height: 30px
    }
    .registrationButon1 button{
        align-items: center;
        background: #5231e4;
        border-radius: 10px;
        border-style: none;
        color: #fff;
        display: flex;
        font-family: SF Pro Display;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        justify-content: center;
        margin: 45px 0 0;
        width: 100%;
        height: 30px;
    }
    .registrationButon2 button{
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    border-style: none;
    color: #000000;
    display: flex;
    font-family: SF Pro Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 17px 0 0 0px;
    width: 100%;
    }
   
   
  }
   #but1{
        text-decoration: underline #5231e4 solid 2px ;  
    }
    #but2{
        text-decoration: underline #E2E2E2 solid 2px ;  
    }
    .acc_contact_name{
        color: red;
        margin-top: 5px;
    }
    .acc_org_name{
        color: red;
        margin-top: 5px;
    }
    .acc_email{
        color: red;
        margin-top: 5px;
    }
    .acc_pass{
        color: red;
        margin-top: 5px;
    }
    .acc_pass_list{
        color: red;
        margin-top: 5px;
    }
    .acc_phone{
        color: red;
        margin-top: 5px;
    }
    .acc_address{
        color: red;
        margin-top: 5px;
    }
   
   
  




                      
                     
                      
                     