* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar-thumb  {
  color: #000;
  background: #807f7f;  
  border-radius: 5px;

}
::-webkit-scrollbar {
  width: 10px;
}


/* img {
  width: 0;
} */

.homeBox3 .list3 ul li {
  list-style-image: url(./home_img/Check.svg);
}
@media (max-width: 2560px) {
  .home {
    padding: 0 100px;
    overflow-y: hidden;
   
  }
  li {
    font-weight: 300;
  }
  h1 {
    font-weight: 700;
    font-size: 70px;
    color: #0e082b;
  }

  .homeBox1 {
    padding-top: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }

  .homBox1img img {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 80%;
    z-index: -1;
    top: -25px;
    left: 600px;
  }

  .homeBox1 .homBox1text h1 {
    padding: 140px 220px 0 0;
    width: 50%;
  }
  .homeBox1 .homBox1text {
    padding: 0 70px 0 0;
  }
  .homeBox1 .homBox1text p {
    width: 24%;
  }
  /* .homeBox1 .homBox1text h1 {
    font-weight: 700;
    font-size: 25px;
    color: #0e082b;
  }
  .homeBox1 .homBox1text p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
  } */
  .homeBox1 .homBox1button {
    margin: 360px 0px 300px 0;
    display: flex;
    width: 100%;
    justify-content: start;
    cursor: pointer;
    margin-left: -10px;
  }

  .homeBox1 .homBox1button a {
    font-size: 30px;
    padding: 24px 88px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff !important;
    background: #3CDBB7;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox1 .homBox1button a img {
    width: 23%;
    margin-left: 20px;
  }
  .homeBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
    height: auto;
  }
  .homeBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .homeBox2 .programss {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  .homeBox2 .programss img {
    width: 10%;
  }
  .homeBox2 p {
    width: 68%;
  }
  .homeBox2 h1 {
    width: 70%;
  }

  .homeBox2 .img2 {
    padding: 0px 0px;
  }
  .homeBox2 .img2 img {
    width: 150%;
  }
  .homeBox2 .homeBox2text {
    width: 57%;
  }

  .homeBox3 {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: 80px;
  }
  .homeBox3 .homeBox31 {
    width: 60%;
  }

  .homeBox3 .homeBox31 .homeBoxe3text p {
    width: 60%;
  }
  .homeBox3 .list3 {
    padding: 0 32px;
    overflow: hidden;
    height: auto;
  }
  .homeBox3 p {
    width: 68%;
  }
  .homeBox3 h1 {
    width: 80%;
  }
  .homeBox3 .list3 ul img {
    position: absolute;
    z-index: -1;
    left: 1000px;
    top: 0px;
    width: 50%;
  }
  .homeBox3 .list3 ul li {
    font-size: 30px;
    padding: 40px 0px 0px 10px;
    width: 30%;
  }
  .homeBox3 .button3 {
    padding-top: 120px;
    margin-bottom: 0px;
    cursor: pointer;
  }
  .homeBox3 .button3 a button {
    font-size: 30px;
    padding: 40px 120px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .homeBox4 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 0px 30px;
    flex-flow: row-reverse;
    display: flex;
    margin-top: 50px;
  }
  .homeBox4 .homeBox4tex {
    margin: 0 0 0 600px;
  }
  .homeBox4 .homeBox4tex h1 {
    width: 80%;
    margin-top: 70px;
  }
  .homeBox4 .homeBox4tex p {
    width: 62%;
  }
  .homeBox4 .image4 img {
    width: 200%;
    margin-left: 80%;
  }
  .homeBox5 {
    padding: 30px 0;
    margin-top: 50px;
  }
  .homeBox5 .homeBox51 {
    display: flex;
  }
  .homeBox5 p {
    padding: 0 0px 0 0;
    width: 60%;
  }
  .homeBox5 div {
    width: 100%;
    margin-top: 20px;
  }
  .homeBox5 .homeBox5h1 h1 {
    width: 45%;
  }
  .homeBox5 .homeBox5Step1 {
    display: table-caption;
    justify-content: center;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step2 {
    display: table-caption;
    justify-content: center;
    flex-flow: row-reverse;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step3 {
    display: table-caption;
    justify-content: center;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step11 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step22 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step33 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step4 {
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step5 {
    margin-left: 40px;
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step6 {
    margin-left: 22px;
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step1 img {
    border-radius: 30px;
    width: 90%;
  }
  .homeBox5 .homeBox5Step2 img {
    border-radius: 30px;
    width: 90%;
  }
  .homeBox5 .homeBox5Step3 img {
    border-radius: 30px;
    width: 90%;
  }

  .homeBox5 .button5 button {
    font-size: 30px;
    padding: 30px 100px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 1610px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }

  .homeBox6 .list6 ul {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    padding: 10px;
    padding-top: 60px;
  }
  .homeBox6 .list6 ul li {
    background: #f9f9f9;
    border-radius: 35px;
    display: flex;
    padding: 20px;
    display: flex;
    flex-direction: column;
    font-size: 30px;
    height: 8vh;
    transition: .9s;
    

  }
  #vector1{
    display: flex;
    height: 10vh;
    transition: .4s;
  
    
  }
  #vector1 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector1 img{
    transition: .4s;
    width: 3%;

  }
  #vector2{
    display: flex;
    height: 10vh;
    transition: .4s;
    
  }
  #vector2 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector2 img{
    transition: .4s;
    width: 3%;

  }
  #vector3{
    display: flex;
    height: 10vh;
    transition: .4s;
  }
  #vector3 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector3 img{
    transition: .4s;
    width: 3%;

  }
  #vector4{
    display: flex;
    height: 10vh;
    transition: .4s;
    
  }
  #vector4 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector4 img{
    transition: .4s;
    width: 3%;

  }
  #vector5{
    display: flex;
    height: 10vh;
    transition: .4s;
    
  }
  #vector5 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector5 img{
    transition: .4s;
    width: 3%;

  }
  #vector6{
    display: flex;
    height: 10vh;
    transition: .4s;
    
  }
  #vector6 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector6 img{
    transition: .4s;
    width: 3%;

  }
  .homeBox6 .homeBox6text h1 {
    margin-top: 30px; 
}
  
 
  .homeBox6 .list6 .text1{
    display: none;
    font-size: 35px;
  }
  .homeBox6 .list6 .text2{
    display: none;
    font-size: 35px;
  }
  .homeBox6 .list6 .text3{
    display: none;
    font-size: 35px;
  }
  .homeBox6 .list6 .text4{
    display: none;
    font-size: 35px;
  }
  .homeBox6 .list6 .text5{
    display: none;
    font-size: 35px;
  }.homeBox6 .list6 .text6{
    display: none;
    font-size: 35px;
  }
  .homeBox6 .homeBox6text p {
    width: 60%;
  }
  .homeBox6 .list6 .list61 {
    /* display: none; */
    font-size: 28px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list62 {
    /* display: none; */
    font-size: 28px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list63 {
    /* display: none; */
    font-size: 28px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list64 {
    /* display: none; */
    font-size: 28px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list65 {
    /* display: none; */
    font-size: 28px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list66 {
    /* display: none; */
    font-size: 28px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .vector6 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector5 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector4 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector3 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector2 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector1 {
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 1920px) {
  .home {
    padding: 0 80px;
  }
  h1 {
    font-weight: 700;
    font-size: 60px ; 
    color: #0e082b;
  }

 
  .homeBox1 {
    padding-top: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }

  .homBox1img img {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 80%;
    z-index: -1;
    top: -25px;
    left: 550px;
  }

  .homeBox1 .homBox1text h1 {
    padding: 0 220px 0 0;
    width: 62%;
  }
  .homeBox1 .homBox1text {
    padding: 50px 70px 0 0;
  }
  .homeBox1 .homBox1text p {
    width: 31%;
  }
  /* .homeBox1 .homBox1text h1 {
    font-weight: 700;
    font-size: 25px;
    color: #0e082b;
  }
  .homeBox1 .homBox1text p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
  } */
  .homeBox1 .homBox1button {
    margin: 200px 0px 320px 0;
    display: flex;
    width: 100%;
    justify-content: start;
    cursor: pointer;
    margin-left: -10px;
  }

  .homeBox1 .homBox1button a {
    font-size: 30px;
    padding: 24px 70px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff !important;
    background: #3CDBB7;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox1 .homBox1button a img {
    width: 23%;
    margin-left: 20px;
  }
  .homeBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
  }
  .homeBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .homeBox2 .programss {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  .homeBox2 .programss img {
    width: 10%;
  }
  .homeBox2 h1 {
    width: 60%;
  }
  .homeBox2 p {
    width: 76%;
  }

  .homeBox2 .img2 {
    padding: 0px 0px;
  }
  .homeBox2 .img2 img {
    width: 100%;
  }
  .homeBox2 .homeBox2text {
    width: 57%;
  }

  .homeBox3 {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .homeBox3 .homeBox31 {
    width: 60%;
  }

  .homeBox3 .homeBox31 .homeBoxe3text p {
    width: 60%;
  }
  .homeBox3 .homeBox31 .homeBoxe3text h1 {
    width: 100%;
  }
  .homeBox3 .list3 {
    padding: 0 32px;
    overflow: hidden;
    height: auto;
  }
  .homeBox3 .list3 ul img {
    position: absolute;
    z-index: -1;
    left: 650px;
    top: 40px;
    width: 55%;;
  }
  .homeBox3 .list3 ul li {
    font-size: 20px;
    padding: 40px 0px 0px 10px;
    width: 26%;
  }
  .homeBox3 .button3 {
    padding-top: 120px;
    margin-bottom:0px;
    cursor: pointer;
  }
  .homeBox3 .button3 a button {
    font-size: 30px;
    padding: 24px 100px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox4 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 0px 30px;
    flex-flow: row-reverse;
    display: flex;
  }
  .homeBox4 .homeBox4tex {
    margin: 0 0 0 600px;
  }
  .homeBox4 .homeBox4tex h1 {
    width: 104%;
    margin-top: 50px;
  }
  .homeBox4 .homeBox4tex p {
    width: 85%;
  }
  .homeBox4 .image4 img {
    width: 140%;
    margin-left: 80%;
  }
  .homeBox5 {
    padding: 30px 0;
  }
  .homeBox5 .homeBox51 {
    display: flex;
  }
  .homeBox5 p {
    padding: 0 0px 0 0;
    width: 69%;
  }
  .homeBox5 div {
    width: 90%;
  }
  .homeBox5 .homeBox5h1 h1 {
    width: 58%;
  }
  .homeBox5 .homeBox5Step1 {
    display: table-caption;
    justify-content: center;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step2 {
    display: table-caption;
    justify-content: center;
    flex-flow: row-reverse;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step3 {
    display: table-caption;
    justify-content: center;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step11 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step22 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step33 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step4 {
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step5 {
    margin-left: 40px;
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step6 {
    margin-left: 22px;
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step1 img {
    border-radius: 30px;
    width: 90%;
    background-color: #f9f9f9;
  }
  .homeBox5 .homeBox5Step2 img {
    border-radius: 30px;
    width: 90%;
    background-color: #f9f9f9;
  }
  .homeBox5 .homeBox5Step3 img {
    border-radius: 30px;
    width: 90%;
    background-color: #f9f9f9;
  }

  .homeBox5 .button5 button {
    font-size: 26px;
    padding: 20px 18px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 1080px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .homeBox6 .list6 ul {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    padding: 10px;
    margin-top: 20px;
  }
  .homeBox6 .list6 ul li {
    background: #f9f9f9;
    border-radius: 35px;
    display: flex;
    padding: 20px;
    display: flex;
    flex-direction: column;
    font-size: 30px;
    height: 10vh;
    transition: .9s;

  }
  #vector1{
    display: flex;
    height: auto;
    transition: .4s;
  
    
  }
  #vector1 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector1 img{
    transition: .4s;
    width: 3%;

  }
  #vector2{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector2 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector2 img{
    transition: .4s;
    width: 3%;

  }
  #vector3{
    display: flex;
    height: auto;
    transition: .4s;
  }
  #vector3 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector3 img{
    transition: .4s;
    width: 3%;

  }
  #vector4{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector4 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector4 img{
    transition: .4s;
    width: 3%;

  }
  #vector5{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector5 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector5 img{
    transition: .4s;
    width: 3%;

  }
  #vector6{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector6 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector6 img{
    transition: .4s;
    width: 3%;

  }
  
 
  .homeBox6 .list6 .text1{
    display: none;
    font-size: 28px;
  }
  .homeBox6 .list6 .text2{
    display: none;
    font-size: 28px;
  }
  .homeBox6 .list6 .text3{
    display: none;
    font-size: 28px;
  }
  .homeBox6 .list6 .text4{
    display: none;
    font-size: 28px;
  }
  .homeBox6 .list6 .text5{
    display: none;
    font-size: 28px;
  }.homeBox6 .list6 .text6{
    display: none;
    font-size: 28px;
  }
  .homeBox6 .homeBox6text p {
    width: 60%;
  }
  .homeBox6 .list6 .list61 {
    /* display: none; */
    font-size: 28px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list62 {
    /* display: none; */
    font-size: 28px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list63 {
    /* display: none; */
    font-size: 28px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list64 {
    /* display: none; */
    font-size: 28px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list65 {
    /* display: none; */
    font-size: 28px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list66 {
    /* display: none; */
    font-size: 28px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .vector6 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector5 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector4 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector3 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector2 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector1 {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 1440px) {
  .home {
    padding: 0 80px;
  }
  h1 {
    font-weight: 700;
    font-size: 50px ;
    color: #0e082b;
  }
  p {
    font-weight: 300;
    font-size: 20px !important ;
    color: #0e082b;
    margin-top: 23px;
  }
  .homeBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }

  .homBox1img img {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 80%;
    z-index: -1;
    top: -25px;
    left: 500px;
  }
  .homBox1text{
    position: relative;
    top: 30px;
  }
  .homeBox1 .homBox1text h1 {
    padding: 0 0px 0 0;
    width: 40%;
  }
  .homeBox1 .homBox1text {
    padding: 0 70px 0 0;
  }
  .homeBox1 .homBox1text p {
    width: 25%;
  }
  /* .homeBox1 .homBox1text h1 {
    font-weight: 700;
    font-size: 25px;
    color: #0e082b;
  }
  .homeBox1 .homBox1text p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
  } */
  .homeBox1 .homBox1button {
    margin: 200px 0px 200px 0;
    display: flex;
    width: 100%;
    justify-content: start;
  }

  .homeBox1 .homBox1button a {
    font-size: 14px;
    padding: 18px 60px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox1 .homBox1button a img {
    width: 23%;
    margin-left: 20px;
  }
  .homeBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
  }
  .homeBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .homeBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .homeBox2 h1 {
    width: 65%;
  }
  .homeBox2 p {
    width: 60%;
  }
  .homeBox2 .img2 {
    padding: 0px 0px 0 0;
  }
  .homeBox2 .img2 img {
    width: 100%;
  }
  .homeBox2 .homeBox2text {
    width: 70%;
  }

  .homeBox3 {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .homeBox3 .homeBox31 {
    width: 60%;
  }

  .homeBox3 .homeBox31 .homeBoxe3text p {
    width: 62%;
  }
  .homeBox3 .list3 {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .homeBox3 .list3 ul img {
    position: absolute;
    z-index: -1;
    left: 550px;
    top: 20px;
    width: 50%;
  }
  .homeBox3 .list3 ul li {
    font-size: 18px;
    padding: 20px 0px 0px 10px;
    width: 33%;
  }
  .homeBox3 .button3 {
    padding-top: 80px;
    margin-bottom: 0px;
  }
  .homeBox3 .button3 a button {
    font-size: 18px;
    padding: 12px 48px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox4 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 0px 30px;
    flex-flow: row-reverse;
    display: flex;
  }
  .homeBox4 .homeBox4tex {
    margin: 0 0 0 60px;
  }
  .homeBox4 .homeBox4tex h1 {
    width: 90%;
  }
  .homeBox4 .homeBox4tex p {
    width: 63%;
  }
  .homeBox4 .image4 img {
    width: 100%;
    margin-left: 5px;
  }
  .homeBox5 {
    padding: 30px 0;
  }
  .homeBox5 .homeBox51 {
    display: flex;
  }
  .homeBox5 p {
    padding: 0 0px 0 0;
    width: 71%;
  }
  .homeBox5 div {
    width: 90%;
  }
  .homeBox5 .homeBox5h1 h1 {
    width: 50%;
  }
  .homeBox5 .homeBox5Step1 {
    display: table-caption;
    justify-content: center;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step2 {
    display: table-caption;
    justify-content: center;
    flex-flow: row-reverse;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step3 {
    display: table-caption;
    justify-content: center;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step11 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step22 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step33 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step4 {
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step5 {
    margin-left: 40px;
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step6 {
    margin-left: 22px;
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step1 img {
    border-radius: 30px;
    width: 90%;
  }
  .homeBox5 .homeBox5Step2 img {
    border-radius: 30px;
    width: 90%;
  }
  .homeBox5 .homeBox5Step3 img {
    border-radius: 30px;
    width: 90%;
  }

  .homeBox5 .button5 button {
    font-size: 14px;
    padding: 12px 30px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox6 .list6 ul {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    padding: 10px;
    margin-top: 20px;
  }
  .homeBox6 .list6 ul li {
    background: #f9f9f9;
    border-radius: 35px;
    display: flex;
    padding: 20px;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    transition: .4s;
  }
  #vector1{
    display: flex;
    height: auto;
    transition: .4s;
    width: 100%;
    
  }
  #vector1 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector1 img{
    transition: .4s;

  }
  #vector2{
    display: flex;
    height: 14vh;
    transition: .4s;
    
  }
  #vector2 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector2 img{
    transition: .4s;

  }
  #vector3{
    display: flex;
    height: 14vh;
    transition: .4s;
    
  }
  #vector3 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector3 img{
    transition: .4s;

  }
  #vector4{
    display: flex;
    height: 14vh;
    transition: .4s;
    
  }
  #vector4 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector4 img{
    transition: .4s;

  }
  #vector5{
    display: flex;
    height: 14vh;
    transition: .4s;
    
  }
  #vector5 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector5 img{
    transition: .4s;

  }
  #vector6{
    display: flex;
    height: 14vh;
    transition: .4s;
    
  }
  #vector6 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector6 img{
    transition: .4s;

  }
  
 
  .homeBox6 .list6 .text1{
    display: none;
    font-size: 18px;
  }
  .homeBox6 .list6 .text2{
    display: none;
    font-size: 18px;
  }
  .homeBox6 .list6 .text3{
    display: none;
    font-size: 18px;
  }
  .homeBox6 .list6 .text4{
    display: none;
    font-size: 18px;
  }
  .homeBox6 .list6 .text5{
    display: none;
    font-size: 18px;
  }.homeBox6 .list6 .text6{
    display: none;
    font-size: 18px;
  }
  .homeBox6 .homeBox6text p {
    width: 60%;
  }
  .homeBox6 .list6 .list61 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list62 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list63 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list64 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list65 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list66 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .vector6 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector5 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector4 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector3 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector2 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector1 {
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 1366px) {
  .home {
    padding: 0 80px;
  }
  h1 {
    font-weight: 700;
    font-size: 50px;
    color: #0e082b;
  }
  p {
    font-weight: 300;
    font-size: 20px;
    color: #0e082b;
    margin-top: 23px;
  }
  .homeBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }

  .homBox1img img {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 80%;
    z-index: -1;
    top: -25px;
    left: 330px;
  }

  .homeBox1 .homBox1text h1 {
    padding: 0 220px 0 0;
    width: 60%;
  }
  .homeBox1 .homBox1text {
    padding: 0 70px 0 0;
  }
  .homeBox1 .homBox1text p {
    width: 25%;
  }
  /* .homeBox1 .homBox1text h1 {
    font-weight: 700;
    font-size: 25px;
    color: #0e082b;
  }
  .homeBox1 .homBox1text p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
  } */
  .homeBox1 .homBox1button {
    margin: 160px 0px 160px 0;
    display: flex;
    width: 100%;
    justify-content: start;
  }

  .homeBox1 .homBox1button a {
    font-size: 14px;
    padding: 10px 30px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox1 .homBox1button a img {
    width: 23%;
    margin-left: 20px;
  }
  .homeBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
    height: auto;
  }
  .homeBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .homeBox2 .programss {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  .homeBox2 p {
    width: 72%;
  }
  .homeBox2 h1 {
    width: 80%;
  }

  .homeBox2 .img2 {
    padding: 0px 0px;
  }
  .homeBox2 .img2 img {
    width: 100%;
  }
  .homeBox2 .homeBox2text {
    width: 57%;
  }

  .homeBox3 {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .homeBox3 .homeBox31 {
    width: 60%;
  }

  .homeBox3 .homeBox31 .homeBoxe3text p {
    width: 67%;
  }
  .homeBox3 .list3 {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .homeBox3 .list3 ul img {
    position: absolute;
    z-index: -1;
    left: 550px;
    top: 0px;
    width: 55%;
  }
  .homeBox3 .list3 ul li {
    font-size: 18px;
    padding: 15px 0px 0px 10px;
    width: 36%;
  }
  .homeBox3 .button3 {
    padding-top: 80px;
    margin-bottom: 0px;
  }
  .homeBox3 .button3 a button {
    font-size: 18px;
    padding: 12px 48px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox4 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 0px 30px;
    flex-flow: row-reverse;
    display: flex;
  }
  .homeBox4 .homeBox4tex {
    margin: 0 0 0 120px;
  }
  .homeBox4 .homeBox4tex h1 {
    width: 84%;
    margin-top: 30px;
  }
  .homeBox4 .homeBox4tex p {
    width: 65%;
  }
  .homeBox4 .image4 img {
    width: 100%;
    margin-left: 0;
  }
  .homeBox5 {
    padding: 30px 0;
  }
  .homeBox5 .homeBox51 {
    display: flex;
  }
  .homeBox5 p {
    
    width: 59%;
  }
  .homeBox5 div {
    width: 100%;
  }
  .homeBox5 .homeBox5h1 h1 {
    width: 46%;
  }
  .homeBox5 .homeBox5Step1 {
    display: table-caption;
    justify-content: center;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step2 {
    display: table-caption;
    justify-content: center;
    flex-flow: row-reverse;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step3 {
    display: table-caption;
    justify-content: center;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step11 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step22 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step33 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step4 {
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step5 {
    margin-left: 40px;
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step6 {
    margin-left: 22px;
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step1 img {
    border-radius: 30px;
    width: 90%;
  }
  .homeBox5 .homeBox5Step2 img {
    border-radius: 30px;
    width: 90%;
  }
  .homeBox5 .homeBox5Step3 img {
    border-radius: 30px;
    width: 90%;
  }

  .homeBox5 .button5 button {
    font-size: 14px;
    padding: 12px 14px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 780px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox6 .list6 ul {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    padding: 10px;
  }
  .homeBox6 .list6 ul li {
    background: #f9f9f9;
    border-radius: 35px;
    display: flex;
    padding: 20px;
    display: flex;
    flex-direction: column;
    font-size: 18px;
  }
  .homeBox6 .homeBox6text p {
    width: 64%;
  }
  .homeBox6 .list6 .list61 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list62 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list63 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list64 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list65 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list66 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .vector6 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector5 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector4 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector3 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector2 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector1 {
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 1024px) {
  .home {
    padding: 0 60px;
  }
  h1 {
    font-weight: 700;
    font-size: 30px;
    color: #0e082b;
    width: 100%;
  }
  p {
    font-weight: 300;
    font-size: 14px !important;
    color: #0e082b;
    margin-top: 23px;
  }
  .homeBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }

  .homBox1img img {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 90%;
    z-index: -1;
    top: -25px;
    left: 280px;
  }

  .homeBox1 .homBox1text h1 {
    padding: 0 20px 0 0;
    width: 47%;
  }
  .homeBox1 .homBox1text {
    padding: 0 70px 0 0;
  }
  .homeBox1 .homBox1text p {
    width: 25%;
  }
  /* .homeBox1 .homBox1text h1 {
    font-weight: 700;
    font-size: 25px;
    color: #0e082b;
  }
  .homeBox1 .homBox1text p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
  } */
  .homeBox1 .homBox1button {
    margin: 140px 0px 160px 0;
    display: flex;
    width: 100%;
    justify-content: start;
    margin-left: -10px;
  }

  .homeBox1 .homBox1button a {
    font-size: 14px;
    padding: 10px 20px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox1 .homBox1button a img {
    width: 23%;
    margin-left: 20px;
  }
  .homeBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
    height: auto;
  }
  .homeBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .homeBox2 .programss {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  .homeBox2 p {
    width: 75%;
  }

  .homeBox2 .img2 {
    padding: 0px 0px;
  }
  .homeBox2 .img2 img {
    width: 100%;
    top: 0px;
  }
  .homeBox2 .homeBox2text {
    width: 62%;
  }

  .homeBox3 {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .homeBox3 .homeBox31 {
    width: 60%;
  }

  .homeBox3 .homeBox3text p {
    padding: 0, 10px 0 0;
  }
  .homeBox3 .list3 {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .homeBox3 .list3 ul img {
    position: absolute;
    z-index: -1;
    left: 450px;
    top: 0px;
    width: 50%;
  }
  .homeBox3 .list3 ul li {
    font-size: 12px;
    padding: 10px 0px 0px 10px;
    width: 34%;
  }
  .homeBox3 .button3 {
    padding-top: 50px;
    margin-bottom: 30px;
  }
  .homeBox3 .button3 a button {
    font-size: 14px;
    padding: 12px 48px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox4 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 0px 30px;
    flex-flow: row-reverse;
    display: flex;
  }
  .homeBox4 .homeBox4tex {
    margin: 0 0 0 60px;
  }
  .homeBox4 .homeBox4tex p {
    width: 69%;
  }
  .homeBox4 .image4 img {
    width: 100%;
  }
  .homeBox5 {
    padding: 30px 0;
  }
  .homeBox5 .homeBox51 {
    display: flex;
  }
  .homeBox5 p {
    padding: 0 40px 0 0;
  }
  .homeBox5 div {
    width: 100%;
    margin-top: 10px;
  }
  .homeBox5 .homeBox5h1 h1 {
    width: 49%;
  }
  .homeBox5 p {
    width: 71%;
}
  .homeBox5 .homeBox5Step1 {
    display: table-caption;
    justify-content: center;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step2 {
    display: table-caption;
    justify-content: center;
    flex-flow: row-reverse;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step3 {
    display: table-caption;
    justify-content: center;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step11 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step22 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step33 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step4 {
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step5 {
    margin-left: 40px;
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step6 {
    margin-left: 22px;
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step1 img {
    border-radius: 30px;
    width: 90%;
  }
  .homeBox5 .homeBox5Step2 img {
    border-radius: 30px;
    width: 90%;
  }
  .homeBox5 .homeBox5Step3 img {
    border-radius: 30px;
    width: 90%;
  }

  .homeBox5 .button5 button {
    font-size: 14px;
    padding: 12px 35px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 665px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox6 .list6 ul {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    padding: 10px;
  }
  .homeBox6 .homeBox6text h1 {
    margin-top: 30px; 
}
  .homeBox6 .homeBox6text p {
    width: 65%;
}
  .homeBox6 .list6 ul li {
    background: #f9f9f9;
    border-radius: 35px;
    display: flex;
    padding: 20px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    transition: .4s;
  }
  #vector1{
    display: flex;
    height: auto;
    transition: .4s;
    width: 100%;
    
  }
  #vector1 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector1 img{
    transition: .4s;

  }
  #vector2{
    display: flex;
    height: 14vh;
    transition: .4s;
    
  }
  #vector2 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector2 img{
    transition: .4s;

  }
  #vector3{
    display: flex;
    height: 14vh;
    transition: .4s;
    
  }
  #vector3 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector3 img{
    transition: .4s;

  }
  #vector4{
    display: flex;
    height: 14vh;
    transition: .4s;
    
  }
  #vector4 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector4 img{
    transition: .4s;

  }
  #vector5{
    display: flex;
    height: 14vh;
    transition: .4s;
    
  }
  #vector5 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector5 img{
    transition: .4s;

  }
  #vector6{
    display: flex;
    height: 14vh;
    transition: .4s;
    
  }
  #vector6 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector6 img{
    transition: .4s;

  }
  
 
  .homeBox6 .list6 .text1{
    display: none;
    font-size: 14px;
  }
  .homeBox6 .list6 .text2{
    display: none;
    font-size: 14px;
  }
  .homeBox6 .list6 .text3{
    display: none;
    font-size: 14px;
  }
  .homeBox6 .list6 .text4{
    display: none;
    font-size: 14px;
  }
  .homeBox6 .list6 .text5{
    display: none;
    font-size: 14px;
  }.homeBox6 .list6 .text6{
    display: none;
    font-size: 14px;
  }
  .homeBox6 .list6 .list61 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list62 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list63 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list64 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list65 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list66 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .vector6 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector5 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector4 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector3 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector2 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector1 {
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 768px) {
  .home {
    padding: 0 40px;
  }

  h1 {
    font-weight: 700;
    font-size: 30px;
    color: #0e082b;
  }
  p {
    font-weight: 300;
    font-size: 14px !important;
    color: #0e082b;
    margin-top: 23px;
  }
  .homeBox1 {
    padding-top: 125 px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }

  .homBox1img img {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    z-index: -1;
    top: -25px;
    left: 198px;
  }

  .homeBox1 .homBox1text h1 {
    padding: 0 20px 0 0;
    width: 55%;
  }
  .homeBox1 .homBox1text {
    padding: 0 70px 0 0;
  }
  .homeBox1 .homBox1text p {
    width: 35%;
  }
  /* .homeBox1 .homBox1text h1 {
    font-weight: 700;
    font-size: 25px;
    color: #0e082b;
  }
  .homeBox1 .homBox1text p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
  } */
  .homeBox1 .homBox1button {
    margin: 100px 0px 100px 0;
    display: flex;
    width: 100%;
    justify-content: start;
    margin-left: -10px;
  }

  .homeBox1 .homBox1button a {
    font-size: 14px;
    padding: 6px 20px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox1 .homBox1button a img {
    width: 23%;
    margin-left: 20px;
  }
  .homeBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
  }
  .homeBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .homeBox2 .programss {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  .homeBox2 p {
    width: 100%;
  }

  .homeBox2 .img2 {
    padding: 0px 0;
  }
  .homeBox2 .img2 img {
    width: 100%;
  }
  .homeBox2 .homeBox2text {
    width: 57%;
  }

  .homeBox3 {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .homeBox3 .homeBox31 {
    width: 60%;
  }

  .homeBox3 .homeBox3text p {
    padding: 0, 10px 0 0;
  }
  .homeBox3 .list3 {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .homeBox3 .homeBoxe3text h1 {
    width: 100%;
  }
  .homeBox3 .homeBox31 .homeBoxe3text p {
    width: 82%;
  }
  .homeBox3 .list3 ul img {
    position: absolute;
    z-index: -1;
    left: 319px;
    top: 20px;
    width: 65%;
  }
  .homeBox3 .list3 ul li {
    font-size: 12px;
    padding: 20px 0px 0px 10px;
    width: 45%;
  }
  .homeBox3 .button3 {
    padding-top: 40px;
    margin-bottom: 0px;
  }
  .homeBox3 .button3 a button {
    font-size: 14px;
    padding: 12px 48px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox4 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 0px 30px;
    flex-flow: row-reverse;
    display: flex;
  }
  .homeBox4 .homeBox4tex {
    margin: 0 0 0 60px;
  }
  .homeBox4 .homeBox4tex h1 {
    width: 90%;
  }
  .homeBox4 .homeBox4tex p {
    width: 100%;
  }
  .homeBox4 .image4 img {
    width: 112%;
  }
  .homeBox5 {
    padding: 30px 0;
  }
  .homeBox5 .homeBox51 {
    display: flex;
  }
  .homeBox5 p {
    width: 94%;
  }
  .homeBox5 div {
    width: 100%;
  }
  .homeBox5 .homeBox5h1 h1 {
    width: 60%;
  }
  .homeBox5 .homeBox5Step1 {
    display: table-caption;
    justify-content: center;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step2 {
    display: table-caption;
    justify-content: center;
    flex-flow: row-reverse;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step3 {
    display: table-caption;
    justify-content: center;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step11 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step22 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step33 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step4 {
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step5 {
    margin-left: 40px;
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step6 {
    margin-left: 22px;
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step1 img {
    border-radius: 30px;
    width: 90%;
  }
  .homeBox5 .homeBox5Step2 img {
    border-radius: 30px;
    width: 90%;
  }
  .homeBox5 .homeBox5Step3 img {
    border-radius: 30px;
    width: 90%;
  }

  .homeBox5 .button5 button {
    font-size: 14px;
    padding: 12px 14px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 515px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox6 .homeBox6text p {
    width: 80%;
  }
  .homeBox6 .list6 ul {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    padding: 10px;
  }
  .homeBox6 .list6 ul li {
    background: #f9f9f9;
    border-radius: 35px;
    display: flex;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  .homeBox6 .list6 .list61 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list62 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list63 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list64 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list65 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list66 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .vector6 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector5 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector4 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector3 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector2 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector1 {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 425px) {
  .home {
    padding: 0 16px;
  }
  h1 {
    font-weight: 700;
    font-size: 33px;
    color: #0e082b;
  }
  p {
    font-weight: 300;
    font-size: 14px !important;
    color: #0e082b;
    margin-top: 23px;
  }
  .homeBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }

  .homBox1img img {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 111%;
    z-index: -1;
    top: 220px;
    left: 10px;
  }
  .homBox1text{
    position: relative;
    top: 0;
  }

  .homeBox1 .homBox1text h1 {
    padding: 0 20px 0 0;
    width: 78%;
  }
  .homeBox1 .homBox1text {
    padding: 0 70px 0 0;
  }
  .homeBox1 .homBox1text p {
    width: 85%;
  }
  /* .homeBox1 .homBox1text h1 {
    font-weight: 700;
    font-size: 25px;
    color: #0e082b;
  }
  .homeBox1 .homBox1text p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
  } */
  .homeBox1 .homBox1button {
    margin: 250px 0px 44px 0;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .homeBox1 .homBox1button a {
    font-size: 12px;
    padding: 12px 40px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff !important;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox1 .homBox1button a img {
    width: 23%;
    margin-left: 20px;
  }
  .homeBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
  }
  .homeBox2 .homeBox2text h1 {
    width: 82%;
  }
  .homeBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .homeBox2 .programss {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  .homeBox2 p {
    width: 80%;
  }

  .homeBox2 .img2 {
    padding: 30px 0;
  }
  .homeBox2 .img2 img {
    width: 100%;
  }
  .homeBox2 .homeBox2text {
    width: 100%;
  }

  .homeBox3 {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: 40px;
  }
  .homeBox3 .homeBox31 {
    width: 100%;
  }
  .homeBox3 .homeBoxe3text h1 {
    width: 70%;
  }

  .homeBox3 .homeBox31 .homeBoxe3text p {
    width: 66%;
  }
  .homeBox3 .list3 {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .homeBox3 .list3 ul img {
    position: absolute;
    z-index: -1;
    left: 180px;
    top: 20px;
    width: 150%;
  }
  .homeBox3 .list3 ul li {
    font-size: 12px;
    padding: 20px 0px 0px 0px;
    width: 42%;
  }
  .homeBox3 .button3 {
    padding-top: 110px;
    margin-bottom: 0px;
  }
  .homeBox3 .button3 a button {
    font-size: 14px;
    padding: 16px 60px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox4 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
  }
  .homeBox4 .homeBox4tex {
    margin: 0 0 0 0px;
  }
  .homeBox4 .homeBox4tex h1 {
    width: 66%;
    margin-top: 10px;
}
  .homeBox4 .homeBox4tex p {
    width: 81%;   
   position: relative;
    
  }
  .homeBox4 .image4 img {
    width: 100%;
    position: relative;
    top: 34px;
  }
  .homeBox5 {
    padding: 0px 0;
  }
  .homeBox5 .homeBox5h1 h1 {
    width: 77%;
  }
  .homeBox5 p {
    width: 73%;
}
  .homeBox5 .homeBox51 {
    display: block;
  }
  .homeBox5 p {
    padding: 0 25px 0 0;
  }
  .homeBox5 div {
    width: 100%;
  }
  .homeBox5 .homeBox5Step1 {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step2 {
    display: flex;
    justify-content: center;
    flex-flow: row-reverse;
    padding-top: 10px;
  }
  .homeBox5 .homeBox5Step3 {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step11 {
    
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step22 {
   
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step33 {
    
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step4 {
    margin-top: 40px;
  }
  .homeBox5 .homeBox5Step5 {
    margin-left: 40px;
  }
  .homeBox5 .homeBox5Step6 {
    margin-left: 22px;
  }
  .homeBox5 .homeBox5Step1 img {
    border-radius: 30px;
    width: 100%;
  }
  .homeBox5 .homeBox5Step2 img {
    border-radius: 30px;
    width: 100%;
  }
  .homeBox5 .homeBox5Step3 img {
    border-radius: 30px;
    width: 90%;
  }

  .homeBox5 .button5 button {
    font-size: 14px;
    padding: 12px 35px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 10px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox6 .homeBox6text p {
    width: 73%;
  }
  .homeBox6 .homeBox6text h1 {
    width: 100%;
    margin-top: 30px;
  }
  .homeBox6 .list6 ul {
    width: 100%;
    display: block;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    padding: 10px;
  }

  .homeBox6P {
    width: 56%;
  }
  .homeBox6 .list6 ul li {
    background: #f9f9f9;
    border-radius: 35px;
    display: flex;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 8vh;
    transition: .4s;
    margin-top: 10px
  }
  #vector1{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector1 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector1 img{
    transition: .4s;

  }
  #vector2{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector2 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector2 img{
    transition: .4s;

  }
  #vector3{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector3 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector3 img{
    transition: .4s;

  }
  #vector4{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector4 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector4 img{
    transition: .4s;

  }
  #vector5{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector5 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector5 img{
    transition: .4s;

  }
  #vector6{
    display: flex;
    height: auto;
    transition: .9s;
    
  }
  #vector6 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector6 img{
    transition: .4s;

  }
  
 
  .homeBox6 .list6 .text1{
    display: none;
  }
  .homeBox6 .list6 .text2{
    display: none;
  }
  .homeBox6 .list6 .text3{
    display: none;
  }
  .homeBox6 .list6 .text4{
    display: none;
  }
  .homeBox6 .list6 .text5{
    display: none;
  }.homeBox6 .list6 .text6{
    display: none;
  }
  .homeBox6 .list6 .list61 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list62 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list63 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list64 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list65 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list66 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .vector6 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector5 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector4 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector3 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector2 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector1 {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 390px) {
  h1 {
    font-weight: 700;
    font-size: 28px;
    color: #0e082b;
    width: 100%;
  }
  p {
    font-weight: 300;
    font-size: 14px !important;
    color: #0e082b;
    margin-top: 23px;
  }
  .homeBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }

  .homBox1img img {
    background-size: cover;
    position: absolute;
    width: 120%;
    z-index: -1;
    top: 235px;
    left: -10px;
  }
  .homeBox1 .homBox1text h1 {
    padding: 0 0px 0 0;
    width: 68%;
  }
  .homeBox1 .homBox1text {
    padding: 0 0px 0 0;
  }
  .homeBox1 .homBox1text p {
    width: 80%;
  }
  /* .homeBox1 .homBox1text h1 {
    font-weight: 700;
    font-size: 25px;
    color: #0e082b;
  }
  .homeBox1 .homBox1text p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
  } */
  .homeBox1 .homBox1button {
    margin:250px 0px 50px 0;
    display: flex;
    width: 100%;
    justify-content: center;
    height: 50px;
  }

  .homeBox1 .homBox1button a {
    font-size: 14px;
    padding: 12px 35px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox1 .homBox1button a img {
    width: 23%;
    margin-left: 20px;
  }
  .homeBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
  }
  .homeBox2 .homeBox2text h1 {
    width: 100%;
  }
  .homeBox2 p {
    width: 93%;
}
  .homeBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .homeBox2 .programss {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .homeBox2 .img2 {
    padding: 0px 0;
  }
  .homeBox2 .homeBox2text {
    width: 100%;
  }

  .homeBox3 {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: 30px;
  }
  .homeBox3 .homeBox31 {
    width: 100%;
  }
  .homeBox3 .homeBoxe3text h1 {
    width: 86%;
  }

  .homeBox3 .homeBox31 .homeBoxe3text h1 {
    width: 70%;
}

  .homeBox3 .homeBox31 .homeBoxe3text p {
    width: 75%;
  }
  .homeBox3 .list3 {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .homeBox3 .list3 ul img {
    position: absolute;
    z-index: -1;
    left: 150px;
    top: 20px;
    width: 150%;
  }
  .homeBox3 .list3 ul li {
    font-size: 12px;
    padding: 10px 0px 20px 0px;
    width: 52%;
  }
  .homeBox3 .button3 {
    padding-top: 10px;
    margin-bottom: 0px;
  }
  .homeBox3 .button3 a button {
    font-size: 14px;
    padding: 12px 35px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox4 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
  }
  .homeBox4 .homeBox4tex {
    margin: 0 0 0 0px;
  }
  .homeBox4 .image4 img {
    width: 100%;
    position: relative;
    top: 33px;
  }
  .homeBox4 .homeBox4tex p {
    width: 90%;
  }
  .homeBox5 {
    padding: 0px 0px;
  }
  .homeBox5 .homeBox5h1 h1 {
    width: 92%;
  }
  .homeBox5 .homeBox51 {
    display: block;
  }
  .homeBox5 p {
    width: 80%;
  }
  .homeBox5 div {
    width: 100%;
  }
  .homeBox5 .homeBox5Step1 {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step2 {
    display: flex;
    justify-content: center;
    flex-flow: row-reverse;
    padding-top: 10px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step11 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step22 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step33 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step3 {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }
  .homeBox5 .homeBox5Step1 img {
    border-radius: 30px;
    width: 100%;
  }
  .homeBox5 .homeBox5Step2 img {
    border-radius: 30px;
    width: 100%;
  }
  .homeBox5 .homeBox5Step3 img {
    border-radius: 30px;
    width: 90%;
  }
  .homeBox5 .homeBox5Step4 {
    margin-top: 20px;
  }
  .homeBox5 .homeBox5Step5 {
    margin-left: 22px;
  }
  .homeBox5 .homeBox5Step6 {
    margin-left: 22px;
  }

  .homeBox5 .button5 button {
    font-size: 14px;
    padding: 12px 35px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 10px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox6 .list6 ul {
    width: 100%;
    display: block;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    padding: 10px;
  }
  .homeBox6 .list6 ul li {
    background: #f9f9f9;
    border-radius: 35px;
    display: flex;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 8vh;
    transition: .9s;
  }
  #vector1{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector1 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector1 img{
    transition: .4s;

  }
  #vector2{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector2 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector2 img{
    transition: .4s;

  }
  #vector3{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector3 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector3 img{
    transition: .4s;

  }
  #vector4{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector4 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector4 img{
    transition: .4s;

  }
  #vector5{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector5 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector5 img{
    transition: .4s;

  }
  #vector6{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector6 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector6 img{
    transition: .4s;

  }
  
 
  .homeBox6 .list6 .text1{
    display: none;
  }
  .homeBox6 .list6 .text2{
    display: none;
  }
  .homeBox6 .list6 .text3{
    display: none;
  }
  .homeBox6 .list6 .text4{
    display: none;
  }
  .homeBox6 .list6 .text5{
    display: none;
  }.homeBox6 .list6 .text6{
    display: none;
  }
  .homeBox6 .homeBox6text h1 {
    width: 100%;
    margin-top: 30px;
  }
  .homeBox6 .list6 .list61 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list62 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list63 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list64 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list65 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list66 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .vector6 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector5 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector4 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector3 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector2 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector1 {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 320px) {
  h1 {
    font-weight: 700;
    font-size: 25px;
    color: #0e082b;
    width: 100%;
  }
  p {
    font-weight: 300;
    font-size: 14px !important;
    color: #0e082b;
    margin-top: 23px;
  }
  .homeBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .homBox1img img {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 120%;
    z-index: -1;
    top: 235px;
    left: -35px;
  }
  .homeBox1 .homBox1text h1 {
    padding: 0 0px 0 0;
    width: 78%;
  }
  .homeBox1 .homBox1text {
    padding: 0 0px 0 0;
  }
  .homeBox1 .homBox1text p {
    width: 95%;
  }

  /* 
  .homeBox1 .homBox1text p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
  } */
  .homeBox1 .homBox1button {
    margin: 200px 0px 40px 0;
    display: flex;
    width: 100%;
    justify-content: start;
  }

  .homeBox1 .homBox1button a {
    font-size: 14px;
    padding: 12px 20px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox1 .homBox1button a img {
    width: 23%;
    margin-left: 20px;
  }
  .homeBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
  }
  .homeBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .homeBox2 .homeBox2text h1 {
    width: 100%;
  }
  .homeBox2 p {
    width: 100%;
  }
  .homeBox2 .programss {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .homeBox2 .img2 {
    padding: 30px 0;
  }
  .homeBox2 .img2 img {
    width: 100%;
  }
  .homeBox2 .homeBox2text {
    width: 115%;
  }

  .homeBox3 {
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .homeBox3 .homeBox31 {
    width: 100%;
  }
  .homeBox3 .homeBox31 .homBox3text h1 {
    padding: 0 0 0 0;
  }

  .homeBox3 .homeBox3text p {
    padding: 0, 10px 0 0;
  }
  .homeBox3 .list3 {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .homeBox3 .homeBoxe3text h1 {
    width: 88%;
  }
  .homeBox3 .list3 ul img {
    position: absolute;
    z-index: -1;
    left: 140px;
    top: 20px;
    width: 160%;
  }
  .homeBox3 .list3 ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 60%;
  }
  .homeBox3 .button3 {
    padding-top: 30px;
    margin-bottom: 0px;
  }
  .homeBox3 .button3 a button {
    font-size: 12px;
    padding: 12px 25px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox4 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
  }
  .homeBox4 .homeBox4tex {
    margin: 0 0 0 0px;
  }
  .homeBox4 .image4 img {
    width: 100%;
    position: relative;
    top: 34px;
  }
  .homeBox5 {
    padding: 0px 0;
  }
  .homeBox5 .homeBox5h1 h1 {
    width: 100%;
  }
  .homeBox5 .homeBox51 {
    display: block;
  }
  .homeBox5 p {
    width: 97%;
  }
  .homeBox5 div {
    width: 100%;
  }
  .homeBox5 .homeBox5Step1 {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  .homeBox5 .homeBox5Step2 {
    display: flex;
    justify-content: center;
    flex-flow: row-reverse;
    padding-top: 10px;
  }
  .homeBox5 .homeBox5Step3 {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step11 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step22 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 .homeBox5Step33 {
    border-radius: 35px;
  }
  .homeBox5 .homeBox5Step1 img {
    border-radius: 30px;
    width: 100%;
  }
  .homeBox5 .homeBox5Step2 img {
    border-radius: 30px;
    width: 100%;
  }
  .homeBox5 .homeBox5Step3 img {
    border-radius: 30px;
    width: 100%;
  }

  .homeBox5 .homeBox5Step5 {
    margin-left: 22px;
  }
  .homeBox5 .homeBox5Step6 {
    margin-left: 22px;
  }

  .homeBox5 .button5 button {
    font-size: 14px;
    padding: 12px 30px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 10px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .homeBox6 .list6 ul {
    width: 100%;
    display: block;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    padding: 10px;
  }
  .homeBox6 .list6 ul li {
    background: #f9f9f9;
    border-radius: 35px;
    display: flex;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 8vh;
    transition: .9s;
  }
  #vector1{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector1 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector1 img{
    transition: .4s;

  }
  #vector2{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector2 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector2 img{
    transition: .4s;

  }
  #vector3{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector3 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector3 img{
    transition: .4s;

  }
  #vector4{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector4 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector4 img{
    transition: .4s;

  }
  #vector5{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector5 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector5 img{
    transition: .4s;

  }
  #vector6{
    display: flex;
    height: auto;
    transition: .4s;
    
  }
  #vector6 img{
    transform: rotate(90deg);
    transition: .4s;

  }
  .vector6 img{
    transition: .4s;

  }
  
 
  .homeBox6 .list6 .text1{
    display: none;
  }
  .homeBox6 .list6 .text2{
    display: none;
  }
  .homeBox6 .list6 .text3{
    display: none;
  }
  .homeBox6 .list6 .text4{
    display: none;
  }
  .homeBox6 .list6 .text5{
    display: none;
  }.homeBox6 .list6 .text6{
    display: none;
  }
  .homeBox6 .homeBox6text h1 {
    width: 102%;
    margin-top: 30px;
  }
  .homeBox6 .list6 .list61 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list62 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list63 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list64 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list65 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .list66 {
    /* display: none; */
    font-size: 12px;
    color: #878496;
    padding: 10px 0;
  }
  .homeBox6 .list6 .vector6 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector5 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector4 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector3 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector2 {
    display: flex;
    justify-content: space-between;
  }
  .homeBox6 .list6 .vector1 {
    display: flex;
    justify-content: space-between;
  }
}

#obsh{
 background: #E6E6E6 ;
}


