* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.navmenu{
  padding: 20px 16px;
}
a{

  color:#000000 !important;
}


@media (max-width: 425px) {
  .navmenuimg{
    display: flex;
    justify-content: space-between;
  }
  .navmenulist ul{
    list-style: none;
    font-weight: 500;
    
  }
  .navmenulist ul li{
    list-style: none;
    font-weight: 500;
    font-size: 18px;
    margin-top: 15%;
  }
  .navmenuButton{
    width: 100%;
    
  }
  .navmenuButton button{
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #eeeafc;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    width: 60%;
    height: 40px;
    margin-top: 40%;

  }
  .telWha img{
    margin-left: 10px;
  }
}
@media (max-width: 390px) {
  .navmenuimg{
    display: flex;
    justify-content: space-between;
  }
  .navmenulist ul{
    list-style: none;
    font-weight: 500;
    
  }
  .navmenulist ul li{
    list-style: none;
    font-weight: 500;
    font-size: 18px;
    margin-top: 15%;
  }
  .navmenuButton{
    width: 100%;
    
  }
  .navmenuButton button{
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #eeeafc;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    width: 60%;
    height: 40px;
    margin-top: 57%;

  }
  .telWha img{
    margin-left: 10px;
  }
}
@media (max-width: 320px) {
  .navmenu{
    width: 0;
    height: 0;
  }
  .navmenuimg{
    display: flex;
    justify-content: space-between;
  }
  .navmenulist ul{
    list-style: none;
    font-weight: 500;
    
  }
  .navmenulist ul li{
    list-style: none;
    font-weight: 500;
    font-size: 18px;
    margin-top: 15%;
  }
  .navmenuButton{
    width: 100%;
    
  }
  .navmenuButton button{
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #eeeafc;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    width: 60%;
    height: 40px;
    margin-top: 82%;

  }
  .telWha img{
    margin-left: 10px;
    margin-top: 10px;

  }
}

 