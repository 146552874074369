.whqr{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00A884;
}
@media (max-width: 2560px) {
    .whqr img{
        width: 500px;
    }
}
@media (max-width: 1920px) {
    .whqr img{
        width: 450px;
    }
}
@media (max-width: 1440px) {
    .whqr img{
        width: 300px;
    }
}
@media (max-width: 1024px) {
    .whqr img{
        width: 270px;
    }
}
@media (max-width: 768px) {
    .whqr img{
        width: 240px;
    }
}
@media (max-width: 425px) {
    .whqr img{
        width: 240px;
    }
}
@media (max-width: 390px) {
    .whqr img{
        width: 240px;
    }
}
@media (max-width: 320px) {
    .whqr img{
        width: 240px;
    }
}