@media (max-width: 2560px) {
    .detail{
        padding: 250px 100px;
    }
    .detailBox1 h2{
        font-size: 50px;
    }
    .detailBox2{
    display: flex;
    justify-content: space-between;
}
.detailBox2 h4{
    color: #5231e4;
}
.detailBox2 div{
    margin-top: 20px;
    font-size: 32px;
}
.detailBox22 ul li{
    display: flex;
    justify-content: start;
    font-size: 32px;
    margin-top: 5px;
    font-weight: 400;
}
.detailBox24{
    display: flex;
    justify-content: center;
    margin-top: 28px;
}
.detailBox23 button{
    font-size: 32px;
    padding: 12px 80px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #5231e4;
    background: #EEEAFC;
    border-radius: 10px;
    border-style: none;
    height: 6vh;
}
}
@media (max-width: 1920px) {
    .detail{
        padding: 200px 100px;
    }
    .detailBox1 h2{
        font-size: 40px;
    }
    .detailBox2{
    display: flex;
    justify-content: space-between;
}
.detailBox2 div{
    margin-top: 20px;
    font-size: 24px;
}
.detailBox22 ul li{
    display: flex;
    justify-content: start;
    font-size: 24px;
    margin-top: 5px;
    font-weight: 400;
}
.detailBox24{
    display: flex;
    justify-content: center;
    margin-top: 28px;
}
.detailBox23 button{
    font-size: 24px;
    padding: 12px 90px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #5231e4;
    background: #EEEAFC;
    border-radius: 10px;
    border-style: none;
    height: 6vh;
}
}

@media (max-width: 1440px) {
    .detail{
        padding: 120px 80px;
    }
    .detailBox1 h2{
        font-size: 30px;
    }
    .detailBox2{
    display: flex;
    justify-content: space-between;
}
.detailBox2 div{
    margin-top: 20px;
    font-size: 18px;
}
.detailBox22 ul li{
    display: flex;
    justify-content: start;
    font-size: 18px;
    margin-top: 5px;
    font-weight: 400;
}
.detailBox24{
    display: flex;
    justify-content: center;
    margin-top: 28px;
}
.detailBox23 button{
    font-size: 14px !important;
    padding: 12px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color:#5231e4 ;
    background: #EEEAFC;
    border-radius: 10px;
    border-style: none;
    height: 5vh;
}
}
@media (max-width: 1366px) {
    .detail{
        padding: 100px 80px;
    }
    .detailBox2{
    display: flex;
    justify-content: space-between;
}
.detailBox2 div{
    margin-top: 20px;
    font-size: 18px;
}
.detailBox22 ul li{
    display: flex;
    justify-content: start;
    font-size: 18px;
    margin-top: 5px;
    font-weight: 400;
}
.detailBox24{
    display: flex;
    justify-content: center;
    margin-top: 28px;
}
.detailBox23 button{
    font-size: 14px;
    padding: 12px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color:#5231e4 ;
    background:#EEEAFC ;
    border-radius: 10px;
    border-style: none;
    height: 5vh;
}
}

@media (max-width: 1024px) {
    .detail{
        padding: 100px 60px;
    }
    .detailBox2{
    display: flex;
    justify-content: space-between;
}
.detailBox1 h2{
    font-size: 24px;
}
.detailBox2 div{
    margin-top: 20px;
    font-size: 14px;
}
.detailBox22 ul li{
    display: flex;
    justify-content: start;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 400;
}
.detailBox24{
    display: flex;
    justify-content: center;
    margin-top: 28px;
}
.detailBox23 button{
    font-size: 14px;
    padding: 12px 58px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color:#5231e4 ;
    background: #EEEAFC;
    border-radius: 10px;
    border-style: none;
    height: 5vh;
}
}
@media (max-width: 768px) {
    .detail{
        padding: 100px 40px;
    }
    .detailBox1{
        margin-left: 0px;
    }
    
    .detailBox2{
    display: flex;
    justify-content: space-between;
}
.detailBox2 div{
    margin-top: 20px;
}
.detailBox22 ul li{
    display: flex;
    justify-content: start;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 400;
}
.detailBox24{
    display: flex;
    justify-content: center;
    margin-top: 28px;
}
.detailBox23 button{
    font-size: 14px;
    padding: 12px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color:#5231e4 ;
    background: #EEEAFC;
    border-radius: 10px;
    border-style: none;
    height: 5vh;
}
}

@media (max-width: 425px) {
    .detail{
        padding: 60px 20px;
    }
    .detailBox2{
    display: block;
    justify-content: space-around;
}
.detailBox1{
    margin-left: 0px;
}
.detailBox2 div{
    margin-top: 20px;
}
.detailBox22 ul li{
    display: flex;
    justify-content: start;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 400;
}
.detailBox24{
    display: flex;
    justify-content: center;
    margin-top: 100px;
}
.detailBox23 button{
    font-size: 14px;
    padding: 12px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color:#5231e4 ;
    background:#EEEAFC ;
    border-radius: 10px;
    border-style: none;
    height: 5vh;
}
}
@media (max-width: 390px) {
    .detail{
        padding: 60px 20px;
    }
    .detailBox2{
    display: block;
    justify-content: space-around;
}
.detailBox2 div{
    margin-top: 20px;
}
.detailBox22 ul li{
    display: flex;
    justify-content: start;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 400;
}
.detailBox24{
    display: flex;
    justify-content: center;
    margin-top: 100px;
}
.detailBox23 button{
    font-size: 14px;
    padding: 12px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color:#5231e4 ;
    background: #EEEAFC;
    border-radius: 10px;
    border-style: none;
}
}

@media (max-width: 320px) {
    .detail{
        padding: 60px 20px;
    }
    .detailBox2{
    display: block;
    justify-content: space-around;
}
.detailBox2 div{
    margin-top: 20px;
}
.detailBox22 ul li{
    display: flex;
    justify-content: start;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 400;
}
.detailBox24{
    display: flex;
    justify-content: center;
    margin-top: 100px;
}
.detailBox23 button{
    font-size: 14px;
    padding: 12px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color:#5231e4 ;
    background: #EEEAFC;
    border-radius: 10px;
    border-style: none;
}
}
