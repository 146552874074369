* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.cateringBox4 .cateringBox4list ul li {
  list-style-image: url(./catering_img/Check.svg);
}
.cateringBox5 .cateringBox5list ul li {
  list-style-image: url(./catering_img/Check.svg);
}
.cateringBox7 .cateringBox7list ul li {
  list-style-image: url(./catering_img/Check.svg);
}
.cateringBox8 .cateringBox8list ul li {
  list-style-image: url(./catering_img/Check.svg);
}
.cateringBox9 .cateringBox9list ul li {
  list-style-image: url(./catering_img/Check.svg);
}
@media (max-width: 2560px) {
  .catering {
    padding: 0 100px;
  }
  h1 {
    font-weight: 700;
    font-size: 70px;
    color: #0e082b;
  }

  .cateringBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: unset;
    position: relative;
  }
  .cateringBox1img {
    width: 100%;
    overflow: hidden;
  }

  .cateringBox1img img {
    width: 120%;
    margin-left: -70px;
    margin-bottom: -222px;
  }
  .cateringBox1 .cateringBox1text h1 {
    padding: 222px 0px 0px 10px;
    width: 60%;
  }
  .cateringBox1 .cateringBox1text {
    padding: 0 0px 0 0;
  }
  .cateringBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
  }
  .cateringBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .cateringBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  .cateringBox2 .programss img {
    width: 10%;
  }
  .cateringBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .cateringBox2 h1 {
    width: 70%;
  }
  .cateringBox2 p {
    width: 64%;
  }
  .cateringBox2 .img2 {
    padding: 40px 290px 0 0;
  }
  .cateringBox2 .img2 img {
    width: 240%;
  }
  .cateringBox2 .cateringBox2text {
    width: 60%;
  }

  .cateringBox3 {
    width: 100%;
    margin-top: 100px;
  }
  .cateringBox3 h1 {
    width: 30%;
  }

  .cateringBox3 .cateringBox3ti {
    width: 100%;
    display: flex;
  }
  .cateringBox3 .cateringBox3ti img {
    display: none;
  }
  .cateringBox3 .cateringBox3img {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    margin-top: 100px;
  }
  .cateringBox3 .cateringBox3img img {
    border-radius: 20px;
    margin: 0 360px 0 0;
    width: 80%;
  }
  .cateringBox3 .cateringBox3img h6 {
    font-size: 40px;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img1 {
    display: flex;
    margin: 20px 0 20px 0;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img2 {
    display: flex;
  }
  .cateringBox3 .cateringBox3Button3 a button {
    font-size: 40px;
    padding: 6px 14px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .cateringBox4 {
    width: 100%;
    display: flex;
  }
  .cateringBox4 .cateringBox4list {
    padding: 0 0px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox4 .cateringBox4ti h1 {
    width: 88%;
  }
  .cateringBox4 .cateringBox4ti p {
    width: 46%;
  }
  .cateringBox4 .cateringBox4list ul li {
    font-size: 36px;
    padding: 40px 0px 10px 0px;
    width: 37%;
  }
  .cateringBox4 .cateringBox4img {
    margin-left: -15%;
  }
  .cateringBox4 .cateringBox4img img {
    width: 190%;
  }
  .cateringBox4Button4 a button {
    font-size: 40px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 60px 0 30px 950px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .cateringBox5 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .cateringBox5 .cateringBox5m {
    margin-left: 350px;
    width: 60%;
  }
  .cateringBox5 .cateringBox5ti h1 {
    width: 78%;
  }
  .cateringBox5 .cateringBox5ti p {
    width: 72%;
  }
  .cateringBox5 .cateringBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox5 .cateringBox5list ul li {
    font-size: 36px;
    padding: 40px 0px 10px 0px;
    width: 41%;
  }
  .cateringBox5 .cateringBox5img  {
    width: 17%;
  }

  .cateringBox5 .cateringBox5img img {
    width: 200%;
    height: -webkit-fill-available;
    margin-left: -50%;
  }
  .cateringBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: 34vh;
    margin-top: 100px;
  }
  .cateringBox6 .cateringBox6ti h1 {
    width: 100%;
    margin-top: 90px;
    margin-right: 100px;
  }
  .cateringBox6 .cateringBox6ti p {
    width: 52%;
  }

  .cateringBox6 .cateringBox6tb {
    display: flex;
    flex-direction: row-reverse;
  }
  .cateringBox6 .cateringBox6Button6 a button {
    font-size: 40px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .cateringBox6 .cateringBox6img img {
    width: 180%;
    margin-left: -170%;
  }
  .cateringBox6Button61 a button {
    font-size: 40px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 100px 0 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .cateringBox7 {
    width: 100%;
    display: flex;
  }
  .cateringBox7 .cateringBox7ti h1 {
    width: 100%;
  }
  .cateringBox7 .cateringBox7ti p {
    width: 55%;
  }
  .cateringBox7 .cateringBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox7 .cateringBox7list ul li {
    font-size: 36px;
    padding: 40px 0px 10px 0px;
    width: 38%;
  }
  .cateringBox7 .cateringBox7img img {
    width: 190%;
    border-radius: 35px;
    margin: 0px 0px 0px 0;
  }
  .cateringBox7Button7 a button {
    font-size: 40px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin:100px 0 50px 1120px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .cateringBox8 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
  }
  .cateringBox8 .cateringBox8m {
    margin-left: 40px;
    
  }
  .cateringBox8 .cateringBox8img {
    margin: 0px 0 0 0;
    width: 100%;
  }
  .cateringBox8 .cateringBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox8 .cateringBox8ti h1 {
    width: 88%;
  }
  .cateringBox8 .cateringBox8ti p {
    width: 87%;
  }
  .cateringBox8 .cateringBox8list ul li {
    font-size: 36px;
    padding: 40px 0px 10px 0px;
    width: 57%;
  }
  .cateringBox8 .cateringBox8img img {
    width: 90%;
  }
  .burg img {
    display: flex;
    position: absolute;
    left: -200px;
    width: 30%;
    top: 270px;
  }
  .cateringBox8 .cateringBox8img p {
    margin: 0px 0px 0 50px;
    width: 83%;
  }
  .cateringBox8Button8 a button {
    font-size: 40px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 100px 0 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .cateringBox9 {
    width: 100%;
  }
  .cateringBox9 .cateringBox9ti h1 {
    width: 80%;
  }
  .cateringBox9 .cateringBox9ti p {
    width: 60%;
  }
  .cateringBox9 .cateringBox9m {
    display: flex;
  }
  .cateringBox9 .cateringBox9list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox9 .cateringBox9list ul li {
    font-size: 36px;
    padding: 40px 0px 10px 0px;
    width: 42%;
  }
  .cateringBox9 .cateringBox9img img {
    width: 180%;
    margin: 0px 20px 0px 0;
    height: -webkit-fill-available;

  }

  .cateringBox9 .cateringBox9Button9 a  {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 12px;
    text-align: right;
    text-decoration-line: underline;
    color: #5131e4 !important;
    cursor: pointer;
  }
  .cateringBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 50px 50px;
    display: block;
    height: auto;
    margin-top: 100px;
  }
  .cateringBox10 .cateringBox10ti h1 {
    width: 55%;

  }
  .cateringBox10 .cateringBox10ti p {
    width: 60%;
  }
  .cateringBox10 .cateringBox10tb {
    display: flex;
  }
  .cateringBox10 .cateringBox10Button10 a button {
    font-size: 40px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .cateringBox10 .cateringBox10img img {
    width: 180%;
    position: relative;
    left: -280px;
    top: 58px;

  }
}
@media (max-width: 1920px) {
  .catering {
    padding: 0 100px;
  }
  ul li {
    color: #0e082b;

  }
  h1 {
    font-weight: 700;
    font-size: 70px;
    color: #0e082b;
  }

  .cateringBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: unset;
    position: relative;
  }
  .cateringBox1img {
    width: 100%;
    overflow: hidden;
  }

  .cateringBox1img img {
    width: 120%;
    margin-left: -70px;
    margin-bottom: -153px;
    position: relative;
    top: 60px;
  }
  .cateringBox1 .cateringBox1text h1 {
    padding: 222px 0px 0px 10px;
    width: 70%;
  }
  .cateringBox1 .cateringBox1text {
    padding: 0 0px 0 0;
  }
  .cateringBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
  }
  .cateringBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .cateringBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  .cateringBox2 .programss img {
    width: 10%;
  }
  .cateringBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .cateringBox2 h1 {
    width: 80%;
  }
  .cateringBox2 p {
    width: 72%;
  }
  .cateringBox2 .img2 {
    padding: 40px 290px 0 0;
  }
  .cateringBox2 .img2 img {
    width: 240%;
  }
  .cateringBox2 .cateringBox2text {
    width: 60%;
  }

  .cateringBox3 {
    width: 100%;
    margin-top: 50px;
  }

  .cateringBox3 .cateringBox3ti {
    width: 100%;
    display: flex;
  }
  .cateringBox3 .cateringBox3ti img {
    display: none;
  }
  .cateringBox3 .cateringBox3img {
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }
  .cateringBox3 .cateringBox3img img {
    border-radius: 20px;
    margin: 0 360px 0 0;
    width: 80%;
  }
  .cateringBox3 .cateringBox3img h6 {
    font-size: 40px;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img1 {
    display: flex;
    margin: 20px 0 20px 0;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img2 {
    display: flex;
  }
  .cateringBox3 .cateringBox3Button3 a button {
    font-size: 28px;
    padding: 6px 14px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .cateringBox4 {
    width: 100%;
    display: flex;
  }
  .cateringBox4 .cateringBox4list {
    padding: 0 0px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox4 .cateringBox4ti h1 {
    width: 88%;
  }
  .cateringBox4 .cateringBox4ti p {
    width: 46%;
  }
  .cateringBox4 .cateringBox4list ul li {
    font-size: 26px;
    padding: 40px 0px 10px 0px;
    width: 37%;
  }
  .cateringBox4 .cateringBox4img {
    margin-left: -15%;
  }
  .cateringBox4 .cateringBox4img img {
    width: 160%;
  }
  .cateringBox4Button4 a button {
    font-size: 28px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 60px 0 40px 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .cateringBox5 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .cateringBox5 .cateringBox5m {
    margin-left: 350px;
    width: 60%;
  }
  .cateringBox5 .cateringBox5ti h1 {
    width: 95%;
  }
  .cateringBox5 .cateringBox5ti p {
    width: 74%;
  }
  .cateringBox5 .cateringBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox5 .cateringBox5list ul li {
    font-size: 26px;
    padding: 40px 0px 10px 0px;
    width: 41%;
  }
  .cateringBox5 .cateringBox5img  {
    width: 20%;
  }

  .cateringBox5 .cateringBox5img img {
    width: 190%;
    height: -webkit-fill-available;
    margin-left: -5%;
  }
  .cateringBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: 42vh;
  }
  .cateringBox6 .cateringBox6ti h1 {
    width: 100%;
  }
  .cateringBox6 .cateringBox6ti p {
    width: 50%;
  }

  .cateringBox6 .cateringBox6tb {
    display: flex;
    flex-direction: row-reverse;
  }
  .cateringBox6 .cateringBox6Button6 a button {
    font-size: 28px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .cateringBox6 .cateringBox6img img {
    width: 130%;
    margin-left: -70%;
  }
  .cateringBox6Button61 a button {
    font-size: 28px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 100px 0 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .cateringBox7 {
    width: 100%;
    display: flex;
  }
  .cateringBox7 .cateringBox7ti h1 {
    width: 100%;
  }
  .cateringBox7 .cateringBox7ti p {
    width: 55%;
  }
  .cateringBox7 .cateringBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox7 .cateringBox7list ul li {
    font-size: 26px;
    padding: 40px 0px 10px 0px;
    width: 38%;
  }
  .cateringBox7 .cateringBox7img img {
    width: 140%;
    border-radius: 35px;
    margin: 0px 0px 0px 0;
    position: relative;
    left: -200px;
  }
  .cateringBox7Button7 a button {
    font-size: 28px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 750px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .cateringBox8 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
  }
  .cateringBox8 .cateringBox8m {
    margin-left: 40px;
    
  }
  .cateringBox8 .cateringBox8img {
    margin: 40px 0 0 0;
    width: 100%;
  }
  .cateringBox8 .cateringBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox8 .cateringBox8ti h1 {
    width: 88%;
  }
  .cateringBox8 .cateringBox8ti p {
    width: 97%;
  }
  .cateringBox8 .cateringBox8list ul li {
    font-size: 26px;
    padding: 40px 0px 10px 0px;
    width: 57%;
  }
  .cateringBox8 .cateringBox8img img {
    width: 90%;
  }
  .burg img {
    display: flex;
    position: absolute;
    left: -145px;
    width: 28%;
    top: 215px;
  }
  .cateringBox8 .cateringBox8img p {
    margin: 0px 0px 0 50px;
    width: 100%;
    font-size: 20px;
  }
  .cateringBox8Button8 a button {
    font-size: 28px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .cateringBox9 {
    width: 100%;
  }
  .cateringBox9 .cateringBox9ti h1 {
    width: 80%;
  }
  .cateringBox9 .cateringBox9ti p {
    width: 60%;
  }
  .cateringBox9 .cateringBox9m {
    display: flex;
    margin: 30px 0px 50px 0px;
  }
  .cateringBox9 .cateringBox9list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox9 .cateringBox9list ul li {
    font-size: 26px;
    padding: 40px 0px 10px 0px;
    width: 41%;
  }
  .cateringBox9 .cateringBox9img img {
    width: 160%;
    margin: 0px 20px 0px 0;
    height: -webkit-fill-available;
    position: relative;
    right: 140px;
  }

  .cateringBox9 .cateringBox9Button9 a  {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 12px;
    text-align: right;
    text-decoration-line: underline;
    color: #5131e4 !important;
    cursor: pointer;
  }
  .cateringBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
    
  }
  .cateringBox10 .cateringBox10ti h1 {
    width: 55%;

  }
  .cateringBox10 .cateringBox10ti p {
    width: 60%;
  }
  .cateringBox10 .cateringBox10tb {
    display: flex;
  }
  .cateringBox10 .cateringBox10Button10 a button {
    font-size: 24px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .cateringBox10 .cateringBox10img img {
    width: 130%;
    position: relative;
    left: -280px;
    top: 35px;

  }
}
@media (max-width: 1440px) {
  h1 {
    font-weight: 700;
    font-size: 50px;
    color: #0e082b;
  }
  p {
    font-weight: 300;
    font-size: 20px;
    color: #0e082b;
    margin-top: 23px;
    width: 60%;
  }
  .cateringBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: unset;
    position: relative;
  }
  .cateringBox1img {
    width: 100%;
    overflow: hidden;
  }

  .cateringBox1img img {
    width: 120%;
    margin-left: -70px;
    margin-bottom: -134px;
    position: relative;
    top: 0px;
  }
  .cateringBox1 .cateringBox1text h1 {
    padding: 140px 0px 0px 10px;
    width: 60%;
  }
  .cateringBox1 .cateringBox1text {
    padding: 0 0px 0 0;
  }
  .cateringBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
  }
  .cateringBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .cateringBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .cateringBox2 h1 {
    width: 62%;
  }
  .cateringBox2 p {
    width: 62%;
  }
  .cateringBox2 .img2 {
    padding: 40px 200px 0 0;
  }
  .cateringBox2 .img2 img {
    width: 210%;
  }
  .cateringBox2 .cateringBox2text {
    width: 100%;
  }

  .cateringBox3 {
    width: 100%;
    margin-top: 50px;
  }

  .cateringBox3 .cateringBox3ti {
    width: 100%;
    display: flex;
  }
  .cateringBox3 .cateringBox3ti img {
    display: none;
  }
  .cateringBox3 .cateringBox3img {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    margin-top: 50px;
  }
  .cateringBox3 .cateringBox3ti h1 {
    width: 30%;
  }
  .cateringBox3 .cateringBox3img img {
    border-radius: 20px;
    margin: 0 120px 0 0;
    width: 80%;
  }
  .cateringBox3 .cateringBox3img h6 {
    font-size: 16px;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img1 {
    display: flex;
    margin: 20px 0 20px 0;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img2 {
    display: flex;
  }
  .cateringBox3 .cateringBox3Button3 a button {
    font-size: 20px;
    padding: 6px 14px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox4 {
    width: 100%;
    display: flex;
  }
  .cateringBox4 .cateringBox4list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox4 .cateringBox4ti h1 {
    width: 88%;
  }
  .cateringBox4 .cateringBox4ti p {
    width: 53%;
  }
  .cateringBox4 .cateringBox4list ul li {
    font-size: 18px;
    padding: 20px 0px 10px 0px;
    width: 37%;
  }
  .cateringBox4 .cateringBox4img {
    margin-left: -10%;
  }
  .cateringBox4 .cateringBox4img img {
    width: 100%;
  }
  .cateringBox4Button4 a button {
    font-size: 20px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox5 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .cateringBox5 .cateringBox5m {
    margin-left: 40px;
    width: 70%;
  }
  
  .cateringBox5 .cateringBox5ti h1 {
    width: 85%;
  }
  .cateringBox5 .cateringBox5ti p {
    width: 80%;
  }
  .cateringBox5 .cateringBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox5 .cateringBox5list ul li {
    font-size: 18px;
    padding: 10px 0px 10px 0px;
    width: 39%;
  }
  .cateringBox5 .cateringBox5img  {
    width: 50%;
  }

  .cateringBox5 .cateringBox5img img {
    width: 100%;
    height: -webkit-fill-available;
    margin-left: 0;
  }
  .cateringBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: 37vh;
  }
  .cateringBox6 .cateringBox6ti h1 {
    width: 100%;
    margin-top: 50px;
    margin-right: 100px;
  }
  .cateringBox6 .cateringBox6tb {
    display: flex;
  }
  
  .cateringBox6 .cateringBox6Button6 a button {
    font-size: 20px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox6 .cateringBox6img img {
    width: 100%;
    margin-left: -35%;
  }
  .cateringBox6Button61 a button {
    font-size: 20px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox7 {
    width: 85%;
    display: flex;
  }
  .cateringBox7 .cateringBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  
  .cateringBox7 .cateringBox7list ul li {
    font-size: 18px;
    padding: 20px 0px 10px 0px;
    width: 42%;
  }
  .cateringBox7 .cateringBox7img img {
    width: 130%;
    border-radius: 35px;
    margin: 0px 0px 0px 0;
    position: relative;
    left: 0px;
  }
  .cateringBox7 .cateringBox7ti h1 {
    width: 100%;
  }
  .cateringBox7 .cateringBox7ti p {
    width: 70%;
  }
  .cateringBox7Button7 a button {
    font-size: 20px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox8 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative;

  }
  .cateringBox8 .cateringBox8m {
    margin-left: 40px;
    
  }
  .cateringBox8 .cateringBox8img {
    margin: 0px 0 0 0;
    width: 100%;
  }
  .cateringBox8 .cateringBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox8 .cateringBox8ti h1 {
    width: 88%;
  }
  .cateringBox8 .cateringBox8ti p {
    width: 88%;
  }
  .cateringBox8 .cateringBox8list ul li {
    font-size: 18px;
    padding: 20px 0px 10px 0px;
    width: 53%;
  }
  .cateringBox8 .cateringBox8img img {
    width: 90%;
  }
  .burg img {
    display: flex;
    position: absolute;
    left: -130px;
    width: 32%;
    top: 135px;
  }
  .cateringBox8 .cateringBox8img p {
    margin: 0px 0px 0 50px;
    width: 66%;
    font-size: 14px;
  }
  .cateringBox8Button8 a button {
    font-size: 20px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox9 {
    width: 100%;
  }
  .cateringBox9 .cateringBox9m {
    display: flex;
  }
  .cateringBox9 .cateringBox9ti h1 {
    width: 80%;
  }
  .cateringBox9 .cateringBox9ti p {
    width: 55%;
  }
  .cateringBox9 .cateringBox9list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox9 .cateringBox9list ul li {
    font-size: 18px;
    padding: 20px 0px 10px 0px;
    width: 32%;
  }
  .cateringBox9 .cateringBox9img img {
    width: 100%;
    margin: 0px 60px 0px 0;
    height: -webkit-fill-available;
    position: relative;
    right: 0px;
  }

  .cateringBox9 .cateringBox9Button9 a button {
    font-size: 20px;
    padding: 0px 0px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox9 .cateringBox9Button9 a {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 12px;
    text-align: right;
    text-decoration-line: underline;
    color: #5131e4 !important;
    cursor: pointer;
}


  .cateringBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
  }
  .cateringBox10 .cateringBox10ti h1 {
    width: 55%;

  }
  .cateringBox10 .cateringBox10ti p {
    width: 60%;
  }
  .cateringBox10 .cateringBox10tb {
    display: flex;
  }
  .cateringBox10 .cateringBox10Button10 a button {
    font-size: 20px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox10 .cateringBox10img img {
    width: 100%;
    position: relative;
    left: -120px;
    top: 35px;
  }
}
@media (max-width: 1366px) {
  h1 {
    font-weight: 700;
    font-size: 50px;
    color: #0e082b;
  }
  p {
    font-weight: 300;
    font-size: 20px;
    color: #0e082b;
    margin-top: 23px;
    width: 60%;
  }
  .cateringBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: unset;
    position: relative;
  }
  .cateringBox1img {
    width: 100%;
    overflow: hidden;
  }

  .cateringBox1img img {
    width: 130%;
    margin-left: -70px;
    margin-bottom: -134px;
    position: relative;
    top: 0px;
  }
  .cateringBox1 .cateringBox1text h1 {
    padding: 140px 0px 0px 10px;
    width: 65%;
  }
  .cateringBox1 .cateringBox1text {
    padding: 0 0px 0 0;
  }
  .cateringBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
  }
  .cateringBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .cateringBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .cateringBox2 h1 {
    width: 70%;
  }
  .cateringBox2 p {
    width: 62%;
  }
  .cateringBox2 .img2 {
    padding: 40px 200px 0 0;
  }
  .cateringBox2 .img2 img {
    width: 210%;
  }
  .cateringBox2 .cateringBox2text {
    width: 100%;
  }

  .cateringBox3 {
    width: 100%;
    margin-top: 50px;
  }

  .cateringBox3 .cateringBox3ti {
    width: 100%;
    display: flex;
  }
  .cateringBox3 .cateringBox3ti img {
    display: none;
  }
  .cateringBox3 .cateringBox3img {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    margin-top: 50px;
  }
  .cateringBox3 .cateringBox3ti h1 {
    width: 30%;
  }
  .cateringBox3 .cateringBox3img img {
    border-radius: 20px;
    margin: 0 120px 0 0;
    width: 80%;
  }
  .cateringBox3 .cateringBox3img h6 {
    font-size: 16px;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img1 {
    display: flex;
    margin: 20px 0 20px 0;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img2 {
    display: flex;
  }
  .cateringBox3 .cateringBox3Button3 a button {
    font-size: 16px;
    padding: 6px 14px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox4 {
    width: 100%;
    display: flex;
  }
  .cateringBox4 .cateringBox4list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox4 .cateringBox4ti h1 {
    width: 88%;
  }
  .cateringBox4 .cateringBox4ti p {
    width: 53%;
  }
  .cateringBox4 .cateringBox4list ul li {
    font-size: 18px;
    padding: 20px 0px 10px 0px;
    width: 39%;
  }
  .cateringBox4 .cateringBox4img {
    margin-left: -10%;
  }
  .cateringBox4 .cateringBox4img img {
    width: 100%;
  }
  .cateringBox4Button4 a button {
    font-size: 16px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 510px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox5 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .cateringBox5 .cateringBox5m {
    margin-left: 40px;
    width: 70%;
  }
  .cateringBox5 .cateringBox5ti h1 {
    width: 86%;
  }
  .cateringBox5 .cateringBox5ti p {
    width: 86%;
  }
  .cateringBox5 .cateringBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox5 .cateringBox5list ul li {
    font-size: 18px;
    padding: 10px 0px 10px 0px;
    width: 50%;
  }
  .cateringBox5 .cateringBox5img  {
    width: 50%;
  }

  .cateringBox5 .cateringBox5img img {
    width: 100%;
    height: -webkit-fill-available;
    margin-left: 0;
  }
  .cateringBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: 39vh;
  }
  .cateringBox6 .cateringBox6ti h1 {
    width: 100%;
    margin-top: 40px;
    margin-right: 40px;
  }
  .cateringBox6 .cateringBox6tb {
    display: flex;
  }
  
  .cateringBox6 .cateringBox6Button6 a button {
    font-size: 16px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox6 .cateringBox6img img {
    width: 100%;
    margin-left: -40%;
  }
  .cateringBox6Button61 a button {
    font-size: 16px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox7 {
    width: 85%;
    display: flex;
  }
  .cateringBox7 .cateringBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  
  .cateringBox7 .cateringBox7list ul li {
    font-size: 18px;
    padding: 20px 0px 10px 0px;
    width: 44%;
  }
  .cateringBox7 .cateringBox7img img {
    width: 130%;
    border-radius: 35px;
    margin: 0px 0px 0px 0;
    position: relative;
    left: 0px;
  }
  .cateringBox7 .cateringBox7ti h1 {
    width: 100%;
  }
  .cateringBox7 .cateringBox7ti p {
    width: 70%;
  }
  .cateringBox7Button7 a button {
    font-size: 16px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 555px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox8 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative;

  }
  .cateringBox8 .cateringBox8m {
    margin-left: 40px;
    
  }
  .cateringBox8 .cateringBox8img {
    margin: -10px 0 0 0;
    width: 100%;
  }
  .cateringBox8 .cateringBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox8 .cateringBox8ti h1 {
    width: 88%;
  }
  .cateringBox8 .cateringBox8ti p {
    width: 95%;
  }
  .cateringBox8 .cateringBox8list ul li {
    font-size: 18px;
    padding: 20px 0px 10px 0px;
    width: 58%;
  }
  .cateringBox8 .cateringBox8img img {
    width: 90%;
  }
  .burg img {
    display: flex;
    position: absolute;
    left: -135px;
    width: 33%;
    top: 110px;
  }
  .cateringBox8 .cateringBox8img p {
    margin: 0px 0px 0 50px;
    width: 80%;
    font-size: 14px;
  }
  .cateringBox8Button8 a button {
    font-size: 16px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox9 {
    width: 100%;
  }
  .cateringBox9 .cateringBox9m {
    display: flex;
  }
  .cateringBox9 .cateringBox9ti h1 {
    width: 80%;
  }
  .cateringBox9 .cateringBox9ti p {
    width: 60%;
  }
  .cateringBox9 .cateringBox9list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox9 .cateringBox9list ul li {
    font-size: 18px;
    padding: 20px 0px 10px 0px;
    width: 50%;
  }
  .cateringBox9 .cateringBox9img img {
    width: 100%;
    margin: 0px 60px 0px 0;
    height: -webkit-fill-available;
    position: relative;
    right: 0px;
  }

  .cateringBox9 .cateringBox9Button9 a button {
    font-size: 16px;
    padding: 0px 0px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox9 .cateringBox9Button9 a {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 12px;
    text-align: right;
    text-decoration-line: underline;
    color: #5131e4 !important;
    cursor: pointer;
}


  .cateringBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
  }
  .cateringBox10 .cateringBox10ti h1 {
    width: 60%;

  }
  .cateringBox10 .cateringBox10ti p {
    width: 65%;
  }
  .cateringBox10 .cateringBox10tb {
    display: flex;
  }
  .cateringBox10 .cateringBox10Button10 a button {
    font-size: 16px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 50px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox10 .cateringBox10img img {
    width: 100%;
    position: relative;
    left: -160px;
    top: 50px;
  }
}
@media (max-width: 1024px) {
  h1 {
    font-weight: 700;
    font-size: 32px;
    color: #0e082b;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
    margin-top: 23px;
    width: 60%;
  }
  .cateringBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: unset;
    position: relative;
  }
  .cateringBox1img {
    width: 100%;
    overflow: hidden;
  }

  .cateringBox1img img {
    width: 130%;
    margin-left: -70px;
    margin-bottom: -100px;
  }
  .cateringBox1 .cateringBox1text h1 {
    padding: 70px 0px 0px 10px;
    width: 70%;
  }
  .cateringBox1 .cateringBox1text {
    padding: 0 0px 0 0;
  }
  .cateringBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
  }
  .cateringBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .cateringBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .cateringBox2 h1 {
    width: 92%;
  }
  .cateringBox2 p {
    width: 84%;
  }
  .cateringBox2 .img2 {
    padding: 0 142px 0 0;
  }
  .cateringBox2 .img2 img {
    width: 190%;
  }
  .cateringBox2 .cateringBox2 {
    width: 100%;
  }

  .cateringBox3 {
    width: 100%;
    margin-top: 30px;
  }

  .cateringBox3 .cateringBox3ti {
    width: 100%;
    display: flex;
  }
  .cateringBox3 .cateringBox3ti img {
    display: none;
  }
  .cateringBox3 .cateringBox3ti h1 {
    width: 35%;
  }
  .cateringBox3 .cateringBox3img {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    margin-top: 30px;
  }
  .cateringBox3 .cateringBox3img img {
    border-radius: 20px;
    margin: 0 10px 0 0;
    width: 80%;
  }
  .cateringBox3 .cateringBox3img h6 {
    font-size: 16px;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img1 {
    display: flex;
    margin: 20px 0 20px 0;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img2 {
    display: flex;
  }
  .cateringBox3 .cateringBox3Button3 a button {
    font-size: 14px;
    padding: 6px 14px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox4 {
    width: 100%;
    display: flex;
  }
  .cateringBox4 .cateringBox4list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox4 .cateringBox4ti h1 {
    width: 100%;
  }
  .cateringBox4 .cateringBox4ti p {
    width: 70%;
}
  .cateringBox4 .cateringBox4list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 43%;
  }
  .cateringBox4 .cateringBox4img img {
    width: 100%;
  }
  .cateringBox4Button4 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 370px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox5 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .cateringBox5 .cateringBox5m {
    margin-left: 40px;
    width: 70%;
  }
  .cateringBox5 .cateringBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox5 .cateringBox5list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 49%;
  }
  .cateringBox5 .cateringBox5img img {
    width: 100%;
  }
  .cateringBox5 .cateringBox5ti h1 {
    width: 95%;
  }
  .cateringBox5 .cateringBox5ti p {
    width: 90%;
  }
  .cateringBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: 35vh;
    margin-top: 40px;
  }
  .cateringBox6 .cateringBox6tb {
    display: flex;
  }
  .cateringBox6 .cateringBox6ti h1 {
    width: 100%;
    margin-top: 30px;
  }
  .cateringBox6 .cateringBox6ti h1 {
    width: 104%;
  }
  .cateringBox6 .cateringBox6ti p {
    width: 49%;
  }
  .cateringBox6 .cateringBox6ti {
    width: 60%;
  }
  .cateringBox6 .cateringBox6Button6 a button {
    font-size: 14px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox6 .cateringBox6img img {
    width: 90%;
    margin-left: -8%;
    position: relative;
    top: -27px;
    left: 10px;
  }
  .cateringBox6Button61 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox7 {
    width: 100%;
    display: flex;
  }
  .cateringBox7 .cateringBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox7 .cateringBox7ti h1 {
    width: 100%;
  }
  .cateringBox7 .cateringBox7ti p {
    width: 77%;
  }
  .cateringBox7 .cateringBox7list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 38%;
  }

  .cateringBox7 .cateringBox7img img {
    width: 100%;
    border-radius: 35px;
    margin: 20px 0px 0px 0;
  }
  .cateringBox7Button7 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox8 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
  }
  .cateringBox8 .cateringBox8m {
    margin-left: 40px;
  }
  .cateringBox8 .cateringBox8img {
    margin: -30px 0 0 0;
  }
  .cateringBox8 .cateringBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox8 .cateringBox8ti h1 {
    width: 100%;
  }
  .cateringBox8 .cateringBox8ti p {
    width: 97%;
  }
  .cateringBox8 .cateringBox8list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 54%;
  }
  .cateringBox8 .cateringBox8img img {
    width: 100%;
    height: 92%;
  }
  .burg img{
    display: flex;
    position: absolute;
    left: -103px;
    width: 35%;
    top: 82px;
  }
  .cateringBox8 .cateringBox8img p {
    margin: 0px 0px 0 50px;
    font-size: 12px;
    width: 100%;
  }
  .cateringBox8Button8 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox9 {
    width: 100%;
  }
  .cateringBox9 .cateringBox9ti h1 {
    width: 80%;
  }
  .cateringBox9 .cateringBox9ti p {
    width: 66%;
  }
  .cateringBox9 .cateringBox9m {
    display: flex;
  }
  .cateringBox9 .cateringBox9list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox9 .cateringBox9list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 48%;
  }
  .cateringBox9 .cateringBox9img img {
    width: 100%;
    margin: 0px 0px 0px 0;
  }

  .cateringBox9 .cateringBox9Button9 a button {
    font-size: 14px;
    padding: 0px 0px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
    margin-top: 40px;
  }
  .cateringBox10 .cateringBox10ti h1 {
    width: 70%;
  }
  .cateringBox10 .cateringBox10ti p {
    width: 75%;
  }
  .cateringBox10 .cateringBox10tb {
    display: flex;
  }
  .cateringBox10 .cateringBox10Button10 a button {
    font-size: 14px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox10 .cateringBox10img img {
    width: 100%;
    position: relative;
    left: -60px;
    top: 35px;
  }
}

@media (max-width: 768px) {
  .catering {
    padding: 0 40px;
  }
  h1 {
    font-weight: 700;
    font-size: 24px;
    color: #0e082b;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
    margin-top: 23px;
  }
  .cateringBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: unset;
    position: relative;
  }
  .cateringBox1img {
    width: 100%;
    overflow: hidden;
  }

  .cateringBox1img img {
    width: 130%;
    margin-left: -35px;
    margin-bottom: -75px;
  }
  .cateringBox1 .cateringBox1text h1 {
    padding: 70px 0px 0px 10px;
    width: 70%;
  }
  .cateringBox1 .cateringBox1text {
    padding: 0 0px 0 0;
  }
  .cateringBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: flex;
  }
  .cateringBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .cateringBox2 .programss {
    width: 40%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .cateringBox2 h1 {
    width: 86%;
  }
  .cateringBox2 p {
    width: 96%;
  }
  .cateringBox2 .img2 {
    padding: 0 100px 0 0;
  }
  .cateringBox2 .img2 img {
    width: 170%;
  }
  .cateringBox2 .cateringBox2 {
    width: 100%;
  }

  .cateringBox3 {
    width: 100%;
  }
  .cateringBox3 .cateringBox3ti h1 {
    width: 35%;
  }

  .cateringBox3 .cateringBox3ti {
    width: 100%;
    display: flex;
  }
  .cateringBox3 .cateringBox3ti img {
    display: none;
  }
  .cateringBox3 .cateringBox3img {
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }
  .cateringBox3 .cateringBox3img img {
    border-radius: 20px;
    margin: 0 10px 0 0;
    width: 80%;
  }
  .cateringBox3 .cateringBox3img h6 {
    font-size: 16px;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img1 {
    display: flex;
    margin: 20px 0 20px 0;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img2 {
    display: flex;
  }
  .cateringBox3 .cateringBox3Button3 a button {
    font-size: 14px;
    padding: 6px 6px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox4 {
    width: 100%;
    display: flex;
  }
  .cateringBox4 .cateringBox4list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox4 .cateringBox4ti h1 {
    width: 90%;
  }
  .cateringBox4 .cateringBox4ti p {
    width: 74%;
  }
  .cateringBox4 .cateringBox4list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 54%;
  }
  .cateringBox4 .cateringBox4img img {
    width: 100%;
  }
  .cateringBox4Button4 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 315px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox5 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .cateringBox5 .cateringBox5m {
    margin-left: 40px;
    width: 70%;
  }
  .cateringBox5 .cateringBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox5 .cateringBox5ti h1 {
    width: 102%;
  }
  .cateringBox5 .cateringBox5ti p {
    width: 100%;
  }
  .cateringBox5 .cateringBox5list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 58%;
  }
  .cateringBox5 .cateringBox5img img {
    width: 110%;
    margin: 0px 0 0 0;
  }
  .cateringBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;  
    height: 32vh;
    margin-top: 30px;
    
  }
  .cateringBox6 .cateringBox6ti h1 {
    width: 105%;
    margin-top: 20px;
  }
  .cateringBox6 .cateringBox6ti p {
    width: 70%;
}
  .cateringBox6 .cateringBox6tb {
    display: flex;
;
  }
  .cateringBox6 .cateringBox6Button6 a button {
    font-size: 14px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox6 .cateringBox6img img {
    width: 90%;
    margin-left: -10%;
    position: relative;
    top: -27px;
    left: 10px;
  }
  .cateringBox6Button61 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox7 {
    width: 100%;
    display: flex;
  }
  .cateringBox7 .cateringBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox7 .cateringBox7ti h1 {
    width: 100%;
  }
  .cateringBox7 .cateringBox7ti p {
    width: 87%;
  }
  .cateringBox7 .cateringBox7list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 47%;
  }
  .cateringBox7 .cateringBox7img img {
    width: 100%;
    border-radius: 35px;
    margin: 22px 0px 0px 0;
  }
  .cateringBox7Button7 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 20px 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox8 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .cateringBox8 .cateringBox8ti h1 {
    width: 100%;
  }
  .cateringBox8 .cateringBox8m {
    margin-left: 40px;
  }
  .cateringBox8 .cateringBox8img {
    margin: 0px 0 0 0;
  }
  .cateringBox8 .cateringBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox8 .cateringBox8list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 66%;
  }
  .cateringBox8 .cateringBox8ti p {
    width: 100%;
  }
  .cateringBox8 .cateringBox8img img {
    width: 100%;
  }
  .burg img{
    display: flex;
    position: absolute;
    left: -88px;
    width: 35%;
    top: 105px;
  }
  
  .cateringBox8 .cateringBox8img .burg img {
    position: absolute;
    left: -60px;
    width: 40%;
  }
  
  .cateringBox8 .cateringBox8img p {
    width: 70%;
  }
  .cateringBox8Button8 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 60px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox9 .cateringBox9Button9 a {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 12px;
    text-align: right;
    text-decoration-line: underline;
    color: #5131e4 !important;
    cursor: pointer;
}


  .cateringBox9 {
    width: 100%;
  }
  .cateringBox9 .cateringBox9m {
    display: flex;
  }
  .cateringBox9 .cateringBox9ti h1 {
    width: 80%;
  }
  .cateringBox9 .cateringBox9ti p {
    width: 85%;
  }
  .cateringBox9 .cateringBox9list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox9 .cateringBox9list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 60%;
  }
  .cateringBox9 .cateringBox9img img {
    width: 100%;
    margin: 0px 0px 0px 0;
  }

  .cateringBox9 .cateringBox9Button9 a button {
    font-size: 14px;
    padding: 0px 0px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
    margin-top: 30px;
  }
  .cateringBox10 .cateringBox10tb {
    display: flex;
  }
  .cateringBox10 .cateringBox10Button10 a button {
    font-size: 14px;
    padding: 14px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 20px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox10 .cateringBox10ti h1 {
    width: 70%;
  }
  .cateringBox10 .cateringBox10ti p {
    width: 90%;
  }
  .cateringBox10 .cateringBox10img img {
    width: 120%;
    position: relative;
    left: -40px;
    top: 35px;
  }
}

@media (max-width: 425px) {
  .catering {
    padding: 0 16px;
  }
  h1 {
    font-weight: 700;
    font-size: 24px;
    color: #0e082b;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
    margin-top: 23px;
  }
  .cateringBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .cateringBox1img {
    width: 100%;
    overflow: hidden;
  }

  .cateringBox1img img {
    width: 200%;
    margin-left: -60px;
    margin-bottom: -120px;
  }
  .cateringBox1 .cateringBox1text h1 {
    padding: 0 0px 0 0;
    width: 60%;
  }
  .cateringBox1 .cateringBox1text {
    padding: 0 0px 0 0;
  }
  .cateringBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
  }
  .cateringBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .cateringBox2 .programss {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  .cateringBox2 h1 {
    width: 80%;
  }
  .cateringBox2 p {
    width: 80%;
  }

  .cateringBox2 .img2 {
    padding: 30px 0;
  }
  .cateringBox2 .img2 img {
    width: 100%;
  }
  .cateringBox2 .cateringBox2 {
    width: 100%;
  }

  .cateringBox3 {
    width: 100%;
  }

  .cateringBox3 .cateringBox3ti {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .cateringBox3 .cateringBox3ti img {
    display: flex;
  }

  .cateringBox3 .cateringBox3img {
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }
  .cateringBox3 .cateringBox3img img {
    border-radius: 20px;
    margin: 0 10px 0 0;
    width: fit-content;
  }
  .cateringBox3 .cateringBox3ti h1 {
    width: 60%;
  }
  .cateringBox3 .cateringBox3img h6 {
    font-size: 16px;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img1 {
    display: flex;
    margin: 20px 0 20px 0;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img2 {
    display: flex;
  }
  .cateringBox3 .cateringBox3Button3 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox4 {
    width: 100%;
    display: block;
  }
  .cateringBox4 .cateringBox4list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox4 .cateringBox4ti h1 {
    width: 72%;
  }
  .cateringBox4 .cateringBox4list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 58%;
  }
  .cateringBox4 .cateringBox4img img {
    width: 100%;
  }
  .cateringBox4 .cateringBox4img {
    margin-left: 0%;
}
  .cateringBox4Button4 a button {
    font-size: 12px;
    padding: 6px 14px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox5 {
    width: 100%;
    display: block;
  }
  .cateringBox5 .cateringBox5m {
    margin-left: 0px;
    width: 100%;
  }
  .cateringBox5 .cateringBox5ti p {
    width: 76%;
  }
  .cateringBox5 .cateringBox5ti h1 {
    width: 62%;
}
  .cateringBox5 .cateringBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox5 .cateringBox5list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 53%;
  }
  .cateringBox5 .cateringBox5img  {
    width: 100%;
  }
  .cateringBox5 .cateringBox5img img {
    width: 100%;
    margin: 0 0 0 0;
  }
  .cateringBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
  }

  .cateringBox6 .cateringBox6tb {
    display: block;
  }
  .cateringBox6 .cateringBox6ti h1 {
    width: 65%;
    margin-top: 0px;
  }
  .cateringBox6 .cateringBox6ti p {
    width: 80%;
  }
  .cateringBox6 .cateringBox6ti {
    width: 100%;
  }
  .cateringBox6Button6 a button {
    font-size: 12px;
    padding: 12px 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox6 .cateringBox6img img {
    width: 100%;
    position: relative;
    top: 34px;
    left: 0;
  }

  .cateringBox6Button61 a button {
    font-size: 12px;
    padding: 6px 8px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox7 {
    width: 100%;
    display: block;
  }
  .cateringBox7 .cateringBox7ti h1 {
    width: 58%;
  }
  .cateringBox7 .cateringBox7ti p {
    width: 82%;
  }
  .cateringBox7 .cateringBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox7 .cateringBox7list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 55%;
  }
  .cateringBox7 .cateringBox7img img {
    width: 100%;
    border-radius: 35px;
    margin: 0px 0px 0px 0;
  }
  .cateringBox7Button7 a button {
    font-size: 12px;
    padding: 6px 10px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox8 {
    width: 100%;
    display: block;
  }
  .cateringBox8 .cateringBox8ti h1 {
    width: 85%;
  }
  .cateringBox8 .cateringBox8ti p {
    width: 83%;
  }
  .cateringBox8 .cateringBox8m {
    margin-left: 0;
  }
  .cateringBox8 .cateringBox8img {
    margin: 0 0 0 0;
  }
  .cateringBox8 .cateringBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox8 .cateringBox8list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 60%;
  }
  .cateringBox8 .cateringBox8img img {
    width: 100%;
  }
  .burg img  {
    display: none;
  }
  .cateringBox8 .cateringBox8img p {
    margin: 0px 0px 0 90px;
    width: 55%
  }
  .cateringBox8Button8 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox9 {
    width: 100%;
  }
  .cateringBox9 .cateringBox9m {
    display: block;
  }
  .cateringBox9 .cateringBox9ti p {
    width: 75%;
  }
  .cateringBox9 .cateringBox9list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox9 .cateringBox9list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 60%;
  }
  .cateringBox9 .cateringBox9img img {
    width: 100%;
    padding: 0 0 0 0;
    margin: 30px 0px 0px 0;
  }

  .cateringBox9 .cateringBox9Button9 a button {
    font-size: 14px;
    padding: 0px 0px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox9 .cateringBox9Button9 a {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 12px;
    text-align: right;
    text-decoration-line: underline;
    color: #5131e4 !important;
    cursor: pointer;
}


  .cateringBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height:auto ;
  }
  .cateringBox10 .cateringBox10tb {
    display: block;
  }
  .cateringBox10 .cateringBox10ti h1 {
    width: 85%;
  }
  .cateringBox10 .cateringBox10ti p {
    width: 77%;
  }
  .cateringBox10 .cateringBox10Button10 a button {
    font-size: 12px;
    padding: 12px 66px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox10 .cateringBox10img img {
    width: 100%;
    position: relative;
    left: 0px;
    top: 35px;
  }
}
@media (max-width: 390px) {
  h1 {
    font-weight: 700;
    font-size: 24px;
    color: #0e082b;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
    margin-top: 23px;
  }
  .cateringBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .cateringBox1img {
    width: 100%;
    overflow: hidden;
  }

  .cateringBox1img img {
    width: 200%;
    margin-left: -60px;
    margin-bottom: -104px;
  }
  .cateringBox1 .cateringBox1text h1 {
    padding: 0 0px 0 0;
    width: 70%;
  }
  .cateringBox1 .cateringBox1text {
    padding: 0 0px 0 0;
  }
  .cateringBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
  }
  .cateringBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .cateringBox2 .programss {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .cateringBox2 .img2 {
    padding: 30px 0;
  }
  .cateringBox2 .img2 img {
    width: 100%;
  }
  .cateringBox2 .cateringBox2 {
    width: 100%;
  }
  .cateringBox2 h1 {
    width: 98%;
  }
  .cateringBox2 p {
    width: 100%;
}

  .cateringBox3 {
    width: 100%;
  }

  .cateringBox3 .cateringBox3ti {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .cateringBox3 .cateringBox3img {
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }
  .cateringBox3 .cateringBox3img img {
    border-radius: 20px;
    margin: 0 10px 0 0;
  }
  .cateringBox3 .cateringBox3img h6 {
    font-size: 16px;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img1 {
    display: flex;
    margin: 20px 0 20px 0;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img2 {
    display: flex;
  }
  .cateringBox3 .cateringBox3Button3 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox4 {
    width: 100%;
  }
  .cateringBox4 .cateringBox4list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox4 .cateringBox4ti h1 {
    width: 82%;
  }
  .cateringBox4 .cateringBox4list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 72%;
  }
  .cateringBox4 .cateringBox4img img {
    width: 100%;
  }
  .cateringBox4Button4 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox5 {
    width: 100%;
  }
  .cateringBox5 .cateringBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox5 .cateringBox5list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 68%;
  }
  .cateringBox5 .cateringBox5ti h1 {
    width: 78%;
  }

  .cateringBox5 .cateringBox5ti p {
    width: 80%;
  }
  .cateringBox5 .cateringBox5img img {
    width: 100%;
    
  }
  .cateringBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
  }
  .cateringBox6 .cateringBox6Button6 a button {
    font-size: 12px;
    padding: 12px 36px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox6 .cateringBox6img img {
    width: 100%;
    position: relative;
    top: 34px;
    left: 0;
  }
  .cateringBox6 .cateringBox6ti h1 {
    width: 77%;
  }
 .cateringBox6Button61 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox7 {
    width: 100%;
  }
  .cateringBox7 .cateringBox7ti h1 {
    width: 67%;
  }
  .cateringBox7 .cateringBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox7 .cateringBox7list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 65%;
  }
  .cateringBox7 .cateringBox7img img {
    width: 100%;
  }
  
  .cateringBox7Button7 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox8 {
    width: 100%;
  }
  .cateringBox8 .cateringBox8ti h1 {
    width: 95%;
  }
  .cateringBox8 .cateringBox8ti p {
    width: 90%;
  }
  .cateringBox8 .cateringBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox8 .cateringBox8ti h1 {
    width: 97%;
  }
  .cateringBox8 .cateringBox8list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 70%;
  }
  .cateringBox8 .cateringBox8img img {
    width: 100%;
  }
  .cateringBox8 .cateringBox8img p {
    margin: 0px 0px 0 50px;
    width: 60%;
  }
  .cateringBox8Button8 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox9 {
    width: 100%;
  }
  .cateringBox9 .cateringBox9list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox9 .cateringBox9list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 54%;
  }
  .cateringBox9 .cateringBox9img img {
    width: 100%;
    margin: 30px 0px 0px 0;
  }
  .cateringBox9 .cateringBox9ti p {
    width: 76%;
  }

  .cateringBox9 .cateringBox9Button9 a button {
    font-size: 14px;
    padding: 0px 0px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;

    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
  }
  .cateringBox10 .cateringBox10ti h1 {
    width: 100%;
  }
  .cateringBox10 .cateringBox10ti p {
    width: 86%;
  }
  .cateringBox10 .cateringBox10Button10 a button {
    font-size: 12px;
    padding: 12px 48px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox10 .cateringBox10img img {
    width: 100%;
    top: 35px;
  }
}
@media (max-width: 320px) {
  h1 {
    font-weight: 700;
    font-size: 24px;
    color: #0e082b;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    color: #0e082b;
    margin-top: 23px;
    width: 100%;
  }
  .cateringBox1 {
    padding-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .cateringBox1img {
    width: 100%;
    overflow: hidden;
  }

  .cateringBox1img img {
    width: 200%;
    margin-left: -60px;
    margin-bottom: -96px;
  }
  .cateringBox1 .cateringBox1text h1 {
    padding: 0 0px 0 0;
    width: 80%;
  }
  .cateringBox1 .cateringBox1text {
    padding: 0 0px 0 0;
  }
  .cateringBox2 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
  }
  .cateringBox2 .progrmss {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 30px 0;
  }
  .cateringBox2 .programss {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  .cateringBox2 p {
    width: 100%;
}

  .cateringBox2 .img2 {
    padding: 30px 0;
  }
  .cateringBox2 .img2 img {
    width: 100%;
  }
  .cateringBox2 .cateringBox2 {
    width: 100%;
  }

  .cateringBox3 {
    width: 100%;
  }

  .cateringBox3 .cateringBox3ti {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .cateringBox3 .cateringBox3ti h1 {
    width: 88%;
  }
  .cateringBox3 .cateringBox3img {
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }
  .cateringBox3 .cateringBox3img img {
    border-radius: 20px;
    margin: 0 10px 0 0;
  }
  .cateringBox3 .cateringBox3img h6 {
    font-size: 16px;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img1 {
    display: flex;
    margin: 20px 0 20px 0;
  }
  .cateringBox3 .cateringBox3img .cateringBox3img2 {
    display: flex;
  }
  .cateringBox3 .cateringBox3Button3 a button {
    font-size: 12px;
    padding: 6px 10px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox4 {
    width: 100%;
  }
  .cateringBox4 .cateringBox4list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox4 .cateringBox4ti h1 {
    width: 98%;
  }
  .cateringBox4 .cateringBox4ti p {
    width: 98%;
}
  .cateringBox4 .cateringBox4list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 88%;
  }
  .cateringBox4 .cateringBox4img img {
    width: 100%;
  }
  .cateringBox4Button4 a button {
    font-size: 14px;
    padding: 6px 16px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox5 {
    width: 100%;
  }
  .cateringBox5 .cateringBox5list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox5 .cateringBox5ti h1 {
    width: 90%;
  }
  .cateringBox5 .cateringBox5ti p {
    width: 100%;
  }

  .cateringBox5 .cateringBox5list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 80%;
  }
  .cateringBox5 .cateringBox5img img {
    width: 100%;
  }
  .cateringBox6 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
  }
  .cateringBox6 .cateringBox6ti p {
    width: 98%;
}
  .cateringBox6 .cateringBox6Button6 a button {
    font-size: 12px;
    padding: 12px 24px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox6 .cateringBox6img img {
    width: 100%;
    position: relative;
    left: 0;
    top: 34px;
  }
  .cateringBox6 .cateringBox6ti h1 {
    width: 90%;
  }

  .cateringBox6Button61 a button {
    font-size: 12px;
    padding: 6px 8px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox7 {
    width: 100%;
  }
  .cateringBox7 .cateringBox7list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox7 .cateringBox7ti h1 {
    width: 80%;
  }
  .cateringBox7 .cateringBox7ti p {
    width: 90%;
}

  .cateringBox7 .cateringBox7list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 80%;
  }
  .cateringBox7 .cateringBox7img img {
    width: 100%;
  }
  .cateringBox7Button7 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox8 {
    width: 100%;
  }
  .cateringBox8 .cateringBox8list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox8 .cateringBox8ti h1 {
    width: 100%;
  }
  .cateringBox8 .cateringBox8ti p {
    width: 102%;
  }

  .cateringBox8 .cateringBox8list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 90%;
  }
  .cateringBox8 .cateringBox8img img {
    width: 100%;
  }
  .cateringBox8 .cateringBox8img p {
    margin: 0px 0px 0 50px;
    width: 66%;
  }
   .cateringBox8Button8 a button {
    font-size: 12px;
    padding: 6px 12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    box-shadow: 0 0 0 2px #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox9 {
    width: 100%;
  }
  .cateringBox9 .cateringBox9list {
    padding: 0 20px;
    overflow: hidden;
    height: auto;
  }
  .cateringBox9 .cateringBox9list ul li {
    font-size: 12px;
    padding: 10px 0px 10px 0px;
    width: 67%;
  }
  .cateringBox9 .cateringBox9img img {
    width: 100%;
    margin: 20px 0px 0px 0;
  }
  .cateringBox9 .cateringBox9ti p {
    width: 92%;
  }
  .cateringBox9 .cateringBox9Button9 a {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 12px;
    text-align: right;
    text-decoration-line: underline;
    color: #5131e4 !important;
    cursor: pointer;
    margin-left: 20px;
}

  .cateringBox9Button9 a button {
    font-size: 14px;
    padding: 0px 0px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #ffffff;
    color: #5231e4;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox10 {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 30px 30px;
    display: block;
    height: auto;
  }
  .cateringBox10 .cateringBox10Button10 a button {
    font-size: 12px;
    padding: 12px 36px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    background: #5231e4;
    color: #ffffff;
    border-radius: 10px;
    border-style: none;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .cateringBox10 .cateringBox10ti h1 {
    width: 107%;
  }
  .cateringBox10 .cateringBox10ti p {
    width: 100%;
  }

  .cateringBox10 .cateringBox10img img {
    width: 100%;
    left: 0;
    top: 35px;
  }
}
